import { FormControl, InputLabel, MenuItem, Select, SelectProps, SxProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import scssVariables from '../../../../Config.module.scss';
import styles from './Inputs.module.scss';
import type { SelectInputOption } from './SelectInput';

interface IMuiDropdownProps<T> {
    className?: string | undefined;
    disabled?: boolean;
    placeholder?: string;
    value?: T;
    options: SelectInputOption<T>[];
    handleOptionChange: (value: T) => void;
    customStyles?: SxProps;
    label?: string | JSX.Element;
    testId?: string;
}

const StyledMuiSelect = styled(Select)<SelectProps>(() => ({
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: `${scssVariables.inputActiveState} !important`,
    },
}));

// eslint-disable-next-line @typescript-eslint/comma-dangle
const Dropdown = <T,>(props: IMuiDropdownProps<T>): JSX.Element => {
    // Material UI does not allow non-string values, this component bridges that gap and allows it.

    const handleOptionChange = (label: string): void => {
        const option = props.options.find((x) => x.label === label);

        if (option) {
            props.handleOptionChange(option.value);
        }
    };

    return (
        <div data-testid={props.testId} className={styles.input_dropdown_flex}>
            {props.label && <p className={styles.input_dropdown_label}>{props.label}</p>}
            <FormControl className={props.className} fullWidth sx={{ m: 1, margin: 0 }} size="small">
                {props.placeholder && <InputLabel shrink={false}>{props.placeholder}</InputLabel>}
                <StyledMuiSelect
                    disabled={props.disabled}
                    className={`${styles.input_base_dropdown}`}
                    value={props.options.find((x) => x.value === props.value)?.label ?? ''}
                    onChange={(event) => handleOptionChange(event.target.value as string)}
                    sx={{
                        ...{
                            backgroundColor: scssVariables.plainWhite,
                            fontFamily: scssVariables.fontFamily,
                        },
                        ...props.customStyles,
                    }}
                    MenuProps={{
                        PaperProps: {
                            style: {
                                maxHeight: 400,
                            },
                        },
                    }}
                >
                    {props.options.map((option) => (
                        <MenuItem
                            key={`${option.label}`}
                            value={`${option.label}`}
                            sx={[
                                { fontFamily: scssVariables.fontFamily },
                                { '&.Mui-selected': { backgroundColor: scssVariables.stonexSkyBlue } },
                                { '&:hover': { backgroundColor: scssVariables.deactivatedBlockColor } },
                            ]}
                        >
                            {option.label}
                        </MenuItem>
                    ))}
                </StyledMuiSelect>
            </FormControl>
        </div>
    );
};

export default Dropdown;
