import * as d3 from 'd3';
import { useEffect, useRef } from 'react';
import scssVariables from '../../../Config.module.scss';

interface ISparklineProps {
    data: number[];
}
interface ILineData {
    x: number;
    y: number;
}

const Sparkline: React.FC<ISparklineProps> = (props: ISparklineProps) => {
    const svgReference = useRef<SVGSVGElement>(null);
    const viewBoxMinimumXValue = 0;
    const viewBoxMinimumYValue = 0;
    const sparklineMaximumScaleValue = 75;
    const viewBoxMaximumScaleValue = 100;
    const rangeBuffer = (viewBoxMaximumScaleValue - viewBoxMinimumXValue - sparklineMaximumScaleValue) / 2;

    useEffect(() => {
        if (props.data) {
            const formattedData: ILineData[] = props.data?.map((element: number, index: number) => ({ x: index, y: element }));
            const xScale = d3
                .scaleLinear()
                .domain([0, formattedData.length - 1])
                .range([rangeBuffer, sparklineMaximumScaleValue + rangeBuffer]);
            const yScale = d3
                .scaleLinear()
                .domain([Math.min(...props.data), Math.max(...props.data)])
                .range([sparklineMaximumScaleValue + rangeBuffer, rangeBuffer]);

            const line = d3
                .line()
                .x((d: ILineData) => xScale(d.x))
                .y((d: ILineData) => yScale(d.y))
                .curve(d3.curveLinear);

            d3.select(svgReference.current).append('path').attr('d', line(formattedData));
        }
    }, [props.data, svgReference.current]);

    return (
        <svg
            ref={svgReference}
            fill="none"
            width="100%"
            height="100%"
            viewBox={`${viewBoxMinimumXValue} ${viewBoxMinimumYValue} ${viewBoxMaximumScaleValue} ${viewBoxMaximumScaleValue}`}
            stroke={scssVariables.stonexSparklinePrimary}
            strokeWidth={5}
        />
    );
};

export default Sparkline;
