import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import React, { useEffect, useMemo, useState } from 'react';
import { DemeterRegion, DemeterTableDefinitionType } from '../../../Generated/Raven-Demeter';
import useTableDefinitionsApi from '../../Apis/Hooks/useTableDefinitionsApiHook';
import useLanguage from '../../Services/Language/useLanguageHook';
import Switch from '../Form/Buttons/Switch';
import { IRegionCommoditySelection } from '../Navigation/Hooks/useRegionCommodityNavigationHook';
import AggregateRegions from './AggregateRegions';
import styles from './AggregateRegionSelector.module.scss';

export interface IAggregateRegionSelectorProps {
    title: string;
    groupByParentRegions?: boolean;
    tableDefinitionType: DemeterTableDefinitionType;
    regionCommoditySelection: IRegionCommoditySelection;
    handleRegionSelections: (regions: DemeterRegion[]) => void;
}

export type RegionOptionsType = { region: DemeterRegion; displayName: string; isSelected: boolean };
export type ParentRegionOptionsType = {
    parentRegion: DemeterRegion;
    isParentSelected: boolean;
    isDropdownOpen: boolean;
    regionOptions: RegionOptionsType[];
};

const AggregateRegionSelector: React.FC<IAggregateRegionSelectorProps> = (props: IAggregateRegionSelectorProps) => {
    const [translations, translate] = useLanguage();
    const tableDefinitions = useTableDefinitionsApi(props.tableDefinitionType);
    const filteredTableDefinitions = useMemo(
        () => tableDefinitions?.filter((tableDefinition) => tableDefinition.region !== props.regionCommoditySelection.region),
        [tableDefinitions],
    );
    const [regionOptions, setRegionOptions] = useState<RegionOptionsType[]>([]);
    const [parentRegionOptions, setParentRegionOptions] = useState<ParentRegionOptionsType[]>([]);

    const handleParentRegionsSelection = (parentRegionOption: ParentRegionOptionsType) => {
        parentRegionOption.isParentSelected = !parentRegionOption.isParentSelected;
        parentRegionOption.regionOptions.forEach((x) => {
            x.isSelected = parentRegionOption.isParentSelected;
        });
        setParentRegionOptions([...parentRegionOptions]);
        props.handleRegionSelections(
            parentRegionOptions
                .flatMap((x) => x.regionOptions)
                .filter((x) => x.isSelected)
                .map((x) => x.region),
        );
    };

    const handleRegionsSelection = (regionOption: RegionOptionsType, parentRegionOption?: ParentRegionOptionsType) => {
        if (props.groupByParentRegions) {
            regionOption.isSelected = !regionOption.isSelected;
            if (parentRegionOption) {
                parentRegionOption.isParentSelected = parentRegionOption.regionOptions.every((x) => x.isSelected);
            }
            setParentRegionOptions([...parentRegionOptions]);
            props.handleRegionSelections(
                parentRegionOptions
                    .flatMap((x) => x.regionOptions)
                    .filter((x) => x.isSelected)
                    .map((x) => x.region),
            );
        } else {
            regionOption.isSelected = !regionOption.isSelected;
            setRegionOptions([...regionOptions]);
            props.handleRegionSelections(regionOptions.filter((x) => x.isSelected).map((x) => x.region));
        }
    };

    useEffect(() => {
        const newRegionOptions = filteredTableDefinitions?.filter((filteredTableDefinition) =>
            filteredTableDefinition.demeterTableDefinitionGroups.find(
                (tableDefinition) =>
                    (tableDefinition.commodity === null &&
                        tableDefinition.demeterTableDefinitions.find(
                            (demeterTableDefinition) => demeterTableDefinition.commodity === props.regionCommoditySelection.commodity,
                        )) ||
                    tableDefinition.commodity === props.regionCommoditySelection.commodity,
            ),
        );

        if (props.groupByParentRegions) {
            if (newRegionOptions && newRegionOptions.length > 0) {
                const parentRegions = Array.from(new Set(newRegionOptions.map((x) => x.parentRegion)));
                const newParentRegionOptions = parentRegions.map((parentRegion) => {
                    const filterParentRegionOptions = newRegionOptions.filter((x) => x.parentRegion === parentRegion);
                    const newParentRegionOption = {
                        parentRegion: parentRegion as DemeterRegion,
                        isParentSelected: true,
                        isDropdownOpen: false,
                        regionOptions: filterParentRegionOptions.map((x) => ({
                            region: x.region as DemeterRegion,
                            displayName: translate(x.displayName),
                            isSelected: true,
                        })),
                    };
                    return newParentRegionOption;
                });
                setParentRegionOptions(newParentRegionOptions);
                props.handleRegionSelections(newRegionOptions.map((x) => x.region) as DemeterRegion[]);
            }
        } else {
            setRegionOptions(
                newRegionOptions && newRegionOptions.length > 0
                    ? newRegionOptions.map((x) => ({
                          region: x.region!,
                          displayName: translate(x.displayName!),
                          isSelected: true,
                      }))
                    : [],
            );
            props.handleRegionSelections(newRegionOptions?.map((x) => x.region) as DemeterRegion[]);
        }
    }, [props.regionCommoditySelection, tableDefinitions]);

    return (
        <div className={styles.aggregate_region_selector_parent}>
            {props.groupByParentRegions ? (
                parentRegionOptions.map((parentRegionOption) => (
                    <div>
                        <div className={styles.aggregate_region_selector_parent_header}>
                            <KeyboardArrowDownIcon
                                className={
                                    parentRegionOption.isDropdownOpen
                                        ? ` ${styles.aggregate_region_selector_dropdown_icon}`
                                        : `${styles.aggregate_region_selector_active_dropdown_icon}`
                                }
                                onClick={() => {
                                    parentRegionOption.isDropdownOpen = !parentRegionOption.isDropdownOpen;
                                    setParentRegionOptions([...parentRegionOptions]);
                                }}
                            />

                            <div className={styles.aggregate_region_selector_parent_switch_label}>
                                <p className={styles.aggregate_region_selector_sub_parent_switch_label}>
                                    {translations.parentRegion[parentRegionOption.parentRegion]}
                                    <Switch
                                        checked={parentRegionOption.isParentSelected}
                                        handleChange={() => {
                                            handleParentRegionsSelection(parentRegionOption);
                                        }}
                                    />
                                </p>
                            </div>
                        </div>
                        {parentRegionOption.isDropdownOpen && (
                            <AggregateRegions
                                regionOptions={parentRegionOption.regionOptions}
                                handleRegionsSelection={(region) => handleRegionsSelection(region, parentRegionOption)}
                            />
                        )}
                    </div>
                ))
            ) : (
                <>
                    <div>
                        <h3 className={styles.aggregate_region_selector_header}>{props.title}</h3>
                    </div>
                    <AggregateRegions regionOptions={regionOptions} handleRegionsSelection={(region) => handleRegionsSelection(region)} />
                </>
            )}
        </div>
    );
};

export default AggregateRegionSelector;
