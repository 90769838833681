import HighchartsReact from 'highcharts-react-official';
import HighStock from 'highcharts/highstock';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { SeasonalGraphOptions } from '../../../../Components/Charts/BaseCharts/GeneralChartDefinitions';
import ChartWrapper from '../../../../Components/ChartWrapper/ChartWrapper';
import useContainerDimensions from '../../../../Components/UseDimensions/useContainerDimensionsHook';
import { monthNamesArray } from '../../../../Core/Common/MonthNames';
import formattingService from '../../../../Core/Formatting/FormattingService';
import applicationConstants from '../../../../Core/Utility/ApplicationConstants';
import { getChartHeight } from '../../../../Core/Utility/ChartUtils';
import { buildYearsArray, getLastYears } from '../../../../Core/Utility/HelperFunctions';
import { DemeterFeatureType, DemeterRegion, DemeterTableDefinitionType, UnitOfMeasure } from '../../../../Generated/Raven-Demeter/api';
import useSeasonalApi from '../../../Apis/Hooks/useSeasonalApiHook';
import featureFlagsService from '../../../Services/FeatureFlags/FeatureFlagsService';
import useFeatureFlag from '../../../Services/FeatureFlags/useFeatureFlagHook';
import useLanguage from '../../../Services/Language/useLanguageHook';
import { IRegionCommoditySelection } from '../../Navigation/Hooks/useRegionCommodityNavigationHook';
import useTableDefinition from '../../Navigation/Hooks/useTableDefinitionHook';
import { chartColors } from '../ChartDefinitions';
import chartService from '../ChartService';
import useChartReflow from '../Hooks/useChartReflowHook';

export interface ISeasonalChartProps {
    title: string;
    tableDefinitionType: DemeterTableDefinitionType;
    regionCommoditySelection: IRegionCommoditySelection;
    aggregateRegions?: DemeterRegion[];
    unitOfMeasure?: UnitOfMeasure;
    testId?: string;
}

const defaultNumberOfYears = 3;
const possibleNumberOfForecastedYears = 1;
const maximumNumberOfYears = 10;
const startYear = 2002;
const lastSetOfYears = getLastYears(defaultNumberOfYears, possibleNumberOfForecastedYears).map((x) => +x);

const SeasonalChart: React.FC<ISeasonalChartProps> = (props: ISeasonalChartProps) => {
    // Data/navigation hooks.
    const [tableDefinitionRegion, tableDefinitionCommodity] = useTableDefinition(props.tableDefinitionType, props.regionCommoditySelection);
    const [translations, translate] = useLanguage();
    const defaultSelections = lastSetOfYears.map((year) => ({
        year,
        isForecast: false,
    }));
    const [yearsSelected, setYearsSelected] = useState<{ year: number; isForecast: boolean }[]>(defaultSelections);
    const yearsToQuery = useMemo(() => {
        const nonDistinctYearsToQuery = [...yearsSelected.map((x) => x.year), ...lastSetOfYears];
        // Typescript is not happy, but this solution seems to be the most popular one:
        // https://plainenglish.io/blog/how-to-get-distinct-values-from-an-array-of-objects-in-javascript
        // @ts-ignore
        return [...new Set(nonDistinctYearsToQuery)]; // Set returns DISTINCT years.
    }, [yearsSelected]);

    const seasonalData = useSeasonalApi(
        props.tableDefinitionType,
        props.regionCommoditySelection,
        props.aggregateRegions,
        props.unitOfMeasure,
        undefined,
        yearsToQuery as number[],
        true,
    );

    const seasonalRows = useMemo(() => seasonalData?.rows?.map((x) => x.monthlyValues).flat(), [seasonalData]);
    const monthNames = monthNamesArray.map((month) => formattingService.toMonthFromEnumeration(month));
    const yearOptions = useMemo<{ label: string; value: { year: number; isForecast: boolean } }[]>(() => {
        const initialYears = buildYearsArray(startYear);
        if (!seasonalRows) {
            return initialYears.map((x) => ({ ...x, label: x.name }));
        }

        const greatestActualYear = Math.max(...seasonalRows.filter((x) => x?.isActualValue).map((x) => x?.year ?? 0));

        // The typescript 'unknown' is required to for the compiler to allow conversion of a set to an array. Other option
        // is to use ts-ignore or change the compiler settings.
        const currentForcastedYears = [...(new Set(seasonalRows.filter((x) => !x?.isActualValue).map((x) => x?.year)) as unknown as number[])];

        return [
            ...currentForcastedYears.map((x) => ({ label: `${x.toString()}-${translations.words.forecast}`, value: { year: x, isForecast: true } })).reverse(),
            ...initialYears.filter((x) => +x.name <= greatestActualYear).map((x) => ({ ...x, label: x.name, value: { year: +x.name, isForecast: false } })),
        ];
    }, [seasonalRows]);

    useEffect(() => {
        setYearsSelected(defaultSelections);
    }, [tableDefinitionCommodity, props.regionCommoditySelection, props.aggregateRegions]);

    const isExportDataOn = useFeatureFlag(featureFlagsService.getFeatureType(DemeterFeatureType.DownloadsGeneral, props.tableDefinitionType));

    // Display hooks.
    const title = useMemo(
        () =>
            `${translate(tableDefinitionRegion?.displayName!)} ${translate(tableDefinitionCommodity?.displayName!)} ${props.title} ${
                translations.charts.text.seasonal
            }`,
        [props.title, tableDefinitionRegion, tableDefinitionCommodity],
    );

    // Chart hooks.
    const chartReference = useRef<any>();
    const chartWrapperContainerReference = useRef<any>();
    useChartReflow(chartReference);
    const dimensions = useContainerDimensions(chartWrapperContainerReference);
    const [isChartPopped, setIsChartPopped] = useState<boolean>(false);
    const [highchartGraphOptions, setHighchartGraphOptions] = useState<SeasonalGraphOptions>(() => ({
        title: {
            text: 'Zone with dash style',
            style: {
                fontSize: '30px',
                color: 'white',
            },
        },
        xAxis: {
            categories: monthNames,
        },
        yAxis: [
            {
                title: {
                    text: '',
                    style: {
                        color: '#B0B3B3',
                    },
                },
                labels: {
                    format: '',
                },
            },
            {
                title: {
                    text: '',
                },
                opposite: true,
            },
        ],
        series: [],
        exporting: {
            sourceHeight: applicationConstants.ChartExportOptionsDimensions.Height,
            sourceWidth: applicationConstants.ChartExportOptionsDimensions.Width,
            scale: applicationConstants.ChartExportOptionsDimensions.Scale,
            buttons: {
                contextButton: {
                    enabled: false,
                },
            },
        },
        credits: { enabled: false },
    }));

    useEffect(() => {
        if (!seasonalData?.rows || !seasonalRows) {
            return;
        }

        const actualYearsArray = yearsSelected.filter((x) => !x.isForecast).map((y) => y.year);
        const forecastedYearsArray = yearsSelected.filter((x) => x.isForecast).map((y) => y.year);

        // "Data" part of the series without forecast.
        const actualDataSeries = seasonalData.rows
            .filter((x) => x.monthlyValues!.length! > 0 && x.monthlyValues![0].isActualValue)
            .filter((x) => actualYearsArray.includes(x.year!))
            .map((value, valueIndex) => ({
                id: value.year,
                name: value.year!.toString(),
                downloadData: value.monthlyValues!.map((x, downloadDataIndex) => ({
                    value: x.isActualValue
                        ? formattingService.toNumberStringWithTrailingZeros(
                              x.value!,
                              tableDefinitionCommodity?.displayDecimalPlacesMinimum ?? 0,
                              tableDefinitionCommodity?.displayDecimalPlacesMaximum ?? 0,
                          )
                        : '-',
                    date: monthNames[downloadDataIndex],
                    rawDate: new Date(x.year, x.month),
                    isActualValue: true,
                })),
                color: chartColors.lineChartColors[valueIndex],
                // Check for the last index of an 'isActualValue' and make the 'actualValue' go to that point regardless
                // of '!isActualValue' points between them.
                // eslint-disable-next-line no-confusing-arrow
                data: value.monthlyValues!.map((dataPoint, dataPointIndex) =>
                    dataPoint.isActualValue || value.monthlyValues!.slice(dataPointIndex, value.monthlyValues!.length).some((x) => x.isActualValue)
                        ? dataPoint.value
                        : null,
                ),
            }));

        // "Data" part of the series, forecast only.
        const forecastDataSeries = seasonalData.rows
            .filter((x) => !x.monthlyValues![x.monthlyValues!.length - 1]?.isActualValue) // Check if the last value in the array is forecasted.
            .filter((x) => forecastedYearsArray.includes(x.monthlyValues![0].year))
            .map((value, index) => {
                const overlappingActualDataSeriesItem = actualDataSeries.find((item) => +item.name === value.year);
                return {
                    id: `${value?.year}-Forecast`,
                    name: `${value?.year}-${translations.words.forecast}`,
                    dashStyle: 'shortDash',
                    downloadData: value.monthlyValues?.map((x, downloadDataIndex) => ({
                        value: !x.isActualValue
                            ? formattingService.toNumberStringWithTrailingZeros(
                                  x.value!,
                                  tableDefinitionCommodity?.displayDecimalPlacesMinimum ?? 0,
                                  tableDefinitionCommodity?.displayDecimalPlacesMaximum ?? 0,
                              )
                            : '-',
                        date: monthNames[downloadDataIndex],
                        rawDate: new Date(x.year, x.month),
                        isActualValue: x.isActualValue,
                    })),
                    marker: {
                        enabled: false,
                    },
                    color: overlappingActualDataSeriesItem?.color ?? chartColors.lineChartColors[index + actualDataSeries.length],
                    data: value.monthlyValues?.map((dataPoint, dataPointIndex) => {
                        // If it's not actual value OR if it's the last actual value (to stretch dotted lines back one value)
                        if (
                            (dataPointIndex < actualDataSeries[actualDataSeries.length - 1]?.data.length && !dataPoint.isActualValue) ||
                            (actualDataSeries.length === 0 && !dataPoint.isActualValue) ||
                            (dataPoint.isActualValue &&
                                value.monthlyValues!.slice(dataPointIndex + 1, value.monthlyValues!.length - 1).every((x) => !x.isActualValue))
                        ) {
                            return dataPoint?.value;
                        }
                        return null;
                    }),
                };
            });

        const seriesArray = [...actualDataSeries, ...forecastDataSeries].map((x, index) => ({ ...x, legendIndex: index }));

        const newOptions: any = {
            ...highchartGraphOptions,
            ...{ series: seriesArray },
            ...{
                yAxis: [
                    {
                        labels: {
                            align: 'right',
                        },
                        title: {
                            text: chartService.getCurrencyAndUnitOfMeasureText(seasonalData.unitOfMeasure, seasonalData.currency),
                        },
                    },
                ],
                tooltip: {
                    // Need to use non-arrow function to change context of 'this'.
                    // eslint-disable-next-line object-shorthand, func-names
                    formatter: function () {
                        return formattingService.getToolTip(
                            tableDefinitionCommodity?.displayDecimalPlacesMinimum ?? 0,
                            tableDefinitionCommodity?.displayDecimalPlacesMaximum ?? 0,
                            this,
                        );
                    },
                },
            },
            downloadData: seriesArray.map((x) => ({
                label: x?.name,
                data: x?.downloadData,
            })),
        };

        // This removes invalid years (forecasted years that aren't selected, but needed in the original search query).
        const validActualYearsSelected = yearsSelected.filter((yearSelected) =>
            yearOptions.some((y) => y.value.year === yearSelected.year && y.value.isForecast === yearSelected.isForecast),
        );

        const allValidYearsExistsInYearsSelectedArray = validActualYearsSelected
            .map((x) => x.year)
            .every((validYear) => yearsSelected.find((selectedYear) => validYear === selectedYear.year));

        const allYearsSelectedExistsInValidYearsArray = yearsSelected
            .map((x) => x.year)
            .every((selectedYear) => yearsSelected.find((validYear) => selectedYear === validYear.year));

        const isSameArray =
            validActualYearsSelected.length === yearsSelected.length && allValidYearsExistsInYearsSelectedArray && allYearsSelectedExistsInValidYearsArray;

        if (validActualYearsSelected.length > 0 && validActualYearsSelected.length < yearsSelected.length && !isSameArray) {
            // Need to keep the forecasted years on the inside or it breaks the 'valid years' test.
            const validForecastedYears = yearOptions.filter((x) => x.value.isForecast).map((y) => y.value);
            setYearsSelected([...validActualYearsSelected, ...validForecastedYears]);
        }

        setHighchartGraphOptions(newOptions);
    }, [seasonalRows, yearsSelected]);

    const handleYearSelect = (years: any[]) => {
        setYearsSelected(years);
    };

    const handleYearRemove = async (yearArgument: string) => {
        if (yearArgument.length > 4) {
            const newSelectedYears = [...yearsSelected.filter((x) => !x.isForecast || x.year.toString() !== yearArgument.slice(0, 4))];
            setYearsSelected(newSelectedYears);
        } else {
            const newSelectedYears = [...yearsSelected.filter((x) => x.year.toString() !== yearArgument || x.isForecast)];
            setYearsSelected(newSelectedYears);
        }
    };

    return (
        <div data-testid={props.testId} ref={chartWrapperContainerReference}>
            {dimensions.width > 0 && (
                <ChartWrapper
                    header={title}
                    chartRef={chartReference}
                    hasActualRollingButons={false}
                    selectedValues={[...yearsSelected]}
                    onRemove={(event: React.FormEvent<HTMLInputElement>) => handleYearRemove(event.currentTarget.value)}
                    onSelect={handleYearSelect}
                    options={['Years', [...yearOptions]]}
                    disablePopoutButton={false}
                    isLimit={yearsSelected.length >= maximumNumberOfYears}
                    limitMessage={translations.charts.limitMessage.yearLimitMessage}
                    handleChartPopped={(value: boolean) => {
                        setIsChartPopped(value);
                    }}
                    dataSourceTag={`${seasonalData?.dataSourceTag}\u00A0 seasonalChartNote`}
                    dropdownPlaceholder={translations.dropdown.seasonalChart}
                    isCsvDownloadAvailable={isExportDataOn}
                >
                    <HighchartsReact
                        ref={chartReference}
                        highcharts={HighStock}
                        options={highchartGraphOptions}
                        containerProps={{
                            style: { height: getChartHeight(isChartPopped, dimensions, true) },
                        }}
                    />
                </ChartWrapper>
            )}
        </div>
    );
};

export default SeasonalChart;
