/* eslint-disable react/jsx-no-useless-fragment */
import React, { useMemo } from 'react';
import formattingService from '../../../../Core/Formatting/FormattingService';
import { CommoditySpotPriceSummaryModel, DemeterRegion } from '../../../../Generated/Raven-Demeter/api';
import useSpotPricesSummaryApi from '../../../Apis/Hooks/useSpotPricesSummaryApiHook';
import ComponentHeader from '../../../Components/Headers/ComponentHeader';
import useLanguage from '../../../Services/Language/useLanguageHook';
import styleService from '../../../Services/Styles/StyleService';
import PricesSummaryTableWrapper from '../PricesSummaryTableWrapper';
import styles from '../PriceSummaries.module.scss';

const CmeSpotSummarySmall: React.FC = () => {
    const summaryData = useSpotPricesSummaryApi(DemeterRegion.UnitedStates);
    const [translations] = useLanguage();
    const columnHeaders = useMemo(
        () => [translations.words.product, translations.words.price, translations.words.change, translations.fields.percentChange],
        [translations],
    );
    const title = useMemo(
        () =>
            `${translations.dashboard.headings.cmeSummaryTableHeading} ${translations.words.summary}  ${formattingService.toShortDayMonthYear(
                summaryData?.asOfDate,
            )}` ?? '',
        [summaryData, translations],
    );
    const subtitle = useMemo(
        () => (summaryData?.rows ? `${summaryData.rows[0].currency} / ${translations.unitOfMeasure[summaryData.rows[0].unitOfMeasure!]}` : ''),
        [summaryData, translations],
    );
    return (
        <>
            <ComponentHeader title={title} subtitle={subtitle} />
            <PricesSummaryTableWrapper
                className={styles.price_summary_table_cme_spot_small}
                loading={!summaryData?.rows || summaryData.rows.length === 0}
                columnHeaders={columnHeaders}
            >
                <>
                    {summaryData?.rows?.map((row: CommoditySpotPriceSummaryModel) => {
                        const { currentPrice, commodity } = row;
                        const changeClass = styleService.getPositiveOrNegativeClass(currentPrice.netChange!);
                        return (
                            <React.Fragment key={row.commodity}>
                                <p>{translations.commodity[commodity]}</p>
                                <p>{formattingService.toPriceString(currentPrice.value)}</p>
                                <p className={changeClass}>{formattingService.toPriceString(currentPrice.netChange)}</p>
                                <p className={changeClass}>{formattingService.toPercent(currentPrice.percentChange)}</p>
                            </React.Fragment>
                        );
                    })}
                </>
            </PricesSummaryTableWrapper>
        </>
    );
};

export default CmeSpotSummarySmall;
