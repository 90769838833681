import { DemeterFilterTimeSpan } from '../../Generated/Raven-Demeter';

const currentYear = new Date().getFullYear();

// Get every year starting from the yearFrom value
const buildYearsArray = (yearFrom: number) => {
    const yearObjectArray: any[] = [];
    for (let i = yearFrom; i <= currentYear; i += 1) {
        yearObjectArray.push({ name: i.toString(), value: i.toString() });
    }
    return yearObjectArray.reverse();
};

const getLastYears = (goBackThisManyYears: number, numberOfAdditionalForecastYears: number = 0) => {
    const yearsArray: any[] = [];
    const yearsAgo = currentYear - goBackThisManyYears;
    for (let i = yearsAgo; i <= currentYear + numberOfAdditionalForecastYears; i += 1) {
        yearsArray.push(i.toString());
    }
    return yearsArray;
};

const getTimespansBasedOnLastAvailableDate = (timeSpanArray: DemeterFilterTimeSpan[], lastAvailableDate: Date) => {
    const newTimeSpanArray: DemeterFilterTimeSpan[] = [];
    const now = new Date();
    const DemeterFilterTimeSpanToTimestampReferenceObject: { [key in keyof typeof DemeterFilterTimeSpan]: number } = {
        [DemeterFilterTimeSpan.OneDay]: now.setDate(now.getDate() - 1),
        [DemeterFilterTimeSpan.ThirtyDays]: now.setDate(now.getDate() - 30),
        [DemeterFilterTimeSpan.OneMonth]: now.setMonth(now.getMonth() - 1),
        [DemeterFilterTimeSpan.ThreeMonths]: now.setMonth(now.getMonth() - 3),
        [DemeterFilterTimeSpan.SixMonths]: now.setMonth(now.getMonth() - 6),
        [DemeterFilterTimeSpan.NineMonths]: now.setMonth(now.getMonth() - 9),
        [DemeterFilterTimeSpan.OneYear]: now.setFullYear(now.getFullYear() - 1),
        [DemeterFilterTimeSpan.TwoYears]: now.setFullYear(now.getFullYear() - 2),
        [DemeterFilterTimeSpan.ThreeYears]: now.setFullYear(now.getFullYear() - 3),
        [DemeterFilterTimeSpan.FourYears]: now.setFullYear(now.getFullYear() - 4),
        [DemeterFilterTimeSpan.FiveYears]: now.setFullYear(now.getFullYear() - 5),
        [DemeterFilterTimeSpan.TenYears]: now.setFullYear(now.getFullYear() - 10),
        [DemeterFilterTimeSpan.FifteenYears]: now.setFullYear(now.getFullYear() - 15),
        [DemeterFilterTimeSpan.TwentyYears]: now.setFullYear(now.getFullYear() - 20),
        // no date expectations on these two types when they exists
        [DemeterFilterTimeSpan.All]: 0,
        [DemeterFilterTimeSpan.LastTwoActuals]: 0,
        [DemeterFilterTimeSpan.YearToDate]: 0,
    };

    let lastToAdd = false;
    timeSpanArray.forEach((element) => {
        if (DemeterFilterTimeSpanToTimestampReferenceObject[element] > lastAvailableDate.getTime() && !lastToAdd) {
            newTimeSpanArray.push(element);
        }
        if (DemeterFilterTimeSpanToTimestampReferenceObject[element] < lastAvailableDate.getTime() && !lastToAdd) {
            newTimeSpanArray.push(element);
            lastToAdd = true;
        }
    });
    return newTimeSpanArray;
};

export { getLastYears, buildYearsArray, getTimespansBasedOnLastAvailableDate };
