import Dropdown from '../../../../Components/Form/Inputs/Dropdown';
import useLanguage from '../../../../Services/Language/useLanguageHook';
import styles from '../ValueMatrixCalculator.module.scss';
import { timeSpanOptions, ValueMatrixTableRequest } from '../ValueMatrixDefinitions';

interface IValueMatrixYearsToLookBackDropdownProps {
    valueMatrixInputs: ValueMatrixTableRequest;
    setValueMatrixInputs: (value: ValueMatrixTableRequest) => void;
}

const ValueMatrixYearsToLookBackDropdown: React.FC<IValueMatrixYearsToLookBackDropdownProps> = (props: IValueMatrixYearsToLookBackDropdownProps) => {
    const [translations] = useLanguage();

    return (
        <div className={styles.value_matrix_dropdown}>
            <Dropdown
                testId="ValueMatrixYearsToLookBackDropdown"
                value={props.valueMatrixInputs.timeSpan as number}
                options={timeSpanOptions}
                handleOptionChange={(value: number) => {
                    props.setValueMatrixInputs({
                        ...props.valueMatrixInputs,
                        timeSpan: +value,
                        updateTriggeredBy: 'generalFieldSelection',
                    });
                }}
                label={translations.calculators.valueMatrix.fields.yearsToLookBack}
            />
        </div>
    );
};

export default ValueMatrixYearsToLookBackDropdown;
