/* eslint-disable react/no-array-index-key */
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import formattingService from '../../../../Core/Formatting/FormattingService';
import { DemeterRegion, ExchangeType } from '../../../../Generated/Raven-Demeter';
import NavigationRoutes from '../../../../Layouts/NavigationRoutes';
import LinkButton, { LinkButtonType } from '../../../Components/Form/Buttons/LinkButton';
import SwitchWithLabel from '../../../Components/Form/Inputs/SwitchWithLabel';
import TextInput from '../../../Components/Form/Inputs/TextInput';
import ComponentSubHeader from '../../../Components/Headers/ComponentSubHeader';
import ConfirmModal from '../../../Components/Modals/ConfirmModal/ConfirmModal';
import { ITreeNode } from '../../../Components/TreeView/TreeItem';
import TreeView from '../../../Components/TreeView/TreeView';
import useLanguage from '../../../Services/Language/useLanguageHook';
import styles from './BasisCalculator.module.scss';
import { BasisCalculatorProduct, BasisCalculatorProductPair, GroupedProducts } from './BasisCalculatorDefinitions';

interface IBasisCalculatorProductSelectorProps {
    productOptions: BasisCalculatorProduct[];
    userProductOptions?: BasisCalculatorProduct[];
    open: boolean;
    productPair?: BasisCalculatorProductPair;
    savedProductPairs?: BasisCalculatorProductPair[];
    activeTab: number;
    onChange: (productPair: BasisCalculatorProductPair) => void;
    onDelete: (productPair: BasisCalculatorProductPair) => void;
    onClose: () => void;
}

const BasisCalculatorProductSelector: FC<IBasisCalculatorProductSelectorProps> = (props: IBasisCalculatorProductSelectorProps) => {
    const [translations] = useLanguage();
    const [activeTab, setActiveTab] = useState<number>();
    const [productOptions, setProductOptions] = useState<ITreeNode[]>([]);
    const [userProductOptions, setUserProductOptions] = useState<ITreeNode[]>([]);
    const [product1Node, setProduct1Node] = useState<ITreeNode | null | undefined>(null);
    const [product2Node, setProduct2Node] = useState<ITreeNode | null | undefined>(null);
    const [useMyData1, setUseMyData1] = useState(false);
    const [useMyData2, setUseMyData2] = useState(false);
    const [searchTerm1, setSearchTerm1] = useState<string>('');
    const [searchTerm2, setSearchTerm2] = useState<string>('');
    const [selectedSavedProductPair, setSelectedSavedProductPair] = useState<BasisCalculatorProductPair | null>(null);
    const [savedProductPairs, setSavedProductPairs] = useState<BasisCalculatorProductPair[] | undefined>(props.savedProductPairs);
    const [productPairToDelete, setProductPairToDelete] = useState<BasisCalculatorProductPair | null>(null);
    const [openConfirmModal, setOpenConfirmModal] = useState(false);

    useEffect(() => {
        setActiveTab(props.activeTab);
    }, [props.activeTab]);

    useEffect(() => {
        setSavedProductPairs(props.savedProductPairs);
    }, [props.savedProductPairs]);

    const handleSearch1 = (searchTerm: string) => {
        setSearchTerm1(searchTerm);
    };

    const handleSearch2 = (searchTerm: string) => {
        setSearchTerm2(searchTerm);
    };

    const handleTabChange = (tab: number) => {
        setActiveTab(tab);
    };

    useEffect(() => {
        if (props.productPair) {
            if (props.productPair.product1) {
                const nodeProduct1 =
                    findElementById(productOptions, props.productPair.product1.id) ?? findElementById(userProductOptions, props.productPair.product1.id);
                setProduct1Node(nodeProduct1);
            }
            if (props.productPair.product2) {
                const nodeProduct2 =
                    findElementById(productOptions, props.productPair.product2.id) ?? findElementById(userProductOptions, props.productPair.product2.id);
                setProduct2Node(nodeProduct2);
            }
        }
    }, [props.open]);

    useEffect(() => {
        const treeNodes = mapProductsToTreeNodes(props.productOptions);
        setProductOptions(treeNodes);
    }, [props.productOptions]);

    useEffect(() => {
        const treeNodes = mapUserProductsToTreeNodes(props.userProductOptions);
        setUserProductOptions(treeNodes);
    }, [props.userProductOptions]);

    const findElementById = (tree: ITreeNode[], id: string) => {
        const flatTree = tree.flatMap((node) => [
            node,
            ...(node.children
                ? node.children.flatMap((child) => [
                      child,
                      ...(child.children ? child.children.flatMap((grandchild) => [grandchild, ...(grandchild.children || [])]) : []),
                  ])
                : []),
        ]);

        return flatTree.find((element) => element.id === id) || null;
    };

    const handleSwitchChange1 = (value: boolean) => {
        setUseMyData1(value);
    };

    const handleSwitchChange2 = (value: boolean) => {
        setUseMyData2(value);
    };

    const selectProduct1 = (product: ITreeNode) => {
        setProduct1Node(product);
    };

    const selectProduct2 = (product: ITreeNode) => {
        setProduct2Node(product);
    };

    const setProductPair = () => {
        if (activeTab === 0) {
            props.onChange({
                ...props.productPair,
                product1: product1Node?.value,
                product2: product2Node?.value,
            });
            props.onClose();
        }
        if (activeTab === 1) {
            if (selectedSavedProductPair) {
                props.onChange(selectedSavedProductPair);
                props.onClose();
                setSelectedSavedProductPair(null);
            }
        }
    };

    const deleteProductPair = (pair: BasisCalculatorProductPair) => {
        setProductPairToDelete(pair);
        setOpenConfirmModal(true);
    };

    const confirmDeleteProductPair = () => {
        if (productPairToDelete) {
            props.onDelete(productPairToDelete);
            setProductPairToDelete(null);
            setSelectedSavedProductPair(null);
        }
        closeConfirmModal();
    };

    const closeConfirmModal = () => {
        setOpenConfirmModal(false);
        setProductPairToDelete(null);
    };

    const mapProductsToTreeNodes = (products: BasisCalculatorProduct[]): ITreeNode[] => {
        const groupedProducts = groupProducts(products);
        const treeNodes = buildTree(groupedProducts);
        return treeNodes;
    };

    const mapUserProductsToTreeNodes = (products: BasisCalculatorProduct[] | undefined): ITreeNode[] => {
        const treeNodes = products?.map((product) => ({ id: product.id, name: product.name, value: product } as ITreeNode)) ?? [];
        return treeNodes;
    };

    const groupProducts = (products: BasisCalculatorProduct[]): GroupedProducts => {
        const grouped: GroupedProducts = {};
        products.forEach((product) => {
            const { category, market, region } = product;
            if (category && market && region) {
                if (!grouped[category]) {
                    grouped[category] = {};
                }
                if (!grouped[category][market]) {
                    grouped[category][market] = {};
                }
                if (!grouped[category][market][region]) {
                    grouped[category][market][region] = [];
                }
                grouped[category][market][region].push(product);
            }
        });
        return grouped;
    };

    const buildTree = (grouped: GroupedProducts): ITreeNode[] => {
        const tree: ITreeNode[] = [];
        Object.keys(grouped).forEach((category) => {
            const categoryNode: ITreeNode = {
                id: category,
                name: category === 'futures' ? translations.calculators.basis.futures : translations.calculators.basis.physicalPrices,
                value: category,
                children: [],
            };
            Object.keys(grouped[category]).forEach((market) => {
                const marketNode: ITreeNode = {
                    id: `${category}-${market}`,
                    name: market,
                    value: market,
                    children: [],
                };
                const marketGroup = grouped[category][market];
                if (Array.isArray(marketGroup)) {
                    marketGroup.forEach((product) => {
                        marketNode.children!.push({
                            id: product.id,
                            name: product.name,
                            value: product,
                            children: [],
                        });
                    });
                } else {
                    Object.keys(marketGroup).forEach((region) => {
                        const regionNode: ITreeNode = {
                            id: `${category}-${market}-${region}`,
                            name:
                                (category === 'futures' ? translations.exchange[region as ExchangeType] : translations.region[region as DemeterRegion]) ??
                                region,
                            value: `${category}-${market}-${region}`,
                            children: [],
                        };
                        marketGroup[region].forEach((product) => {
                            regionNode.children!.push({
                                id: product.id,
                                name: product.name,
                                value: product,
                                children: [],
                            });
                        });
                        marketNode.children!.push(regionNode);
                    });
                }
                categoryNode.children!.push(marketNode);
            });
            tree.push(categoryNode);
        });
        return tree;
    };

    const isSetButtonDisabled = (activeTab === 0 && !product1Node && !product2Node) || (activeTab === 1 && !selectedSavedProductPair);

    return (
        <>
            <Dialog open={props.open} onClose={props.onClose} sx={{ '& .MuiDialog-paper': { maxWidth: '720px' } }}>
                <DialogTitle>
                    <div className={styles.basis_calculator_product_selector_header}>
                        <ComponentSubHeader title={translations.calculators.basis.productSelector.header} />
                        <IconButton onClick={props.onClose} size="small">
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <div className={styles.basis_calculator_product_selector_content}>
                        <div className={styles.basis_calculator_product_selector_content_tabs_container}>
                            <button
                                type="button"
                                className={
                                    activeTab === 0
                                        ? styles.basis_calculator_product_selector_content_tabs_tab_selected
                                        : styles.basis_calculator_product_selector_content_tabs_tab
                                }
                                onClick={() => handleTabChange(0)}
                            >
                                {translations.calculators.basis.productSelector.tabs.productsTab.name}
                            </button>
                            <button
                                type="button"
                                className={
                                    activeTab === 1
                                        ? styles.basis_calculator_product_selector_content_tabs_tab_selected
                                        : styles.basis_calculator_product_selector_content_tabs_tab
                                }
                                onClick={() => handleTabChange(1)}
                            >
                                {translations.calculators.basis.productSelector.tabs.savedProductPairsTab.name}
                            </button>
                            <div className={styles.basis_calculator_product_selector_content_tabs_empty_underline_space} />
                        </div>

                        {activeTab === 0 && (
                            <div className={styles.basis_calculator_container}>
                                <div className={styles.basis_calculator_row}>
                                    <div className={styles.basis_calculator_cell_half_width}>
                                        <div className={styles.basis_calculator_product_selector_content_section}>
                                            <div className={styles.basis_calculator_product_selector_content_section_header}>
                                                <strong>{translations.calculators.basis.productSelector.tabs.productsTab.product1}</strong>
                                                <div>
                                                    <SwitchWithLabel
                                                        checked={useMyData1}
                                                        onChange={handleSwitchChange1}
                                                        label={translations.calculators.basis.productSelector.tabs.productsTab.useMyData}
                                                    />
                                                </div>
                                            </div>

                                            <TextInput
                                                value={searchTerm1}
                                                placeholder={translations.calculators.basis.productSelector.tabs.productsTab.search}
                                                handleTextChange={handleSearch1}
                                            />
                                            <div className={styles.basis_calculator_product_selector_content_section_selection}>
                                                <TreeView
                                                    data={useMyData1 ? userProductOptions : productOptions}
                                                    searchTerm={searchTerm1}
                                                    selectedNode={product1Node}
                                                    setSelectedNode={selectProduct1}
                                                    disabledNodes={product2Node ? [product2Node] : []}
                                                    noData={
                                                        useMyData1 ? (
                                                            <Link className={styles.basis_calculator_link} to={NavigationRoutes.UserData}>
                                                                {translations.calculators.basis.productSelector.actions.uploadMyData}
                                                            </Link>
                                                        ) : (
                                                            <div>{translations.calculators.basis.productSelector.tabs.productsTab.noData}</div>
                                                        )
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styles.basis_calculator_cell_half_width}>
                                        <div className={styles.basis_calculator_product_selector_content_section}>
                                            <div className={styles.basis_calculator_product_selector_content_section_header}>
                                                <strong>{translations.calculators.basis.productSelector.tabs.productsTab.product2}</strong>
                                                <div>
                                                    <SwitchWithLabel
                                                        checked={useMyData2}
                                                        onChange={handleSwitchChange2}
                                                        label={translations.calculators.basis.productSelector.tabs.productsTab.useMyData}
                                                    />
                                                </div>
                                            </div>
                                            <TextInput
                                                value={searchTerm2}
                                                placeholder={translations.calculators.basis.productSelector.tabs.productsTab.search}
                                                handleTextChange={handleSearch2}
                                            />
                                            <div className={styles.basis_calculator_product_selector_content_section_selection}>
                                                <TreeView
                                                    data={useMyData2 ? userProductOptions : productOptions}
                                                    searchTerm={searchTerm2}
                                                    selectedNode={product2Node}
                                                    setSelectedNode={selectProduct2}
                                                    disabledNodes={product1Node ? [product1Node] : []}
                                                    noData={
                                                        useMyData2 ? (
                                                            <Link className={styles.basis_calculator_link} to={NavigationRoutes.UserData}>
                                                                {translations.calculators.basis.productSelector.actions.uploadMyData}
                                                            </Link>
                                                        ) : (
                                                            <div>{translations.calculators.basis.productSelector.tabs.productsTab.noData}</div>
                                                        )
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        {activeTab === 1 && (
                            <div className={styles.basis_calculator_product_selector_content_section_product_list}>
                                <div className={styles.basis_calculator_product_selector_content_section_product_list_table}>
                                    <div className={styles.basis_calculator_product_selector_content_section_product_list_table_row}>
                                        <div className={styles.basis_calculator_product_selector_content_section_product_list_table_header_cell}>
                                            {translations.calculators.basis.productSelector.tabs.savedProductPairsTab.product1}
                                        </div>
                                        <div className={styles.basis_calculator_product_selector_content_section_product_list_table_header_cell}>
                                            {translations.calculators.basis.productSelector.tabs.savedProductPairsTab.product2}
                                        </div>
                                        <div className={styles.basis_calculator_product_selector_content_section_product_list_table_header_cell}>
                                            {translations.calculators.basis.productSelector.tabs.savedProductPairsTab.dateSaved}
                                        </div>
                                        <div className={styles.basis_calculator_product_selector_content_section_product_list_table_header_cell}> </div>
                                    </div>

                                    {savedProductPairs
                                        ?.slice()
                                        .reverse()
                                        .map((productPair, index) => (
                                            <div
                                                key={index}
                                                className={
                                                    productPair === selectedSavedProductPair
                                                        ? styles.basis_calculator_product_selector_content_section_product_list_table_row_selected
                                                        : styles.basis_calculator_product_selector_content_section_product_list_table_row
                                                }
                                                onClick={() => setSelectedSavedProductPair(productPair)}
                                                role="presentation"
                                                onKeyPress={() => {}}
                                            >
                                                <div className={styles.basis_calculator_product_selector_content_section_product_list_table_cell}>
                                                    {productPair.product1?.name}
                                                </div>
                                                <div className={styles.basis_calculator_product_selector_content_section_product_list_table_cell}>
                                                    {productPair.product2?.name}
                                                </div>
                                                <div className={styles.basis_calculator_product_selector_content_section_product_list_table_cell}>
                                                    {productPair.saved && formattingService.toLongDateAndTimeFormat(productPair.saved)}
                                                </div>
                                                <div className={styles.basis_calculator_product_selector_content_section_product_list_table_cell}>
                                                    <IconButton
                                                        size="small"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            deleteProductPair(productPair);
                                                        }}
                                                    >
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </div>
                                            </div>
                                        ))}
                                </div>
                            </div>
                        )}
                    </div>
                </DialogContent>
                <DialogActions>
                    <div className={styles.basis_calculator_product_selector_actions}>
                        <Link className={styles.basis_calculator_link} to={NavigationRoutes.UserData}>
                            {translations.calculators.basis.productSelector.actions.uploadMyData}
                        </Link>

                        <div className={styles.basis_calculator_product_selector_actions_right}>
                            <LinkButton
                                title={translations.calculators.basis.productSelector.actions.cancel}
                                type={LinkButtonType.White}
                                onClick={props.onClose}
                            />
                            <LinkButton
                                title={translations.calculators.basis.productSelector.actions.set}
                                type={LinkButtonType.Blue}
                                disabled={isSetButtonDisabled}
                                onClick={setProductPair}
                            />
                        </div>
                    </div>
                </DialogActions>
            </Dialog>
            <ConfirmModal
                header={translations.calculators.basis.productSelector.tabs.savedProductPairsTab.deleteProductPair}
                message={translations.calculators.basis.productSelector.tabs.savedProductPairsTab.confirmDeleteProductPair}
                showModal={openConfirmModal}
                handleConfirm={confirmDeleteProductPair}
                handleCancel={closeConfirmModal}
            />
        </>
    );
};

export default BasisCalculatorProductSelector;
