/* eslint-disable max-len */
import { FC, useEffect, useState } from 'react';
import formattingService from '../../../../Core/Formatting/FormattingService';
import { Currency } from '../../../../Generated/Raven';
import { DemeterRegion, ExchangeType, UnitOfMeasure } from '../../../../Generated/Raven-Demeter';
import ToggleableContainer from '../../../Components/Containers/Toggleable/ToggleableContainer';
import Dropdown from '../../../Components/Form/Inputs/Dropdown';
import { SelectInputOption } from '../../../Components/Form/Inputs/SelectInput';
import SwitchWithLabel from '../../../Components/Form/Inputs/SwitchWithLabel';
import ComponentSubHeader from '../../../Components/Headers/ComponentSubHeader';
import useLanguage from '../../../Services/Language/useLanguageHook';
import styles from './BasisCalculator.module.scss';
import {
    BasisAdjustment,
    basisAdjustments,
    BasisCalculatorProductPair,
    basisCurrencies,
    BasisLagPeriod,
    basisLagPeriods,
    BasisMonthlyDate,
    basisMonthlyDates,
    BasisPeriod,
    basisPeriods,
    basisUnitOfMeasures,
} from './BasisCalculatorDefinitions';
import { IBasisCalculatorCalculationResult } from './BasisCalculatorService';

interface IBasisCalculatorFormProps {
    productPair: BasisCalculatorProductPair;
    calculationResult?: IBasisCalculatorCalculationResult | undefined;
    onChange: (productPair: BasisCalculatorProductPair) => void;
    openProductSelector: () => void;
}

const BasisCalculatorForm: FC<IBasisCalculatorFormProps> = (props: IBasisCalculatorFormProps) => {
    const [translations] = useLanguage();
    const [productPair, setProductPair] = useState<BasisCalculatorProductPair>(props.productPair);
    const [calculationResult, setCalculationResult] = useState<IBasisCalculatorCalculationResult | undefined>(props.calculationResult);

    const basisCurrencyOptions: SelectInputOption<Currency>[] = basisCurrencies.map((currency) => ({
        label: translations.currency[currency],
        value: currency,
    }));
    const basisUnitOfMeasureOptions: SelectInputOption<UnitOfMeasure>[] = basisUnitOfMeasures.map((unit) => ({
        label: translations.unitOfMeasure[unit],
        value: unit,
    }));
    const basisLagPeriodOptions: SelectInputOption<BasisLagPeriod>[] = basisLagPeriods.map((lag) => ({
        label: translations.calculators.basis.basisLagPeriod[lag],
        value: lag,
    }));
    const basisPeriodOptions: SelectInputOption<BasisPeriod>[] = basisPeriods.map((period) => ({
        label: translations.calculators.basis.basisPeriod[period],
        value: period,
    }));
    const basisAdjustmentOptions: SelectInputOption<BasisAdjustment>[] = basisAdjustments.map((adjustment) => ({
        label: translations.calculators.basis.basisAdjustment[adjustment],
        value: adjustment,
    }));

    useEffect(() => {
        setProductPair(props.productPair);
    }, [props.productPair]);

    useEffect(() => {
        setCalculationResult(props.calculationResult);
    }, [props.calculationResult]);

    useEffect(() => {
        props.onChange(productPair);
    }, [productPair]);

    const changeCurrency = (value: Currency) => {
        setProductPair({
            ...productPair,
            currency: value,
        });
    };

    const changeUnitOfMeasure = (value: UnitOfMeasure) => {
        setProductPair({
            ...productPair,
            unitOfMeasure: value,
        });
    };

    const changeBasisPeriod = (value: BasisPeriod) => {
        setProductPair({
            ...productPair,
            basisPeriod: value,
        });
    };

    const changeLagPeriod = (value: BasisLagPeriod) => {
        setProductPair({
            ...productPair,
            basisLagPeriod: value,
        });
    };

    const changeBasisAdjustment = (value: BasisAdjustment) => {
        setProductPair({
            ...productPair,
            basisAdjustment: value,
        });
    };

    const changeStartDate = (value: BasisMonthlyDate) => {
        setProductPair({
            ...productPair,
            startDate: value,
        });
    };

    const changeUseRegression = (value: boolean) => {
        setProductPair({
            ...productPair,
            useRegression: value,
        });
    };

    const changeShowAdvancedSettings = (value: boolean) => {
        setProductPair({
            ...productPair,
            showAdvancedSettings: value,
        });
    };

    return (
        <div className={styles.basis_calculator_form}>
            <div className={styles.basis_calculator_container}>
                <div className={styles.basis_calculator_row}>
                    <div className={styles.basis_calculator_cell_half_width}>
                        <ComponentSubHeader title={translations.calculators.basis.form.product1} />
                        <p>
                            <button type="button" onClick={props.openProductSelector} className={styles.basis_calculator_link}>
                                {productPair.product1?.name}
                                {productPair.product1?.category === 'futures' &&
                                    productPair.product1?.region &&
                                    ` (${translations.exchange[productPair.product1?.region as ExchangeType]})`}
                                {productPair.product1?.category === 'physicalPrices' &&
                                    productPair.product1?.region &&
                                    ` (${translations.region[productPair.product1?.region as DemeterRegion]})`}
                            </button>
                        </p>
                    </div>
                    <div className={styles.basis_calculator_cell_half_width}>
                        <ComponentSubHeader title={translations.calculators.basis.form.product2} />
                        <p>
                            <button type="button" onClick={props.openProductSelector} className={styles.basis_calculator_link}>
                                {productPair.product2?.name}
                                {productPair.product2?.category === 'futures' &&
                                    productPair.product2?.region &&
                                    ` (${translations.exchange[productPair.product2?.region as ExchangeType]})`}
                                {productPair.product2?.category === 'physicalPrices' &&
                                    productPair.product2?.region &&
                                    ` (${translations.region[productPair.product2?.region as DemeterRegion]})`}
                            </button>
                        </p>
                    </div>
                </div>
                <div className={styles.basis_calculator_row}>
                    <div className={styles.basis_calculator_cell_full_width}>
                        <hr />
                    </div>
                </div>
                <div className={styles.basis_calculator_row}>
                    <div className={styles.basis_calculator_cell_third_width}>
                        <Dropdown
                            value={productPair.currency}
                            options={basisCurrencyOptions}
                            handleOptionChange={changeCurrency}
                            label={translations.words.currency}
                        />
                    </div>
                    <div className={styles.basis_calculator_cell_third_width}>
                        <Dropdown
                            value={productPair.unitOfMeasure}
                            options={basisUnitOfMeasureOptions}
                            handleOptionChange={changeUnitOfMeasure}
                            label={translations.words.unit}
                        />
                    </div>
                    <div className={styles.basis_calculator_cell_third_width}>
                        <Dropdown
                            value={productPair.startDate}
                            options={basisMonthlyDates}
                            handleOptionChange={changeStartDate}
                            label={translations.calculators.basis.form.historyStartDate}
                        />
                    </div>
                </div>

                <div className={styles.basis_calculator_row}>
                    <div className={styles.basis_calculator_cell_full_width}>
                        <hr />
                    </div>
                </div>
                <div className={styles.basis_calculator_row}>
                    <div className={styles.basis_calculator_cell_full_width}>
                        <ComponentSubHeader title={translations.calculators.basis.form.correlation} />
                    </div>
                </div>
                <div className={styles.basis_calculator_row}>
                    <div className={styles.basis_calculator_cell_third_width}>
                        <p>
                            <strong>{translations.calculators.basis.form.correlation}</strong>
                        </p>
                        <p>{formattingService.toPercent(calculationResult?.correlation)}</p>
                    </div>
                    <div className={styles.basis_calculator_cell_third_width}>
                        <p>
                            <strong>
                                R<sup>2</sup>
                            </strong>
                        </p>
                        <p>{formattingService.toPercent(calculationResult?.rSquared)}</p>
                    </div>
                    {productPair.useOptimalLag && (
                        <div className={styles.basis_calculator_cell_third_width}>
                            <p>
                                <strong>{translations.calculators.basis.form.optimalLag}</strong>
                            </p>
                            <p>
                                {calculationResult && (
                                    <span>
                                        {formattingService.toPercent(calculationResult.optimalLagCorrelation)}
                                        <span className={styles.basis_calculator_form_optimal_lag}>
                                            {calculationResult.optimalLagMonth}{' '}
                                            {calculationResult?.optimalLagMonth! > 1
                                                ? translations.calculators.basis.form.months
                                                : translations.calculators.basis.form.month}
                                        </span>
                                    </span>
                                )}
                            </p>
                        </div>
                    )}
                </div>
                <div className={styles.basis_calculator_row}>
                    <div className={styles.basis_calculator_cell_half_width}>
                        <Dropdown
                            value={productPair.basisLagPeriod}
                            options={basisLagPeriodOptions}
                            handleOptionChange={changeLagPeriod}
                            label={translations.calculators.basis.form.lagPeriod}
                        />
                    </div>
                    <div className={styles.basis_calculator_cell_half_width}> </div>
                </div>
                <div className={styles.basis_calculator_row}>
                    <div className={styles.basis_calculator_cell_full_width}>
                        <hr />
                    </div>
                </div>
                <div className={styles.basis_calculator_row}>
                    <div className={styles.basis_calculator_cell_full_width}>
                        <ToggleableContainer
                            open={productPair.showAdvancedSettings}
                            collapsedText={translations.calculators.basis.form.openAdvancedSettings}
                            expandedText={translations.calculators.basis.form.closeAdvancedSettings}
                            onChange={changeShowAdvancedSettings}
                        >
                            <div className={styles.basis_calculator_container}>
                                <div className={styles.basis_calculator_row}>
                                    <div className={styles.basis_calculator_cell_ful_width}>
                                        <ComponentSubHeader title={translations.calculators.basis.form.statisticalRelationship} />
                                    </div>
                                </div>
                                <div className={styles.basis_calculator_row}>
                                    <div className={styles.basis_calculator_cell_half_width}>
                                        <p>
                                            <strong>{translations.calculators.basis.form.slope}</strong>
                                        </p>
                                        <p>{formattingService.toFormattedNumber(calculationResult?.slope)}</p>
                                    </div>
                                    <div className={styles.basis_calculator_cell_half_width}>
                                        <p>
                                            <strong>{translations.calculators.basis.form.intercept}</strong>
                                        </p>
                                        <p>{formattingService.toFormattedNumber(calculationResult?.intercept)}</p>
                                    </div>
                                </div>
                                <div className={styles.basis_calculator_row}>
                                    <div className={styles.basis_calculator_cell_full_width}>
                                        <SwitchWithLabel
                                            checked={productPair.useRegression!}
                                            onChange={changeUseRegression}
                                            label={translations.calculators.basis.form.useRegression}
                                        />
                                    </div>
                                </div>
                                <div className={styles.basis_calculator_row}>
                                    <div className={styles.basis_calculator_cell_full_width}>
                                        <hr />
                                    </div>
                                </div>
                                <div className={styles.basis_calculator_row}>
                                    <div className={styles.basis_calculator_cell_full_width}>
                                        <ComponentSubHeader title={translations.calculators.basis.form.basisSettings} />
                                    </div>
                                </div>
                                <div className={styles.basis_calculator_row}>
                                    <div className={styles.basis_calculator_cell_half_width}>
                                        <Dropdown
                                            value={productPair.basisPeriod}
                                            options={basisPeriodOptions}
                                            handleOptionChange={changeBasisPeriod}
                                            label={translations.calculators.basis.form.basisPeriod}
                                        />
                                    </div>
                                    <div className={styles.basis_calculator_cell_half_width}>
                                        <Dropdown
                                            value={productPair.basisAdjustment}
                                            options={basisAdjustmentOptions}
                                            handleOptionChange={changeBasisAdjustment}
                                            label={translations.calculators.basis.form.basisAdjustment}
                                        />
                                    </div>
                                </div>
                                <div className={styles.basis_calculator_row}>
                                    <div className={styles.basis_calculator_cell_full_width}>
                                        <p>
                                            <strong>{productPair.basisPeriod && translations.calculators.basis.basisPeriod[productPair.basisPeriod]}</strong>{' '}
                                            <strong>
                                                {productPair.basisAdjustment && translations.calculators.basis.basisAdjustment[productPair.basisAdjustment]}
                                            </strong>
                                        </p>
                                        <p>{calculationResult && formattingService.toFormattedNumber(calculationResult.metric)}</p>
                                    </div>
                                </div>
                                <div className={styles.basis_calculator_row}>
                                    <div className={styles.basis_calculator_cell_full_width}>
                                        <hr />
                                    </div>
                                </div>
                            </div>
                        </ToggleableContainer>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BasisCalculatorForm;
