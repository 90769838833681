import { FC } from 'react';
import { Currency, UnitOfMeasure } from '../../../../Generated/Raven-Demeter';
import { IChartBarDataSeries, IChartDataSeries } from '../../../Components/Charts/ChartDefinitions';
import ChartWrapper from '../../../Components/Charts/ChartWrapper/ChartWrapper';
import ProjectionChartRaw from '../../../Components/Charts/Projection/ProjectionChartRaw';
import styles from './BasisCalculator.module.scss';

interface IBasisCalculatorChart {
    title: string;
    dataSourceTag: string[];
    currency: Currency | undefined;
    unitOfMeasure: UnitOfMeasure | undefined;
    linesSeries: IChartDataSeries[];
    barSeries: IChartBarDataSeries;
}

const BasisCalculatorChart: FC<IBasisCalculatorChart> = (props: IBasisCalculatorChart) => (
    <div className={styles.basis_calculator_chart}>
        <ChartWrapper name="BasisCalculatorChart" title={props.title} dataSourceTag={props.dataSourceTag} isLoading={false}>
            <ProjectionChartRaw linesSeries={props.linesSeries} barSeries={props.barSeries} currency={props.currency} unitOfMeasure={props.unitOfMeasure} />
        </ChartWrapper>
    </div>
);

export default BasisCalculatorChart;
