import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Currency, DemeterRegion, UnitOfMeasure } from '../../Generated/Raven-Demeter';
import type { Language } from '../../Refactor/Services/Language/LanguageService';
import type { RootState } from '../ReduxStore';

export type SearchParameters = { [key: string]: string };

export interface SystemState {
    storeId: string;
    searchParameters: SearchParameters;
    language?: Language;
    location?: DemeterRegion;
    currency?: Currency;
    unitOfMeasureGeneral?: UnitOfMeasure;
    unitOfMeasureMetricImperial?: UnitOfMeasure;
}

const initialState: SystemState = {
    storeId: 'default',
    searchParameters: {},
    language: undefined,
    location: undefined,
    currency: undefined,
    unitOfMeasureGeneral: undefined,
    unitOfMeasureMetricImperial: undefined,
};

// This file was based off the Redux example:
// For information about async reducers check out the below.
// https://github.com/reduxjs/cra-template-redux-typescript/blob/master/template/src/features/counter/counterSlice.ts
export const systemSlice = createSlice({
    name: 'system',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions.
    reducers: {
        setStoreId: (state: SystemState, action: PayloadAction<{ storeId: string }>): void => {
            state.storeId = action.payload.storeId;
        },
        setSearchParameters: (state: SystemState, action: PayloadAction<{ searchParameters: SearchParameters }>): void => {
            let searchParametersObject: SearchParameters = {};

            if (action.payload.searchParameters instanceof URLSearchParams) {
                searchParametersObject = Object.fromEntries(new URLSearchParams(action.payload.searchParameters));
            } else {
                Object.keys(action.payload.searchParameters).forEach((key: string) => {
                    searchParametersObject[key] = (action.payload.searchParameters as Record<string, string>)[key] ?? '';
                });
            }

            // To prevent the infinte loop of redux updates.
            const hasChanges = JSON.stringify(searchParametersObject) !== JSON.stringify(state.searchParameters);
            if (hasChanges) {
                state.searchParameters = searchParametersObject;
            }
        },
        setLanguage: (state: SystemState, action: PayloadAction<{ language: Language }>): void => {
            state.language = action.payload.language;
        },
        setLocation: (state: SystemState, action: PayloadAction<{ location: DemeterRegion }>): void => {
            state.location = action.payload.location;
        },
        setCurrency: (state: SystemState, action: PayloadAction<{ currency?: Currency }>): void => {
            state.currency = action.payload.currency;
        },
        setUnitOfMeasureGeneral: (state: SystemState, action: PayloadAction<{ unitOfMeasure?: UnitOfMeasure }>): void => {
            state.unitOfMeasureGeneral = action.payload.unitOfMeasure;
        },
        setUnitOfMeasureMetricImperial: (state: SystemState, action: PayloadAction<{ unitOfMeasure?: UnitOfMeasure }>): void => {
            state.unitOfMeasureMetricImperial = action.payload.unitOfMeasure;
        },
    },
});

export const { setStoreId, setSearchParameters, setLanguage, setLocation, setUnitOfMeasureMetricImperial, setUnitOfMeasureGeneral, setCurrency } =
    systemSlice.actions;

export const selectStoreId = (state: RootState): string => state.system?.storeId ?? 'default';
export const selectSearchParameters = (state: RootState): SearchParameters => state.system?.searchParameters;
export const selectLanguage = (state: RootState): Language | undefined => state.system?.language;
export const selectLocation = (state: RootState): DemeterRegion | undefined => state.system?.location;
export const selectUnitOfMeasureMetricImperial = (state: RootState): UnitOfMeasure | undefined => state.system?.unitOfMeasureMetricImperial;
export const selectUnitOfMeasureGeneral = (state: RootState): UnitOfMeasure | undefined => state.system?.unitOfMeasureGeneral;
export const selectCurrency = (state: RootState): Currency | undefined => state.system?.currency;

export default systemSlice.reducer;
