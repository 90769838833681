import React, { useState } from 'react';
import { DemeterTableDefinitionType } from '../../../Generated/Raven-Demeter';
import UnitOfMeasureMetricImperialDropdown from '../../Components/Form/Inputs/UnitOfMeasureMetricImperialDropdown';
import PageHeaderWithSelections from '../../Components/Headers/PageHeaderWithSelections';
import PageLoadingSpinner from '../../Components/LoadingSpinner/PageLoadingSpinner';
import useTableDefinitionNavigation from '../../Components/Navigation/Hooks/useTableDefinitionNavigationHook';
import useUnitOfMeasure from '../../Core/Hooks/useUnitOfMeasureHook';
import useLanguage from '../../Services/Language/useLanguageHook';
import BalanceTable from './BalanceTable';
import BalanceTableChart from './BalanceTableChart';
import styles from './BalanceTablePage.module.scss';

export type BalanceType = 'domestic' | 'exports' | 'imports' | 'production' | 'stocks' | 'stocksCommercial' | 'stocksGovernment';

const BalanceTablePage: React.FC = () => {
    // Constants.
    const [translations] = useLanguage();
    const pageTitle = translations.balanceTable.title;
    const tableDefinitionType = DemeterTableDefinitionType.CommodityYearlyBalanceTable;

    // Hooks.
    const [, tableDefinitionCommodity, regionCommoditySelection] = useTableDefinitionNavigation(tableDefinitionType);
    const [unitOfMeasure, setUnitOfMeasure] = useUnitOfMeasure('MetricImperial');
    const [balanceType, setBalanceType] = useState<BalanceType>('production');
    const [isPercent, setIsPercent] = useState(false);

    return (
        <div className={styles.master_page_container}>
            <PageHeaderWithSelections
                title={pageTitle}
                tableDefinitionType={tableDefinitionType}
                rightSideOfHeaderComponent={<UnitOfMeasureMetricImperialDropdown unitOfMeasure={unitOfMeasure} handleChange={setUnitOfMeasure} />}
                testId="BalanceTablePageHeader"
            />
            {!tableDefinitionCommodity || !regionCommoditySelection ? (
                <PageLoadingSpinner />
            ) : (
                <>
                    <BalanceTable
                        regionCommoditySelection={regionCommoditySelection}
                        unitOfMeasure={unitOfMeasure}
                        handleSelectBalanceType={(newbalanceType: BalanceType, newIsPercent: boolean) => {
                            setBalanceType(newbalanceType);
                            setIsPercent(newIsPercent);
                        }}
                        testId="BalanceTableTable"
                    />
                    <div className={styles.balance_table_chart_container}>
                        <BalanceTableChart
                            regionCommoditySelection={regionCommoditySelection}
                            unitOfMeasure={unitOfMeasure}
                            balanceType={balanceType}
                            isPercent={isPercent}
                            testId="BalanceTableChart"
                        />
                    </div>
                </>
            )}
        </div>
    );
};

export default BalanceTablePage;
