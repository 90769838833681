import { useEffect, useRef } from 'react';
import { demeterUsersApi } from '../../../Apis/Apis';
import applicationConstants from '../../../Core/Utility/ApplicationConstants';
import { useApplicationSelector } from '../../../Redux/ReduxStore';
import { selectUserTokens } from '../../../Redux/Slices/UserSlice';
import useApi from './useApiHook';

const useUserSessionPingApiHook = (sessionLoaded: boolean): void => {
    const userTokens = useApplicationSelector(selectUserTokens);
    const sessionReference = useRef<{ sessionLoaded: boolean; accessToken?: string; sessionError?: string }>({
        sessionLoaded: false,
        accessToken: undefined,
        sessionError: undefined,
    });
    const [, refreshApi, , sessionError] = useApi(() => demeterUsersApi.pingDemeterUserSession(), {
        stopAutoExecute: true,
        stopGlobalErrorHandling: true,
    });

    useEffect(() => {
        sessionReference.current = {
            sessionLoaded,
            accessToken: userTokens?.accessToken,
            sessionError,
        };
    }, [sessionLoaded, userTokens?.accessToken, sessionError]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (sessionReference.current.sessionLoaded && sessionReference.current.accessToken && !sessionReference.current.sessionError) {
                refreshApi();
            } else if (sessionReference.current.sessionError) {
                delete sessionReference.current.sessionError;
            }
        }, applicationConstants.SessionRefreshTimeInMinutes * 60 * 1000);

        return () => clearInterval(interval);
    }, []);
};

export default useUserSessionPingApiHook;
