import { useMemo } from 'react';
import Dropdown from '../../../../../../Components/Form/Inputs/Dropdown';
import useLanguage from '../../../../../../Services/Language/useLanguageHook';
import { HistoricalParameters, ThresholdValueKey, UpsertMarketIndicatorFactorRequest } from '../../../MarketIndicatorsManagementDefinitions';
import styles from '../../MarketIndicatorFactorPage.module.scss';
import MarketFactorParameterTextInput from './MarketFactorParameterTextInput';

interface IMarketFactorCalculationTypeDropdownProps {
    calculationTypeIndex: number;
    upsertMarketIndicatorFactorRequest: UpsertMarketIndicatorFactorRequest;
    setUpsertMarketIndicatorFactorRequest: (value: UpsertMarketIndicatorFactorRequest) => void;
}

const MarketFactorCalculationTypeDropdown: React.FC<IMarketFactorCalculationTypeDropdownProps> = (props: IMarketFactorCalculationTypeDropdownProps) => {
    const [translations] = useLanguage();

    const thresholdOptions = useMemo(
        () => [
            {
                label: translations.words.average,
                value: 'Average',
            },
            {
                label: translations.text.lastYear,
                value: 'LastYear',
            },
            {
                label: translations.words.previous,
                value: 'Previous',
            },
            {
                label: translations.text.versesAverageChange,
                value: 'VersesAverageChange',
            },
            {
                label: translations.text.versesThreshold,
                value: 'VersesThreshold',
            },
        ],
        [translations],
    );

    const isDisabled =
        (props.upsertMarketIndicatorFactorRequest.parameters as HistoricalParameters).subFactorCalculationTypes.split(',')[props.calculationTypeIndex] !==
        'VersesThreshold';

    return (
        <div className={styles.indicator_add_and_edit_dropdown}>
            <Dropdown
                value={
                    (props.upsertMarketIndicatorFactorRequest.parameters as HistoricalParameters).subFactorCalculationTypes.split(',')[
                        props.calculationTypeIndex
                    ]
                }
                handleOptionChange={(value) => {
                    const subFactorCalculationsArray = (
                        props.upsertMarketIndicatorFactorRequest.parameters as HistoricalParameters
                    ).subFactorCalculationTypes.split(',');
                    subFactorCalculationsArray[props.calculationTypeIndex] = value;
                    const initialUpsertMarketIndicatorFactorRequest = {
                        ...props.upsertMarketIndicatorFactorRequest,
                        parameters: {
                            ...props.upsertMarketIndicatorFactorRequest.parameters,
                            subFactorCalculationTypes: subFactorCalculationsArray.join(','),
                        },
                    };

                    if (
                        (initialUpsertMarketIndicatorFactorRequest.parameters as HistoricalParameters).subFactorCalculationTypes.split(',')[
                            props.calculationTypeIndex
                        ] !== 'VersesThreshold'
                    ) {
                        (initialUpsertMarketIndicatorFactorRequest.parameters as HistoricalParameters)[
                            `thresholdValue${props.calculationTypeIndex + 1}` as ThresholdValueKey
                        ] = undefined;
                    }

                    props.setUpsertMarketIndicatorFactorRequest(initialUpsertMarketIndicatorFactorRequest);
                }}
                options={thresholdOptions}
                label={`${translations.marketIndicatorsManagement.fields.calculationType} ${props.calculationTypeIndex + 1}`}
            />
            <div className={styles.indicator_add_and_edit_row}>
                <MarketFactorParameterTextInput
                    upsertMarketIndicatorFactorRequest={props.upsertMarketIndicatorFactorRequest}
                    setUpsertMarketIndicatorFactorRequest={props.setUpsertMarketIndicatorFactorRequest}
                    title={`${translations.marketIndicatorsManagement.fields.thresholdValue} ${props.calculationTypeIndex + 1}`}
                    field={`thresholdValue${props.calculationTypeIndex + 1}`}
                    disabled={isDisabled}
                />
            </div>
        </div>
    );
};

export default MarketFactorCalculationTypeDropdown;
