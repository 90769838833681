import { useMemo } from 'react';
import infoSvg from '../../../../Components/Assets/info.svg';
import trialExpirationHeaderSvg from '../../../../Components/Assets/trialExpirationHeader.svg';
import Footer from '../../../../Components/Footer/Footer';
import applicationSettings from '../../../../Core/Settings/ApplicationSettings';
import EmailLink from '../../../Components/Navigation/EmailLink/EmailLink';
import { translationKeys } from '../../../Services/Language/TranslationDefinitions';
import useLanguage from '../../../Services/Language/useLanguageHook';
import styles from './AccessDenied.module.scss';

export type DenialType = 'Expired' | 'Forbidden';

export interface IAccessDeniedProps {
    denialType: DenialType;
}

const AccessDenied: React.FC<IAccessDeniedProps> = (props: IAccessDeniedProps) => {
    const [, translate, language] = useLanguage();

    const contactList = useMemo(
        () => [
            { label: translate(translationKeys.registration.text.chicagoTelephone), number: applicationSettings.telephoneNumbers.chicago },
            { label: translate(translationKeys.registration.text.singaporeTelephone), number: applicationSettings.telephoneNumbers.singapore },
            { label: translate(translationKeys.registration.text.dublinTelephone), number: applicationSettings.telephoneNumbers.dublin },
        ],
        [language],
    );

    return (
        <>
            <div className={styles.access_denied_container}>
                {props.denialType === 'Expired' && (
                    <div className={styles.access_denied_info}>
                        <img src={infoSvg} alt="#" /> {translate(translationKeys.trialExpiration.text.trialExpirationNotice)}
                    </div>
                )}
                <img src={trialExpirationHeaderSvg} alt="#" />
                <div className={styles.access_denied_messages_container}>
                    <div className={styles.access_denied_message_block}>
                        {props.denialType === 'Expired' ? (
                            <>
                                <h3>{translate(translationKeys.trialExpiration.header.hopeYouEnjoyed)}</h3>
                                <p>{translate(translationKeys.trialExpiration.text.weTrustThat)}</p>
                                <p>
                                    <span>{translate(translationKeys.trialExpiration.text.stayOneStepAhead)} </span>
                                    <EmailLink linkText={applicationSettings.emails.contactEmail} email={applicationSettings.emails.contactEmail} />
                                    <span>{translate(translationKeys.trialExpiration.text.weWillTakeCare)} </span>
                                </p>
                            </>
                        ) : (
                            <>
                                <h3>{translate(translationKeys.trialExpiration.header.apologizeForInconvenience)}</h3>
                                <p>
                                    {`${translate(translationKeys.trialExpiration.text.errorWhileAccessingContactTeam)} ${
                                        applicationSettings.displayName
                                    }. ${translate(translationKeys.trialExpiration.text.contactTeam)} `}
                                    <EmailLink linkText={applicationSettings.emails.contactEmail} email={applicationSettings.emails.contactEmail} />
                                    {`, ${translate(translationKeys.registration.text.callTheNumber)}`}
                                </p>
                                <div className={styles.access_denied_contact_main_container}>
                                    {contactList.map((x) => (
                                        <div className={styles.access_denied_contact_phone_number_container}>
                                            <p className={styles.access_denied_contact_phone_number_container_label}>{x.label}</p>
                                            <p>{x.number}</p>
                                        </div>
                                    ))}
                                </div>
                            </>
                        )}
                    </div>
                    <div className={styles.access_denied_benefits_block_wrapper}>
                        <div className={styles.access_denied_benefits_block}>
                            <h3>{translate(translationKeys.trialExpiration.header.benefitsOfSubscribing)}</h3>
                            <ul>
                                <li>{translate(translationKeys.trialExpiration.benefitsList.gainAccessInsights)}</li>
                                <li>{translate(translationKeys.trialExpiration.benefitsList.analyzeMarketData)}</li>
                                <li>{translate(translationKeys.trialExpiration.benefitsList.fullyCustomize)}</li>
                                <li>{translate(translationKeys.trialExpiration.benefitsList.stayUpToDate)}</li>
                                <li>{translate(translationKeys.trialExpiration.benefitsList.keepTrack)}</li>
                                <li>{translate(translationKeys.trialExpiration.benefitsList.developUnderstanding)}</li>
                                <li>{translate(translationKeys.trialExpiration.benefitsList.buildCompletePicture)}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};
export default AccessDenied;
