/* eslint-disable import/no-cycle */
/* eslint-disable @typescript-eslint/no-redeclare */
import moment from 'moment';
import formattingService from '../../../../Core/Formatting/FormattingService';
import { Currency, UnitOfMeasure } from '../../../../Generated/Raven-Demeter';
import languageService from '../../../Services/Language/LanguageService';

moment.locale(languageService.getLanguage());

const simpleHash = (input: string): number => {
    let hash = 5381;
    for (let i = 0; i < input.length; i += 1) {
        // eslint-disable-next-line no-bitwise
        hash = (hash * 33) ^ input.charCodeAt(i);
    }
    // eslint-disable-next-line no-bitwise
    return hash >>> 0;
};

const numberToHex32 = (num: number): string => `00000000${num.toString(16)}`.slice(-8);

export const generateUniqueId = (input: string): string => {
    const hash1 = simpleHash(input);
    const hash2 = simpleHash(input.split('').reverse().join(''));
    // eslint-disable-next-line no-bitwise
    const uniqueId = numberToHex32(hash1) + numberToHex32(hash2) + numberToHex32(hash1 ^ hash2) + numberToHex32(hash1 + hash2);
    return uniqueId.slice(0, 32);
};

export type BasisCalculatorProduct = {
    id: string;
    name: string;
    category: string;
    market?: string | null | undefined;
    reutersInstrumentCodePrefix?: string | null | undefined;
    commodity?: string | null | undefined;
    region?: string | null | undefined;
    contractNumber?: number | null | undefined;
    pricesDataSource?: string | null | undefined;
    currency?: Currency | undefined;
    unitOfMeasure?: UnitOfMeasure | undefined;
};

export type BasisCalculatorProductPair = {
    product1: BasisCalculatorProduct | undefined;
    product2: BasisCalculatorProduct | undefined;
    saved?: Date | undefined;
    currency?: Currency | undefined;
    unitOfMeasure?: UnitOfMeasure | undefined;
    showAdvancedSettings?: boolean | undefined;
    basisLagPeriod?: BasisLagPeriod | undefined;
    basisPeriod?: BasisPeriod | undefined;
    basisAdjustment?: BasisAdjustment | undefined;
    useOptimalLag?: boolean | undefined;
    useRegression?: boolean | undefined;
    startDate?: BasisMonthlyDate | undefined;
};

export type BasisValueModel = {
    asOfDate: Date;
    value: number;
    isActualValue: boolean;
};

export type GroupedProducts = {
    [category: string]: {
        [market: string]: {
            [region: string]: BasisCalculatorProduct[];
        };
    };
};

export const BasisPeriod = {
    Basis: 'Basis',
    ThreeMonthBasis: 'ThreeMonthBasis',
    SixMonthBasis: 'SixMonthBasis',
    NineMonthBasis: 'NineMonthBasis',
    TwelveMonthBasis: 'TwelveMonthBasis',
} as const;

export type BasisPeriod = (typeof BasisPeriod)[keyof typeof BasisPeriod];

export const BasisLagPeriod = {
    NoLag: 'NoLag',
    OneMonthLag: 'OneMonthLag',
    TwoMonthLag: 'TwoMonthLag',
    ThreeMonthLag: 'ThreeMonthLag',
    FourMonthLag: 'FourMonthLag',
    FiveMonthLag: 'FiveMonthLag',
    SixMonthLag: 'SixMonthLag',
} as const;

export type BasisLagPeriod = (typeof BasisLagPeriod)[keyof typeof BasisLagPeriod];

export const basisLagPeriods = [
    BasisLagPeriod.NoLag,
    BasisLagPeriod.OneMonthLag,
    BasisLagPeriod.TwoMonthLag,
    BasisLagPeriod.ThreeMonthLag,
    BasisLagPeriod.FourMonthLag,
    BasisLagPeriod.FiveMonthLag,
    BasisLagPeriod.SixMonthLag,
];

export const BasisAdjustment = {
    Average: 'Average',
    Max: 'Max',
    Min: 'Min',
    Top10Percent: 'Top10Percent',
    Top20Percent: 'Top20Percent',
    Top30Percent: 'Top30Percent',
    Top40Percent: 'Top40Percent',
    Top50Percent: 'Top50Percent',
    Bottom40Percent: 'Bottom40Percent',
    Bottom30Percent: 'Bottom30Percent',
    Bottom20Percent: 'Bottom20Percent',
    Bottom10Percent: 'Bottom10Percent',
} as const;

export type BasisAdjustment = (typeof BasisAdjustment)[keyof typeof BasisAdjustment];

export const basisCurrencies = [Currency.Usd, Currency.Eur, Currency.Gbp];

export const basisUnitOfMeasures = [UnitOfMeasure.HundredWeight, UnitOfMeasure.Kilogram, UnitOfMeasure.MetricTon, UnitOfMeasure.Pound, UnitOfMeasure.ShortTon];

export const basisPeriods = [
    BasisPeriod.Basis,
    BasisPeriod.ThreeMonthBasis,
    BasisPeriod.SixMonthBasis,
    BasisPeriod.NineMonthBasis,
    BasisPeriod.TwelveMonthBasis,
];

export const basisAdjustments = [
    BasisAdjustment.Average,
    BasisAdjustment.Min,
    BasisAdjustment.Max,
    BasisAdjustment.Top10Percent,
    BasisAdjustment.Top20Percent,
    BasisAdjustment.Top30Percent,
    BasisAdjustment.Top40Percent,
    BasisAdjustment.Top50Percent,
    BasisAdjustment.Bottom40Percent,
    BasisAdjustment.Bottom30Percent,
    BasisAdjustment.Bottom20Percent,
    BasisAdjustment.Bottom10Percent,
];

export const basisIntervals: BasisPeriod[] = [BasisPeriod.ThreeMonthBasis, BasisPeriod.SixMonthBasis, BasisPeriod.NineMonthBasis, BasisPeriod.TwelveMonthBasis];

const generateMonthlyDates = (startDate: Date): { [key: string]: string } => {
    const currentDate = new Date();
    const endDate = new Date(startDate);
    const result: { [key: string]: string } = {};
    while (currentDate >= endDate) {
        const key = formattingService.toMonthYear(currentDate);
        result[key] = key;
        currentDate.setMonth(currentDate.getMonth() - 1);
    }
    return result;
};

const getDateYearsAgo = (yearsAgo: number): Date => {
    const pastDate = new Date();
    pastDate.setFullYear(pastDate.getFullYear() - yearsAgo);
    return pastDate;
};

const yearsToGenerateMonthsBack = 7;

export const BasisMonthlyDate = {
    ...generateMonthlyDates(getDateYearsAgo(yearsToGenerateMonthsBack)),
} as const;

export type BasisMonthlyDate = (typeof BasisMonthlyDate)[keyof typeof BasisMonthlyDate];

const startDateYearsBack = 5;

export const defaultStartDate = formattingService.toMonthYear(getDateYearsAgo(startDateYearsBack));

export const basisMonthlyDates = Object.keys(BasisMonthlyDate).map((key) => ({
    label: key,
    value: BasisMonthlyDate[key],
}));
