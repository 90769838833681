import { SnackbarKey, SnackbarProvider } from 'notistack';
import { ReactNode } from 'react';
import styles from './NotificationsWrapper.module.scss';
import SnackbarCloseButton from './SnackbarCloseButton';

export interface INotificationsWrapperProps {
    children: ReactNode;
}

const NotificationsWrapper: React.FC<INotificationsWrapperProps> = (props: INotificationsWrapperProps) => {
    const snackbarCloseButton = (snackbarKey: SnackbarKey): ReactNode => <SnackbarCloseButton snackbarKey={snackbarKey} />;

    return (
        <SnackbarProvider
            maxSnack={2}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            classes={{
                containerAnchorOriginBottomLeft: styles.notifications_container_anchor_origin_bottom_left,
                containerRoot: styles.notifications_container_root,
            }}
            autoHideDuration={3000}
            preventDuplicate
            action={snackbarCloseButton}
        >
            {props.children}
        </SnackbarProvider>
    );
};
export default NotificationsWrapper;
