import React, { useEffect, useMemo, useState } from 'react';
import { DemeterDataFrequency, DemeterFeatureType, DemeterTableDefinitionType, UnitOfMeasure } from '../../../Generated/Raven-Demeter';
import useYearlyBalancesApi from '../../Apis/Hooks/useYearlyBalancesApiHook';
import BarChartRaw from '../../Components/Charts/Bar/BarChartRaw';
import { IChartData } from '../../Components/Charts/ChartDefinitions';
import ChartWrapper from '../../Components/Charts/ChartWrapper/ChartWrapper';
import { IRegionCommoditySelection } from '../../Components/Navigation/Hooks/useRegionCommodityNavigationHook';
import useTableDefinition from '../../Components/Navigation/Hooks/useTableDefinitionHook';
import useFeatureFlag from '../../Services/FeatureFlags/useFeatureFlagHook';
import useLanguage from '../../Services/Language/useLanguageHook';
import type { BalanceType } from './BalanceTablePage';

export interface IBalanceTableChartProps {
    regionCommoditySelection: IRegionCommoditySelection;
    unitOfMeasure?: UnitOfMeasure;
    balanceType: BalanceType;
    isPercent?: boolean;
    testId?: string;
}

export const yearsToDisplay = 10;

const BalanceTableChart: React.FC<IBalanceTableChartProps> = (props: IBalanceTableChartProps) => {
    const [, translate] = useLanguage();

    const [tableDefinitionRegion, tableDefinitionCommodity] = useTableDefinition(
        DemeterTableDefinitionType.CommodityYearlyBalanceTable,
        props.regionCommoditySelection,
    );
    const yearBalancesData = useYearlyBalancesApi(props.regionCommoditySelection, yearsToDisplay, props.unitOfMeasure);
    const [chartData, setChartData] = useState<IChartData[]>();

    useEffect(() => {
        if (!yearBalancesData?.rows) {
            return;
        }

        const newChartData = yearBalancesData.rows
            .filter((row) => row[props.balanceType] && row[props.balanceType].asOfDate)
            .slice(0, yearsToDisplay)
            .map((row) => ({
                value: props.isPercent ? +(row[props.balanceType].yearOverYearChangePercent ?? 0) : +(row[props.balanceType].value ?? 0),
                asOfDate: new Date(row[props.balanceType].asOfDate!),
                isActualValue: row[props.balanceType].isActualValue ?? true,
            }))
            .reverse();

        setChartData(newChartData);
    }, [yearBalancesData, props.balanceType, props.isPercent]);

    const title = useMemo(
        () =>
            `${translate(tableDefinitionRegion?.displayName ?? '')} ${translate(tableDefinitionCommodity?.displayName!)} ${translate(
                props.balanceType,
                'title',
            )}`,
        [tableDefinitionRegion, tableDefinitionCommodity, props.balanceType],
    );

    return (
        <ChartWrapper
            headerOptions={{
                isCsvDownloadAvailable: useFeatureFlag(DemeterFeatureType.DownloadsGeneral),
            }}
            name="BalanceTableChart"
            title={title}
            dataSourceTag={yearBalancesData?.dataSourceTag ?? ''}
            isLoading={!chartData || chartData.length < 1}
            testId={props.testId}
        >
            <BarChartRaw
                barSeries={[
                    {
                        label: title,
                        data: chartData ?? [],
                    },
                ]}
                format={props.isPercent ? 'percent' : 'value'}
                dataFrequency={DemeterDataFrequency.Yearly}
                unitOfMeasure={props.isPercent ? UnitOfMeasure.Percent : yearBalancesData?.unitOfMeasure}
                displayDecimalPlacesMinimum={props.isPercent ? 2 : 0}
                displayDecimalPlacesMaximum={props.isPercent ? 2 : 0}
            />
        </ChartWrapper>
    );
};

export default BalanceTableChart;
