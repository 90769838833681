import React from 'react';
import styles from './RegistrationButton.module.scss';

export enum RegistrationButtonType {
    WhitePrimary = 'WHITE_PRIMARY',
    WhiteSecondary = 'WHITE_SECONDARY',
    BluePrimary = 'BLUE_PRIMARY',
    WhitePure = 'WHITE_PURE',
}

interface ILinkButtonProps {
    title: string;
    type: RegistrationButtonType;
    navigateTo?: string;
    onClick?: () => void;
    disabled?: boolean;
    testId?: string;
}

const RegistrationButton: React.FC<ILinkButtonProps> = (props: ILinkButtonProps) => {
    let buttonStyle = styles.button_blue;
    let linkStyle = styles.button_link_white;

    if (props.type === RegistrationButtonType.BluePrimary) {
        buttonStyle = styles.registration_button_blue_primary;
        linkStyle = styles.registration_button_link_blue_primary;
    } else if (props.type === RegistrationButtonType.WhitePrimary) {
        buttonStyle = styles.registration_button_white_primary;
        linkStyle = styles.registration_button_link_white_primary;
    } else if (props.type === RegistrationButtonType.WhiteSecondary) {
        buttonStyle = styles.registration_button_white_secondary;
        linkStyle = styles.registration_button_link_white_secondary;
    } else if (props.type === RegistrationButtonType.WhitePure) {
        buttonStyle = styles.registration_button_white_pure;
        linkStyle = styles.registration_button_link_white_pure;
    }

    return (
        <button type="button" data-testid={props.testId} disabled={props.disabled} className={buttonStyle} onClick={() => props.onClick && props.onClick()}>
            {props.navigateTo ? (
                <a className={linkStyle} href={props.navigateTo}>
                    {props.title}
                </a>
            ) : (
                <div className={linkStyle}>{props.title}</div>
            )}
        </button>
    );
};

export default RegistrationButton;
