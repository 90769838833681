/* eslint-disable react/no-array-index-key */
import { FC, useState } from 'react';
import formattingService from '../../../../Core/Formatting/FormattingService';
import CheckboxDropdown from '../../../Components/Form/Inputs/CheckboxDropdown';
import { SelectInputOption } from '../../../Components/Form/Inputs/SelectInput';
import useLanguage from '../../../Services/Language/useLanguageHook';
import styles from './BasisCalculator.module.scss';
import { BasisAdjustment, basisIntervals, BasisPeriod } from './BasisCalculatorDefinitions';
import { IBasisCalculatorCalculationResult } from './BasisCalculatorService';

interface IBasisCalculatorGridProps {
    calculationResult?: IBasisCalculatorCalculationResult;
}

const BasisCalculatorGrid: FC<IBasisCalculatorGridProps> = (props: IBasisCalculatorGridProps) => {
    const [translations] = useLanguage();
    const [selectedItervals, setSelectedIntervals] = useState<BasisPeriod[]>([]);

    const intervalOptions: SelectInputOption<BasisPeriod>[] = basisIntervals.map((interval) => ({
        label: translations.calculators.basis.basisPeriod[interval],
        value: interval,
    }));

    const deciles = [
        {
            label: translations.calculators.basis.basisAdjustment[BasisAdjustment.Max],
            value: 10,
        },
        {
            label: '90%',
            value: 9,
        },
        {
            label: '80%',
            value: 8,
        },
        {
            label: '70%',
            value: 7,
        },
        {
            label: '60%',
            value: 6,
        },
        {
            label: '50%',
            value: 5,
        },
        {
            label: '40%',
            value: 4,
        },
        {
            label: '30%',
            value: 3,
        },
        {
            label: '20%',
            value: 2,
        },
        {
            label: '10%',
            value: 1,
        },
        {
            label: translations.calculators.basis.basisAdjustment[BasisAdjustment.Min],
            value: 0,
        },
    ];

    const randomKey = (min: number = 1, max: number = 1000000000): number => Math.floor(Math.random() * (max - min + 1)) + min;

    const handleIntervalChange = (values: BasisPeriod[]) => {
        const orderMap: Map<BasisPeriod, number> = new Map();
        basisIntervals.forEach((value, index) => {
            orderMap.set(value, index);
        });
        const sortedValues = values.sort((a, b) => {
            const indexA = orderMap.get(a) ?? -1;
            const indexB = orderMap.get(b) ?? -1;
            return indexA - indexB;
        });
        setSelectedIntervals(sortedValues);
    };

    return (
        <div className={styles.basis_calculator_grid}>
            <div className={styles.basis_calculator_grid_interval}>
                <CheckboxDropdown
                    values={selectedItervals}
                    options={intervalOptions}
                    handleOptionSelect={handleIntervalChange}
                    placeholder={translations.calculators.basis.form.selectIntervals}
                />
            </div>
            <div className={styles.basis_calculator_grid_table}>
                <div key={randomKey()} className={styles.basis_calculator_grid_table_row}>
                    <div className={styles.basis_calculator_grid_table_cell}> </div>
                    <div className={styles.basis_calculator_grid_table_cell_header}>{translations.calculators.basis.basisPeriod[BasisPeriod.Basis]}</div>
                    {selectedItervals.map((interval) => (
                        <div className={styles.basis_calculator_grid_table_cell_header}>{translations.calculators.basis.basisPeriod[interval]}</div>
                    ))}
                </div>
                <div key={randomKey()} className={styles.basis_calculator_grid_table_row}>
                    <div className={styles.basis_calculator_grid_table_cell_average}>
                        <strong>{translations.calculators.basis.basisAdjustment[BasisAdjustment.Average]}</strong>
                    </div>
                    <div className={styles.basis_calculator_grid_table_cell_average}>
                        {formattingService.toFormattedNumber(props.calculationResult?.basisAverage)}
                    </div>
                    {selectedItervals.map((interval) => (
                        <div className={styles.basis_calculator_grid_table_cell_average}>
                            {interval === BasisPeriod.ThreeMonthBasis && formattingService.toFormattedNumber(props.calculationResult?.threeMonthsBasisAverage)}
                            {interval === BasisPeriod.SixMonthBasis && formattingService.toFormattedNumber(props.calculationResult?.sixMonthsBasisAverage)}
                            {interval === BasisPeriod.NineMonthBasis && formattingService.toFormattedNumber(props.calculationResult?.nineMonthsBasisAverage)}
                            {interval === BasisPeriod.TwelveMonthBasis &&
                                formattingService.toFormattedNumber(props.calculationResult?.twelveMonthsBasisAverage)}
                        </div>
                    ))}
                </div>
                {deciles.map((decile, index) => (
                    <div key={randomKey()} className={styles.basis_calculator_grid_table_row}>
                        <div className={styles[`basis_calculator_grid_table_cell_decile${index}`]}>
                            <strong>{decile.label}</strong>
                        </div>
                        <div className={styles.basis_calculator_grid_table_cell}>
                            {formattingService.toFormattedNumber(props.calculationResult?.basisDeciles[index])}
                        </div>
                        {selectedItervals.map((interval) => (
                            <div className={styles.basis_calculator_grid_table_cell}>
                                {interval === BasisPeriod.ThreeMonthBasis &&
                                    formattingService.toFormattedNumber(props.calculationResult?.threeMonthsBasisDeciles[index])}
                                {interval === BasisPeriod.SixMonthBasis &&
                                    formattingService.toFormattedNumber(props.calculationResult?.sixMonthsBasisDeciles[index])}
                                {interval === BasisPeriod.NineMonthBasis &&
                                    formattingService.toFormattedNumber(props.calculationResult?.nineMonthsBasisDeciles[index])}
                                {interval === BasisPeriod.TwelveMonthBasis &&
                                    formattingService.toFormattedNumber(props.calculationResult?.twelveMonthsBasisDeciles[index])}
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default BasisCalculatorGrid;
