import { useMemo, useState } from 'react';
import { DemeterFilterTimeSpan, DemeterTableDefinitionType, UnitOfMeasure } from '../../../../Generated/Raven-Demeter';
import useOriginsAndDestinationsApiHook from '../../../Apis/Hooks/useOriginsAndDestinationsApiHook';
import useLanguage from '../../../Services/Language/useLanguageHook';
import OriginsAndDestinationsChart from '../../Charts/OriginsAndDestinations/OriginsAndDestinationsChart';
import JoinedSelectionButtons from '../../Form/Buttons/JoinedSelectionButtons/JoinedSelectionButtons';
import ComponentHeader from '../../Headers/ComponentHeader';
import { IRegionCommoditySelection } from '../../Navigation/Hooks/useRegionCommodityNavigationHook';
import useTableDefinition from '../../Navigation/Hooks/useTableDefinitionHook';
import OriginsAndDestinationsTable from '../../Tables/OriginsAndDestinations/OriginsAndDestinationsTable';
import styles from './OriginsAndDestinationsContainer.module.scss';

interface IOriginsAndDestinationsContainerProps {
    tableDefinitionType: DemeterTableDefinitionType;
    regionCommoditySelection: IRegionCommoditySelection;
    unitOfMeasure?: UnitOfMeasure;
    testId?: string;
}

export type DestinationsOrOrigins = 'Origins' | 'Destinations';

const OriginsAndDestinationsContainer: React.FC<IOriginsAndDestinationsContainerProps> = (props) => {
    const [tableDefinitionRegion, tableDefinitionCommodity] = useTableDefinition(props.tableDefinitionType, props.regionCommoditySelection);
    const originsAndDestinationsResponse = useOriginsAndDestinationsApiHook(props.tableDefinitionType, props.regionCommoditySelection!, props.unitOfMeasure);
    const [timeSpan, setTimeSpan] = useState<DemeterFilterTimeSpan>(DemeterFilterTimeSpan.OneYear);
    const [translations, translate] = useLanguage();

    const destinationsOrOrigins: DestinationsOrOrigins =
        props.tableDefinitionType === DemeterTableDefinitionType.CommodityMonthlyImportsTable ? 'Origins' : 'Destinations';

    const title = useMemo(() => {
        const subtitle =
            destinationsOrOrigins === 'Origins'
                ? translations.destinationsOrOrigins.subHeader.topOrigin
                : translations.destinationsOrOrigins.subHeader.topDestination;

        return `${translate(tableDefinitionRegion?.displayName ?? '')} ${translate(tableDefinitionCommodity?.displayName ?? '')} ${subtitle}`;
    }, [tableDefinitionRegion, tableDefinitionCommodity]);

    const timeSpanOptions = useMemo(
        () => [
            {
                label: translations.text.twelveMonths,
                value: DemeterFilterTimeSpan.OneYear,
            },
            {
                label: translations.text.yearToDateShort,
                value: DemeterFilterTimeSpan.YearToDate,
            },
        ],
        [],
    );

    return originsAndDestinationsResponse?.rows && originsAndDestinationsResponse?.rows?.length > 0 ? (
        <>
            <div className={styles.origins_and_destinations_donut_selection_buttons}>
                <ComponentHeader title={title} />
                <JoinedSelectionButtons
                    options={timeSpanOptions}
                    handleSelectionChange={(selectionValue: DemeterFilterTimeSpan) => setTimeSpan(selectionValue)}
                    selection={timeSpan}
                />
            </div>
            <div className={`${styles.master_flex} ${styles.master_width_100} ${styles.master_spacing_between_charts}`}>
                <div className={styles.origins_and_destinations_table}>
                    <OriginsAndDestinationsTable
                        filterTimeSpan={timeSpan}
                        originsOrDestinationsResponse={originsAndDestinationsResponse}
                        testId={`${props.testId}Table`}
                    />
                </div>
                <div className={styles.origins_and_destinations_chart}>
                    <OriginsAndDestinationsChart
                        filterTimeSpan={timeSpan}
                        title={title}
                        originsOrDestinationsResponse={originsAndDestinationsResponse}
                        testId={`${props.testId}Chart`}
                    />
                </div>
            </div>
        </>
    ) : null;
};

export default OriginsAndDestinationsContainer;
