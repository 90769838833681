import React from 'react';
import styles from './LoadingSpinner.module.scss';
import PageLoadingSpinner from './PageLoadingSpinner';

const LoadingSpinner: React.FC = () => (
    <div className={styles.loading_container}>
        <PageLoadingSpinner />
    </div>
);

export default LoadingSpinner;
