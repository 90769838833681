import HighchartsReact from 'highcharts-react-official';
import { useCallback, useState } from 'react';

const useChartReference = (): [
    // Use this for any components that needs the ref, basically the ChartWrapper.
    chartReference: HighchartsReact.RefObject | undefined,
    // Use this for the component that is setting the ref, basically the wrapper of HighChartsReact.
    setChartReference: (newChartReference: HighchartsReact.RefObject) => void,
] => {
    const [chartReference, setInternalChartReference] = useState<HighchartsReact.RefObject>();

    const setChartReference = useCallback((newChartReference: HighchartsReact.RefObject) => {
        setInternalChartReference(newChartReference);
    }, []);

    return [chartReference, setChartReference];
};

export default useChartReference;
