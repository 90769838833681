import React, { Children, PropsWithChildren, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import loggingService from '../../../Core/Logging/LoggingService';
import applicationSettings from '../../../Core/Settings/ApplicationSettings';
import PageLoadingSpinner from '../../Components/LoadingSpinner/PageLoadingSpinner';
import languageService, { Language } from './LanguageService';
import useLanguage from './useLanguageHook';

const LanguageWrapperPostTranslations: React.FC<PropsWithChildren> = (props: PropsWithChildren) => {
    const [languageLoaded, setLanguageLoaded] = useState(false);
    const [, , , setLanguage] = useLanguage();

    useEffect(() => {
        const loadLanguage = async () => {
            const browserLanguageCode = window?.navigator?.language;
            const enumLanguageCode = Object.values(Language).find((x) => browserLanguageCode === x || x.startsWith(browserLanguageCode));
            if (browserLanguageCode && enumLanguageCode) {
                await setLanguage(enumLanguageCode as Language);
            } else {
                loggingService.trackTrace(`Language does not exist: ${browserLanguageCode}, setting to default language: ${languageService.defaultLanguage}.`);
                await setLanguage(languageService.defaultLanguage);
            }
            setLanguageLoaded(true);
        };

        loadLanguage();
    }, []);

    return !languageLoaded ? <PageLoadingSpinner /> : <>{Children.only(props.children)}</>;
};

const LanguageWrapper: React.FC<PropsWithChildren> = (props: PropsWithChildren) => {
    const { ready: translationLoaded } = useTranslation(applicationSettings.locize.namespace);

    // We have to load the translations from locize before we use the useLanugageHook.
    // This is why we have a wrapper inside a wrapper.
    return !translationLoaded ? <PageLoadingSpinner /> : <LanguageWrapperPostTranslations>{Children.only(props.children)}</LanguageWrapperPostTranslations>;
};

export default LanguageWrapper;
