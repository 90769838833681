export type ChartBackgroundRectElement = HTMLElement & { width: { baseVal: { valueAsString: string } } };
export type DocumentObjectModelAttribute = 'data-column-number';

/* eslint-disable class-methods-use-this */
class DocumentObjectModelService {
    getFirstElementWithAnAttribute = (attribute: DocumentObjectModelAttribute) => {
        (document.querySelector(`[${attribute}]`) as HTMLElement).focus();
    };

    selectElementsWithAttributeStatus = (attribute: DocumentObjectModelAttribute, status: string | number, ignoreDisabledNodes?: boolean) => {
        const allInputElementsOfColumn: HTMLElement[] = [];
        document.querySelectorAll(`[${attribute}='${status}']`).forEach((node) => {
            if ((node as HTMLInputElement).disabled && ignoreDisabledNodes) {
                return;
            }
            allInputElementsOfColumn.push(node as HTMLElement);
        });

        return allInputElementsOfColumn;
    };

    getActiveElementParent = () => document.activeElement?.parentElement?.parentElement;

    getWidthOfAnElementByClassName = (className: string) =>
        (document.getElementsByClassName(className)[0] as ChartBackgroundRectElement)?.width.baseVal.valueAsString;
}

const documentObjectModelService = new DocumentObjectModelService();

export default documentObjectModelService;
