import React from 'react';
import stonexLogoSvg from '../../Assets/Icons/stonexPlusWhite.svg';
import useLanguage from '../../Services/Language/useLanguageHook';
import styles from './RegistrationPage.module.scss';

const RegistrationSelfSummary: React.FC = () => {
    // Actions hooks.
    const [translations] = useLanguage();

    return (
        <div className={styles.registration_self_summary}>
            <img className={styles.registration_self_summary_logo} src={stonexLogoSvg} alt="#" />

            <div>
                <p>{translations.registration.success.paragraph1}</p>
                <p>{translations.registration.success.paragraph2}</p>
                <p>{translations.registration.success.paragraph3}</p>
                <p>{translations.registration.success.paragraph4}</p>
                <p>{translations.registration.success.paragraph5}</p>
                <p>{translations.registration.success.paragraph6}</p>
            </div>
            <div>{translations.registration.success.copyright}</div>
        </div>
    );
};

export default RegistrationSelfSummary;
