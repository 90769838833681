/* eslint-disable class-methods-use-this */
import { IChartData } from '../../ChartDefinitions';

class MarketIndicatorTechnicalChartService {
    calculateMovingAverage = (data: IChartData[], days: number | string): IChartData[] => {
        const k = 2 / (Number(days) + 1);
        const emaArray: IChartData[] = [];
        let emaValue: number = 0;
        data.forEach((current, index) => {
            if (index === 0) {
                emaValue = current.value;
            } else {
                emaValue = current.value * k + emaValue * (1 - k);
            }
            emaArray.push({
                value: emaValue,
                asOfDate: current.asOfDate,
                isActualValue: current.isActualValue,
            });
        });
        return emaArray;
    };
}

const marketIndicatorTechnicalChartService = new MarketIndicatorTechnicalChartService();
export default marketIndicatorTechnicalChartService;
