import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import React from 'react';
import styles from './Headers.module.scss';

interface IComponentSubHeaderProps {
    title?: string | ReactJSXElement;
    containerClassName?: string;
}

const ComponentSubHeader: React.FC<IComponentSubHeaderProps> = (props: IComponentSubHeaderProps) => (
    <div className={props.containerClassName ?? styles.component_subheader_container}>
        {props.title && <h3 className={styles.component_subheader_main}>{props.title}</h3>}
    </div>
);

export default ComponentSubHeader;
