import formattingService from '../../../Core/Formatting/FormattingService';
import { MonthlyRiskCompositeModel } from './RiskDefinitions';
import styles from './RiskPage.module.scss';

interface IRiskDataProps {
    className?: string;
    calculatedRisk: MonthlyRiskCompositeModel[];
    fieldName: keyof MonthlyRiskCompositeModel;
    displayDecimalPlacesMinimum?: number | undefined;
}

const RiskDataRow: React.FC<IRiskDataProps> = (props: IRiskDataProps) => {
    const getTextValue = (positionOrBudget: MonthlyRiskCompositeModel): string => {
        const value = positionOrBudget ? +(positionOrBudget[props.fieldName] as number) : undefined;

        return props.displayDecimalPlacesMinimum === undefined
            ? formattingService.toPriceString(value)
            : formattingService.toNumberStringWithTrailingZerosOrDash(value, props.displayDecimalPlacesMinimum);
    };

    return (
        <div className={props.className}>
            {props.calculatedRisk.map((x) => (
                <div
                    className={x.isUpdatedValue ? styles.calculator_table_updated_column_text_only : styles.calculator_table_base_column_text_only}
                    key={`${props.fieldName}_${x.contractYear}_${x.contractMonth}`}
                >
                    {getTextValue(x)}
                </div>
            ))}
        </div>
    );
};

export default RiskDataRow;
