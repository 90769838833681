/* eslint-disable no-template-curly-in-string */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Children, useMemo, useState } from 'react';
import { EventActionsEnum, EventCategoriesEnum, EventDataTargetsEnum } from '../../Core/Logging/DataLayerDefinitions';
import loggingService from '../../Core/Logging/LoggingService';
import applicationSettings from '../../Core/Settings/ApplicationSettings';
import IconButton from '../../Refactor/Components/Form/Buttons/IconButton';
import JoinedSelectionButtons from '../../Refactor/Components/Form/Buttons/JoinedSelectionButtons/JoinedSelectionButtons';
import CheckboxDropdown from '../../Refactor/Components/Form/Inputs/CheckboxDropdown';
import DatePickerInput from '../../Refactor/Components/Form/Inputs/DatePickerInput';
import IconMenuDropdown from '../../Refactor/Components/Form/Inputs/IconMenuDropdown';
import ComponentHeader from '../../Refactor/Components/Headers/ComponentHeader';
import EmailLink from '../../Refactor/Components/Navigation/EmailLink/EmailLink';
import { ActualOrRollingType } from '../../Refactor/Pages/Futures/Prices/FuturesPricesChart';
import useLanguage from '../../Refactor/Services/Language/useLanguageHook';
import AlertModal from '../AlertModal/AlertModal';
import chartTypeSvg from '../Assets/chart-type.svg';
import chartPopoutSvg from '../Assets/chartPopout.svg';
import ChartPopOutModal from '../ChartPopOutModal/ChartPopOutModal';
import ChartsEnum from '../ChartPopOutModal/ChartsEnum';
import DataSourceTag from '../DataSourceTag/DataSourceTag';
import styles from './ChartWrapper.module.scss';
import ForwardCurveSpreadHeader from './ForwardCurveSpread/ForwardCurveSpreadHeader';
import ExportOptionsDropdown from './Interactions/ExportOptionsDropdown/ExportOptionsDropdown';
import { IChartWrapperProps } from './InterfaceChartWrapper';

const ChartWrapper: React.FC<IChartWrapperProps> = (props) => {
    const [open, setOpen] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [translations, translate] = useLanguage();

    const actualRollingOptions: { label: string; value: ActualOrRollingType }[] = useMemo(
        () => [
            {
                label: translations.words.actual,
                value: 'actual',
            },
            {
                label: translations.words.rolling,
                value: 'rolling',
            },
        ],
        [],
    );

    const updateButtonSelectionWithAnalytics = (selectionValue: ActualOrRollingType) => {
        let isRolling;
        if (selectionValue === 'rolling') {
            isRolling = true;
        } else {
            isRolling = false;
        }
        loggingService.trackEventWithAnalytics(
            EventActionsEnum.ButtonClick,
            EventCategoriesEnum.JoinedButtonsClicked,
            isRolling ? 'Rolling' : 'Actual',
            EventDataTargetsEnum.LightStreamerGraph,
            props.setIsRollingData,
            isRolling,
        );
    };

    const handleClickOpen = () => {
        loggingService.trackEventWithAnalytics(EventActionsEnum.ModalInteraction, EventCategoriesEnum.PopoutChartModalOpen, props.chartName, props.chartName);
        if (props.handleChartPopped !== undefined) {
            props.handleChartPopped(true);
        }
        setOpen(true);
        if (props.setIsChartPopped) {
            props.setIsChartPopped(true);
        }
    };

    const handleClickClose = () => {
        loggingService.trackEventWithAnalytics(EventActionsEnum.ModalInteraction, EventCategoriesEnum.PopoutChartModalClose, props.chartName, props.chartName);
        if (props.handleChartPopped !== undefined) {
            props.handleChartPopped(false);
        }
        setOpen(false);
        if (props.setIsChartPopped) {
            props.setIsChartPopped(false);
        }
    };

    return (
        <ChartPopOutModal openChart={open} closeChart={handleClickClose} chartNameRef={props.chartName}>
            <>
                <div
                    className={
                        props.chartName === ChartsEnum.ForwardCurveSpreadChart || props.chartName === ChartsEnum.PriceCurveChart
                            ? styles.chart_wrapper_curve_header
                            : `${styles.master_flex} ${styles.chart_wrapper_chart_header}`
                    }
                >
                    {!props.price && !props.ignoreHeaderValue && <ComponentHeader title={props.header} />}
                    {props.chartName === ChartsEnum.ForwardCurveSpreadChart && <ForwardCurveSpreadHeader {...props} />}
                    {props.hasDatePicker && (
                        <div className={styles.chart_wrapper_date_picker}>
                            <DatePickerInput value={props.datePickerValue} handleDateChange={props.setDatePickerValue} />
                        </div>
                    )}

                    <div className={props.isSmallChart ? styles.chart_wrapper_header_right_side_small_chart : styles.chart_wrapper_header_right_side}>
                        <div className={styles.chart_wrapper_small_screen_100}>
                            {props.hasActualRollingButons && (
                                <div className={styles.chart_wrapper_actual_rolling_margin}>
                                    <JoinedSelectionButtons
                                        options={actualRollingOptions}
                                        handleSelectionChange={updateButtonSelectionWithAnalytics}
                                        selection={props.isRollingData ? 'rolling' : 'actual'}
                                    />
                                </div>
                            )}
                            {props.selectedValues && props.onRemove && props.onSelect && props.dropdownPlaceholder && (
                                <div className={styles.chart_wrapper_dropdown_container}>
                                    <CheckboxDropdown
                                        options={props.options[1]}
                                        handleOptionSelect={props.onSelect}
                                        values={props.selectedValues}
                                        placeholder={props.dropdownPlaceholder ?? translations.actions.select}
                                        isSelectionLimit={props.isLimit}
                                        limitMessage={props.limitMessage}
                                    />
                                </div>
                            )}
                            {props.handleChartTypeChange && props.chartTypeOptions && (
                                <IconMenuDropdown
                                    iconButton={<img className={styles.chart_wrapper_dropdown_img} src={chartTypeSvg} alt="#" />}
                                    options={props.chartTypeOptions}
                                    handleSelection={props.handleChartTypeChange}
                                    tooltip={translations.charts.buttons.changeType}
                                />
                            )}
                            {!props.exportDisabled && <ExportOptionsDropdown {...props} setShowModal={setShowModal} />}
                            {!props.disablePopoutButton && !open && (
                                <IconButton
                                    className={styles.chart_wrapper_image_button}
                                    handleClick={handleClickOpen}
                                    tooltip={translations.charts.buttons.showModal}
                                >
                                    <img className={styles.chart_wrapper_dropdown_img} src={chartPopoutSvg} alt="#" />
                                </IconButton>
                            )}
                            {showModal ? (
                                <AlertModal
                                    setShowModal={setShowModal}
                                    message={
                                        <h3>
                                            {translations.errors.downloadSubscriptionError}{' '}
                                            <EmailLink linkText={translations.text.brokerSupport} email={applicationSettings.emails.supportEmail} />
                                        </h3>
                                    }
                                />
                            ) : null}
                        </div>
                    </div>
                </div>
                <ComponentHeader subtitle={props.subHeader} />
                {Children.only(props.children)}
                <DataSourceTag value={props.dataSourceTag} />
            </>
        </ChartPopOutModal>
    );
};

export default ChartWrapper;
