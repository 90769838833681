import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import jwt_decode from 'jwt-decode';
import { DemeterMarket, DemeterPermissionModel, DemeterRegistrationSource, DemeterUserType } from '../../Generated/Raven-Demeter';
import type { FeatureFlags } from '../../Refactor/Services/FeatureFlags/FeatureFlagsService';
import type { RootState } from '../ReduxStore';

export interface UserState {
    userGuid?: string;
    userType?: DemeterUserType;
    userName?: string;
    accessToken?: string;
    currentMarket?: DemeterMarket;
    demeterCompanyGuid?: string;
    featureFlags: FeatureFlags;
    identityToken?: string;
    markets: DemeterMarket[];
    permissions?: DemeterPermissionModel[];
    registrationSource?: DemeterRegistrationSource;
    sessionToken?: string;
}

type UserTokensType = {
    accessToken?: string;
    identityToken?: string;
    sessionToken?: string;
};

const initialState: UserState = {
    featureFlags: { features: {} },
    markets: [],
};

// This file was based off the Redux example:
// For information about async reducers check out the below.
// https://github.com/reduxjs/cra-template-redux-typescript/blob/master/template/src/features/counter/counterSlice.ts
export const userSlice = createSlice({
    name: 'user',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions.
    reducers: {
        setUser: (
            state: UserState,
            action: PayloadAction<UserTokensType & { markets?: DemeterMarket[]; demeterCompanyGuid?: string; registrationSource?: DemeterRegistrationSource }>,
        ): void => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes.
            state.accessToken = action.payload.accessToken;
            state.identityToken = action.payload.identityToken;
            state.sessionToken = action.payload.sessionToken;

            const decodedToken = jwt_decode(state.accessToken!) as { cds_id: string; sub: string };
            state.userGuid = decodedToken.cds_id;
            state.userName = decodedToken.sub;
            state.demeterCompanyGuid = action.payload.demeterCompanyGuid ?? state.demeterCompanyGuid;
            state.markets = action.payload.markets?.sort() ?? state.markets ?? [];
            state.registrationSource = action.payload.registrationSource ?? state.registrationSource;
        },
        setUserType: (state: UserState, action: PayloadAction<{ userType: DemeterUserType }>): void => {
            state.userType = action.payload.userType;
        },
        setUserCurrentMarket: (state: UserState, action: PayloadAction<{ userCurrentMarket: DemeterMarket }>): void => {
            state.currentMarket = action.payload.userCurrentMarket;
        },
        setUserPermissions: (state: UserState, action: PayloadAction<{ userPermissions: DemeterPermissionModel[] }>): void => {
            state.permissions = action.payload.userPermissions;
        },
        setFeatureFlags: (state: UserState, action: PayloadAction<{ featureFlags: FeatureFlags }>): void => {
            state.featureFlags = action.payload.featureFlags;
        },
        resetUser: (state: UserState): void => {
            delete state.userGuid;
            delete state.demeterCompanyGuid;
            delete state.accessToken;
            delete state.identityToken;
            delete state.userName;
            delete state.registrationSource;
        },
    },
});

export const { setUser, resetUser, setUserType, setUserCurrentMarket, setFeatureFlags, setUserPermissions } = userSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`.
export const selectUserTokens = (state: RootState): UserTokensType | null => ({
    accessToken: state.user?.accessToken,
    identityToken: state.user?.identityToken,
    sessionToken: state.user?.sessionToken,
});
export const selectUserGuid = (state: RootState): string | null => state.user?.userGuid ?? null;
export const selectUserName = (state: RootState): string | null => state.user?.userName ?? null;
export const selectUserType = (state: RootState): DemeterUserType | null => state.user?.userType ?? null;
export const selectUserCurrentMarket = (state: RootState): DemeterMarket => state.user?.currentMarket!;
export const selectUserMarkets = (state: RootState): DemeterMarket[] => state.user?.markets;
export const selectUserPermissions = (state: RootState): DemeterPermissionModel[] => state.user?.permissions ?? [];
export const selectFeatureFlags = (state: RootState): FeatureFlags => state.user?.featureFlags;
export const selectCompanyGuid = (state: RootState): string | null => state.user?.demeterCompanyGuid ?? null;
export const selectRegistrationSource = (state: RootState): string | null => state.user?.registrationSource ?? null;

export default userSlice.reducer;
