import useLanguage from '../../../Services/Language/useLanguageHook';
import styles from './IndicatorFrequencyNote.module.scss';

const IndicatorFrequencyNote: React.FC = () => {
    const [translations] = useLanguage();
    return (
        <div className={styles.indicators_frequency_flex}>
            <p className={styles.indicators_frequency_header}>{`${translations.dashboard.indicators.indicatorFrequency}:`}</p>
            <p className={styles.indicators_frequency_footnote}>
                {`D = ${translations.dataFrequency.Daily}, 
                  W = ${translations.dataFrequency.Weekly}, 
                  M = ${translations.dataFrequency.Monthly}, 
                  Y = ${translations.dataFrequency.Yearly}, 
                  S = ${translations.dataFrequency.Streaming}`}
            </p>
        </div>
    );
};

export default IndicatorFrequencyNote;
