import { AxiosResponse } from 'axios';
import useApi, { UseApiOptions } from './useApiHook';

const useApiWithoutAutoExecute = <TRequest, TResponse>(
    apiCallback: (request?: TRequest) => Promise<AxiosResponse<TResponse, any>> | null | undefined,
    options?: UseApiOptions,
): [loading: boolean, callApi: (request?: TRequest) => void, data?: TResponse, error?: string] => {
    const [loading, callApi, data, error] = useApi(apiCallback, { ...options, stopAutoExecute: true });

    return [loading, callApi, data, error];
};

export default useApiWithoutAutoExecute;
