import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { demeterApi } from '../../../../Apis/Apis';
import CacheKeys from '../../../../Core/Cache/CacheKeys';
import formattingService from '../../../../Core/Formatting/FormattingService';
import {
    Currency,
    DemeterDataFrequency,
    DemeterFilterTimeSpan,
    DemeterSymbolModel,
    ListMarketPricesForwardCurveWithConversionsResponse,
    MarketPricesTimeSpan,
    UnitOfMeasure,
} from '../../../../Generated/Raven-Demeter';
import useCacheThenApi from '../../../Apis/Hooks/useCacheThenApiHook';
import useSymbolsApi from '../../../Apis/Hooks/useSymbolsApiHook';
import { ChartDisplayType, IChartPriceDataSeries } from '../../../Components/Charts/ChartDefinitions';
import ChartWrapper from '../../../Components/Charts/ChartWrapper/ChartWrapper';
import FilterTimeSpans from '../../../Components/Charts/FilterTimeSpans/FilterTimeSpans';
import PriceChartRaw from '../../../Components/Charts/Price/PriceChartRaw';
import DatePickerInput from '../../../Components/Form/Inputs/DatePickerInput';
import { IExchangeCommoditySelection } from '../../../Components/Navigation/Hooks/useExchangeCommodityNavigationHook';
import useLanguage from '../../../Services/Language/useLanguageHook';
import styles from './FuturesPricesTable.module.scss';

export interface FuturesPricesForwardCurveChartProps {
    exchangeCommoditySelection: IExchangeCommoditySelection;
    currency?: Currency;
    unitOfMeasure?: UnitOfMeasure;
    testId?: string;
}

const availableFilterTimeSpans = [
    DemeterFilterTimeSpan.OneYear,
    DemeterFilterTimeSpan.ThreeYears,
    DemeterFilterTimeSpan.FiveYears,
    DemeterFilterTimeSpan.TenYears,
];

const defaultFilterTimeSpan = DemeterFilterTimeSpan.FiveYears;

const oneMonthAgo = moment().subtract(1, 'M');
const twoMonthsAgo = moment().subtract(2, 'M');

const FuturesPricesForwardCurveChart: React.FC<FuturesPricesForwardCurveChartProps> = (props: FuturesPricesForwardCurveChartProps) => {
    // Text hooks.
    const [translations] = useLanguage();

    // Symbols hooks.
    const symbols = useSymbolsApi();
    const [symbolModel, setSymbolModel] = useState<DemeterSymbolModel>();

    // Data hooks.
    const [linesSeries, setLinesSeries] = useState<IChartPriceDataSeries[]>([]);
    const [filterTimeSpan, setFilterTimeSpan] = useState<DemeterFilterTimeSpan>(defaultFilterTimeSpan);
    const [asOfDate1, setAsOfDate1] = useState<Date>(oneMonthAgo.toDate());
    const [asOfDate2, setAsOfDate2] = useState<Date>(twoMonthsAgo.toDate());

    const [marketPricesForwardCurveLoading1, , marketPricesForwardCurveData1] = useCacheThenApi(
        // eslint-disable-next-line max-len
        `${CacheKeys.ListMarketPricesForwardCurves}1_${symbolModel?.reutersInstrumentCodePrefix}_${asOfDate1}_${props.currency}__${props.unitOfMeasure}_${filterTimeSpan}`,
        () => {
            if (!symbolModel) {
                return null;
            }

            return demeterApi.listMarketPricesForwardCurveWithConversions(
                symbolModel.reutersInstrumentCodePrefix,
                formattingService.toApiDate(asOfDate1),
                props.currency,
                props.unitOfMeasure,
                filterTimeSpan as MarketPricesTimeSpan,
            );
        },
    );

    const [marketPricesForwardCurveLoading2, , marketPricesForwardCurveData2] = useCacheThenApi(
        // eslint-disable-next-line max-len
        `${CacheKeys.ListMarketPricesForwardCurves}2_${symbolModel?.reutersInstrumentCodePrefix}_${asOfDate2}_${props.currency}__${props.unitOfMeasure}_${filterTimeSpan}`,
        () => {
            if (!symbolModel) {
                return null;
            }

            return demeterApi.listMarketPricesForwardCurveWithConversions(
                symbolModel.reutersInstrumentCodePrefix,
                formattingService.toApiDate(asOfDate2),
                props.currency,
                props.unitOfMeasure,
                filterTimeSpan as MarketPricesTimeSpan,
            );
        },
    );
    useEffect(() => {
        if (!symbols) {
            return;
        }

        const selectedSymbol = symbols.find(
            (x) => x.exchange === props.exchangeCommoditySelection.exchange && x.commodity === props.exchangeCommoditySelection.commodity,
        );

        if (selectedSymbol) {
            setSymbolModel(selectedSymbol);
        }
    }, [symbols, props.exchangeCommoditySelection]);

    const getForwardCurveValues = (marketPricesForwardCurveData: ListMarketPricesForwardCurveWithConversionsResponse) =>
        marketPricesForwardCurveData.asOfDateMarketPrices!.map((row) => {
            const rowAsOfDate = new Date(row.asOfDate);

            return {
                value: row.settlementPrice ?? 0,
                asOfDate:
                    row.contractYear === rowAsOfDate.getFullYear() && row.contractMonth === rowAsOfDate.getMonth() + 1
                        ? new Date(new Date(rowAsOfDate).setDate(rowAsOfDate.getDate() + 1))
                        : new Date(row.contractYear, row.contractMonth - 1, 1),
                isActualValue: false,
            };
        });

    useEffect(() => {
        if (
            marketPricesForwardCurveLoading1 ||
            marketPricesForwardCurveLoading2 ||
            !marketPricesForwardCurveData1 ||
            !marketPricesForwardCurveData2 ||
            !marketPricesForwardCurveData1.rows ||
            !marketPricesForwardCurveData2.rows ||
            marketPricesForwardCurveData1.rows?.length === 0 ||
            marketPricesForwardCurveData2.rows?.length === 0
        ) {
            setLinesSeries([]);
            return;
        }

        const actualValues = marketPricesForwardCurveData1.rows!.map((row) => ({
            value: row.settlementPrice ?? 0,
            asOfDate: new Date(row.asOfDate),
            isActualValue: true,
        }));

        const lastAsOfDate = actualValues[actualValues.length - 1].asOfDate;
        const currentDayValues = marketPricesForwardCurveData1?.currentMarketPrices!.map((row) => ({
            value: row.settlementPrice ?? 0,
            // If we are at the current month, then set the next contract date to the lastAsOfDate + 1 day,
            // otherwise set it to the first of the month.
            asOfDate:
                row.contractYear === lastAsOfDate.getFullYear() && row.contractMonth === lastAsOfDate.getMonth() + 1
                    ? new Date(new Date(lastAsOfDate).setDate(lastAsOfDate.getDate() + 1))
                    : new Date(row.contractYear, row.contractMonth - 1, 1),
            isActualValue: false,
        }));

        const currentMarketPrices = [...actualValues, ...currentDayValues];
        const forwardCurveValues1 = getForwardCurveValues(marketPricesForwardCurveData1);
        const forwardCurveValues2 = getForwardCurveValues(marketPricesForwardCurveData2);

        setLinesSeries([
            {
                label: formattingService.toDisplayName(symbolModel),
                forecastLabel: translations.charts.legend.lastSettlement,
                data: currentMarketPrices,
            },
            {
                label: formattingService.toShortDayMonthYear(asOfDate1),
                data: [],
                forwardCurveData: forwardCurveValues1,
                forwardCurveLabel: formattingService.toShortDayMonthYear(asOfDate1),
                forwardCurveLineStyle: 'ShortDash',
            },
            {
                label: formattingService.toShortDayMonthYear(asOfDate2),
                data: [],
                forwardCurveData: forwardCurveValues2,
                forwardCurveLabel: formattingService.toShortDayMonthYear(asOfDate2),
                forwardCurveLineStyle: 'ShortDash',
            },
        ]);
    }, [marketPricesForwardCurveData1, marketPricesForwardCurveData2]);

    const title = useMemo(() => `${formattingService.toDisplayName(symbolModel)} ${translations.futures.headers.priceCurve}`, [symbolModel]);

    const currency = useMemo<Currency>(
        () => (marketPricesForwardCurveData1 && marketPricesForwardCurveData1.currency)! ?? props.currency,
        [marketPricesForwardCurveData1, props.currency],
    );
    const unitOfMeasure = useMemo<UnitOfMeasure>(
        () => (marketPricesForwardCurveData1 && marketPricesForwardCurveData1.unitOfMeasure)! ?? props.unitOfMeasure,
        [marketPricesForwardCurveData1, props.unitOfMeasure],
    );

    return (
        <ChartWrapper
            name="FuturesPricesForwardCurveChart"
            title={title}
            dataSourceTag={marketPricesForwardCurveData1?.dataSourceTag ?? ''}
            isLoading={marketPricesForwardCurveLoading1 && marketPricesForwardCurveLoading2 && linesSeries.length === 0}
            header={
                <div className={styles.futures_prices_multiple_header_components_container}>
                    <DatePickerInput
                        value={asOfDate1}
                        handleDateChange={(newDate: Date | undefined) => {
                            if (newDate) {
                                setAsOfDate1(newDate);
                            }
                        }}
                    />
                    <DatePickerInput
                        value={asOfDate2}
                        handleDateChange={(newDate: Date | undefined) => {
                            if (newDate) {
                                setAsOfDate2(newDate);
                            }
                        }}
                    />
                </div>
            }
            footer={
                <FilterTimeSpans
                    name="FuturesPricesForwardCurveChart"
                    filterTimeSpanOptions={availableFilterTimeSpans}
                    filterTimeSpan={filterTimeSpan}
                    handleTimeSpanSelected={(timeSpan) => setFilterTimeSpan(timeSpan)}
                />
            }
            testId={props.testId}
        >
            <PriceChartRaw
                displayType={ChartDisplayType.Line}
                linesSeries={linesSeries}
                dataFrequency={DemeterDataFrequency.Daily}
                hidePriceNavigator
                currency={currency}
                unitOfMeasure={unitOfMeasure}
                displayDecimalPlacesMinimum={0} // TODO: Make these futures constants somewhere.
                displayDecimalPlacesMaximum={4} // TODO: Use symbols to determine these.
            />
        </ChartWrapper>
    );
};

export default FuturesPricesForwardCurveChart;
