import { useMemo } from 'react';
import applicationSettings from '../../../../../../../Core/Settings/ApplicationSettings';
import { DemeterMarket } from '../../../../../../../Generated/Raven-Demeter';
import Dropdown from '../../../../../../Components/Form/Inputs/Dropdown';
import useLanguage from '../../../../../../Services/Language/useLanguageHook';
import styles from '../../MarketIndicatorFactorPage.module.scss';

interface IDataSelectorMarketDropdownProps {
    market: DemeterMarket;
    handleChange: (market?: DemeterMarket) => void;
}

const DataSelectorMarketDropdown: React.FC<IDataSelectorMarketDropdownProps> = (props: IDataSelectorMarketDropdownProps) => {
    // Application hooks.
    const [translations] = useLanguage();

    // Dropdown options - these have more markets than the appllication supports due to needing more data from other markets.

    const marketOptions = useMemo(
        () => [
            {
                label: translations.market[DemeterMarket.Currency],
                value: DemeterMarket.Currency,
            },
            {
                label: translations.market[DemeterMarket.Dairy],
                value: DemeterMarket.Dairy,
            },
            {
                label: translations.market[DemeterMarket.Energy],
                value: DemeterMarket.Energy,
            },
            {
                label: translations.market[DemeterMarket.Grains],
                value: DemeterMarket.Grains,
            },
            {
                label: translations.market[DemeterMarket.Other],
                value: DemeterMarket.Other,
            },
        ],
        [translations],
    );

    return (
        <div className={styles.indicator_add_and_edit_dropdown}>
            <Dropdown
                value={props.market ?? applicationSettings.markets[0]}
                options={marketOptions}
                handleOptionChange={props.handleChange}
                label={translations.words.sector}
            />
        </div>
    );
};

export default DataSelectorMarketDropdown;
