import { useEffect, useState } from 'react';
import { DemeterCommodity, DemeterRegion, DemeterTableDefinitionType } from '../../../Generated/Raven-Demeter/api';
import useCommentaryApi from '../../Apis/Hooks/useCommentaryApiHook';
import { ListCommodityMonthlyProjectionResponse } from '../../Apis/Hooks/useProjectionApiHook';
import AggregateRegionSelector from '../../Components/AggregateRegionSelector/AggregateRegionSelector';
import ProjectionChart from '../../Components/Charts/Projection/ProjectionChart';
import SeasonalChart from '../../Components/Charts/Seasonal/SeasonalChart';
import UnitOfMeasureMetricImperialDropdown from '../../Components/Form/Inputs/UnitOfMeasureMetricImperialDropdown';
import PageHeaderWithSelections from '../../Components/Headers/PageHeaderWithSelections';
import PageLoadingSpinner from '../../Components/LoadingSpinner/PageLoadingSpinner';
import useTableDefinitionNavigation from '../../Components/Navigation/Hooks/useTableDefinitionNavigationHook';
import SeasonalValueAndPercentTables from '../../Components/Tables/Seasonal/SeasonalValueAndPercentTables';
import useUnitOfMeasure from '../../Core/Hooks/useUnitOfMeasureHook';
import useLanguage from '../../Services/Language/useLanguageHook';
import styles from './ProductionPage.module.scss';

const ProductionPage: React.FC = () => {
    // Constants.
    const [translations] = useLanguage();
    const pageTitle = translations.production.title;
    const tableDefinitionType = DemeterTableDefinitionType.CommodityMonthlyProductionTable;
    const [unitOfMeasure, setUnitOfMeasure] = useUnitOfMeasure('MetricImperial');

    // Hooks.
    const [, tableDefinitionCommodity, regionCommoditySelection] = useTableDefinitionNavigation(tableDefinitionType);
    const commentary = useCommentaryApi(tableDefinitionCommodity?.demeterCommentaryGuid);
    const [aggregateRegions, setAggregateRegions] = useState<DemeterRegion[]>([]);

    const showMarginChart =
        tableDefinitionCommodity?.commodity === DemeterCommodity.Milk &&
        (regionCommoditySelection?.region === DemeterRegion.EuropeanUnion || regionCommoditySelection?.region === DemeterRegion.UnitedStates) &&
        !regionCommoditySelection.subRegion;

    useEffect(() => {
        setAggregateRegions([]);
    }, [regionCommoditySelection?.region]);

    const getSubtitle = (data: ListCommodityMonthlyProjectionResponse): string => {
        if (!data?.rows || data?.rows!.length === 0) {
            return translations.production.marginChart.subtitle;
        }

        return `${translations.production.marginChart.subtitle}, ${data.currency}/${translations.unitOfMeasure[`Short${data.unitOfMeasure}`]}`;
    };

    return (
        <div className={styles.master_page_container}>
            <PageHeaderWithSelections
                title={pageTitle}
                tableDefinitionType={tableDefinitionType}
                rightSideOfHeaderComponent={<UnitOfMeasureMetricImperialDropdown unitOfMeasure={unitOfMeasure} handleChange={setUnitOfMeasure} />}
                testId="ProductionPageHeader"
            />
            {!tableDefinitionCommodity || !regionCommoditySelection ? (
                <PageLoadingSpinner />
            ) : (
                <>
                    <div className={styles.production_main_container}>
                        <div className={styles.production_projection_chart}>
                            <ProjectionChart
                                title={pageTitle}
                                tableDefinitionType={tableDefinitionType}
                                regionCommoditySelection={regionCommoditySelection}
                                aggregateRegions={aggregateRegions}
                                unitOfMeasure={unitOfMeasure}
                                testId="ProductionProjectionChart"
                            />
                        </div>
                        {regionCommoditySelection.region === DemeterRegion.MajorExporters ? (
                            <div className={styles.production_commentary_container}>
                                <AggregateRegionSelector
                                    title={translations.region.MajorExporters}
                                    tableDefinitionType={tableDefinitionType}
                                    regionCommoditySelection={regionCommoditySelection}
                                    handleRegionSelections={setAggregateRegions}
                                />
                            </div>
                        ) : (
                            tableDefinitionCommodity?.demeterCommentaryGuid && (
                                <div className={styles.production_commentary_container}>
                                    <p>{commentary}</p>
                                </div>
                            )
                        )}
                    </div>
                    <div />
                    <div className={styles.production_charts_container}>
                        {showMarginChart ? (
                            <>
                                <div className={styles.production_projection_chart_50}>
                                    <ProjectionChart
                                        title={translations.production.marginChart.title}
                                        subtitle={(data) => getSubtitle(data)}
                                        hideCommodityDisplayName
                                        tableDefinitionType={DemeterTableDefinitionType.CommodityMonthlyMarginTable}
                                        regionCommoditySelection={regionCommoditySelection}
                                        aggregateRegions={aggregateRegions}
                                        solidLineFields={[
                                            { fieldName: 'expansion', title: translations.charts.solidLine.expansion },
                                            { fieldName: 'contraction', title: translations.charts.solidLine.contraction },
                                        ]}
                                        unitOfMeasure={unitOfMeasure}
                                        testId="ProductionMarginChart"
                                    />
                                </div>
                                <div className={styles.production_seasonal_chart_50}>
                                    <SeasonalChart
                                        title={pageTitle}
                                        tableDefinitionType={tableDefinitionType}
                                        regionCommoditySelection={regionCommoditySelection}
                                        aggregateRegions={aggregateRegions}
                                        unitOfMeasure={unitOfMeasure}
                                        testId="ProductionSeasonalChart"
                                    />
                                </div>
                            </>
                        ) : (
                            <div className={styles.production_seasonal_chart}>
                                <SeasonalChart
                                    title={pageTitle}
                                    tableDefinitionType={tableDefinitionType}
                                    regionCommoditySelection={regionCommoditySelection}
                                    aggregateRegions={aggregateRegions}
                                    unitOfMeasure={unitOfMeasure}
                                    testId="ProductionSeasonalChart"
                                />
                            </div>
                        )}
                    </div>
                    <SeasonalValueAndPercentTables
                        title={pageTitle}
                        tableDefinitionType={tableDefinitionType}
                        regionCommoditySelection={regionCommoditySelection}
                        aggregateRegions={aggregateRegions}
                        unitOfMeasure={unitOfMeasure}
                        testId="ProductionSeasonalValueAndPercentTables"
                    />
                </>
            )}
        </div>
    );
};
export default ProductionPage;
