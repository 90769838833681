/* eslint-disable class-methods-use-this */
import { IChartAreaRangeData, IChartData } from '../../ChartDefinitions';

class MarketIndicatorHistoricalChartService {
    calculateLastYearData(data: IChartData[]): IChartData[] {
        const lastYearsData = data.map((dataItem) => {
            const currentDate = dataItem.asOfDate;
            const lastYearDate = new Date(currentDate);
            lastYearDate.setFullYear(currentDate.getFullYear() - 1);
            const lastYearData = data.find((d) => d.asOfDate >= lastYearDate);
            return {
                value: lastYearData?.value,
                asOfDate: currentDate,
                isActualValue: lastYearData?.isActualValue,
            } as IChartData;
        });
        return lastYearsData;
    }

    calculateDecilesData = (data: IChartData[], yearsBack: number): { [decileRank: string]: IChartAreaRangeData[] } => {
        const decilesData: { [decile: string]: IChartAreaRangeData[] } = {};
        data.forEach((currentData) => {
            const currentDate = new Date(currentData.asOfDate);
            const startDate = new Date(currentDate);
            startDate.setFullYear(currentDate.getFullYear() - yearsBack);

            const valuesInRange = data
                .filter((d) => {
                    const dDate = new Date(d.asOfDate);
                    return dDate >= startDate && dDate <= currentDate;
                })
                .map((d) => d.value);

            if (valuesInRange.length > 0) {
                const deciles = this.calculateDeciles(valuesInRange);
                deciles.forEach((decile, index) => {
                    const decileRank = index * 2 + 2;
                    if (!decilesData[decileRank]) {
                        decilesData[decileRank] = [];
                    }
                    decilesData[decileRank].push({
                        asOfDate: currentData.asOfDate,
                        minimumValue: decile.minValue,
                        maximumValue: decile.maxValue,
                        isActualValue: currentData.isActualValue,
                    } as IChartAreaRangeData);
                });
            }
        });
        return decilesData;
    };

    calculateDeciles = (values: number[]): { minValue: number; maxValue: number }[] => {
        values.sort((a, b) => a - b);
        const deciles: { minValue: number; maxValue: number }[] = [];

        for (let i = 0; i < 10; i += 2) {
            const minValue = this.getDecile(i, values);
            const maxValue = this.getDecile(i + 2, values);
            deciles.push({ minValue, maxValue });
        }
        return deciles;
    };

    getDecile = (decileRank: number, values: number[]) => {
        if (decileRank === 0) {
            return values[0];
        }
        if (decileRank === 10) {
            return values[values.length - 1];
        }
        const rawDecileIndex = (values.length * decileRank) / 10;
        const lowerDecileIndex = Math.floor(rawDecileIndex);
        const currentDecile =
            values[lowerDecileIndex] + (values[lowerDecileIndex + 1] - values[lowerDecileIndex]) * +(rawDecileIndex - Math.floor(rawDecileIndex));
        return currentDecile;
    };

    calculateAverageData = (data: IChartData[], yearsBack: number): IChartData[] => {
        const averages = data.map((dataItem) => {
            const currentDate = new Date(dataItem.asOfDate);
            const pastDate = new Date(currentDate);
            pastDate.setFullYear(currentDate.getFullYear() - yearsBack);

            const dateRangeValues = data
                .filter((item) => {
                    const itemDate = new Date(item.asOfDate);
                    return itemDate >= pastDate && itemDate <= currentDate;
                })
                .map((item) => item.value);

            const averageValue = dateRangeValues.reduce((sum, value) => sum + value, 0) / dateRangeValues.length;

            return {
                value: averageValue,
                asOfDate: dataItem.asOfDate,
                isActualValue: dataItem.isActualValue,
            };
        }) as IChartData[];
        return averages;
    };
}

const marketIndicatorHistoricalChartService = new MarketIndicatorHistoricalChartService();
export default marketIndicatorHistoricalChartService;
