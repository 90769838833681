import React, { useMemo } from 'react';
import { DemeterDataFrequency, DemeterDataSource, DemeterSymbolModel } from '../../../../Generated/Raven-Demeter';
import BarChartRaw from '../../../Components/Charts/Bar/BarChartRaw';
import { IChartDataSeries } from '../../../Components/Charts/ChartDefinitions';
import ChartWrapper from '../../../Components/Charts/ChartWrapper/ChartWrapper';
import useLanguage from '../../../Services/Language/useLanguageHook';
import { MonthlyRiskCompositeModel, RiskRequest } from '../RiskDefinitions';

export interface IMonthlyNetValueAtRisk {
    symbol: DemeterSymbolModel;
    risksRequest: RiskRequest;
    calculatedRisks: MonthlyRiskCompositeModel[];
}

const MonthlyNetValueAtRiskChart: React.FC<IMonthlyNetValueAtRisk> = (props: IMonthlyNetValueAtRisk) => {
    const [translations] = useLanguage();

    const barSeries = useMemo<IChartDataSeries[]>(
        () => [
            {
                label: translations.risk.text.riskMonthly,
                data: props.calculatedRisks.map((x) => ({
                    value: x.riskMonthly ?? 0,
                    asOfDate: new Date(x.dataYear, x.dataMonth, 1),
                    isActualValue: true,
                })),
            },
        ],
        [props.calculatedRisks],
    );

    return (
        <ChartWrapper
            name="MonthlyNetValueAtRiskChart"
            title={translations.risk.headers.monthlyNetPositionValueAtRisk}
            dataSourceTag={[translations.exchange[props.symbol.exchange], translations.dataSource[DemeterDataSource.StoneXCalculations]]}
            isLoading={props.calculatedRisks.length === 0}
        >
            <BarChartRaw barSeries={barSeries} currency={props.risksRequest.currency} format="currency" dataFrequency={DemeterDataFrequency.Monthly} />
        </ChartWrapper>
    );
};

export default MonthlyNetValueAtRiskChart;
