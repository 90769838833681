/* eslint-disable jsx-a11y/click-events-have-key-events */
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import React, { useEffect, useState } from 'react';
import { DemeterSubRegion, DemeterTableDefinitionRegionModel } from '../../Generated/Raven-Demeter/api';
import languageService from '../../Refactor/Services/Language/LanguageService';
import WindowWatcher from '../WindowWatcher/WindowWatcher';
import styles from './MenuSubRegionOrOthersDropdown.module.scss';

export interface IMenuSubRegionOrOthersDropdownProps {
    tableDefinitions: DemeterTableDefinitionRegionModel[];
    isRegionSelected?: boolean;
    handleRegionUpdate: (regionDefinition: DemeterTableDefinitionRegionModel, subRegion?: DemeterSubRegion) => void;
}

const MenuSubRegionOrOthersDropdown: React.FC<IMenuSubRegionOrOthersDropdownProps> = (props: IMenuSubRegionOrOthersDropdownProps) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

    useEffect(() => {
        if (Array.isArray(props.tableDefinitions)) {
            props.tableDefinitions.sort((a, b) => {
                const region1: string = a?.displayName || '';
                const region2: string = b?.displayName || '';
                return region1?.localeCompare(region2);
            });
        }
    });

    const iconClass = props.isRegionSelected
        ? styles.subregion_menu_dropdown_arrow_icon_menu_no_hover_selected
        : styles.subregion_menu_dropdown_arrow_icon_menu_no_hover;

    return (
        <WindowWatcher setWatcherClosed={(setDropdownOpen: boolean) => setIsDropdownOpen(setDropdownOpen)}>
            <div className={styles.menu_file_drp}>
                <div className={styles.region_menu_dropdown_wrapper}>
                    <div className={styles.subregion_menu_dropdown}>
                        <span className={isDropdownOpen ? `${iconClass} ${styles.subregion_active_dropdown_icon}` : `${iconClass}`}>
                            <KeyboardArrowDownIcon />
                        </span>
                    </div>
                    {isDropdownOpen && (
                        <div className={styles.region_menu_dropdown_children}>
                            {props.tableDefinitions.length === 1 &&
                                props.tableDefinitions[0].subRegions?.map((subRegion: DemeterSubRegion, index: number) => (
                                    <React.Fragment key={`subRegion_${props.tableDefinitions[0].region}_${subRegion}`}>
                                        <div
                                            role="button"
                                            tabIndex={index}
                                            onClick={() => props.handleRegionUpdate(props.tableDefinitions[0], subRegion)}
                                            className={styles.region_menu_dropdown_sub_header}
                                        >
                                            {languageService.translate(subRegion)}
                                        </div>
                                    </React.Fragment>
                                ))}
                            {props.tableDefinitions.length > 1 &&
                                props.tableDefinitions?.map((otherRegionDefinition: DemeterTableDefinitionRegionModel, index: number) => (
                                    <React.Fragment key={otherRegionDefinition.region}>
                                        <div
                                            role="button"
                                            tabIndex={index}
                                            onClick={() => props.handleRegionUpdate(otherRegionDefinition)}
                                            className={styles.region_menu_dropdown_sub_header}
                                        >
                                            {languageService.translate(otherRegionDefinition.displayName)}
                                        </div>
                                    </React.Fragment>
                                ))}
                        </div>
                    )}
                </div>
            </div>
        </WindowWatcher>
    );
};

export default MenuSubRegionOrOthersDropdown;
