import { useEffect, useMemo, useState } from 'react';
import { DemeterSymbolModel } from '../../../../Generated/Raven-Demeter';
import { SearchParameters } from '../../../../Redux/Slices/SystemSlice';
import useSymbolsApi from '../../../Apis/Hooks/useSymbolsApiHook';
import useSearchParameters from './useSearchParametersHook';

export interface IExchangeCommoditySelection extends SearchParameters {
    exchange: string;
    commodity: string;
}

const useExchangeCommodityNavigation = (): [IExchangeCommoditySelection, DemeterSymbolModel | undefined] => {
    const [searchParameters] = useSearchParameters();

    const symbols = useSymbolsApi();
    const [symbolModel, setSymbolModel] = useState<DemeterSymbolModel>();

    const exchangeCommoditySelection = useMemo<IExchangeCommoditySelection>(() => {
        const value: IExchangeCommoditySelection = {
            exchange: searchParameters.exchange ?? '',
            commodity: searchParameters.commodity ?? '',
        };

        value.searchString = Object.keys(value).reduce(
            (searchString, key) => (searchString === '' ? `${key}=${value[key]}` : `${searchString}&${key}=${value[key]}`),
            '',
        );

        return value;
    }, [searchParameters]);

    useEffect(() => {
        if (!symbols) {
            return;
        }

        const selectedSymbol = symbols.find((x) => x.exchange === exchangeCommoditySelection.exchange && x.commodity === exchangeCommoditySelection.commodity);

        if (selectedSymbol) {
            setSymbolModel(selectedSymbol);
        }
    }, [symbols, exchangeCommoditySelection]);

    return [exchangeCommoditySelection, symbolModel];
};

export default useExchangeCommodityNavigation;
