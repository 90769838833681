import { useEffect, useState } from 'react';
import { useApplicationDispatch, useApplicationSelector } from '../../../Redux/ReduxStore';
import { selectLanguage, setLanguage as setLanguageRedux } from '../../../Redux/Slices/SystemSlice';
import languageService, { Language } from './LanguageService';
import { TranslationType } from './TranslationDefinitions';

const useLanguage = (): [
    translations: TranslationType,
    translate: (...keys: string[]) => string,
    language: string,
    setLanguage: (language: Language) => Promise<void>,
] => {
    const dispatch = useApplicationDispatch();
    const language = useApplicationSelector(selectLanguage) ?? languageService.defaultLanguage;
    const [translations, setTranslations] = useState(() => languageService.getTranslations());

    // TODO: Figure out multi-word translations.

    const setLanguageExternally = async (newLanguage: Language): Promise<void> => {
        await languageService.setLanguage(newLanguage);

        dispatch(setLanguageRedux({ language: newLanguage }));
        setTranslations(languageService.getTranslations());
    };

    useEffect(() => {
        setTranslations(languageService.getTranslations());
    }, [language]);

    const translate = (...keys: string[]): string => languageService.translate(...keys);

    return [translations, translate, language, setLanguageExternally];
};

export default useLanguage;
