import Dropdown from '../../../../../../Components/Form/Inputs/Dropdown';
import useLanguage from '../../../../../../Services/Language/useLanguageHook';
import { SlidingWindowSeasonalParameters, UpsertMarketIndicatorFactorRequest, windowStartDayOptions } from '../../../MarketIndicatorsManagementDefinitions';
import styles from '../../MarketIndicatorFactorPage.module.scss';

interface IMarketFactorWindowStartDayDropdownProps {
    upsertMarketIndicatorFactorRequest: UpsertMarketIndicatorFactorRequest;
    setUpsertMarketIndicatorFactorRequest: (value: UpsertMarketIndicatorFactorRequest) => void;
}

const MarketFactorWindowStartDayDropdown: React.FC<IMarketFactorWindowStartDayDropdownProps> = (props: IMarketFactorWindowStartDayDropdownProps) => {
    const [translations] = useLanguage();

    return (
        <div className={styles.indicator_add_and_edit_dropdown}>
            <Dropdown
                value={(props.upsertMarketIndicatorFactorRequest.parameters as SlidingWindowSeasonalParameters).windowStartDay}
                handleOptionChange={(value) => {
                    const newUpsertMarketIndicatorFactorRequest = {
                        ...props.upsertMarketIndicatorFactorRequest,
                        parameters: {
                            ...props.upsertMarketIndicatorFactorRequest.parameters,
                            windowStartDay: value,
                        },
                    };

                    if (+value > +(props.upsertMarketIndicatorFactorRequest.parameters as SlidingWindowSeasonalParameters).windowEndDay) {
                        (newUpsertMarketIndicatorFactorRequest.parameters as SlidingWindowSeasonalParameters).windowEndDay = value;
                    }

                    props.setUpsertMarketIndicatorFactorRequest(newUpsertMarketIndicatorFactorRequest);
                }}
                options={windowStartDayOptions}
                label={translations.marketIndicatorsManagement.fields.windowStartDay}
            />
        </div>
    );
};

export default MarketFactorWindowStartDayDropdown;
