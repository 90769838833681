/* eslint-disable react/jsx-indent */
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import React from 'react';
import TreeItem, { ITreeNode } from './TreeItem';

interface ITreeViewProps {
    data: ITreeNode[];
    searchTerm: string;
    selectedNode: ITreeNode | null | undefined;
    setSelectedNode: (node: ITreeNode) => void;
    disabledNodes?: (ITreeNode | null | undefined)[];
    noData?: ReactJSXElement | null | undefined;
}

const TreeView: React.FC<ITreeViewProps> = (props: ITreeViewProps) => {
    const isNodeDisabled = (node: ITreeNode) => {
        if (props.disabledNodes) {
            return props.disabledNodes.some((disabledNode) => disabledNode && disabledNode.id === node.id);
        }
        return false;
    };

    const filterTree = (nodes: ITreeNode[]): ITreeNode[] =>
        nodes.reduce<ITreeNode[]>((accumulator, node) => {
            if (node.name && node.name.toLowerCase().indexOf(props.searchTerm.toLowerCase()) !== -1) {
                accumulator.push({
                    ...node,
                    children: node.children ? filterTree(node.children) : [],
                });
            } else if (node.children) {
                const filteredChildren = filterTree(node.children);
                if (filteredChildren.length > 0) {
                    accumulator.push({
                        ...node,
                        children: filteredChildren,
                    });
                }
            }
            return accumulator;
        }, []);

    return (
        <div>
            {props.data.length > 0
                ? filterTree(props.data).map((node) => (
                      <TreeItem
                          key={node.id}
                          node={node}
                          searchTerm={props.searchTerm}
                          selectedNode={props.selectedNode}
                          setSelectedNode={props.setSelectedNode}
                          disabled={isNodeDisabled(node)}
                          disabledNodes={props.disabledNodes}
                          expanded
                          level={0}
                      />
                  ))
                : props.noData}
        </div>
    );
};

export default TreeView;
