import { useMemo } from 'react';
import { DemeterUserType } from '../../../../Generated/Raven-Demeter';
import { useApplicationSelector } from '../../../../Redux/ReduxStore';
import { selectUserType } from '../../../../Redux/Slices/UserSlice';
import useLanguage from '../../../Services/Language/useLanguageHook';

const useUserTypeOptions = (
    showAdditionalAllOption: boolean = false,
    currentUserType?: DemeterUserType,
): { value: DemeterUserType | 'All'; label: string }[] => {
    const myUserType = useApplicationSelector(selectUserType);
    const [translations] = useLanguage();

    const userTypeOptions = useMemo(() => {
        const options: { value: DemeterUserType | 'All'; label: string }[] = [
            {
                value: DemeterUserType.Premium,
                label: translations.users.roles.Premium,
            },
        ];

        if (!currentUserType || currentUserType === DemeterUserType.Regular) {
            options.unshift({
                value: DemeterUserType.Regular,
                label: translations.users.roles.Regular,
            });
        }

        if (showAdditionalAllOption) {
            options.unshift({
                value: 'All',
                label: translations.users.roles.All,
            });
        }

        if (myUserType === DemeterUserType.BusinessOwner || myUserType === DemeterUserType.Administrator) {
            options.push({
                value: DemeterUserType.BusinessOwner,
                label: translations.users.roles.BusinessOwner,
            });
        }

        if (myUserType === DemeterUserType.Administrator) {
            options.push({
                value: DemeterUserType.Administrator,
                label: translations.users.roles.Administrator,
            });
        }

        return options;
    }, [myUserType, currentUserType]);

    return userTypeOptions;
};

export default useUserTypeOptions;
