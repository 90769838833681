import React from 'react';
import InputContainer from './InputContainer';

interface ITextAreaProps {
    className?: string;
    title?: string;
    placeholder?: string;
    value?: string | null;
    disabled?: boolean;
    handleTextChange: (value: string) => void;
}

const TextArea: React.FC<ITextAreaProps> = (props: ITextAreaProps) => (
    <InputContainer title={props.title}>
        <textarea
            className={props.className}
            disabled={props.disabled}
            placeholder={props.placeholder}
            value={props.value ?? ''}
            onChange={(event) => {
                props.handleTextChange(event.target.value ?? '');
            }}
        />
    </InputContainer>
);

export default TextArea;
