/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useMemo } from 'react';
import Footer from '../../../Components/Footer/Footer';
import applicationSettings from '../../../Core/Settings/ApplicationSettings';
import { ExchangeType } from '../../../Generated/Raven-Demeter';
import basedWhereIconPng from '../../Assets/Registration/basedWhereIcon.png';
import freeTrialSvg from '../../Assets/Registration/freeTrial.svg';
import howMuchDoesStoneXCostJpg from '../../Assets/Registration/howMuchDoesStoneXCost.jpg';
import powerfulAnalysisToolsIconSvg from '../../Assets/Registration/powerfulAnalysisToolsIcon.svg';
import pricesIconSvg from '../../Assets/Registration/pricesIcon.svg';
import productionIconSvg from '../../Assets/Registration/productionIcon.svg';
import registrationLaptopFullPng from '../../Assets/Registration/registrationLaptopFull.png';
import tradeDataIconPng from '../../Assets/Registration/tradeDataIcon.png';
import whatLanguageIconPng from '../../Assets/Registration/whatLanguageIcon.png';
import whyChooseStonexJpg from '../../Assets/Registration/whyChooseStonex.jpg';
import RegistrationButton, { RegistrationButtonType } from '../../Components/Form/Buttons/RegistrationButton/RegistrationButton';
import useLanguage from '../../Services/Language/useLanguageHook';
import RegistrationCheckmarkList from './RegistrationCheckmarkList';
import styles from './RegistrationPage.module.scss';
import RegistrationSelfFormComponent from './RegistrationSelfFormComponent';
import RegistrationSelfLogoHeader from './RegistrationSelfLogoHeader';
import RegistrationSelfSummary from './RegistrationSelfSummary';
import RegistrationTwistDown from './RegistrationTwistDown';

const RegistrationSelfPage: React.FC = () => {
    // Actions hooks.
    const [translations] = useLanguage();

    const benefits = useMemo(
        () => [
            translations.registration.text.accessLeadingMarketIntelligence,
            translations.registration.text.analyzeAllRelevent,
            translations.registration.text.customizeThePlatform,
            translations.registration.text.recieveLiveAndDelayed,
            translations.registration.text.accessTradingAccount,
        ],
        [translations],
    );

    const reasonsToChooseStoneX = useMemo(
        () => [
            translations.registration.text.fourTrillionCombined,
            translations.registration.text.derivativesContractsTrading,
            translations.registration.text.reportsWereViewed,
        ],
        [translations],
    );

    return (
        <>
            {/* https://stackoverflow.com/questions/32102747/how-to-make-a-back-to-top-button-using-css-and-html-only */}
            <a id="top" />
            <RegistrationSelfLogoHeader />
            <div className={styles.registration_self_form_header_image} />
            <div className={styles.registration_self_first_container} />
            <div className={styles.registration_self_page_content_container}>
                <div className={styles.registration_self_halves_container}>
                    <div>
                        <h3 className={styles.registration_self_section_header_white}>{translations.registration.text.dairyMarketsInsights}</h3>
                        <p className={styles.registration_self_section_white}>{translations.registration.text.discoverHowPowerful}</p>

                        <div className={styles.registration_self_benefits_container}>
                            <RegistrationCheckmarkList list={benefits} />
                        </div>
                    </div>

                    <div className={styles.registration_self_form_container}>
                        <RegistrationSelfFormComponent />
                    </div>
                </div>
                <div className={styles.registration_self_section_double_spacer} />
                <div className={styles.registration_self_halves_container}>
                    <div>
                        <h3 className={styles.registration_self_section_header}>{translations.registration.text.provideYourBusiness}</h3>
                        <p>{translations.registration.text.stonexPlusData}</p>
                        <p>{translations.registration.text.ourDairyGroup}</p>
                    </div>
                    <div>
                        <img className={styles.registration_self_form_laptop_image} src={registrationLaptopFullPng} alt="#" />
                    </div>
                </div>
                <div className={styles.registration_self_section_spacer} />

                <div className={styles.registration_self_center}>
                    <h3 className={styles.registration_self_section_header}>{translations.registration.text.whatInformationDoes}</h3>
                </div>
                <div className={styles.registration_self_quarters_container}>
                    <div className={styles.registration_self_quarter_card}>
                        <img className={styles.registration_self_form_indicator_logo} src={pricesIconSvg} alt="#" />

                        <p className={styles.registration_self_quarter_card_title}>{translations.registration.text.prices}</p>
                        <ul>
                            <li>{translations.registration.text.moreThanOneHundred}</li>
                            <li>{translations.registration.text.futuresPrices}</li>
                            <li>{translations.exchange[ExchangeType.Eex]}</li>
                            <li>{translations.exchange[ExchangeType.Cme]}</li>
                            <li>{translations.exchange[ExchangeType.Sgx]}</li>
                            <li>{translations.registration.text.proprietaryOverTheCounter}</li>
                            <li>{translations.registration.text.globalDairyTradePrices}</li>
                        </ul>
                    </div>
                    <div className={styles.registration_self_quarter_card}>
                        <img className={styles.registration_self_form_indicator_logo} src={productionIconSvg} alt="#" />
                        <p className={styles.registration_self_quarter_card_title}>{translations.registration.text.production}</p>
                        <ul>
                            <li>{translations.registration.text.milkAndMajor}</li>
                            <li>{translations.registration.text.monthlyProduction}</li>
                            <li>{translations.registration.text.statisticallyConsistent}</li>
                            <li>{translations.registration.text.fatAndProtein}</li>
                        </ul>
                    </div>
                    <div className={styles.registration_self_quarter_card}>
                        <img src={tradeDataIconPng} alt="#" />

                        <p className={styles.registration_self_quarter_card_title}>{translations.registration.text.tradeData}</p>
                        <ul>
                            <li>{translations.registration.text.exportData}</li>
                            <li>{translations.registration.text.moreThanTwenty}</li>
                            <li>{translations.registration.text.forecastForTheTop}</li>
                            <li>{translations.registration.text.importsData}</li>
                        </ul>
                    </div>
                    <div className={styles.registration_self_quarter_card}>
                        <img className={styles.registration_self_form_indicator_logo} src={powerfulAnalysisToolsIconSvg} alt="#" />
                        <p className={styles.registration_self_quarter_card_title}>{translations.registration.text.powerfulAnalysis}</p>
                        <ul>
                            <li>{translations.registration.text.forwardSpreadCalculator}</li>
                            <li>{translations.registration.text.customizedDashboard}</li>
                            <li>{translations.registration.text.fmmoCalculator}</li>
                            <li>{translations.registration.text.positionAnalysis}</li>
                        </ul>
                    </div>
                </div>
                <div className={styles.registration_self_section_spacer} />
            </div>

            <div className={styles.registration_self_midnight_container}>
                <div className={styles.registration_self_page_content_container}>
                    <div className={styles.registration_self_section_spacer} />
                    <div className={styles.registration_self_halves_container}>
                        <div>
                            <h3 className={styles.registration_self_section_header}>{translations.registration.text.howMuchDoesStoneX}</h3>
                            <p>{translations.registration.text.weCreateBespoke}</p>
                            <p>{translations.registration.text.toRequestMoreInformation}</p>
                            <RegistrationButton
                                title={translations.actions.requestPricingInformation}
                                navigateTo={`mailto:${applicationSettings.emails.contactEmail}`}
                                type={RegistrationButtonType.WhitePrimary}
                            />
                        </div>

                        <div>
                            <img className={styles.registration_self_form_indicator_logo} src={howMuchDoesStoneXCostJpg} alt="#" />
                        </div>
                    </div>
                    <div className={styles.registration_self_section_spacer} />

                    <div className={styles.registration_self_thirds_container}>
                        <div>
                            <img className={styles.registration_self_form_indicator_logo} src={freeTrialSvg} alt="#" />

                            <h3 className={styles.registration_self_section_header}>{translations.registration.text.doesPlusHave}</h3>
                            <p>{translations.registration.text.yesStoneXPlusCan}</p>
                        </div>
                        <div>
                            <img className={styles.registration_self_form_indicator_logo} src={whatLanguageIconPng} alt="#" />

                            <h3 className={styles.registration_self_section_header}>{translations.registration.text.whatLanguageIs}</h3>
                            <p>{translations.registration.text.stonexPlusIsAvailable}</p>
                        </div>
                        <div>
                            <img className={styles.registration_self_form_indicator_logo} src={basedWhereIconPng} alt="#" />

                            <h3 className={styles.registration_self_section_header}>{translations.registration.text.whereAreWeBased}</h3>
                            <p>{translations.registration.text.weCurrentlyHaveDairy}</p>
                        </div>
                    </div>
                    <div className={styles.registration_self_section_spacer} />
                    <div className={styles.registration_self_halves_midnight_container}>
                        <div>
                            <h3 className={styles.registration_self_section_header}>{translations.registration.text.whyChooseStonex}</h3>
                            <p>{translations.registration.text.stoneXIsAnInstitution}</p>
                            <p>{translations.registration.text.ourDiverseGlobal}</p>
                            <p>{translations.registration.text.stonexIsAlso}</p>

                            <RegistrationCheckmarkList list={reasonsToChooseStoneX} />
                        </div>

                        <div>
                            <img className={styles.registration_self_form_indicator_logo} src={whyChooseStonexJpg} alt="#" />
                        </div>
                    </div>
                </div>
            </div>

            <div className={styles.registration_self_page_content_container}>
                <div className={styles.registration_self_request_information_card}>
                    <div className={styles.registration_self_card}>
                        <div className={styles.master_flex}>
                            <div className={styles.registration_self_two_thirds}>
                                <h3>{translations.registration.text.howToRequestMore}</h3>
                                <p>{translations.registration.text.toDiscoverHowStonex}</p>
                            </div>
                            <div className={styles.registration_self_request_information_button}>
                                <RegistrationButton
                                    title={translations.actions.requestMoreInformation}
                                    navigateTo={`mailto:${applicationSettings.emails.contactEmail}`}
                                    type={RegistrationButtonType.WhiteSecondary}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={styles.registration_self_frequently_asked_questions_container}>
                        <h3 className={styles.registration_self_section_header}>{translations.registration.text.frequentlyAskedQuestions}</h3>
                        <div className={styles.registration_self_twist_down_wrapper}>
                            <RegistrationTwistDown
                                title={translations.registration.text.isTheFuturesInformation}
                                body={translations.registration.text.eachExchangeHas}
                            />
                        </div>
                        <div className={styles.registration_self_twist_down_wrapper}>
                            <RegistrationTwistDown
                                title={translations.registration.text.howAreTheForecasts}
                                body={translations.registration.text.allForecastingIs}
                            />
                        </div>
                        <div className={styles.registration_self_twist_down_wrapper}>
                            <RegistrationTwistDown title={translations.registration.text.canIUseStoneX} body={translations.registration.text.theOtcPrices} />
                        </div>
                        <div className={styles.registration_self_twist_down_wrapper}>
                            <RegistrationTwistDown
                                title={translations.registration.text.howArePricesDetermined}
                                body={translations.registration.text.currentlyStonex}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className={styles.registration_self_midnight_container}>
                <div className={styles.registration_self_summary}>
                    <div className={styles.registration_self_back_to_top}>
                        <RegistrationButton title={translations.actions.backToTop} navigateTo="#top" type={RegistrationButtonType.WhitePure} />
                    </div>
                </div>
                <RegistrationSelfSummary />
            </div>
            <Footer />
        </>
    );
};

export default RegistrationSelfPage;
