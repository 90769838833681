import { isValid } from 'date-fns';
import { useEffect, useState } from 'react';
import { demeterApi } from '../../../Apis/Apis';
import { DemeterFeatureType, DemeterUserType, UpdateDemeterUserFeaturesRequestFeatures } from '../../../Generated/Raven-Demeter';
import { useApplicationSelector } from '../../../Redux/ReduxStore';
import { selectStoreId } from '../../../Redux/Slices/SystemSlice';
import { selectFeatureFlags } from '../../../Redux/Slices/UserSlice';
import useApiWithoutAutoExecute from '../../Apis/Hooks/useApiWithoutAutoExecute';
import LinkButton, { LinkButtonType } from '../../Components/Form/Buttons/LinkButton';
import Switch from '../../Components/Form/Buttons/Switch';
import Dropdown from '../../Components/Form/Inputs/Dropdown';
import PageHeader from '../../Components/Headers/PageHeader';
import useNotificationHook from '../../Components/Notifications/useNotificationHook';
import useEffectAfterFirstRender from '../../Core/Hooks/useEffectAfterFirstRenderHook';
import featureFlagsService from '../../Services/FeatureFlags/FeatureFlagsService';
import useLanguage from '../../Services/Language/useLanguageHook';
import useUserTypeOptions from '../Administration/Users/useUserTypeOptionsHook';
import styles from './FeatureFlagsPage.module.scss';

const FeatureFlagsPage = () => {
    const storeId = useApplicationSelector(selectStoreId);
    const featureFlags = useApplicationSelector(selectFeatureFlags);

    const [translations] = useLanguage();
    const userTypeOptions = useUserTypeOptions(true);
    const [selectedUserType, setSelectedUserType] = useState<DemeterUserType | 'All'>(DemeterUserType.Administrator);
    const [, displaySuccess] = useNotificationHook();

    useEffectAfterFirstRender(() => {
        featureFlagsService.initializeFeatureFlags(storeId, selectedUserType === 'All' ? DemeterUserType.User : selectedUserType);
    }, [selectedUserType]);

    const [saving, callApi, response] = useApiWithoutAutoExecute(() => {
        const featureMap: UpdateDemeterUserFeaturesRequestFeatures = {};
        Object.values(DemeterFeatureType).forEach((feature: string) => {
            const featureType = feature as DemeterFeatureType;
            featureMap[feature as keyof UpdateDemeterUserFeaturesRequestFeatures] = !!featureFlags.features[featureType];
        });

        return demeterApi.updateDemeterUserFeatures({
            userType: selectedUserType === 'All' ? undefined : selectedUserType,
            features: featureMap,
        });
    });

    useEffect(() => {
        if (response) {
            displaySuccess(translations.featureFlags.text.successUpdated);
        }
    }, [response]);

    return (
        <div className={styles.master_page_container}>
            <PageHeader title={translations.featureFlags.title} testId="FeatureFlagsPageHeader" />
            <div data-testid="FeatureFlagsDropdown" className={styles.feature_flags_user_type}>
                <Dropdown
                    value={selectedUserType}
                    options={userTypeOptions}
                    handleOptionChange={(value: DemeterUserType | 'All') => {
                        setSelectedUserType(value);
                    }}
                />
            </div>
            <div data-testid="FeatureFlags">
                {Object.values(DemeterFeatureType).map((feature: string) => {
                    const featureType = feature as DemeterFeatureType;
                    const isOn = !!featureFlags.features[featureType];

                    return (
                        <div className={styles.feature_flags_row} key={feature}>
                            <Switch checked={isOn} handleChange={(checked: boolean) => featureFlagsService.setFeatureFlag(storeId, checked, featureType)} />
                            <div>{translations.featureType[featureType]}</div>
                        </div>
                    );
                })}
            </div>
            <div className={styles.feature_flags_save_button}>
                <LinkButton
                    title={translations.actions.save}
                    type={LinkButtonType.Blue}
                    onClick={() => {
                        callApi();
                    }}
                    disabled={!isValid || saving}
                />
            </div>
        </div>
    );
};

export default FeatureFlagsPage;
