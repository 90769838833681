/* eslint-disable indent */
import { useMemo } from 'react';
import IconMenuDropdown from '../../../../Refactor/Components/Form/Inputs/IconMenuDropdown';
import languageService from '../../../../Refactor/Services/Language/LanguageService';
import useLanguage from '../../../../Refactor/Services/Language/useLanguageHook';
import utilitySvg from '../../../Assets/utility.svg';
import HighChartExportOptions from '../../../Charts/ChartHelpers/HighChartExportOptions';
import styles from './ExportOptionsDropdown.module.scss';

interface IExportOptionsDropdown {
    header?: string;
    className?: string;
    formattedPrintHeader?: string;
    formattedPrintSubHeader?: string;
    chartRef: any;
    dataSourceTag?: string;
    formattedPrintHeaderColor?: string;
    formattedPrintSubHeaderColor?: string;
    ignoreDateInDownload?: boolean;
    setShowModal: Function;
    isCsvDownloadAvailable?: boolean;
}
type DownloadType = 'png' | 'jpeg' | 'svg' | 'pdf' | 'csv';

const ExportOptionsDropdown: React.FC<IExportOptionsDropdown> = (props) => {
    const [translations] = useLanguage();

    const exportOptions = useMemo(() => {
        const options: { label: string; value: DownloadType }[] = [
            {
                value: 'png',
                label: languageService.translate('png'),
            },
            {
                value: 'jpeg',
                label: languageService.translate('jpeg'),
            },
            {
                value: 'svg',
                label: languageService.translate('svg'),
            },
            {
                value: 'pdf',
                label: languageService.translate('pdf'),
            },
        ];

        if (props.isCsvDownloadAvailable) {
            options.push({
                value: 'csv',
                label: languageService.translate('csv'),
            });
        }
        return options;
    }, []);

    // export buttons functionality
    const exportHandler = (type: string) => {
        if (props.chartRef && props.chartRef.current && props.chartRef.current.chart) {
            const chart = props.chartRef?.current?.chart;
            const isDonutSliceLabelsEnabled = chart?.options?.plotOptions?.pie?.dataLabels?.enabled;
            chart.options.exporting.chartOptions = HighChartExportOptions.getDefaultOptionsWithHeaderTitle(
                `${languageService.multiWordTranslate(props.formattedPrintHeader || props.header!)}`,
                `${languageService.translate(props.formattedPrintSubHeader || '')}`,
                props.formattedPrintHeaderColor || '',
                props.formattedPrintSubHeaderColor || '',
                `${languageService.translate('Source')}: ${languageService.multiWordTranslate(props.dataSourceTag || '')}`,
                props.ignoreDateInDownload,
            );
            const maxDataInSeriesForNavigator = 1000;
            const maxDataInSeriesForDisplay = 3000;
            const userOptionSeries = chart?.userOptions.series[0].data.length || 0;
            const enabledDataGrouping = (dataGroupingEnabled: boolean) => ({
                series: {
                    dataGrouping: {
                        enabled: dataGroupingEnabled,
                    },
                },
            });

            if (userOptionSeries >= maxDataInSeriesForNavigator) {
                chart?.update({ navigator: enabledDataGrouping(true) });
            }
            if (userOptionSeries >= maxDataInSeriesForDisplay) {
                chart?.update({ plotOptions: enabledDataGrouping(true) });
            }

            chart?.update({ plotOptions: { pie: { dataLabels: { enabled: true } } } });
            const chartHeight = chart?.userOptions?.chart?.height;
            if (chartHeight) {
                chart?.update({ chart: { height: isDonutSliceLabelsEnabled ? '27%' : '55%' } });
            }

            switch (type) {
                case 'png':
                    props.chartRef.current.chart.exportChart();
                    break;

                case 'jpeg':
                    chart.exportChart({ type: 'image/jpeg' });
                    break;

                case 'svg':
                    chart.exportChart({ type: 'image/svg+xml' });
                    break;

                case 'pdf':
                    chart.exportChart({ type: 'application/pdf' });
                    break;

                case 'csv':
                    /* For Plus Users */
                    /* chartRef.current.chart.downloadCSV(); */
                    /* For Trial Users */
                    props.setShowModal(true);
                    break;

                case 'xls':
                    chart.downloadXLS();
                    break;

                case 'table':
                    chart.toggleDataTable();
                    break;

                case 'fullScreen':
                    chart.fullscreen.toggle();
                    break;

                case 'print':
                    chart.print();
                    break;

                default:
                    break;
            }
            chart?.update({ navigator: enabledDataGrouping(false), plotOptions: enabledDataGrouping(false), chart: { height: chartHeight } });
            chart?.update({ plotOptions: { pie: { dataLabels: { enabled: isDonutSliceLabelsEnabled } } } });
        }
    };

    const exportButtonClickHandler = (value: string) => {
        exportHandler(value);
    };

    return (
        <IconMenuDropdown
            iconButton={<img className={styles.chart_wrapper_dropdown_img} src={utilitySvg} alt="#" />}
            options={exportOptions}
            handleSelection={exportButtonClickHandler}
            tooltip={translations.actions.clickToDownload}
        />
    );
};

export default ExportOptionsDropdown;
