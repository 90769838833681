import { useMemo } from 'react';
import { marketIntelligenceApi } from '../../../Apis/Apis';
import formattingService from '../../../Core/Formatting/FormattingService';
import applicationSettings from '../../../Core/Settings/ApplicationSettings';
import useApi from '../../Apis/Hooks/useApiHook';
import ComponentHeader from '../../Components/Headers/ComponentHeader';
import LoadingSpinner from '../../Components/LoadingSpinner/LoadingSpinner';
import browserService from '../../Services/BrowserService';
import useLanguage from '../../Services/Language/useLanguageHook';
import styles from './Dashboard.module.scss';

const defaultSearchTerm = undefined;
const defaultAuthor = undefined;
const defaultIsExactSearch = undefined;
const defaultDateRangeStart = undefined;
const defaultDateRangeEnd = undefined;
const defaultMarketId = 166;
const defaultContentConfigurationId = undefined;
const defaultLanguageCode = undefined;
const defaultUsePremiumTrial = false;
const defaultSubscriptionCountry = undefined;
const defaultMobileApplication = undefined;
const defaultSource = applicationSettings.applicationName;
const defaultShowOnlySubscibedArticles = true;
const defaultTake = 10;
const defaultSkip = 0;

const DashboardMarketIntelligenceReports: React.FC = () => {
    const [translations] = useLanguage();
    const titleString = useMemo(() => translations.dashboard.headings.articleHeader, [translations]);

    const [isLoading, , listArticlesResponse] = useApi(() =>
        marketIntelligenceApi.listArticles(
            defaultSearchTerm,
            defaultAuthor,
            defaultIsExactSearch,
            defaultDateRangeStart,
            defaultDateRangeEnd,
            defaultMarketId,
            defaultContentConfigurationId,
            defaultLanguageCode,
            defaultUsePremiumTrial,
            defaultSubscriptionCountry,
            defaultMobileApplication,
            defaultSource,
            defaultShowOnlySubscibedArticles,
            defaultTake,
            defaultSkip,
        ),
    );

    return isLoading ? (
        <LoadingSpinner />
    ) : (
        <div className={styles.dashboard_gray_background}>
            <div className={styles.dashboard_header}>
                <ComponentHeader title={titleString} />
            </div>
            <div className={styles.dashboard_market_intelligence_content}>
                {listArticlesResponse?.rows?.map((x) => (
                    <div className={styles.dashboard_news_item}>
                        <img className={styles.dashboard_image} src={x.articleImageUrl!} alt="#" />
                        <div className={styles.dashboard_information_rows}>
                            <button
                                type="button"
                                onClick={() => {
                                    browserService.openMarketIntelligenceArticle({
                                        language: x.languageCode,
                                        articleId: x.articleId,
                                    });
                                }}
                                className={styles.dashboard_title}
                            >
                                {x.title}
                            </button>
                            <div className={styles.dashboard_author}>{x.author.name}</div>
                            <div className={styles.dashboard_published_date}>{formattingService.toDayOfWeekWithDayMonthYear(x.publishedDate)}</div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default DashboardMarketIntelligenceReports;
