import scssVariables from '../../../Config.module.scss';
import languageService from '../../../Refactor/Services/Language/LanguageService';
import StoneXLogo from '../../Assets/StoneX_Dark_Logo.png';

class HighChartExportOptions {
    static getDefaultOptionsWithHeaderTitle(
        title: string,
        subtitle: string,
        titleColor?: string,
        subtitleColor?: string,
        sourceData?: string,
        ignoreDateInDownload?: boolean,
    ) {
        return {
            ...this.defaultOptions,
            title: {
                ...this.defaultOptions.title,
                text: title,
                style: {
                    ...this.defaultOptions.title.style,
                    color: titleColor || this.defaultOptions.title.style.color,
                },
            },
            subtitle: {
                ...this.defaultOptions.subtitle,
                text: subtitle,
                style: {
                    ...this.defaultOptions.subtitle.style,
                    color: subtitleColor || this.defaultOptions.subtitle.style.color,
                },
            },
            rangeSelector: {
                enabled: false,
                ...this.defaultOptions.rangeSelector,
                y: subtitle ? -75 : this.defaultOptions.rangeSelector.y,
            },
            chart: {
                marginTop: subtitle ? 90 : 70,
                spacingBottom: 110,
                spacingTop: 20,
                events: {
                    load: (e: any) => {
                        const currentChart = e.target;
                        currentChart.renderer
                            .image(StoneXLogo, currentChart.chartWidth - 70, currentChart.chartHeight - 116, 51, 16)
                            .addClass('credits')
                            .add();

                        const sourceLabel = currentChart.renderer
                            .label(
                                ignoreDateInDownload ? sourceData : `${sourceData} - ${languageService.translate('asOf')} ${new Date().toLocaleDateString()}`,
                            )
                            .css({ width: '450px', color: scssVariables.stonexDarkGray, fontSize: '9px' })
                            .add();

                        const disclaimerLabel = currentChart.renderer
                            .label(
                                'The trading of derivatives such as futures, options, and over-the-counter (OTC) products or "swaps" ' +
                                    'may not be suitable for all investors. Information contained herein was obtained from sources believed to be reliable, ' +
                                    'but is not guaranteed.',
                            )
                            .css({ width: '550px', color: scssVariables.stonexDarkGray, fontSize: '8px' })
                            .add();

                        sourceLabel.align(
                            {
                                ...sourceLabel.getBBox(),
                                align: 'center',
                                x: 0, // offset
                                verticalAlign: 'bottom',
                                y: sourceData?.length || 0 > 85 ? 55 : 50, // offset
                            },
                            null,
                            'spacingBox',
                        );

                        disclaimerLabel.align(
                            {
                                ...disclaimerLabel.getBBox(),
                                align: 'left',
                                x: 10, // offset
                                verticalAlign: 'bottom',
                                y: sourceData?.length || 0 > 85 ? 95 : 90, // offset
                            },
                            null,
                            'spacingBox',
                        );
                    },
                },
            },
        };
    }

    private static defaultOptions: any = {
        legend: {
            align: 'left',
            y: 15,
            itemStyle: {
                color: scssVariables.mainTextColor,
                fontSize: '10px',
                fontWeight: 600,
            },
        },
        title: {
            align: 'left',
            style: {
                color: scssVariables.mainTextColor,
                fontWeight: 700,
                fontSize: '12px',
            },
        },
        subtitle: {
            align: 'left',
            y: 24,
            style: {
                color: scssVariables.stonexMediumGray,
                fontWeight: 700,
                fontSize: '8px',
            },
        },
        rangeSelector: {
            floating: true,
            y: -60,
        },
    };
}

export default HighChartExportOptions;
