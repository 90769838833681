import { useEffect, useState } from 'react';
import { demeterApi } from '../../../Apis/Apis';
import CacheKeys from '../../../Core/Cache/CacheKeys';
import useCacheThenApi from './useCacheThenApiHook';

const useCommentaryApi = (demeterCommentaryGuid?: string | null): string => {
    const [commentary, setCommentary] = useState('');

    const [loading, refreshApi, commentaryResponse, commentaryError] = useCacheThenApi(
        `${CacheKeys.GetDemeterCommentary}_${demeterCommentaryGuid}`,
        () => {
            if (!demeterCommentaryGuid) {
                return null;
            }

            return demeterApi.getDemeterCommentary(demeterCommentaryGuid);
        },
        {
            stopGlobalErrorHandling: true,
        },
    );

    useEffect(() => {
        if (!demeterCommentaryGuid) {
            return;
        }

        refreshApi();
    }, [demeterCommentaryGuid]);

    useEffect(() => {
        if (loading || commentaryError) {
            setCommentary('');
            return;
        }

        setCommentary(commentaryResponse?.commentary?.content ?? '');
    }, [loading, commentaryResponse, commentaryError]);

    return commentary;
};

export default useCommentaryApi;
