import { useMemo } from 'react';
import useSymbolsApi from '../../../../Apis/Hooks/useSymbolsApiHook';
import Dropdown from '../../../../Components/Form/Inputs/Dropdown';
import useLanguage from '../../../../Services/Language/useLanguageHook';
import styles from '../ValueMatrixCalculator.module.scss';
import { ValueMatrixTableRequest } from '../ValueMatrixDefinitions';

type SymbolCategoryOption = { label: string; value: string };

interface IValueMatrixMarketDropdownProps {
    valueMatrixInputs: ValueMatrixTableRequest;
    setValueMatrixInputs: (value: ValueMatrixTableRequest) => void;
}

const ValueMatrixMarketDropdown: React.FC<IValueMatrixMarketDropdownProps> = (props: IValueMatrixMarketDropdownProps) => {
    const [translations, translate] = useLanguage();
    const symbols = useSymbolsApi();

    const marketOptions = useMemo(() => {
        if (!symbols) {
            return [];
        }

        // Create a list of unique categories.
        const symbolCategoryOptions: SymbolCategoryOption[] = [];
        symbols.forEach((x) => {
            if (symbolCategoryOptions.map((y) => y.value).includes(x.symbolCategory)) {
                return;
            }

            symbolCategoryOptions.push({
                label: translate(x.symbolCategory),
                value: x.symbolCategory,
            });
        });

        return symbolCategoryOptions;
    }, [translations, symbols]);

    return (
        <div className={styles.value_matrix_dropdown}>
            <Dropdown
                testId="ValueMatrixMarketDropdown"
                value={props.valueMatrixInputs.market}
                options={marketOptions}
                handleOptionChange={(value) => {
                    props.setValueMatrixInputs({
                        ...props.valueMatrixInputs,
                        market: value,
                    });
                }}
                label={translations.calculators.valueMatrix.fields.sector}
            />
        </div>
    );
};

export default ValueMatrixMarketDropdown;
