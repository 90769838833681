import { useMemo } from 'react';
import { DemeterMarket, DemeterRegion, DemeterSubRegion, DemeterTableDefinitionType } from '../../../../../../../Generated/Raven-Demeter';
import useTableDefinitionsApi from '../../../../../../Apis/Hooks/useTableDefinitionsApiHook';
import Dropdown from '../../../../../../Components/Form/Inputs/Dropdown';
import useLanguage from '../../../../../../Services/Language/useLanguageHook';
import styles from '../../MarketIndicatorFactorPage.module.scss';

interface IDataSelectorSubRegionDropdownProps {
    market: DemeterMarket;
    fundamentalCategory: DemeterTableDefinitionType | null;
    region?: DemeterRegion;
    subRegion?: DemeterSubRegion;
    handleChange: (region?: DemeterSubRegion) => void;
}

const DataSelectorSubRegionDropdown: React.FC<IDataSelectorSubRegionDropdownProps> = (props: IDataSelectorSubRegionDropdownProps) => {
    // Application hooks.
    const [translations, translate] = useLanguage();
    const tableDefinitions = useTableDefinitionsApi(props?.fundamentalCategory!, true, props.market);

    const subregionOptions = useMemo(() => {
        if (!props.region || !tableDefinitions) {
            return [];
        }

        const subregionDefinitions = tableDefinitions
            .find((x) => x.region === props.region)
            ?.subRegions.map((subregion) => ({
                label: translate(subregion), // TODO - We didn't have a translated subregion object yet.
                value: subregion as DemeterSubRegion | undefined,
            }));

        if (subregionDefinitions && subregionDefinitions.length > 0) {
            subregionDefinitions?.unshift({ label: '', value: undefined });
        }

        return subregionDefinitions;
    }, [props.region, tableDefinitions]);

    return !subregionOptions || subregionOptions?.length === 0 ? null : (
        <div className={styles.indicator_add_and_edit_dropdown}>
            <Dropdown
                value={props.subRegion ?? subregionOptions[0].value}
                options={subregionOptions as { label: DemeterSubRegion | string; value: DemeterSubRegion | undefined }[]}
                handleOptionChange={(value) => {
                    props.handleChange(value);
                }}
                label={translations.marketIndicatorsManagement.fields.subRegion}
            />
        </div>
    );
};

export default DataSelectorSubRegionDropdown;
