import styles from './ActionsCellButton.module.scss';

interface IActionCellButtonInterface {
    text: string;
    handleClick: () => void;
    ignoreSeparator?: boolean;
}

const ActionsCellButton: React.FC<IActionCellButtonInterface> = (props: IActionCellButtonInterface) => (
    <>
        <span className={styles.actions_cell_separator}> {!props.ignoreSeparator && ' | '} </span>
        <button type="button" className={styles.actions_cell_button} onClick={props.handleClick}>
            {props.text}
        </button>
    </>
);

export default ActionsCellButton;
