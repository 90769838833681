import { DemeterPermissionModel, DemeterPermissionType, DemeterUserType } from '../../../Generated/Raven-Demeter';
import { setUserPermissions } from '../../../Redux/Slices/UserSlice';
import applicationInitializationService from '../../../Services/ApplicationInitializationService';

class PermissionsService {
    private permissions: DemeterPermissionModel[] = [];

    private userType?: DemeterUserType;

    setPermissions = (storeId: string, permissions: DemeterPermissionModel[], userType?: DemeterUserType): void => {
        this.permissions = permissions;
        this.userType = userType;

        applicationInitializationService.getStore(storeId).dispatch(setUserPermissions({ userPermissions: permissions }));
    };

    getPermissions = (): DemeterPermissionModel[] => this.permissions;

    getPermission = (permissionType: DemeterPermissionType): boolean => {
        if (this.userType === DemeterUserType.Administrator) {
            return true;
        }

        return this.permissions.some((x) => x.permissionType === permissionType && x.hasPermission);
    };
}

const permissionsService = new PermissionsService();

export default permissionsService;
