import { CellClassParams, ColDef, RowClickedEvent, ValueFormatterParams } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import AgGridBuilder from '../../../Components/AgGridBuilder/AgGridBuilder';
import DataSourceTag from '../../../Components/DataSourceTag/DataSourceTag';
import formattingService from '../../../Core/Formatting/FormattingService';
import applicationConstants from '../../../Core/Utility/ApplicationConstants';
import { CommodityYearlyBalanceByYearModel, UnitOfMeasure } from '../../../Generated/Raven-Demeter';
import NavigationRoutes from '../../../Layouts/NavigationRoutes';
import useYearlyBalancesApi from '../../Apis/Hooks/useYearlyBalancesApiHook';
import chartSvg from '../../Assets/Icons/table-icons/chart-icon.svg';
import LoadingSpinner from '../../Components/LoadingSpinner/LoadingSpinner';
import { IRegionCommoditySelection } from '../../Components/Navigation/Hooks/useRegionCommodityNavigationHook';
import useSearchParameters from '../../Components/Navigation/Hooks/useSearchParametersHook';
import useLanguage from '../../Services/Language/useLanguageHook';
import styleService from '../../Services/Styles/StyleService';
import type { BalanceType } from './BalanceTablePage';
import styles from './BalanceTablePage.module.scss';

export interface IBalanceTableProps {
    regionCommoditySelection: IRegionCommoditySelection;
    unitOfMeasure?: UnitOfMeasure;
    handleSelectBalanceType: (balanceType: BalanceType, isPercent: boolean) => void;
    testId?: string;
}

type BalanceRow = {
    rowGroup: number;
    id: string;
    title: string;
    link?: string;
    balanceType: BalanceType;
    isPercent: boolean;
    selected?: boolean;
} & Record<string, string | null | boolean | number | undefined>;

export const yearsToDisplay = 10;
const defaultSelectedRowIndex = 0;

const balanceTableColumnOptions = {
    resizable: true,
    autoHeaderHeight: true,
    wrapHeaderText: true,
    flex: 1,
    rowDragManaged: true,
    animateRows: true,
    wrapText: true,
    enableCellChangeFlash: true,
};

const BalanceTable: React.FC<IBalanceTableProps> = (props: IBalanceTableProps) => {
    const [translations, , language] = useLanguage();
    const [, setSearchParameters] = useSearchParameters();
    const yearBalancesData = useYearlyBalancesApi(props.regionCommoditySelection, yearsToDisplay, props.unitOfMeasure);
    const [rowData, setRowData] = useState<BalanceRow[]>([]);
    const gridReference = useRef<AgGridReact>();

    const cellClassSetter = (parameters: CellClassParams) => {
        const cellStyles = [];
        if (parameters.data.isPercent) {
            cellStyles.push(...styleService.positiveOrNegativeCellClassSetter(parameters));
        } else {
            // The GDT table has percent and regular number values in the same column, so we need to right-align non-percent numbers separately.
            cellStyles.push(styles.master_right_align);
        }
        return cellStyles;
    };

    const balanceTableFormatter = (parameters: ValueFormatterParams): string => {
        if (!parameters.value) {
            return applicationConstants.TablePlaceholderZeroOrEmpty;
        }
        if (parameters.data.isPercent) {
            return formattingService.toPercent(+parameters.value);
        }
        return formattingService.toFormattedNumber(+parameters.value, 0)!;
    };

    const renderFirstCell = (parameters: { data: BalanceRow }) => (
        <div className={styles.balance_table_first_column}>
            {parameters.data.link ? (
                <Link onClick={() => setSearchParameters(props.regionCommoditySelection)} to={parameters.data.link}>
                    <span className={styles.balance_table_cell_label}>{parameters.data.title}</span>
                </Link>
            ) : (
                <span>{parameters.data.title}</span>
            )}
            {parameters.data.selected && <img className={styles.balance_table_cell_icon} src={chartSvg} alt="#" />}
        </div>
    );

    const columnDefinitions = useMemo(() => {
        const year = ((yearBalancesData?.rows && yearBalancesData.rows[0].year) ?? +new Date().getFullYear()) - yearsToDisplay + 1;

        const definitions: ColDef[] = [
            { field: 'compositeTitle', headerName: ' ', cellRenderer: renderFirstCell, minWidth: 150, width: 300, suppressMenu: true },
            { field: `${year}`, cellClass: cellClassSetter, suppressMenu: true, type: 'rightAligned', valueFormatter: balanceTableFormatter },
        ];

        for (let i = 1; i < yearsToDisplay; i += 1) {
            definitions.push({
                field: `${year + i}`,
                cellClass: cellClassSetter,
                valueFormatter: balanceTableFormatter,
                suppressMenu: true,
                type: 'rightAligned',
            });
        }

        return definitions;
    }, [yearBalancesData]);

    const initialRowData = useMemo<BalanceRow[]>(
        () => [
            {
                rowGroup: 0,
                id: 'Production',
                title: translations.production.title,
                link: NavigationRoutes.Production,
                balanceType: 'production',
                isPercent: false,
            },
            {
                rowGroup: 0,
                id: 'Production Percent',
                title: translations.fields.percentChange,
                balanceType: 'production',
                isPercent: true,
            },
            {
                rowGroup: 1,
                id: 'Imports',
                title: translations.imports.title,
                link: NavigationRoutes.Imports,
                balanceType: 'imports',
                isPercent: false,
            },
            {
                rowGroup: 1,
                id: 'Imports Percent',
                title: translations.fields.percentChange,
                balanceType: 'imports',
                isPercent: true,
            },
            {
                rowGroup: 2,
                id: 'Exports',
                title: translations.exports.title,
                link: NavigationRoutes.Exports,
                balanceType: 'exports',
                isPercent: false,
            },
            {
                rowGroup: 2,
                id: 'Exports Percent',
                title: translations.fields.percentChange,
                balanceType: 'exports',
                isPercent: true,
            },
            {
                rowGroup: 3,
                id: 'Consumption',
                title: translations.domestics.title,
                link: NavigationRoutes.Domestics,
                balanceType: 'domestic',
                isPercent: false,
            },
            {
                rowGroup: 3,
                id: 'Consumption Percent',
                title: translations.fields.percentChange,
                balanceType: 'domestic',
                isPercent: true,
            },
            {
                rowGroup: 4,
                id: 'Stocks',
                title: translations.stocks.title,
                link: NavigationRoutes.Stocks,
                balanceType: 'stocks',
                isPercent: false,
            },
            {
                rowGroup: 4,
                id: 'Stocks Percent',
                title: translations.fields.percentChange,
                balanceType: 'stocks',
                isPercent: true,
            },
            {
                rowGroup: 5,
                id: 'CommercialStocks',
                title: translations.stocks.text.shortCommericalStocks,
                balanceType: 'stocksCommercial',
                isPercent: false,
            },
            {
                rowGroup: 5,
                id: 'GovernmentStocks',
                title: translations.stocks.text.shortGovernmentStocks,
                balanceType: 'stocksGovernment',
                isPercent: false,
            },
        ],
        [language],
    );

    useEffect(() => {
        gridReference?.current?.api?.refreshCells();
    }, [rowData]);

    useEffect(() => {
        if (yearBalancesData?.rows) {
            const newRowData: BalanceRow[] = [
                ...initialRowData.map((x, index) => ({
                    selected: index === defaultSelectedRowIndex,
                    ...x,
                })),
            ];

            let hasCommercialStocks = false;
            let hasGovernmentStocks = false;

            yearBalancesData.rows.forEach((row: CommodityYearlyBalanceByYearModel) => {
                newRowData[0][`${row.year}`] = row.production?.value!;
                newRowData[1][`${row.year}`] = row.production?.yearOverYearChangePercent!;
                newRowData[2][`${row.year}`] = row.imports?.value!;
                newRowData[3][`${row.year}`] = row.imports?.yearOverYearChangePercent!;
                newRowData[4][`${row.year}`] = row.exports?.value!;
                newRowData[5][`${row.year}`] = row.exports?.yearOverYearChangePercent!;
                newRowData[6][`${row.year}`] = row.domestic?.value!;
                newRowData[7][`${row.year}`] = row.domestic?.yearOverYearChangePercent!;
                newRowData[8][`${row.year}`] = row.stocks?.value!;
                newRowData[9][`${row.year}`] = row.stocks?.yearOverYearChangePercent!;
                newRowData[10][`${row.year}`] = row.stocksCommercial?.value!;
                newRowData[11][`${row.year}`] = row.stocksGovernment?.value!;

                hasCommercialStocks = (hasCommercialStocks || !!row.stocksCommercial?.value) ?? false;
                hasGovernmentStocks = (hasGovernmentStocks || !!row.stocksGovernment?.value) ?? false;
            });

            if (!hasGovernmentStocks) {
                newRowData.splice(11, 1);
            }

            if (!hasCommercialStocks) {
                newRowData.splice(10, 1);
            }

            setRowData(newRowData);
        }
    }, [yearBalancesData]);

    const selectRow = (rowIndex: number) => {
        const compositeTitle = columnDefinitions[0].field ?? '';
        rowData.forEach((row, index) => {
            const selectedRow = gridReference?.current?.api?.getRowNode(row.id);

            if (index === rowIndex) {
                row.selected = true;
                selectedRow?.setSelected(row.selected);
                selectedRow?.setDataValue(compositeTitle, row.selected);
            } else {
                row.selected = false;
                selectedRow?.setSelected(row.selected);
                selectedRow?.setDataValue(compositeTitle, row.selected);
            }
        });
    };

    if (!rowData || rowData.length < 1) {
        return <LoadingSpinner />;
    }

    return (
        <div className={styles.balance_table_table_container}>
            <div className={styles.balance_table_table}>
                <AgGridBuilder
                    gridRef={gridReference}
                    rowData={rowData}
                    gridHeightFull
                    hasSaveColumnsState
                    setColumnDefinitions={() => {}}
                    columnDefinitions={columnDefinitions}
                    defaultColumnDefinition={balanceTableColumnOptions}
                    cellClickedHandler={(event: { rowIndex: number }) => selectRow(event.rowIndex)}
                    onSelectionChanged={() => {}}
                    onRowClicked={(e: RowClickedEvent) => {
                        props.handleSelectBalanceType(e.data.balanceType, e.data.isPercent);
                    }}
                    domLayout="autoHeight"
                    onGridReady={() => selectRow(defaultSelectedRowIndex)}
                    testId={props.testId}
                />
            </div>
            <DataSourceTag value={yearBalancesData?.tagMessage} />
        </div>
    );
};

export default BalanceTable;
