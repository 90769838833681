/* eslint-disable no-console */
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { IGtmDataLayer } from '@mono/api/lib/widgetApi/MonoWidget';
import moment from 'moment';
import { DemeterTableDefinitionModel } from '../../Generated/Raven-Demeter';
import applicationSettings from '../Settings/ApplicationSettings';
import { breadCrumbs } from '../Utility/HelperObjects';

class LoggingService {
    private globalApplicationInsights?: ApplicationInsights;

    private dataLayer: IGtmDataLayer | undefined;

    private get applicationInsights(): ApplicationInsights {
        if (this.globalApplicationInsights) {
            return this.globalApplicationInsights;
        }

        this.globalApplicationInsights = new ApplicationInsights({
            config: {
                instrumentationKey: applicationSettings.applicationInsightsInstrumentKey,
                disableFetchTracking: false,
            },
        });

        this.globalApplicationInsights.loadAppInsights();
        this.globalApplicationInsights.trackPageView();

        return this.globalApplicationInsights;
    }

    public setDataLayer = (dataLayer: IGtmDataLayer) => {
        this.dataLayer = dataLayer;
    };

    trackButton = (name: string): void => {
        console.log(`${applicationSettings.applicationName} | BUTTON clicked: ${name}`);
        this.applicationInsights.trackTrace({ message: `Button clicked: ${name}` });
    };

    trackEventWithAnalytics = (
        eventAction: string,
        eventCategory: string,
        eventLabel: string = '',
        dataTarget?: string,
        callback?: Function,
        domEvent?: DemeterTableDefinitionModel | React.MouseEvent | Event | string | boolean,
    ) => {
        const breadCrumbsString =
            `Page: ${breadCrumbs.page} > ` +
            `Region: ${breadCrumbs.regionOfFocus} > ` +
            `Sub Region: ${breadCrumbs.subRegionOfFocus} > ` +
            `Commodity: ${breadCrumbs.commodityOfFocus} > ` +
            `Commodity Group: ${breadCrumbs.commodityGroupOfFocus} > ` +
            `Data Target: ${dataTarget} > ` +
            `Data Source: ${breadCrumbs.dataSourceOfFocus} ` +
            `(${applicationSettings.applicationName})`;
        const trackedEvent = {
            event: 'eventTracker',
            eventAct: eventAction,
            eventCat: eventCategory,
            eventLbl: eventLabel,
            breadCrumbs: breadCrumbsString,
            eventTime: moment().format('yyyy-MM-DD HH:mm:ss'),
        };

        this.trackEvent(trackedEvent);

        if (callback && domEvent) {
            return callback(domEvent);
        }

        if (callback) {
            return callback();
        }

        return null;
    };

    trackEvent = (
        trackedEvent: { event: string; eventAct: string; eventCat: string; eventLbl?: string; eventTime?: string; breadCrumbs?: string },
        eventProperties?: {},
    ): void => {
        const logProperties = { ...eventProperties };
        console.log(`${applicationSettings.applicationName} | EVENT: ${trackedEvent.eventAct}-${trackedEvent.eventCat}`, logProperties);
        this.applicationInsights.trackEvent({ name: `${trackedEvent.eventAct}-${trackedEvent.eventCat}`, properties: logProperties });
        if (this.dataLayer) {
            this.dataLayer.push(trackedEvent);
        }
    };

    trackException = (exception: Error | unknown, message?: string): void => {
        console.log(`${applicationSettings.applicationName} | ERROR: ${message}`, exception);
        if (applicationSettings.isMono) {
            console.error(exception, `{"appErrorInfo":{"appId":"${applicationSettings.monoApplicationId}","widgetId":"./${applicationSettings.monoWidgetId}"}`);
        }
        this.applicationInsights.trackException({ exception: exception as Error }, { message });
    };

    trackPage = (pageName: string): void => {
        console.log(`${applicationSettings.applicationName} | PAGE: ${pageName}`);
        this.applicationInsights.trackPageView({ name: `Page visited: ${pageName}` });
    };

    trackTrace = (message: string, traceProperties?: {}): void => {
        if (traceProperties) {
            console.log(`${applicationSettings.applicationName} | TRACE: ${message}`, traceProperties);
        } else {
            console.log(`${applicationSettings.applicationName} | TRACE: ${message}`);
        }

        this.applicationInsights.trackTrace({ message }, traceProperties);
    };

    // eslint-disable-next-line class-methods-use-this
    logLocal = (message: string, ...additionalParameters: any[]): void => {
        console.log(`${applicationSettings.applicationName} | LOCAL: ${message}`, ...additionalParameters);
    };
}

const loggingService = new LoggingService();

export default loggingService;
