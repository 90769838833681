const NavigationRoutes = {
    // Application links.
    ApplicationContainer: 'app',
    Blank: '_blank',
    Home: '',
    LoginCallback: 'callback',
    Logout: 'logout',
    RegistrationActivate: 'registration/activate',
    RegistrationSelf: 'registration/self',
    RegistrationSelfSuccess: 'registration/self/success',
    SessionExpiration: 'sessionExpiration',
    CancelTrial: 'cancelTrial',
    // Navigation links.
    Dashboard: 'dashboard',
    Futures: 'futures',
    PhysicalPrices: 'physicalPrices',
    Production: 'production',
    Exports: 'exports',
    Imports: 'imports',
    Domestics: 'domestics',
    Stocks: 'stocks',
    BalanceTable: 'balanceTable',
    MarketIndicators: 'marketIndicators',
    Calculators: 'calculators',
    Risk: 'risk',
    UserData: 'userData',
    // Management links.
    Administration: 'administration',
    Companies: 'administration/companies',
    CompaniesView: 'administration/companies/:demeterCompanyGuid',
    CompaniesAdd: 'administration/companies/add',
    CompaniesEdit: 'administration/companies/:demeterCompanyGuid/edit',
    CompaniesUsers: 'administration/companies/:demeterCompanyGuid/users',
    AdministrationMarketIndicators: 'administration/marketsIndicators',
    AdministrationMarketIndicatorEdit: 'administration/marketsIndicators/:marketIndicatorGuid/edit',
    AdministrationMarketFactorEdit:
        'administration/marketsIndicators/:marketIndicatorGuid/factorGroups/:marketIndicatorFactorGroupName/factors/:marketIndicatorFactorGuid/edit',
    AdministrationMarketFactorAdd: 'administration/marketsIndicators/:marketIndicatorGuid/factorGroups/:marketIndicatorFactorGroupName/add',
    Users: 'administration/users',
    UsersView: 'administration/users/:demeterTrialUserGuid',
    UsersAdd: 'administration/users/add',
    UsersEdit: 'administration/users/:demeterTrialUserGuid/edit',
    UsersImport: 'administration/users/import',
    FeatureFlags: 'featureFlags',
    // Other links.
    Faqs: 'faqs',
    Disclaimer: 'disclaimer',
    PrivacyPolicy: 'privacyPolicy',
    OutlookAndReports: 'outlookAndReports',
};

export type NavigationRouteType = (typeof NavigationRoutes)[keyof typeof NavigationRoutes];
export type NavigationRoutesType = {
    [key: NavigationRouteType]: string;
};

export const PublicRoutes: { [key: string]: boolean } = {
    ApplicationContainer: true,
    Home: true,
    LoginCallback: true,
    Logout: true,
    RegistrationSelfSuccess: true,
    SessionExpiration: true,
};

export default NavigationRoutes;
