import React from 'react';
import useLanguage from '../../../Services/Language/useLanguageHook';
import styles from './SmallDisclaimerText.module.scss';

const SmallDisclaimerText: React.FC = () => {
    const [translations] = useLanguage();

    return <div className={styles.small_disclaimer_text}>{translations.pageFooterDisclaimer}</div>;
};

export default SmallDisclaimerText;
