/* eslint-disable no-confusing-arrow */
import React, { ReactNode } from 'react';
import styles from './Inputs.module.scss';

interface IInputContainerProps {
    title?: string | JSX.Element;
    children: ReactNode;
}

const InputContainer: React.FC<IInputContainerProps> = (props: IInputContainerProps) =>
    props.title ? (
        <div className={styles.input_input_container}>
            <h3 className={styles.input_input_component_h3}>{props.title}</h3>
            <div className={styles.input_search_area}>{props.children}</div>
        </div>
    ) : (
        <div className={styles.input_search_area}>{props.children}</div>
    );

export default InputContainer;
