import { AxiosResponse } from 'axios';
import { useEffect, useRef, useState } from 'react';
import useNotificationHook from '../../Components/Notifications/useNotificationHook';
import useLanguage from '../../Services/Language/useLanguageHook';
import { UseApiOptions } from './useApiHook';

const useMultipleApis = <TRequest, TResponse>(
    apisCallback: (request?: TRequest) => Promise<AxiosResponse<TResponse, any>>[] | null | undefined,
    options?: UseApiOptions,
): [loading: boolean, refreshApi: () => void, datas?: TResponse[], error?: string] => {
    const [loading, setLoading] = useState<boolean>(false);
    const [datas, setDatas] = useState<TResponse[] | undefined>();
    const [error, setError] = useState<string>();
    const [refreshCounter, setRefreshCounter] = useState(0);
    const refreshReference = useRef<{ counter: number; request: TRequest | undefined }>({ counter: 0, request: undefined });
    const [displayError, displaySuccess] = useNotificationHook();

    const [translations] = useLanguage();

    const refreshApi = (request?: TRequest) => {
        refreshReference.current.counter += 1;
        refreshReference.current.request = request;
        setRefreshCounter(refreshReference.current.counter);
    };

    useEffect(() => {
        let cancelRequest = false;
        const requestKey = refreshReference.current.counter;

        const fetchDataFromApi = async () => {
            try {
                const apisToCall = apisCallback(refreshReference.current?.request);
                if (!apisToCall) {
                    return;
                }
                setLoading(true);
                const responses = await Promise.all<AxiosResponse<TResponse, any>>(Array.isArray(apisToCall) ? [...apisToCall] : [apisToCall]);
                const responseDatas = responses.map((x) => x.data);

                if (requestKey !== refreshReference.current.counter || cancelRequest) {
                    return;
                }

                setDatas(responseDatas);
                setError(undefined);
                setLoading(false);

                if (options?.successMessage) {
                    displaySuccess(options.successMessage);
                }
            } catch (exception) {
                if (cancelRequest) {
                    return;
                }

                // We just simply state that was have an api error in this multiple api call scenario.
                setError(translations.errors.apiError);
                setLoading(false);

                if (options?.errorMessage) {
                    displayError(options.errorMessage);
                } else if (!options?.stopGlobalErrorHandling) {
                    displayError(translations.errors.apiError);
                }
            }
        };

        if (!options?.stopAutoExecute || refreshCounter > 0) {
            fetchDataFromApi();
        }
        return (): void => {
            cancelRequest = true;
        };
    }, [refreshCounter]);

    return [loading, refreshApi, datas, error];
};

export default useMultipleApis;
