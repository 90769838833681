import { FC } from 'react';
import userData from '../../../Components/Assets/userData.svg';
import LinkButton, { LinkButtonType } from '../../Components/Form/Buttons/LinkButton';
import useSearchParameters from '../../Components/Navigation/Hooks/useSearchParametersHook';
import useLanguage from '../../Services/Language/useLanguageHook';
import styles from './UserDataPage.module.scss';

const UserDataStartup: FC = () => {
    const [translations] = useLanguage();
    const [, setSearchParameters] = useSearchParameters();

    const handleClick = () => {
        setSearchParameters({ show: 'Upload' });
    };

    return (
        <div className={styles.master_page_container}>
            <div className={styles.user_data_startup}>
                <img className={styles.user_data_startup_icon} src={userData} alt="" />
                <h2>{translations.userData.startup.header}</h2>
                <div className={styles.user_data_startup_info}>{translations.userData.startup.info}</div>
                <LinkButton title={translations.userData.startup.upload} type={LinkButtonType.Blue} onClick={handleClick} />
                <div className={styles.user_data_startup_note}>{translations.userData.startup.note}</div>
            </div>
        </div>
    );
};
export default UserDataStartup;
