import { useEffect, useMemo } from 'react';
import applicationConstants from '../../../../../../../Core/Utility/ApplicationConstants';
import { DemeterCommodity, DemeterMarket, DemeterRegion, ExchangeType } from '../../../../../../../Generated/Raven-Demeter';
import useSymbolsApi from '../../../../../../Apis/Hooks/useSymbolsApiHook';
import Dropdown from '../../../../../../Components/Form/Inputs/Dropdown';
import useLanguage from '../../../../../../Services/Language/useLanguageHook';
import styles from '../../MarketIndicatorFactorPage.module.scss';

interface IDataSelectorExchangeDropdownProps {
    market: DemeterMarket;
    region: DemeterRegion;
    commodity: DemeterCommodity;
    exchangeType?: ExchangeType;
    handleChange: (exchangeType: ExchangeType, region?: DemeterRegion) => void;
}

const DataSelectorExchangeDropdown: React.FC<IDataSelectorExchangeDropdownProps> = (props: IDataSelectorExchangeDropdownProps) => {
    const [translations] = useLanguage();
    const symbols = useSymbolsApi();

    // Dropdown Options.
    const exchangeOptions = useMemo(() => {
        if (!symbols) {
            return [];
        }

        const filteredExchanges = applicationConstants.AvailableExchanges.filter((x) =>
            symbols
                .filter((y) => y.symbolCategory === props.market)
                .map((y) => y.exchange)
                .includes(x),
        );

        // TODO - This works right now with all the commodity options that we have, but we will need to come up with a
        // different solution to set the region. An exchange can have multiple regions.
        return filteredExchanges.map((exchange) => ({
            label: translations.exchange[exchange],
            value: {
                region: symbols.find((y) => y.exchange === exchange)?.region!,
                exchangeType: exchange,
            },
        }));
    }, [translations, symbols, props.market]);

    useEffect(() => {
        if (!symbols || !exchangeOptions) {
            return;
        }

        const lastSelectedSymbol = symbols?.find((x) => x.region === props.region && x.commodity === props.commodity);
        let currentExchange = lastSelectedSymbol?.exchange ?? exchangeOptions[0]?.value.exchangeType;
        let currentRegion = lastSelectedSymbol?.region ?? exchangeOptions[0]?.value.region;

        // If the current region on the request doesn't match with the updated exchange (basically whenever we change
        // markets), we need to update it.
        if (!exchangeOptions.find((x) => x.value.exchangeType === lastSelectedSymbol?.exchange)) {
            currentExchange = exchangeOptions[0]?.value.exchangeType;
            currentRegion = exchangeOptions[0].value.region;
        }

        props.handleChange(currentExchange, currentRegion);
    }, [exchangeOptions]);

    return (
        <div className={styles.indicator_add_and_edit_dropdown}>
            <Dropdown
                value={exchangeOptions.find((x) => x.value.exchangeType === props.exchangeType)?.value}
                options={exchangeOptions}
                handleOptionChange={(value) => {
                    props.handleChange(value.exchangeType, value.region!);
                }}
                label={translations.words.exchange}
            />
        </div>
    );
};

export default DataSelectorExchangeDropdown;
