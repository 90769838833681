import React from 'react';
import styles from './AlertPage.module.scss';
import AlertPageInterface from './AlertPageInterface';

const AlertPage: React.FC<AlertPageInterface> = ({ message }) => (
    <div className={styles.alertPage_container}>
        <div className={styles.AlertModal}>
            <h3>{message}</h3>
        </div>
    </div>
);

export default AlertPage;
