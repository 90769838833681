import applicationConstants from './ApplicationConstants';

export interface SizePropsInterface {
    width: number;
    height: number;
}

export const getChartHeight = (isChartPopped: boolean, size?: SizePropsInterface, isChartSeasonal?: boolean): string | number => {
    let height;
    if (isChartPopped) {
        if (window && window.innerHeight !== 0) {
            height = isChartSeasonal
                ? window.innerHeight * applicationConstants.ChartExportOptionsDimensions.AdjustFactorSeasonal
                : window.innerHeight * applicationConstants.ChartExportOptionsDimensions.AdjustFactor;
        } else {
            height = applicationConstants.ChartExportOptionsDimensions.MinHeight;
        }
    } else {
        height = size ? size.width / 2 : '';
    }
    return height;
};
