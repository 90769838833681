import React from 'react';
import { Trans } from 'react-i18next';
import languageService from '../../Services/Language/LanguageService';
import PrivacyPolicyLink from './PrivacyPolicyLink';
import styles from './PrivacyPolicyPage.module.scss';

const PrivacyPolicyPage: React.FC = () => (
    <div className={styles.master_page_container}>
        <div className={styles.privacy_policy}>
            <h3 className={styles.privacy_policy_h3}>{languageService.translate('privacyPolicyHeader')}</h3>
            <Trans i18nKey="privacyPolicyContents" components={{ PrivacyPolicyLink: <PrivacyPolicyLink />, ul: <ul />, ol: <ol />, li: <li /> }} />
        </div>
    </div>
);

export default PrivacyPolicyPage;
