/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import styles from './RiskPage.module.scss';

interface IStickyColumnGroupProps {
    columnHeaders: {
        sectionTitle: string | JSX.Element;
        subRowTitles: (string | JSX.Element)[];
        hideGroup?: boolean;
        handleGroupShowHide?: () => void;
        useAlternateBackgroundColor?: boolean;
    }[];
}

const rowHeight = 56;

const StickyColumnGroup: React.FC<IStickyColumnGroupProps> = (props: IStickyColumnGroupProps) => (
    <>
        <div className={styles.calculator_table_flex_header_column}>
            {props.columnHeaders.map(
                (columnHeader) =>
                    !columnHeader.hideGroup && (
                        <h3
                            key={`${columnHeader.sectionTitle}`}
                            onClick={columnHeader.handleGroupShowHide}
                            className={
                                columnHeader.useAlternateBackgroundColor ? styles.calculator_table_dark_background_header : styles.calculator_table_header
                            }
                            style={{ height: `${columnHeader.subRowTitles.length * rowHeight}px` }}
                        >
                            {columnHeader.sectionTitle}
                        </h3>
                    ),
            )}
        </div>
        <div className={styles.calculator_table_flex_subheader_column}>
            {props.columnHeaders
                .filter((x) => !x.hideGroup)
                .map((columnHeader, rowIndex) =>
                    columnHeader.subRowTitles.map((subRowTitle) => (
                        <div
                            style={{ height: `${rowHeight}px` }}
                            className={
                                rowIndex % 2 || columnHeader.useAlternateBackgroundColor
                                    ? styles.calculator_table_alternate_background_header
                                    : styles.calculator_table_base_background_header
                            }
                        >
                            {subRowTitle}
                        </div>
                    )),
                )}
        </div>
    </>
);

export default StickyColumnGroup;
