/* eslint-disable @typescript-eslint/indent */
/* eslint-disable indent */
import CloseIcon from '@mui/icons-material/Close';
import { Modal } from '@mui/material';
import { useEffect, useState } from 'react';
import {
    DemeterChartType,
    DemeterCommodity,
    DemeterDataFrequency,
    DemeterLeadingIndicatorModel,
    DemeterRegion,
    DemeterSymbolContractModel,
    DemeterSymbolModel,
    DemeterTableDefinitionType,
} from '../../Generated/Raven-Demeter';
import useSymbolsApi from '../../Refactor/Apis/Hooks/useSymbolsApiHook';
import ProjectionChart from '../../Refactor/Components/Charts/Projection/ProjectionChart';
import { IExchangeCommoditySelection } from '../../Refactor/Components/Navigation/Hooks/useExchangeCommodityNavigationHook';
import useTableDefinition from '../../Refactor/Components/Navigation/Hooks/useTableDefinitionHook';
import FuturesPricesChart from '../../Refactor/Pages/Futures/Prices/FuturesPricesChart';
import { SymbolContractCompositeModel } from '../../Refactor/Pages/Futures/Prices/FuturesPricesTableDefinitions';
import PhysicalPricesChart from '../../Refactor/Pages/PhysicalPrices/PhysicalPricesChart';
import useLanguage from '../../Refactor/Services/Language/useLanguageHook';
import styles from './DashboardPage.module.scss';

export interface IIndicatorModalProps {
    indicatorModel?: DemeterLeadingIndicatorModel;
    isModalOpen: boolean;
    setIsModalOpen: Function;
}

const IndicatorModal: React.FC<IIndicatorModalProps> = ({ isModalOpen, indicatorModel, setIsModalOpen }: IIndicatorModalProps) => {
    const symbolsContracts = useSymbolsApi();
    const [exchangeCommoditySelection, setExchangeCommoditySelection] = useState<IExchangeCommoditySelection>({ exchange: '', commodity: '' });
    const [isRollingData, setIsRollingData] = useState<boolean>(false);
    const [selectedSymbolContracts, setSelectedSymbolContracts] = useState<SymbolContractCompositeModel[]>([]);
    const [dataFrequencyGridData, setDataFrequencyGridData] = useState<any>();
    const [displayNameForPhysicalPrices, setDisplayNameForPhysicalPrices] = useState<string>();
    const [translations, translate] = useLanguage();

    const region = indicatorModel?.region ?? DemeterRegion.UnitedStates;
    const commodity = indicatorModel?.commodity ?? DemeterCommodity.All;
    const subRegion = indicatorModel?.subRegion ?? '';
    const extraParameters = indicatorModel?.dataSource ?? '';
    const dataFrequency = '';

    // Custom hooks and state
    const [, tableDefinitionCommodity] = useTableDefinition(DemeterTableDefinitionType.CommodityPricesTable, {
        region,
        commodity,
        subRegion,
        extraParameters,
        dataFrequency: '',
    });

    useEffect(() => {
        const getNewDataFrequencyData = () => {
            const newDataFrequencyData = [
                {
                    display: true,
                    tableDefinitionType: indicatorModel?.tableDefinitionType,
                    Currency: indicatorModel?.currency,
                    Frequency: indicatorModel?.priceFrequency,
                    Name: indicatorModel?.displayName,
                    id: indicatorModel?.displayName,
                    SubRegion: indicatorModel?.subRegion,
                    Unit: indicatorModel?.unitOfMeasure,
                    Metric: indicatorModel?.unitOfMeasure,
                    currentCommodity: indicatorModel?.commodity,
                    Region: indicatorModel?.region,
                    data: {
                        dataSource: indicatorModel?.dataSource,
                    },
                },
            ];

            return { displayName: indicatorModel?.displayName, newDataFrequencyData };
        };

        const data = getNewDataFrequencyData();
        if (data?.displayName && data.newDataFrequencyData) {
            setDisplayNameForPhysicalPrices(data?.displayName);
            setDataFrequencyGridData(data?.newDataFrequencyData);
        }

        if (indicatorModel) {
            setIsModalOpen(true);
            setIsRollingData(false);
        }
    }, [indicatorModel]);

    const closeModal = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        if (!symbolsContracts || !indicatorModel?.reutersInstrumentCode) {
            return;
        }

        const symbolModel = symbolsContracts.find((row: DemeterSymbolModel) =>
            row.symbolContracts.some((element: DemeterSymbolContractModel) => element.reutersInstrumentCode === indicatorModel.reutersInstrumentCode),
        );

        const contract = symbolModel?.symbolContracts.find(
            (symbolContract: DemeterSymbolContractModel) => symbolContract.reutersInstrumentCode === indicatorModel.reutersInstrumentCode,
        );

        if (contract) {
            // Don't need the 'selected' property here
            const selectedContract: SymbolContractCompositeModel = {
                id: '0',
                rowIndex: 0,
                multipler: symbolModel!.priceMultiplierFutures,
                reutersInstrumentCodePrefix: symbolModel!.reutersInstrumentCodePrefix,
                ...contract,
            } as SymbolContractCompositeModel;

            setExchangeCommoditySelection({ exchange: symbolModel?.exchange ?? '', commodity: symbolModel?.commodity ?? '' });
            setSelectedSymbolContracts([selectedContract]);
        }
    }, [symbolsContracts, indicatorModel]);

    let chartName = indicatorModel?.tableDefinitionType.replace('CommodityMonthly', '').replace('Table', '');
    // TODO: Fix this with translations.
    if (chartName?.charAt(chartName.length - 1) !== 's' && chartName?.charAt(chartName.length - 1) !== 'n') {
        chartName = `${chartName}s`;
    }

    return isModalOpen ? (
        <Modal onClose={closeModal} open={isModalOpen} className={styles.dynamic_modal} disableEnforceFocus>
            <div className={styles.dynamic_chart_container}>
                {indicatorModel?.chartType === DemeterChartType.Futures && (
                    <div className={styles.dynamic_chart}>
                        {selectedSymbolContracts && (
                            <FuturesPricesChart
                                exchangeCommoditySelection={exchangeCommoditySelection}
                                symbolContracts={selectedSymbolContracts}
                                currency={indicatorModel?.currency}
                                unitOfMeasure={indicatorModel?.unitOfMeasure}
                            />
                        )}
                    </div>
                )}
                {indicatorModel?.chartType === DemeterChartType.Value && (
                    <div className={styles.dynamic_chart}>
                        <ProjectionChart
                            unitOfMeasure={indicatorModel.unitOfMeasure}
                            tableDefinitionType={indicatorModel.tableDefinitionType}
                            regionCommoditySelection={{ region, subRegion, commodity, extraParameters, dataFrequency }}
                        />
                    </div>
                )}

                {indicatorModel?.chartType === DemeterChartType.Price && (
                    <div className={styles.dynamic_chart}>
                        <PhysicalPricesChart
                            title={indicatorModel?.displayName}
                            dataFrequency={indicatorModel?.priceFrequency as DemeterDataFrequency}
                            tableDefinitionType={indicatorModel?.tableDefinitionType}
                            regionCommoditySelections={[
                                {
                                    region,
                                    subRegion,
                                    commodity,
                                    extraParameters,
                                    dataFrequency,
                                    currency: indicatorModel?.currency!,
                                    displayName: indicatorModel?.displayName ?? '',
                                },
                            ]}
                            displayDecimalPlacesMinimum={tableDefinitionCommodity?.displayDecimalPlacesMinimum ?? 0}
                            displayDecimalPlacesMaximum={tableDefinitionCommodity?.displayDecimalPlacesMaximum ?? 4}
                            currency={indicatorModel?.currency}
                            unitOfMeasure={indicatorModel?.unitOfMeasure}
                        />
                    </div>
                )}
                <div className={styles.dashboard_chart_close_icon}>
                    <CloseIcon className={styles.close_icon} onClick={closeModal} />
                </div>
            </div>
        </Modal>
    ) : null;
};

export default IndicatorModal;
