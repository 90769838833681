import { useEffect, useState } from 'react';
import { DemeterFeatureType } from '../../../Generated/Raven-Demeter';
import { useApplicationSelector } from '../../../Redux/ReduxStore';
import { selectFeatureFlags } from '../../../Redux/Slices/UserSlice';
import featureFlagsService from './FeatureFlagsService';

const useFeatureFlag = (featureType: DemeterFeatureType): boolean => {
    const featureFlags = useApplicationSelector(selectFeatureFlags);
    const [isFeatureOn, setIsFeatureOn] = useState<boolean>(() => featureFlagsService.getFeatureFlag(featureType));

    useEffect(() => {
        setIsFeatureOn(featureFlagsService.getFeatureFlag(featureType));
    }, [featureFlags]);

    return isFeatureOn;
};

export default useFeatureFlag;
