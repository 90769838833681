/* eslint-disable class-methods-use-this */
import { demeterDownloadsApi } from '../../../Apis/Apis';
import { DataSeries } from '../../../Generated/Raven-Demeter';
import browserService from '../BrowserService';

class DownloadService {
    downloadCsv = (rawDownloadData: DataSeries[], title: string) => {
        (async () => {
            const structuredCsvData = await demeterDownloadsApi.downloadData(
                {
                    dataSeries: rawDownloadData,
                },
                {
                    headers: {
                        accept: 'text/csv',
                    },
                },
            );
            browserService.downloadCsv(structuredCsvData.data as string, title);
        })();
    };
}

const downloadService = new DownloadService();

export default downloadService;
