// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import '../../NamespacedOverwrites.css';
import { useApplicationSelector } from '../../Redux/ReduxStore';
import { selectUserTokens } from '../../Redux/Slices/UserSlice';
import PageLoadingSpinner from '../../Refactor/Components/LoadingSpinner/PageLoadingSpinner';
import NavigationRoutes from '../NavigationRoutes';

const MonoHomePage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const userTokens = useApplicationSelector(selectUserTokens);

    useEffect(() => {
        // If we go here by default, redirect to dashboard.
        if (userTokens?.accessToken) {
            const urlIndex = location.search.indexOf(`${NavigationRoutes.ApplicationContainer}/`);
            if (urlIndex > 0) {
                const redirectUrl = location.search.substring(urlIndex);
                if (redirectUrl.includes('&')) {
                    // I believe mono will take care of the search parameters automatically.
                    navigate(redirectUrl.substring(0, redirectUrl.indexOf('&')));
                } else {
                    navigate(redirectUrl);
                }
            } else {
                navigate(NavigationRoutes.Dashboard);
            }
        }
    }, [userTokens]);

    return <PageLoadingSpinner />;
};

export default MonoHomePage;
