import Dropdown from '../../../../../../Components/Form/Inputs/Dropdown';
import useLanguage from '../../../../../../Services/Language/useLanguageHook';
import { AverageSeasonalParameters, UpsertMarketIndicatorFactorRequest, yearsOfDataOptions } from '../../../MarketIndicatorsManagementDefinitions';
import styles from '../../MarketIndicatorFactorPage.module.scss';

interface IMarketFactorYearsOfDataDropdownProps {
    upsertMarketIndicatorFactorRequest: UpsertMarketIndicatorFactorRequest;
    setUpsertMarketIndicatorFactorRequest: (value: UpsertMarketIndicatorFactorRequest) => void;
}

const MarketFactorYearsOfDataDropdown: React.FC<IMarketFactorYearsOfDataDropdownProps> = (props: IMarketFactorYearsOfDataDropdownProps) => {
    const [translations] = useLanguage();

    return (
        <div className={styles.indicator_add_and_edit_dropdown}>
            <Dropdown
                value={(props.upsertMarketIndicatorFactorRequest.parameters as AverageSeasonalParameters).yearsOfData}
                handleOptionChange={(value) => {
                    props.setUpsertMarketIndicatorFactorRequest({
                        ...props.upsertMarketIndicatorFactorRequest,
                        parameters: {
                            ...props.upsertMarketIndicatorFactorRequest.parameters,
                            yearsOfData: value,
                        },
                    });
                }}
                options={yearsOfDataOptions}
                label={translations.marketIndicatorsManagement.fields.yearsOfData}
            />
        </div>
    );
};

export default MarketFactorYearsOfDataDropdown;
