import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import axios from 'axios';
import { FC, useRef, useState } from 'react';
import { Trans } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import checkCircle from '../../../Components/Assets/checkCircle.svg';
import close from '../../../Components/Assets/close.svg';
import errorFilled from '../../../Components/Assets/errorFilled.svg';
import ContentType from '../../../Core/Common/ContentTypes';
import FileExtension from '../../../Core/Common/FileExtensions';
import { BASE_PATH } from '../../../Generated/Raven-Demeter/base';
import LinkButton, { LinkButtonType } from '../../Components/Form/Buttons/LinkButton';
import useSearchParameters from '../../Components/Navigation/Hooks/useSearchParametersHook';
import useLanguage from '../../Services/Language/useLanguageHook';
import { IUserData } from './UserDataDefinitions';
import UserDataExternalLink from './UserDataExternalLink';
import UserDataForm from './UserDataForm';
import styles from './UserDataPage.module.scss';

const UserDataUpload: FC = () => {
    const [translations] = useLanguage();
    const [uploadFormOpen, setUploadFormOpen] = useState(false);
    const [selectedFile, setSelectedFile] = useState<File | undefined>();
    const [error, setError] = useState<string | null>(null);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [, setSearchParameters] = useSearchParameters();
    const navigate = useNavigate();

    const validateFile = (file: File | undefined) => {
        if (!file) {
            return null;
        }
        const validMimeType = ContentType.Csv;
        const validExtension = FileExtension.Csv;

        if (file.type !== validMimeType) {
            return translations.userData.upload.invalidFile;
        }
        if (!file.name.endsWith(validExtension)) {
            return translations.userData.upload.invalidFile;
        }
        return null;
    };

    const handleBack = () => {
        navigate(-1);
    };

    const handleFileSelect = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleFileUpload = (userData: IUserData) => {
        if (selectedFile) {
            setUploadFormOpen(false);
            const formData = new FormData();
            formData.append('name', userData.name);
            formData.append('fileName', selectedFile.name);
            formData.append('currency', userData.currency);
            formData.append('unitOfMeasure', userData.unitOfMeasure);
            formData.append('file', selectedFile);
            const url = new URL('/demeter/user-data', BASE_PATH).toString();
            axios
                .post(url, formData, {
                    headers: {
                        'Content-Type': ContentType.MultipartFormData,
                    },
                })
                .finally(() => {
                    setSearchParameters({ show: 'Management' });
                });
        }
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        const validationError = validateFile(file);
        setError(validationError);
        if (file) {
            setSelectedFile(file);
        }
    };

    const handleFileDelete = () => {
        setSelectedFile(undefined);
        setError(null);
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };

    return (
        <div className={styles.master_page_container}>
            <div className={styles.user_data_file_select}>
                <div className={styles.user_data_file_select_header_container}>
                    <LinkButton title={translations.actions.back} onClick={handleBack} type={LinkButtonType.White} icon={<ArrowBackIcon />} />
                    <h2>{translations.userData.upload.header}</h2>
                </div>
                <div className={styles.user_data_file_select_container}>
                    <div className={styles.user_data_file_select_container_description}>
                        <Trans i18nKey="userData.upload.description" components={{ UserDataExternalLink: <UserDataExternalLink /> }} />
                    </div>
                    <div className={styles.user_data_file_select_container_form}>
                        <input type="file" ref={fileInputRef} style={{ display: 'none' }} onChange={handleFileChange} />
                        <LinkButton title={translations.userData.upload.chooseFile} onClick={handleFileSelect} type={LinkButtonType.White} />
                        {selectedFile && (
                            <div className={styles.user_data_file_select_container_form_file}>
                                {!error && <img src={checkCircle} alt="" className={styles.user_data_file_select_container_form_file_icon_success} />}
                                {error && <img src={errorFilled} alt="" className={styles.user_data_file_select_container_form_file_icon_error} />}
                                <div className={styles.user_data_file_select_container_form_file_name}>
                                    {selectedFile.name}
                                    {error && <div className={styles.user_data_file_select_container_form_file_error}>{error}</div>}
                                </div>
                                <button type="button" onClick={handleFileDelete}>
                                    <img src={close} alt="" className={styles.user_data_file_select_container_form_file_icon_delete} />
                                </button>
                            </div>
                        )}
                        {selectedFile && (
                            <LinkButton
                                title={translations.userData.upload.import}
                                disabled={error !== null}
                                onClick={() => setUploadFormOpen(true)}
                                type={LinkButtonType.Blue}
                            />
                        )}
                    </div>
                </div>
            </div>
            <UserDataForm open={uploadFormOpen} onClose={() => setUploadFormOpen(false)} onChange={handleFileUpload} />
        </div>
    );
};
export default UserDataUpload;
