import RiskCalculatorInput from './RiskCalculatorInput';
import { MonthlyRiskCompositeModel, RiskRequest, TabEventKeyType } from './RiskDefinitions';

interface ICalculatorInputProps {
    className: string;
    riskRequest: RiskRequest;
    setRiskRequest: (riskRequest: RiskRequest, index: number) => void;
    fieldName: keyof MonthlyRiskCompositeModel;
    disabled?: boolean;
    handleGridTabNavigation: (columnIndex: number, rowIndex: number, direction: TabEventKeyType) => void;
    validation?: RegExp;
}

const RiskCalculatorInputRow: React.FC<ICalculatorInputProps> = (props: ICalculatorInputProps) => {
    const riskRequest = props.riskRequest.risks;

    return (
        <div className={props.className}>
            {riskRequest!.map((x, index) => (
                <RiskCalculatorInput
                    key={`${props.fieldName}_${x.contractYear}_${x.contractMonth}`}
                    riskRequest={props.riskRequest}
                    setRiskRequest={(newRiskRequest) => {
                        props.setRiskRequest(newRiskRequest, index);
                    }}
                    fieldName={props.fieldName}
                    disabled={props.disabled}
                    index={index}
                    handleGridTabNavigation={props.handleGridTabNavigation}
                    validation={props.validation}
                />
            ))}
        </div>
    );
};

export default RiskCalculatorInputRow;
