import { useMemo } from 'react';
import { MarketIndicatorOutlook } from '../../../../../../../Generated/Raven-Demeter';
import Dropdown from '../../../../../../Components/Form/Inputs/Dropdown';
import useLanguage from '../../../../../../Services/Language/useLanguageHook';
import { ManualEntryParameters, UpsertMarketIndicatorFactorRequest } from '../../../MarketIndicatorsManagementDefinitions';
import styles from '../../MarketIndicatorFactorPage.module.scss';

interface IMarketFactorOutlookDropdownProps {
    upsertMarketIndicatorFactorRequest: UpsertMarketIndicatorFactorRequest;
    setUpsertMarketIndicatorFactorRequest: (value: UpsertMarketIndicatorFactorRequest) => void;
}

const MarketFactorOutlookDropdown: React.FC<IMarketFactorOutlookDropdownProps> = (props: IMarketFactorOutlookDropdownProps) => {
    const [translations] = useLanguage();

    const outlookOptions = useMemo(
        () => [
            {
                label: translations.marketIndicatorOutlook[MarketIndicatorOutlook.VeryBearish],
                value: MarketIndicatorOutlook.VeryBearish,
            },
            {
                label: translations.marketIndicatorOutlook[MarketIndicatorOutlook.Bearish],
                value: MarketIndicatorOutlook.Bearish,
            },
            {
                label: translations.marketIndicatorOutlook[MarketIndicatorOutlook.Flat],
                value: MarketIndicatorOutlook.Flat,
            },
            {
                label: translations.marketIndicatorOutlook[MarketIndicatorOutlook.Bullish],
                value: MarketIndicatorOutlook.Bullish,
            },
            {
                label: translations.marketIndicatorOutlook[MarketIndicatorOutlook.VeryBullish],
                value: MarketIndicatorOutlook.VeryBullish,
            },
        ],
        [],
    );

    return (
        <div className={styles.indicator_add_and_edit_dropdown}>
            <Dropdown
                value={(props.upsertMarketIndicatorFactorRequest.parameters as ManualEntryParameters).outlook}
                options={outlookOptions}
                handleOptionChange={(value) => {
                    props.setUpsertMarketIndicatorFactorRequest({
                        ...props.upsertMarketIndicatorFactorRequest,
                        parameters: {
                            ...props.upsertMarketIndicatorFactorRequest.parameters,
                            outlook: value,
                        },
                    });
                }}
                label={translations.marketIndicatorsManagement.fields.outlook}
            />
        </div>
    );
};

export default MarketFactorOutlookDropdown;
