import { useEffect, useMemo } from 'react';
import { MarketIndicatorDataCombinationType } from '../../../../../../../Generated/Raven-Demeter';
import Dropdown from '../../../../../../Components/Form/Inputs/Dropdown';
import useLanguage from '../../../../../../Services/Language/useLanguageHook';
import { UpsertMarketIndicatorFactorRequest } from '../../../MarketIndicatorsManagementDefinitions';
import styles from '../../MarketIndicatorFactorPage.module.scss';

interface IMarketFactorMathOperationDropdownProps {
    upsertMarketIndicatorFactorRequest: UpsertMarketIndicatorFactorRequest;
    setUpsertMarketIndicatorFactorRequest: (value: UpsertMarketIndicatorFactorRequest) => void;
}

const MarketFactorMathOperationDropdown: React.FC<IMarketFactorMathOperationDropdownProps> = (props: IMarketFactorMathOperationDropdownProps) => {
    // Application hooks.
    const [translations] = useLanguage();
    const marketOptions = useMemo(
        () =>
            [
                {
                    label: translations.marketIndicatorDataCombinationType[MarketIndicatorDataCombinationType.Aggregate],
                    value: MarketIndicatorDataCombinationType.Aggregate,
                },
                {
                    label: translations.marketIndicatorDataCombinationType[MarketIndicatorDataCombinationType.Average],
                    value: MarketIndicatorDataCombinationType.Average,
                },
                {
                    label: translations.marketIndicatorDataCombinationType[MarketIndicatorDataCombinationType.Ratio],
                    value: MarketIndicatorDataCombinationType.Ratio,
                },

                {
                    label: translations.marketIndicatorDataCombinationType[MarketIndicatorDataCombinationType.Multiply],
                    value: MarketIndicatorDataCombinationType.Multiply,
                },
                {
                    label: translations.marketIndicatorDataCombinationType[MarketIndicatorDataCombinationType.Spread],
                    value: MarketIndicatorDataCombinationType.Spread,
                },
            ] as { label: string; value: MarketIndicatorDataCombinationType }[],
        [translations],
    );

    useEffect(() => {
        if (!marketOptions || props.upsertMarketIndicatorFactorRequest.dataCombinationType) {
            return;
        }

        props.setUpsertMarketIndicatorFactorRequest({
            ...props.upsertMarketIndicatorFactorRequest,
            dataCombinationType: marketOptions[0].value,
        });
    }, [marketOptions, props.upsertMarketIndicatorFactorRequest]);

    return (
        <div className={styles.indicator_add_and_edit_row}>
            <div className={styles.indicator_add_and_edit_dropdown}>
                <Dropdown
                    value={props.upsertMarketIndicatorFactorRequest.dataCombinationType}
                    options={marketOptions}
                    handleOptionChange={(selectedMathOperation) => {
                        props.setUpsertMarketIndicatorFactorRequest({
                            ...props.upsertMarketIndicatorFactorRequest,
                            dataCombinationType: selectedMathOperation,
                        });
                    }}
                    label={translations.words.mathOperation}
                />
            </div>
        </div>
    );
};

export default MarketFactorMathOperationDropdown;
