import { UnitOfMeasure } from '../../../Generated/Raven-Demeter';
import { useApplicationDispatch, useApplicationSelector } from '../../../Redux/ReduxStore';
import {
    selectUnitOfMeasureGeneral,
    selectUnitOfMeasureMetricImperial,
    setUnitOfMeasureGeneral,
    setUnitOfMeasureMetricImperial,
} from '../../../Redux/Slices/SystemSlice';

type UnitOfMeasureType = 'General' | 'MetricImperial';

const useUnitOfMeasure = (
    unitOfMeasureType: UnitOfMeasureType = 'General',
): [UnitOfMeasure | undefined, (unitOfMeasure: UnitOfMeasure | undefined) => void] => {
    const dispatch = useApplicationDispatch();
    const unitOfMeasureMetricImperial = useApplicationSelector(selectUnitOfMeasureMetricImperial);
    const unitOfMeasureGeneral = useApplicationSelector(selectUnitOfMeasureGeneral);

    const setUnitOfMeasureFromHook = (newUnitOfMeasure: UnitOfMeasure | undefined): void => {
        if (unitOfMeasureType === 'MetricImperial') {
            dispatch(setUnitOfMeasureMetricImperial({ unitOfMeasure: newUnitOfMeasure }));
        } else {
            dispatch(setUnitOfMeasureGeneral({ unitOfMeasure: newUnitOfMeasure }));
        }
    };

    return [unitOfMeasureType === 'MetricImperial' ? unitOfMeasureMetricImperial : unitOfMeasureGeneral, setUnitOfMeasureFromHook];
};

export default useUnitOfMeasure;
