import React, { useEffect, useMemo, useState } from 'react';
import { MarketIndicatorFactorModel } from '../../../../../../../Generated/Raven-Demeter';
import plusSvg from '../../../../../../Assets/Icons/expand.svg';
import useSearchParameters from '../../../../../../Components/Navigation/Hooks/useSearchParametersHook';
import Tabs from '../../../../../../Components/Navigation/Tabs/Tabs';
import useLanguage from '../../../../../../Services/Language/useLanguageHook';
import { CombinationParameters, UpsertMarketIndicatorFactorRequest } from '../../../MarketIndicatorsManagementDefinitions';
import styles from './CombinationTabs.module.scss';

interface ICombinationTabsProps {
    marketIndicatorFactor: MarketIndicatorFactorModel;
    upsertMarketIndicatorFactorRequest: UpsertMarketIndicatorFactorRequest;
    setUpsertMarketIndicatorFactorRequest: (value: UpsertMarketIndicatorFactorRequest) => void;
}

const minimumNumberOfSubFactors = 2;
const maximumNumberOfTabOptions = 4;
const numberOfMultipliers = 12;

const CombinationTabs: React.FC<ICombinationTabsProps> = (props: ICombinationTabsProps) => {
    const [translations] = useLanguage();
    const [searchParameters] = useSearchParameters();
    const [numberOfSubFactors, setNumberOfSubFactors] = useState(minimumNumberOfSubFactors);

    useEffect(() => {
        if (!(props.upsertMarketIndicatorFactorRequest.parameters as CombinationParameters)?.multipliers1) {
            return;
        }

        const currentNumberOfSubFactors = Math.max(
            props.marketIndicatorFactor.subFactors.length,
            (props.upsertMarketIndicatorFactorRequest.parameters as CombinationParameters)?.multipliers1?.split(',').length,
        );

        setNumberOfSubFactors(currentNumberOfSubFactors);
    }, [props.marketIndicatorFactor, (props.upsertMarketIndicatorFactorRequest.parameters as CombinationParameters)?.multipliers1]);

    const marketIndicatorFactorTabOptions = useMemo(() => {
        const options = [
            {
                title: translations.words.parent,
                key: 'parent',
            },
        ];

        for (let index = 0; index < numberOfSubFactors; index += 1) {
            options.push({
                title: props.marketIndicatorFactor?.subFactors[index] ? props.marketIndicatorFactor?.subFactors[index].displayName : `Child ${index + 1}`,
                key: props.marketIndicatorFactor?.subFactors[index]
                    ? props.marketIndicatorFactor?.subFactors[index].marketIndicatorFactorGuid
                    : `child${index + 1}`,
            });
        }

        return options;
    }, [props.marketIndicatorFactor?.subFactors, numberOfSubFactors]);

    const addColumnOfMultipliers = () => {
        const updatedMultipliers = props.upsertMarketIndicatorFactorRequest?.parameters! as CombinationParameters;

        for (let index = 0; index < numberOfMultipliers; index += 1) {
            updatedMultipliers[`multipliers${index + 1}` as keyof CombinationParameters] += ',1';
        }

        props.setUpsertMarketIndicatorFactorRequest({ ...props.upsertMarketIndicatorFactorRequest, parameters: updatedMultipliers });
    };

    return (
        <div className={styles.combination_tabs_flex}>
            <Tabs tabOptions={marketIndicatorFactorTabOptions} />
            {searchParameters.tab === 'parent' && marketIndicatorFactorTabOptions.length <= maximumNumberOfTabOptions && (
                <button onClick={addColumnOfMultipliers} className={styles.combination_tabs_plus_button} type="button">
                    <img src={plusSvg} alt="#" />
                </button>
            )}
        </div>
    );
};

export default CombinationTabs;
