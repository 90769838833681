/* eslint-disable jsx-a11y/click-events-have-key-events */
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import React, { useEffect, useState } from 'react';
import { DemeterTableDefinitionGroupModel, DemeterTableDefinitionModel } from '../../Generated/Raven-Demeter/api';
import languageService from '../../Refactor/Services/Language/LanguageService';
import WindowWatcher from '../WindowWatcher/WindowWatcher';
import styles from './MenuDropdown.module.scss';

export interface IMenuDropdownProps {
    tableDefinitionGroup: DemeterTableDefinitionGroupModel;
    useButtonDropdown?: boolean;
    isCommoditySelected?: boolean;
    externalIsDropdownOpen?: boolean;
    handleCommodityUpdate: (commodityDefinition: DemeterTableDefinitionModel) => void;
    testId?: string;
}

const MenuDropdown = (props: IMenuDropdownProps) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

    useEffect(() => {
        if (props.externalIsDropdownOpen !== undefined) {
            setIsDropdownOpen(props.externalIsDropdownOpen);
        }
    }, [props.externalIsDropdownOpen]);

    let iconClass = props.isCommoditySelected ? styles.menu_dropdown_arrow_icon_selected : styles.menu_dropdown_arrow_icon_menu;
    if (props.useButtonDropdown) {
        iconClass = props.isCommoditySelected ? styles.menu_dropdown_arrow_icon_selected : styles.menu_dropdown_arrow_icon_menu_no_hover;
    }
    return (
        <WindowWatcher setWatcherClosed={(setDropdownOpen: boolean) => setIsDropdownOpen(setDropdownOpen)} testId={props.testId}>
            <div className={styles.menu_dropdown_wrapper}>
                <div className={styles.menu_dropdown}>
                    <span className={isDropdownOpen ? `${iconClass} ${styles.menu_dropdown_active_dropdown_icon}` : `${iconClass}`}>
                        <KeyboardArrowDownIcon />
                    </span>
                </div>
                {isDropdownOpen && (
                    <div className={styles.menu_dropdown_children}>
                        {props.tableDefinitionGroup.demeterTableDefinitions.map((tableDefinition: DemeterTableDefinitionModel, index: number) => {
                            const { displayName, isSubGroupHeader, hasSubGroupBreakBefore, commodity } = tableDefinition;
                            return (
                                <React.Fragment key={`commodity_${displayName}`}>
                                    {hasSubGroupBreakBefore && <hr className={styles.menu_dropdown_menu_item_divider} />}
                                    {isSubGroupHeader && !commodity && (
                                        <div className={styles.menu_dropdown_sub_header}>{languageService.translate(displayName)}</div>
                                    )}
                                    {isSubGroupHeader && commodity && (
                                        <div
                                            role="button"
                                            tabIndex={index}
                                            onClick={() => props.handleCommodityUpdate(tableDefinition)}
                                            className={styles.menu_dropdown_sub_header}
                                            data-testid={`MenuCommodity${tableDefinition.commodity}`}
                                        >
                                            {languageService.translate(displayName)}
                                        </div>
                                    )}
                                    {!isSubGroupHeader && (
                                        <div
                                            role="button"
                                            tabIndex={index}
                                            onClick={() => props.handleCommodityUpdate(tableDefinition)}
                                            className={styles.menu_dropdown_single_child}
                                            data-testid={`MenuCommodity${tableDefinition.commodity}`}
                                        >
                                            {languageService.translate(displayName)}
                                        </div>
                                    )}
                                </React.Fragment>
                            );
                        })}
                    </div>
                )}
            </div>
        </WindowWatcher>
    );
};

export default MenuDropdown;
