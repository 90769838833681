import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import React, { useState } from 'react';
import styles from './RegistrationPage.module.scss';

interface IRegistrationTwistDownProps {
    title: string;
    body: string;
}

const RegistrationTwistDown: React.FC<IRegistrationTwistDownProps> = (props: IRegistrationTwistDownProps) => {
    const [isOpen, setIsOpen] = useState<boolean>();

    return (
        <div className={styles.registration_self_card}>
            <h3>{props.title}</h3>
            {isOpen ? (
                <>
                    <button className={styles.registration_self_collapse_expand} type="button" onClick={() => setIsOpen(!isOpen)}>
                        <KeyboardArrowUpIcon />
                    </button>
                    <div>{props.body}</div>
                </>
            ) : (
                <button className={styles.registration_self_collapse_expand} type="button" onClick={() => setIsOpen(!isOpen)}>
                    <KeyboardArrowDownIcon />
                </button>
            )}
        </div>
    );
};

export default RegistrationTwistDown;
