import { demeterApi } from '../../../Apis/Apis';
import CacheKeys from '../../../Core/Cache/CacheKeys';
import { DemeterMarket, DemeterTableDefinitionRegionModel, DemeterTableDefinitionType } from '../../../Generated/Raven-Demeter/api';
import { useApplicationSelector } from '../../../Redux/ReduxStore';
import { selectUserCurrentMarket } from '../../../Redux/Slices/UserSlice';
import useCacheOrApi from './useCacheOrApiHook';

const useTableDefinitionsApi = (
    tableDefinitionType: DemeterTableDefinitionType,
    showHiddenTableDefinitions?: boolean,
    overrideMarket?: DemeterMarket,
): DemeterTableDefinitionRegionModel[] | undefined => {
    // We will use the override market when we are changing the market to get different table definitions for the page,
    // but we dont want to change the application level market.
    const applicatiionMarket = useApplicationSelector(selectUserCurrentMarket);
    const market = overrideMarket ?? applicatiionMarket;

    const [loading, , listDemeterTableDefinitionsResponse] = useCacheOrApi(
        `${CacheKeys.ListDemeterTableDefinitions}_${tableDefinitionType}_${market}_${!!showHiddenTableDefinitions}`,
        () => {
            if (showHiddenTableDefinitions) {
                return demeterApi.listAllDemeterTableDefinitions(tableDefinitionType, market);
            }

            return demeterApi.listDemeterTableDefinitions(tableDefinitionType, market);
        },
    );

    return loading ? undefined : listDemeterTableDefinitionsResponse?.rows ?? undefined;
};

export default useTableDefinitionsApi;
