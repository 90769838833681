import { FC, useState } from 'react';
import { ReactComponent as AdditionSymbolIcon } from '../../../Assets/Icons/additionSymbol.svg';
import useLanguage from '../../../Services/Language/useLanguageHook';
import styles from './BasisCalculator.module.scss';
import { BasisCalculatorProduct, BasisCalculatorProductPair } from './BasisCalculatorDefinitions';
import BasisCalculatorProductSelector from './BasisCalculatorProductSelector';

interface IBasisCalculatorStartupProps {
    productOptions: BasisCalculatorProduct[];
    userProductOptions?: BasisCalculatorProduct[];
    onChangeProductPair: (productPair: BasisCalculatorProductPair) => void;
}

const BasisCalculatorStartup: FC<IBasisCalculatorStartupProps> = (props: IBasisCalculatorStartupProps) => {
    const [translations] = useLanguage();
    const [productSelectorOpen, setProductSelectorOpen] = useState(false);

    const changeProductPair = (value: BasisCalculatorProductPair) => {
        props.onChangeProductPair(value);
    };

    return (
        <div>
            <p>{translations.calculators.basis.chooseProductsHeader}</p>
            <div className={styles.basis_calculator_startup}>
                <button type="button" onClick={() => setProductSelectorOpen(true)} className={styles.basis_calculator_startup_button}>
                    <AdditionSymbolIcon className={styles.basis_calculator_startup_button_icon} />
                    {translations.calculators.basis.chooseProducts}
                </button>
            </div>
            <BasisCalculatorProductSelector
                open={productSelectorOpen}
                activeTab={0}
                onChange={changeProductPair}
                onDelete={() => {}}
                onClose={() => setProductSelectorOpen(false)}
                productOptions={props.productOptions}
                userProductOptions={props.userProductOptions}
            />
        </div>
    );
};

export default BasisCalculatorStartup;
