import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import { useEffect, useState } from 'react';
import {
    DeleteMarketIndicatorFactorRequest,
    MarketIndicatorFactorSummaryModel,
    UpdateMarketIndicatorFactorGroupRequest,
} from '../../../../../Generated/Raven-Demeter';
import TextInput from '../../../../Components/Form/Inputs/TextInput';
import ActionModal from '../../../../Components/Modals/ActionModal/ActionModal';
import ConfirmModal from '../../../../Components/Modals/ConfirmModal/ConfirmModal';
import RegexValidators from '../../../../Core/Validation/RegexValidators';
import useLanguage from '../../../../Services/Language/useLanguageHook';
import styles from './MarketIndicatorActions.module.scss';

interface IMarketIndicatorFactorGroupActionsProps {
    factors: MarketIndicatorFactorSummaryModel[];
    marketIndicatorGuid: string;
    currentDisplayName: string;
    setCurrentDisplayName: (value: string) => void;
    handleDeleteMarketIndicatorFactors: (marketIndicatorFactors: DeleteMarketIndicatorFactorRequest[]) => void;
    handleUpdateMarketIndicatorFactorsGroup: (marketIndicatorFactor: UpdateMarketIndicatorFactorGroupRequest) => void;
}

const MarketIndicatorFactorGroupActions: React.FC<IMarketIndicatorFactorGroupActionsProps> = (props: IMarketIndicatorFactorGroupActionsProps) => {
    // Application hooks.
    const [translations] = useLanguage();

    // Component states.
    const [showEditFactorGroupNameModal, setShowEditFactorGroupNameModal] = useState(false);
    const [showDeleteFactorGroupModal, setShowDeleteFactorGroupModal] = useState(false);

    // Form request.
    const [selectedIndicator, setSelectedIndicator] = useState<DeleteMarketIndicatorFactorRequest[] | UpdateMarketIndicatorFactorGroupRequest>({
        marketIndicatorGuid: props.marketIndicatorGuid,
        displayGroupName: props.currentDisplayName,
        newDisplayGroupName: '',
    });

    // When we add a group for the first time and it has no factors, we want to reset the group name.
    useEffect(() => {
        if (props.factors.length > 0) {
            return;
        }

        setShowEditFactorGroupNameModal(true);
    }, [props.currentDisplayName]);

    return (
        <>
            <EditIcon
                onClick={() => {
                    setSelectedIndicator({
                        marketIndicatorGuid: props.marketIndicatorGuid,
                        displayGroupName: props.currentDisplayName,
                        newDisplayGroupName: props.currentDisplayName,
                    });
                    setShowEditFactorGroupNameModal(true);
                }}
            />
            <CloseIcon
                onClick={() => {
                    const deleteFactorGroupRequest = props.factors.map((x) => ({
                        marketIndicatorGuid: props.marketIndicatorGuid ?? '',
                        marketIndicatorFactorGuid: x.marketIndicatorFactorGuid,
                    }));
                    setSelectedIndicator(deleteFactorGroupRequest);
                    setShowDeleteFactorGroupModal(true);
                }}
            />
            <ActionModal
                header={translations.marketIndicatorsManagement.headers.editFactorGroup}
                showModal={showEditFactorGroupNameModal}
                actionButtonName={translations.actions.save}
                handleAction={() => {
                    props.handleUpdateMarketIndicatorFactorsGroup(selectedIndicator as UpdateMarketIndicatorFactorGroupRequest);
                    setShowEditFactorGroupNameModal(false);
                }}
                handleCancel={() => setShowEditFactorGroupNameModal(false)}
            >
                <div className={styles.markets_management_modal_input_wrapper}>
                    <TextInput
                        title={translations.marketIndicatorsManagement.headers.indicatorName}
                        required
                        value={props.currentDisplayName}
                        handleTextChange={(value) => {
                            props.setCurrentDisplayName(value);
                            setSelectedIndicator({ ...selectedIndicator, newDisplayGroupName: value });
                        }}
                        validation={RegexValidators.AlphaNumericMinimumLength2}
                        errorMessage={translations.marketIndicatorsManagement.messages.displayNameInvalid}
                    />
                </div>
            </ActionModal>

            <ConfirmModal
                header={translations.marketIndicatorsManagement.headers.deleteFactorGroup}
                showModal={showDeleteFactorGroupModal}
                message={translations.marketIndicatorsManagement.text.areYouSureDeleteFactorGroup}
                handleConfirm={() => {
                    props.handleDeleteMarketIndicatorFactors(selectedIndicator as DeleteMarketIndicatorFactorRequest[]);
                    setShowDeleteFactorGroupModal(false);
                }}
                handleCancel={() => setShowDeleteFactorGroupModal(false)}
            />
        </>
    );
};

export default MarketIndicatorFactorGroupActions;
