import { useMemo } from 'react';
import { UnitOfMeasure } from '../../../../Generated/Raven-Demeter';
import useLanguage from '../../../Services/Language/useLanguageHook';
import Dropdown from './Dropdown';

type UnitOfMeasureDropdownOptionsType = { label: string; value: UnitOfMeasure };

interface IUnitOfMeasureMetricImperialDropdownProps {
    unitOfMeasure?: UnitOfMeasure;
    handleChange: (value: UnitOfMeasure | undefined) => void;
}

const UnitOfMeasureMetricImperialDropdown: React.FC<IUnitOfMeasureMetricImperialDropdownProps> = (
    props: IUnitOfMeasureMetricImperialDropdownProps,
): JSX.Element => {
    const [translations] = useLanguage();

    const unitOfMeasureDropdownOptions = useMemo(
        () => [
            {
                label: translations.dropdown.nativeUnits,
                value: undefined,
            },
            {
                label: translations.unitOfMeasure[UnitOfMeasure.Imperial],
                value: UnitOfMeasure.Imperial,
            },
            {
                label: translations.unitOfMeasure[UnitOfMeasure.Metric],
                value: UnitOfMeasure.Metric,
            },
        ],
        [translations],
    ) as UnitOfMeasureDropdownOptionsType[];

    return (
        <Dropdown
            value={props.unitOfMeasure}
            options={unitOfMeasureDropdownOptions}
            handleOptionChange={(value: UnitOfMeasure | undefined) => props.handleChange(value)}
        />
    );
};

export default UnitOfMeasureMetricImperialDropdown;
