import Dropdown from '../../../../../../Components/Form/Inputs/Dropdown';
import { ForwardCurveParameters, longTermContractNumberOptions, UpsertMarketIndicatorFactorRequest } from '../../../MarketIndicatorsManagementDefinitions';
import styles from '../../MarketIndicatorFactorPage.module.scss';

interface IMarketFactorLongTermContractNumberDropdownProps {
    upsertMarketIndicatorFactorRequest: UpsertMarketIndicatorFactorRequest;
    setUpsertMarketIndicatorFactorRequest: (value: UpsertMarketIndicatorFactorRequest) => void;
}

const MarketFactorLongTermContractNumberDropdown: React.FC<IMarketFactorLongTermContractNumberDropdownProps> = (
    props: IMarketFactorLongTermContractNumberDropdownProps,
) => (
    <div className={styles.indicator_add_and_edit_dropdown}>
        <Dropdown
            value={(props.upsertMarketIndicatorFactorRequest.parameters as ForwardCurveParameters).longTermContractNumber}
            options={longTermContractNumberOptions}
            handleOptionChange={(value) => {
                props.setUpsertMarketIndicatorFactorRequest({
                    ...props.upsertMarketIndicatorFactorRequest,
                    parameters: {
                        ...props.upsertMarketIndicatorFactorRequest.parameters,
                        longTermContractNumber: value,
                    },
                });
            }}
        />
    </div>
);

export default MarketFactorLongTermContractNumberDropdown;
