import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import applicationSettings from '../../../../Core/Settings/ApplicationSettings';
import { useApplicationDispatch, useApplicationSelector } from '../../../../Redux/ReduxStore';
import { SearchParameters, selectSearchParameters, setSearchParameters } from '../../../../Redux/Slices/SystemSlice';

const useSearchParameters = (): [SearchParameters, (searchParameters: SearchParameters) => void] => {
    // For mono with redux bridge.
    const searchParametersFromRedux = useApplicationSelector(selectSearchParameters);
    const dispatch = useApplicationDispatch();

    const setUpdatedSearchParameters = (updatedSearchParameters: SearchParameters): void => {
        dispatch(setSearchParameters({ searchParameters: updatedSearchParameters }));
    };

    // For stand-alone mode.
    const [searchParams, setSearchParams] = useSearchParams();
    const [parsedSearchParams, setParsedSearchParams] = useState<SearchParameters>(() => Object.fromEntries(searchParams));

    useEffect(() => {
        if (applicationSettings.isMono) {
            return;
        }

        setParsedSearchParams(Object.fromEntries(searchParams));
    }, [searchParams]);

    return applicationSettings.isMono ? [searchParametersFromRedux, setUpdatedSearchParameters] : [parsedSearchParams, setSearchParams];
};

export default useSearchParameters;
