import applicationConstants from '../../../Core/Utility/ApplicationConstants';
import { chartColors } from '../../../Refactor/Components/Charts/ChartDefinitions';

const HighChartsGraphOptionsOriginsDestinationsStub = {
    colors: chartColors.donutChartColors,
    chart: {
        type: 'pie',
        height: '50%',
    },
    title: {
        text: '',
        useHTML: true,
        floating: true,
    },
    xAxis: {
        type: 'datetime',
    },
    subtitle: {
        text: {},
        verticalAlign: 'middle',
        useHTML: true,
        floating: true,
    },
    yAxis: [
        {
            title: {
                text: 'Total percent market share',
            },
        },
    ],
    plotOptions: {
        pie: {
            shadow: false,
            allowPointSelect: true,
            dataLabels: {
                useHTML: true,
                enabled: false,
            },
        },
    },
    tooltip: {
        useHTML: true,
        pointFormat: '',
    },
    series: [
        {
            name: 'OriginsAndDestinations',
            data: [],
            size: '100%',
            innerSize: '50%',
            id: 'originsAndDestinations',
        },
    ],
    credits: {
        enabled: false,
    },
    exporting: {
        buttons: {
            contextButton: {
                enabled: false,
            },
        },
    },
};

const HighChartsGraphOptionsBaseStub = {
    chart: {
        type: '',
        zoomType: 'xy',
        height: `${(1 / 2) * 100}%`,
    },
    tooltip: {
        valueDecimals: 5,
    },
    title: {
        text: 'f',
        style: {
            fontSize: '30px',
            color: 'white',
        },
    },
    rangeSelector: {
        enabled: false,
    },

    legend: {
        enabled: true,
    },
    yAxis: {
        title: {
            text: null,
        },
        labels: {
            align: 'center',
            x: 10,
            y: 0,
        },
        crosshair: {
            snap: true,
            dashStyle: 'shortDash',
        },
    },
    xAxis: {
        type: 'datetime',
        crosshair: {
            snap: true,
            dashStyle: 'shortDash',
        },
    },
    plotOptions: {
        ohlc: {
            color: 'red',
            upColor: 'green',
        },
        series: {
            pointInterval: 1,
            pointStart: 1,
        },
    },
    exporting: {
        sourceHeight: applicationConstants.ChartExportOptionsDimensions.Height,
        sourceWidth: applicationConstants.ChartExportOptionsDimensions.Width,
        scale: applicationConstants.ChartExportOptionsDimensions.Scale,
    },
    series: [
        {
            type: 'candlestick',
            id: 'aapl',
            name: 'Class III Dairy',
            data: [5, 6, 8, 2] as any[],
            marker: {
                enabled: false,
            },
        },
    ],
    credits: {
        enabled: false,
    },
};

const bollingerBandsStub = {
    type: 'bb',
    linkedTo: 'aapl',
    color: 'grey',
    lineWidth: 2,
    params: {
        period: 4,
    },
};

const annualPercentageChangeSeriesStub: any = {
    name: 'Annual Percentage Change',
    type: 'column',
    color: '#2A67CD',
    linkedTo: 'aapl',
    data: [] as any[],
    params: {
        period: 4,
    },
    tooltip: {
        valueSuffix: '%',
    },
};

const movingAverageStub = [
    {
        type: 'sma',
        name: 'Simple Moving Average Period 4',
        showInLegend: true,
        linkedTo: 'aapl',
        color: 'lime',
        params: {
            period: 4,
        },
    },
    {
        type: 'sma',
        name: 'Simple Moving Average Period 5',
        showInLegend: true,
        linkedTo: 'aapl',
        color: 'red',
        params: {
            period: 5,
        },
    },
];

const trixStub = [
    {
        type: 'trix',
        linkedTo: 'aapl',
        yAxis: 0,
        marker: {
            enabled: false,
        },
        showInLegend: true,
    },
    {
        type: 'trix',
        linkedTo: 'aapl',
        params: {
            period: 5,
        },
        yAxis: 0,
        marker: {
            enabled: false,
        },
        showInLegend: true,
    },
];

const relativeStrengthIndexStub = {
    yAxis: 0,
    type: 'rsi',
    linkedTo: 'aapl',
};

const rateOfChangeStub = [
    {
        type: 'roc',
        linkedTo: 'aapl',
    },
    {
        type: 'roc',
        linkedTo: 'aapl',
        params: {
            period: 5,
        },
    },
];

const percentagePriceOscillatorStub = {
    type: 'ppo',
    linkedTo: 'aapl',
    yAxis: 0,
    color: '#001e84',
    lineWidth: 2,
};

const linearRegressionSlopeStub = [
    {
        type: 'linearRegressionSlope',
        linkedTo: 'aapl',
        yAxis: 0,
        zIndex: -1,
        params: {
            period: 5,
        },
    },
    {
        type: 'linearRegressionSlope',
        linkedTo: 'aapl',
        yAxis: 0,
        zIndex: -1,
        params: {
            period: 10,
        },
    },
];

const linearRegressionInterceptStub = [
    {
        type: 'linearRegressionIntercept',
        linkedTo: 'aapl',
        yAxis: 0,
        zIndex: -1,
        params: {
            period: 5,
        },
    },
    {
        type: 'linearRegressionIntercept',
        linkedTo: 'aapl',
        yAxis: 0,
        zIndex: -1,
        params: {
            period: 50,
        },
    },
];

const detrendedPriceStub = {
    type: 'dpo',
    linkedTo: 'aapl',
    yAxis: 0,
    lineWidth: 2,
    marker: {
        enabled: false,
    },
    params: {
        period: 2,
        index: 3,
    },
};

const aroonStub = {
    yAxis: 0,
    type: 'aroon',
    linkedTo: 'aapl',
    color: 'green',
    lineWidth: 1,
    aroonDown: {
        styles: {
            lineColor: 'red',
        },
    },
    params: {
        period: 10,
    },
};

const wmaStub = [
    {
        type: 'wma',
        name: 'Weighted Moving Average',
        showInLegend: true,
        linkedTo: 'aapl',
    },
    {
        type: 'wma',
        name: 'Weighted Moving Average Period 10',
        showInLegend: true,
        linkedTo: 'aapl',
        params: {
            period: 10,
        },
    },
];

const temaStub = {
    type: 'tema',
    linkedTo: 'aapl',
    showInLegend: true,
};

const linearRegressionStub = [
    {
        type: 'linearRegression',
        linkedTo: 'aapl',
        params: {
            period: 5,
        },
    },
    {
        type: 'linearRegression',
        linkedTo: 'aapl',
        params: {
            period: 10,
        },
    },
];

const emaStub = {
    type: 'ema',
    name: 'Exponential Moving Average',
    showInLegend: true,
    linkedTo: 'aapl',
    params: {
        period: 5,
    },
};

const demaStub = [
    {
        type: 'dema',
        name: 'Double Exponential Moving Average',
        showInLegend: true,
        linkedTo: 'aapl',
    },
    {
        type: 'dema',
        name: 'Double Exponential Moving Average Period 50',
        showInLegend: true,
        linkedTo: 'aapl',
        params: {
            period: 50,
        },
    },
];

const accelerationBandsStub = {
    type: 'abands',
    linkedTo: 'aapl',
};

const absolutePriceStub = {
    type: 'apo',
    linkedTo: 'aapl',
    yAxis: 0,
    color: 'grey',
    lineWidth: 2,
    params: {
        periods: [2, 3],
    },
};

const priceChannelStub = {
    type: 'pc',
    linkedTo: 'aapl',
};

const keltnerChannelStub = {
    type: 'keltnerchannels',
    linkedTo: 'aapl',
};

const pivotPointsStub = [
    {
        type: 'pivotpoints',
        linkedTo: 'aapl',
        zIndex: 0,
        lineWidth: 1,
        name: 'Camarilla PP (56)',
        params: {
            algorithm: 'camarilla',
            period: 5,
        },
    },
    {
        type: 'pivotpoints',
        linkedTo: 'aapl',
        zIndex: 0,
        lineWidth: 1,
        params: {
            algorithm: 'fibonacci',
            period: 4,
        },
    },
];

const superTrendStub = {
    type: 'supertrend',
    linkedTo: 'aapl',
    lineWidth: 2,
    marker: {
        enabled: true,
    },
    risingTrendColor: '#16C535',
    fallingTrendColor: '#F22303',
    changeTrendLine: {
        styles: {
            lineWidth: 0.5,
            lineColor: '#000',
            dashStyle: 'Dash',
        },
    },
};

const zigzagStub = [
    {
        type: 'zigzag',
        linkedTo: 'aapl',
    },
    {
        type: 'zigzag',
        linkedTo: 'aapl',
        params: {
            deviation: 5,
        },
    },
];

const priceEnvelopesStub = {
    type: 'priceenvelopes',
    linkedTo: 'aapl',
};

const averageTrueRangeStub = [
    {
        type: 'atr',
        linkedTo: 'aapl',
        yAxis: 0,
    },
    {
        type: 'atr',
        linkedTo: 'aapl',
        yAxis: 0,
        params: {
            period: 50,
        },
    },
];

const commodityChannelIndexStub = [
    {
        type: 'cci',
        linkedTo: 'aapl',
        yAxis: 0,
    },
    {
        type: 'cci',
        linkedTo: 'aapl',
        yAxis: 0,
        params: {
            period: 50,
        },
    },
];

const stocasticStub = {
    yAxis: 0,
    type: 'stochastic',
    linkedTo: 'aapl',
};

const slowStocasticStub = {
    yAxis: 0,
    type: 'slowstochastic',
    linkedTo: 'aapl',
};

const normalizedAverageTrueRangeStub = [
    {
        type: 'natr',
        linkedTo: 'aapl',
        yAxis: 0,
    },
    {
        type: 'natr',
        linkedTo: 'aapl',
        yAxis: 0,
        params: {
            period: 5,
        },
    },
];

const momentumStub = [
    {
        type: 'momentum',
        linkedTo: 'aapl',
    },
    {
        type: 'momentum',
        linkedTo: 'aapl',
        params: {
            period: 50,
        },
    },
];

const movingAverageConvergenceDivergenceStub = {
    yAxis: 0,
    type: 'macd',
    linkedTo: 'aapl',
    params: {
        shortPeriod: 12,
        longPeriod: 26,
        signalPeriod: 9,
        period: 26,
    },
};

const linearRegressionAngleStub = [
    {
        type: 'linearRegressionAngle',
        linkedTo: 'aapl',
        yAxis: 0,
        zIndex: -1,
        params: {
            period: 5,
        },
    },
    {
        type: 'linearRegressionAngle',
        linkedTo: 'aapl',
        yAxis: 0,
        zIndex: -1,
        params: {
            period: 20,
        },
    },
];

const volumeStub = {
    data: [9, 4, 4, 6],
    yAxis: 0,
    name: 'Volume',
    id: 'volume',
    type: 'column',
};

const onBalanceVolumeStub = {
    linkedTo: 'aapl',
    type: 'obv',
    params: {
        volumeSeriesID: 'volume',
    },
    showInLegend: true,
    yAxis: 2,
};

const moneyFlowIndexStub = {
    type: 'mfi',
    linkedTo: 'aapl',
    yAxis: 2,
    marker: {
        enabled: false,
    },
    params: {
        volumeSeriesID: 'volume',
        period: 4,
    },
};

const klingerStub = {
    type: 'klinger',
    linkedTo: 'aapl',
    showInLegend: true,
    params: {
        volumeSeriesID: 'volume',
    },
    yAxis: 0,
};

const chaikinMoneyFlowStub = [
    {
        type: 'cmf',
        linkedTo: 'aapl',
        yAxis: 2,
        marker: {
            enabled: false,
        },
    },
    {
        type: 'cmf',
        linkedTo: 'aapl',
        yAxis: 2,
        marker: {
            enabled: false,
        },
        params: {
            period: 50,
        },
    },
];

const chaikinStub = {
    type: 'chaikin',
    linkedTo: 'aapl',
    yAxis: 2,
    params: {
        volumeSeriesID: 'volume',
    },
};

const accumulationDistributionStub = {
    type: 'ad',
    linkedTo: 'aapl',
    yAxis: 2,
    params: {
        period: 0,
        volumeSeriesID: 'volume',
    },
};

const volumeWeightedAveragePriceStub = {
    type: 'vwap',
    linkedTo: 'aapl',
    showInLegend: true,
};

const volumeByPriceStub = {
    type: 'vbp',
    linkedTo: 'aapl',
    showInLegend: true,
};

const ichimokuKinkoHyoStub = {
    type: 'ikh',
    linkedTo: 'aapl',
    tenkanLine: {
        styles: {
            lineColor: 'lightblue',
        },
    },
    kijunLine: {
        styles: {
            lineColor: 'darkred',
        },
    },
    chikouLine: {
        styles: {
            lineColor: 'lightgreen',
        },
    },
    senkouSpanA: {
        styles: {
            lineColor: 'green',
        },
    },
    senkouSpanB: {
        styles: {
            lineColor: 'red',
        },
    },
    senkouSpan: {
        color: 'rgba(0, 255, 0, 0.3)',
        styles: {
            fill: 'rgba(0, 0, 255, 0.1)',
        },
    },
};

const aroonOscillatorStub = {
    type: 'aroonoscillator',
    linkedTo: 'aapl',
    yAxis: 0,
    color: 'turquoise',
    lineWidth: 1.5,
    style: {
        lineWidth: 5,
    },
    params: {
        period: 14,
    },
};

const awesomeOscillatorStub = {
    type: 'ao',
    yAxis: 0,
    greaterBarColor: '#00cc66',
    lowerBarColor: '#FF5E5E',
    linkedTo: 'aapl',
    showInLegend: true,
};

const chandeMomentumOscillatorStub = {
    type: 'cmo',
    linkedTo: 'aapl',
    yAxis: 0,
    params: {
        period: 9,
    },
};

const directionalMovementIndexStub = {
    type: 'dmi',
    linkedTo: 'aapl',
    yAxis: 0,
    params: {
        period: 9,
    },
};

const disparityindexStub = {
    type: 'disparityindex',
    linkedTo: 'aapl',
    yAxis: 0,
    params: {
        period: 5,
    },
};

const williamsrStub = {
    yAxis: 0,
    type: 'williamsr',
    linkedTo: 'aapl',
    color: 'green',
    lineWidth: 1.5,
    marker: {
        enabled: false,
    },
    params: {
        period: 4,
    },
    zones: [
        {
            value: -80,
            color: 'green',
        },
        {
            value: -20,
            color: '#bbb',
        },
    ],
};

export {
    HighChartsGraphOptionsOriginsDestinationsStub,
    HighChartsGraphOptionsBaseStub,
    bollingerBandsStub,
    annualPercentageChangeSeriesStub,
    movingAverageStub,
    trixStub,
    relativeStrengthIndexStub,
    rateOfChangeStub,
    percentagePriceOscillatorStub,
    linearRegressionSlopeStub,
    linearRegressionInterceptStub,
    detrendedPriceStub,
    aroonStub,
    wmaStub,
    temaStub,
    linearRegressionStub,
    emaStub,
    demaStub,
    accelerationBandsStub,
    absolutePriceStub,
    priceChannelStub,
    keltnerChannelStub,
    pivotPointsStub,
    superTrendStub,
    zigzagStub,
    priceEnvelopesStub,
    averageTrueRangeStub,
    commodityChannelIndexStub,
    stocasticStub,
    slowStocasticStub,
    normalizedAverageTrueRangeStub,
    momentumStub,
    movingAverageConvergenceDivergenceStub,
    linearRegressionAngleStub,
    volumeStub,
    onBalanceVolumeStub,
    moneyFlowIndexStub,
    klingerStub,
    chaikinMoneyFlowStub,
    chaikinStub,
    accumulationDistributionStub,
    volumeWeightedAveragePriceStub,
    volumeByPriceStub,
    ichimokuKinkoHyoStub,
    aroonOscillatorStub,
    awesomeOscillatorStub,
    chandeMomentumOscillatorStub,
    directionalMovementIndexStub,
    disparityindexStub,
    williamsrStub,
};
