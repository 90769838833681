import React from 'react';
import languageService from '../../Services/Language/LanguageService';
import styles from './FrequentlyAskedQuestionsPage.module.scss';

const FrequentlyAskedQuestionsPage: React.FC = () => (
    <div className={styles.master_page_container}>
        <div className={styles.faqs}>
            <h3 className={styles.faqs_h3}>{languageService.translate('frequentlyAskedQuestionsHeader')}</h3>
            <div className={styles.faqs_question}>{languageService.translate('frequentlyAskedQuestionsQuestion1')}</div>
            <div className={styles.faqs_answer}>{languageService.translate('frequentlyAskedQuestionsAnswer1')}</div>
            <div className={styles.faqs_question}>{languageService.translate('frequentlyAskedQuestionsQuestion3')}</div>
            <div className={styles.faqs_answer}>{languageService.translate('frequentlyAskedQuestionsAnswer3')}</div>
            <div className={styles.faqs_question}>{languageService.translate('frequentlyAskedQuestionsQuestion4')}</div>
            <div className={styles.faqs_answer}>{languageService.translate('frequentlyAskedQuestionsAnswer4')}</div>
            <div className={styles.faqs_question}>{languageService.translate('frequentlyAskedQuestionsQuestion5')}</div>
            <div className={styles.faqs_answer}>{languageService.translate('frequentlyAskedQuestionsAnswer5')}</div>
            <div className={styles.faqs_question}>{languageService.translate('frequentlyAskedQuestionsQuestion6')}</div>
            <div className={styles.faqs_answer}>{languageService.translate('frequentlyAskedQuestionsAnswer6')}</div>
            <div className={styles.faqs_question}>{languageService.translate('frequentlyAskedQuestionsQuestion7')}</div>
            <div className={styles.faqs_answer}>{languageService.translate('frequentlyAskedQuestionsAnswer7')}</div>
            <div className={styles.faqs_question}>{languageService.translate('frequentlyAskedQuestionsQuestion8')}</div>
            <div className={styles.faqs_answer}>{languageService.translate('frequentlyAskedQuestionsAnswer8')}</div>
        </div>
    </div>
);

export default FrequentlyAskedQuestionsPage;
