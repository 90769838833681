import React from 'react';
import MenuFileSelector from '../../../Components/MenuFileSelector/MenuFileSelector';
import { DemeterTableDefinitionType } from '../../../Generated/Raven-Demeter/api';
import PageHeader from './PageHeader';

export interface IPageHeaderWithSelectionsProps {
    title: string;
    tableDefinitionType: DemeterTableDefinitionType;
    showMainRegionsAndGroupsOnly?: boolean;
    rightSideOfHeaderComponent?: JSX.Element;
    rightSideOfBreadcrumbsComponent?: JSX.Element;
    testId?: string;
}

const PageHeaderWithSelections: React.FC<IPageHeaderWithSelectionsProps> = (props: IPageHeaderWithSelectionsProps) => (
    <PageHeader title={props.title} rightSideComponent={props.rightSideOfHeaderComponent} testId={props.testId}>
        {props.tableDefinitionType && (
            <MenuFileSelector
                showMainRegionsAndGroupsOnly={props.showMainRegionsAndGroupsOnly}
                tableDefinitionType={props.tableDefinitionType}
                rightSideOfBreadcrumbsComponent={props.rightSideOfBreadcrumbsComponent}
            />
        )}
    </PageHeader>
);

export default PageHeaderWithSelections;
