import React, { ReactNode } from 'react';
import styles from './Headers.module.scss';

export interface IHeaderProps {
    title: string;
    children?: ReactNode;
    rightSideComponent?: JSX.Element;
    testId?: string;
}

const PageHeader: React.FC<IHeaderProps> = (props: IHeaderProps) => (
    <div className={styles.page_header_container}>
        <div className={styles.page_header_title_container}>
            <h3 data-testid={props.testId} className={styles.page_header_page_title}>
                {props.title}
            </h3>
            {props.rightSideComponent && <div className={styles.master_flex_and_float_right}>{props.rightSideComponent}</div>}
        </div>
        {props.children && <div>{props.children}</div>}
    </div>
);

export default PageHeader;
