import { Modal } from '@mui/material';
import React, { Children } from 'react';
import useDimensions from 'react-use-dimensions';
import { EventActionsEnum, EventCategoriesEnum } from '../../../../Core/Logging/DataLayerDefinitions';
import loggingService from '../../../../Core/Logging/LoggingService';
import styles from './ChartWrapper.module.scss';

interface IChartModalWrapperProps {
    children: JSX.Element;
    name: string;
    showModal: boolean;
    setShowModal: (showModal: boolean) => void;
}

// Modal should be allowed to "stretch" width-wise more since it should be the only chart on the screen.
const modalMaxWidthHeightRatio = 6.0;
const modalMaxPercentOfScreenHeight = 95;

const ChartModalWrapper: React.FC<IChartModalWrapperProps> = (props: IChartModalWrapperProps) => {
    const [chartContainerReference, chartContainerDimensions] = useDimensions();

    const handleClose = () => {
        props.setShowModal(false);
        loggingService.trackEventWithAnalytics(EventActionsEnum.ModalInteraction, EventCategoriesEnum.PopoutChartModalOpen, props.name, props.name);
    };

    return (
        <>
            {props.showModal && (
                <Modal open={props.showModal} onClose={handleClose} disableEnforceFocus>
                    <div className={styles.chart_modal_wrapper} data-testid="ChartModal">
                        <div ref={chartContainerReference} className={styles.chart_modal_wrapper_inner}>
                            {React.cloneElement(props.children, {
                                isInModal: true,
                                chartContainerDimensions,
                                maxWidthHeightRatio: modalMaxWidthHeightRatio,
                                maxPercentOfScreenHeight: modalMaxPercentOfScreenHeight,
                            })}
                        </div>
                    </div>
                </Modal>
            )}
            {Children.only(props.children)}
        </>
    );
};

export default ChartModalWrapper;
