import { demeterUsersApi } from '../../Apis/Apis';
import promiseHandler from '../../Core/Handlers/PromiseHandler';
import { DemeterUserStoreModel, DemeterUserStoreType } from '../../Generated/Raven-Demeter';

export const getUserDataPreferences = async (key?: string): Promise<any> => {
    const userDataResponse = await loadUserDataPreferences();
    if (userDataResponse) {
        if (key && userDataResponse.value[key]) {
            return userDataResponse.value[key];
        }
    }
    return null;
};

export const loadUserDataPreferences = async (): Promise<DemeterUserStoreModel | null> => {
    const userDataResponse = await promiseHandler(demeterUsersApi.getUserStore(DemeterUserStoreType.UserData));
    if (userDataResponse.data.userStore) {
        return userDataResponse.data.userStore;
    }
    return null;
};

export const updateUserDataPreferences = async (key: string, value: object | string, prevUserDataPreference?: any) => {
    let userDataPref = prevUserDataPreference;
    if (!userDataPref) {
        userDataPref = await loadUserDataPreferences();
    }
    userDataPref.value[key] = value;
    await promiseHandler(
        demeterUsersApi.updateUserStore(DemeterUserStoreType.UserData, {
            userStoreType: DemeterUserStoreType.UserData,
            value: userDataPref.value,
        }),
    );
};
