import React, { useEffect, useMemo, useState } from 'react';
import { Currency, MarketIndicatorFactorDataModel, UnitOfMeasure } from '../../../../../Generated/Raven-Demeter';
import {
    AverageSeasonalParameters,
    leadingIndicatorTypeDefinitions,
} from '../../../../Pages/Administration/MarketIndicatorsManagement/MarketIndicatorsManagementDefinitions';
import useLanguage from '../../../../Services/Language/useLanguageHook';
import LoadingSpinner from '../../../LoadingSpinner/LoadingSpinner';
import { IChartDataSeries } from '../../ChartDefinitions';
import ChartWrapper from '../../ChartWrapper/ChartWrapper';
import { IMarketIndicatorChartProps } from '../MarketIndicatorChart';
import { defaultChartPalette } from '../MarketIndicatorChartColorPalette';
import { defaultAverageSeasonalParameters } from '../MarketIndicatorChartDefaultParameters';
import MarketIndicatorChartRaw from '../MarketIndicatorChartRaw';
import marketIndicatorChartService from '../MarketIndicatorChartService';
import marketIndicatorAverageSeasonalChartService from './MarketIndicatorAverageSeasonalChartService';

export interface IMarketIndicatorAverageSeasonalChartProps extends IMarketIndicatorChartProps {
    parameters?: AverageSeasonalParameters;
}

const MarketIndicatorAverageSeasonalChart: React.FC<IMarketIndicatorAverageSeasonalChartProps> = (props: IMarketIndicatorAverageSeasonalChartProps) => {
    const [translations, translate] = useLanguage();
    const [lineSeries, setLineSeries] = useState<IChartDataSeries[]>([]);

    useEffect(() => {
        const calculationData = marketIndicatorChartService.filterByYears(chartData, numberOfYears + 1);
        const thisYearData = marketIndicatorChartService.filterByYears(calculationData, 1);
        const seasonalAverageData = marketIndicatorChartService.filterByYears(
            marketIndicatorAverageSeasonalChartService.calculateSeasonalAverageData(calculationData, numberOfYears),
            1,
        );
        const newLinesSeries: IChartDataSeries[] = [
            {
                label: translations.text.thisYear,
                data: thisYearData,
            },
            {
                label: `${numberOfYears} ${translations.text.yearlyAverage}`,
                data: seasonalAverageData,
            },
        ];
        setLineSeries(newLinesSeries);
    }, [props.runTestMarketIndicatorFactorResponse, props.parameters]);

    const numberOfYears = useMemo(() => {
        const parameters = props.parameters ?? defaultAverageSeasonalParameters;
        const value = Number(parameters.yearsOfData);
        return value;
    }, [props.parameters]);

    const chartData = useMemo(() => {
        if (
            props.runTestMarketIndicatorFactorResponse &&
            props.runTestMarketIndicatorFactorResponse.rows &&
            props.runTestMarketIndicatorFactorResponse.rows.length > 0
        ) {
            return marketIndicatorChartService.convertToChartData((props.runTestMarketIndicatorFactorResponse?.rows as MarketIndicatorFactorDataModel[]) ?? []);
        }
        return [];
    }, [props.runTestMarketIndicatorFactorResponse]);

    const isLoading = useMemo(
        () => !props.runTestMarketIndicatorFactorResponse && !props.showOnlyAsPopout,
        [props.runTestMarketIndicatorFactorResponse, props.showOnlyAsPopout],
    );

    const title = useMemo(() => {
        if (props.title) {
            return props.title;
        }
        if (props.runTestMarketIndicatorFactorResponse && props.runTestMarketIndicatorFactorResponse.commodityDisplayName) {
            const region = props.runTestMarketIndicatorFactorResponse?.subRegion
                ? translate(props.runTestMarketIndicatorFactorResponse?.subRegion)
                : translate(props.runTestMarketIndicatorFactorResponse?.regionDisplayName!);
            const tableDefinitionType =
                translations.tableDefinitionType[
                    leadingIndicatorTypeDefinitions.find((x) => x.value === props.runTestMarketIndicatorFactorResponse?.leadingIndicatorType)
                        ?.tableDefinitionType!
                ];
            const commdityName = props.runTestMarketIndicatorFactorResponse.commodityDisplayName;
            return [region, commdityName, tableDefinitionType].filter((x) => x !== null && x !== '').join(' ');
        }
        return '';
    }, [props.runTestMarketIndicatorFactorResponse]);

    const sourceTag = useMemo(() => {
        if (
            props.runTestMarketIndicatorFactorResponse &&
            props.runTestMarketIndicatorFactorResponse.dataSourceTags &&
            props.runTestMarketIndicatorFactorResponse.dataSourceTags.length > 0
        ) {
            return `${props.runTestMarketIndicatorFactorResponse.dataSourceTags[0]}, ${translations.dataSource.StoneXCalculations}`;
        }
        return '';
    }, [props.runTestMarketIndicatorFactorResponse]);

    const currency = useMemo(() => {
        if (props.runTestMarketIndicatorFactorResponse && props.runTestMarketIndicatorFactorResponse.currency) {
            return props.runTestMarketIndicatorFactorResponse.currency;
        }
        return '' as Currency;
    }, [props.runTestMarketIndicatorFactorResponse]);

    const unitOfMeasure = useMemo(() => {
        if (props.runTestMarketIndicatorFactorResponse && props.runTestMarketIndicatorFactorResponse.unitOfMeasure) {
            return props.runTestMarketIndicatorFactorResponse.unitOfMeasure;
        }
        return '' as UnitOfMeasure;
    }, [props.runTestMarketIndicatorFactorResponse]);

    return isLoading ? (
        <LoadingSpinner />
    ) : (
        <ChartWrapper
            name="MarketIndicatorAverageSeasonalChart"
            title={title}
            dataSourceTag={sourceTag}
            isLoading={isLoading}
            headerOptions={{
                showOnlyAsPopout: props.showOnlyAsPopout,
            }}
            showPopout={props.showPopout}
            setShowPopout={props.setShowPopout}
        >
            <MarketIndicatorChartRaw lineSeries={lineSeries} currency={currency} unitOfMeasure={unitOfMeasure} colorPalette={defaultChartPalette} />
        </ChartWrapper>
    );
};

export default MarketIndicatorAverageSeasonalChart;
