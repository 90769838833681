import { useEffect, useState } from 'react';
import Switch from '../Buttons/Switch';
import styles from './Inputs.module.scss';

interface ISwitchWithLabelProps {
    label: string;
    checked: boolean;
    onChange: (checked: boolean) => void;
}

const SwitchWithLabel = (props: ISwitchWithLabelProps): JSX.Element => {
    const [checked, setChecked] = useState<boolean>(props.checked);

    useEffect(() => {
        setChecked(props.checked);
    }, [props.checked]);

    useEffect(() => {
        props.onChange(checked);
    }, [checked]);

    const handleClick = () => {
        setChecked(!checked);
    };

    return (
        <div className={styles.input_switch_with_label}>
            <span onClick={handleClick} tabIndex={0} onKeyPress={() => {}} role="button">
                <Switch checked={checked} handleChange={() => {}} /> {props.label}
            </span>
        </div>
    );
};

export default SwitchWithLabel;
