import { SeriesLinearregressionOptions, SeriesOptionsType } from 'highcharts';
import highchartsMore from 'highcharts/highcharts-more';
import HighStock from 'highcharts/highstock';
import apo from 'highcharts/indicators/apo';
import bollingerBands from 'highcharts/indicators/bollinger-bands';
import ema from 'highcharts/indicators/ema';
import indicators from 'highcharts/indicators/indicators';
import regressions from 'highcharts/indicators/regressions';
import rsi from 'highcharts/indicators/rsi';
import exportData from 'highcharts/modules/export-data';
import exporting from 'highcharts/modules/exporting';
// eslint-disable-next-line import/no-cycle
import languageService from '../../../Services/Language/LanguageService';

// Default prototype mappings.
highchartsMore(HighStock);
exportData(HighStock);
exporting(HighStock);
indicators(HighStock);

// Study prototype mappings.
bollingerBands(HighStock);
regressions(HighStock);
ema(HighStock);
rsi(HighStock);
apo(HighStock);

export enum ChartStudyType {
    BollingerBands = 'BollingerBands',
    LinearRegression = 'LinearRegression',
    MovingAverage = 'MovingAverage',
    ExponentialMovingAverage = 'ExponentialMovingAverage',
    RelativeStrengthIndex = 'RelativeStrengthIndex',
    AbsolutePrice = 'AbsolutePrice',
}

export type ChartStudySeriesOptions = SeriesOptionsType & {
    nameValueGetter?: () => string;
};

export const StudyToSeriesMap: { [key in keyof typeof ChartStudyType]: ChartStudySeriesOptions[] } = {
    [ChartStudyType.BollingerBands]: [
        {
            type: 'bb',
            nameValueGetter: () => `${languageService.translate('chartStudy', ChartStudyType.BollingerBands)} 4`,
            showInLegend: true,
            lineWidth: 2,
            params: {
                period: 4,
            },
            visible: true,
            yAxis: 0,
        },
    ],
    [ChartStudyType.LinearRegression]: [
        {
            type: 'linearRegression',
            nameValueGetter: () => `${languageService.translate('chartStudy', ChartStudyType.LinearRegression)} 5`,
            showInLegend: true,
            params: {
                period: 5,
            },
            yAxis: 0,
        },
        {
            type: 'linearRegression',
            nameValueGetter: () => `${languageService.translate('chartStudy', ChartStudyType.LinearRegression)} 10`,
            showInLegend: true,
            params: {
                period: 10,
            },
            yAxis: 0,
        },
    ] as SeriesLinearregressionOptions & { type: 'linearRegression' },
    [ChartStudyType.MovingAverage]: [
        {
            type: 'sma',
            nameValueGetter: () => `${languageService.translate('chartStudy', ChartStudyType.MovingAverage)} 4`,
            showInLegend: true,
            params: {
                period: 4,
            },
            yAxis: 0,
        },
        {
            type: 'sma',
            nameValueGetter: () => `${languageService.translate('chartStudy', ChartStudyType.MovingAverage)} 5`,
            showInLegend: true,
            params: {
                period: 5,
            },
            yAxis: 0,
        },
    ],
    [ChartStudyType.ExponentialMovingAverage]: [
        {
            type: 'ema',
            nameValueGetter: () => `${languageService.translate('chartStudy', ChartStudyType.ExponentialMovingAverage)} 5`,
            showInLegend: true,
            params: {
                period: 5,
            },
            yAxis: 0,
        },
    ],
    [ChartStudyType.RelativeStrengthIndex]: [
        {
            type: 'rsi',
            nameValueGetter: () => `${languageService.translate('chartStudy', ChartStudyType.RelativeStrengthIndex)}`,
            showInLegend: true,
            yAxis: 0,
        },
    ],
    [ChartStudyType.AbsolutePrice]: [
        {
            type: 'apo',
            nameValueGetter: () => `${languageService.translate('chartStudy', ChartStudyType.AbsolutePrice)} 2 3`,
            showInLegend: true,
            params: {
                periods: [2, 3],
            },
            yAxis: 0,
        },
    ],
};
