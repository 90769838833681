import React, { useState } from 'react';
import { usersApi } from '../../../Apis/Apis';
import AlertModal from '../../../Components/AlertModal/AlertModal';
import passwordLegalCheck from '../../../Components/Assets/passwordLegalCheck.svg';
import passwordLegalX from '../../../Components/Assets/passwordLegalX.svg';
import useApi from '../../Apis/Hooks/useApiHook';
import LinkButton, { LinkButtonType } from '../../Components/Form/Buttons/LinkButton';
import TextInput from '../../Components/Form/Inputs/TextInput';
import RegexValidators from '../../Core/Validation/RegexValidators';
import useLanguage from '../../Services/Language/useLanguageHook';
import styles from './RegistrationPage.module.scss';

interface IRegistrationActivatePageProps {
    activationCode?: string | null;
    email?: string | null;
    navigateToLogin: () => void;
}

const RegistrationActivateComponent: React.FC<IRegistrationActivatePageProps> = (props: IRegistrationActivatePageProps) => {
    const [password, setPassword] = useState<string>();
    const [confirmPassword, setConfirmPassword] = useState<string>();
    const [isSaving, setIsSaving] = useState(false);
    const [translations] = useLanguage();

    const [, callVerifyRegistrationApi, verifyRegistrationResponse, error]: any = useApi(
        () => {
            if (!password) {
                return null;
            }

            return usersApi.verifyRegistration({
                activationCode: props.activationCode!.replace(/\D/g, ''),
                email: props.email!.replaceAll(' ', '+'),
                password,
            });
        },
        { stopAutoExecute: true },
    );

    // If we are missing vital parameters to register, an error is displayed.
    if (!props.activationCode || !props.email) {
        return (
            <div className={styles.registration_activate_background}>
                <div className={styles.registration_activate_errors}>
                    <div className={styles.registration_activate_vertically_centered_text}>{translations.registration.errors.missingParameters}</div>
                </div>
            </div>
        );
    }

    // If there is a registration error, say so.
    if (isSaving && error) {
        return <AlertModal setShowModal={() => {}} message={translations.registration.text.registrationFailed} />;
    }

    // Once we are done, alert the user and move them to login screen.
    if (isSaving && verifyRegistrationResponse) {
        // TODO: Move them to mono login.
        return (
            <AlertModal
                setShowModal={() => {
                    props.navigateToLogin();
                }}
                message={translations.registration.text.registrationSucceeded}
            />
        );
    }

    const passwordRequirements = [
        { message: translations.registration.text.lengthMessage, condition: password && password?.length >= 14 },
        { message: translations.registration.text.specialCharacterMessage, condition: password?.match(RegexValidators.SpecialCharacter) },
        { message: translations.registration.text.matchingPasswords, condition: password && password === confirmPassword },
    ];

    return (
        <div className={styles.registration_activate_background}>
            <div className={styles.registration_activate_container}>
                <div className={styles.registration_activate_auth_content}>
                    <h3 className={styles.registration_activate_title}>{translations.registration.title}</h3>
                    <div className={styles.registration_activate_welcome_message}>{translations.registration.text.welcomeMessage}</div>
                    <div className={styles.registration_activate_password_requirements}>
                        <div className={styles.registration_activate_password_requirements_text}>{translations.registration.text.passwordRequirements}</div>
                        {passwordRequirements.map((element) => (
                            <p key={element.message} className={styles.registration_activate_password_validation}>
                                {element.condition ? (
                                    <img className={styles.registration_activate_validation_image} src={passwordLegalCheck} alt="#" />
                                ) : (
                                    <img className={styles.registration_activate_validation_image} src={passwordLegalX} alt="#" />
                                )}
                                {element.message}
                            </p>
                        ))}
                    </div>
                    <div className={styles.registration_activate_input_area}>
                        <div className={styles.registration_activate_input}>
                            <TextInput
                                title={translations.registration.fields.password}
                                placeholder={translations.registration.fields.choosePasswordPlaceholder}
                                required
                                type="password"
                                value={password}
                                handleTextChange={(value) => setPassword(value)}
                                validation={RegexValidators.Password}
                                errorMessage={translations.registration.errors.passwordInvalid}
                            />
                        </div>
                        <div className={styles.registration_activate_input}>
                            <TextInput
                                title={translations.registration.fields.confirmPassword}
                                placeholder={translations.registration.fields.confirmPasswordPlaceholder}
                                required
                                type="password"
                                value={confirmPassword}
                                handleTextChange={(value) => setConfirmPassword(value)}
                                validation={() => password === confirmPassword}
                                errorMessage={translations.registration.errors.confirmPasswordInvalid}
                            />
                        </div>
                    </div>
                    <div className={styles.registration_activate_submit_action_row}>
                        <LinkButton
                            title={translations.registration.actions.completeRegistration}
                            type={LinkButtonType.Blue}
                            onClick={() => {
                                setIsSaving(true);
                                callVerifyRegistrationApi();
                            }}
                            disabled={passwordRequirements.some((x) => !x.condition) || isSaving}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RegistrationActivateComponent;
