import { useMemo } from 'react';
import { DemeterDataFrequency, MarketIndicatorTemplateType } from '../../../../../../../Generated/Raven-Demeter';
import Dropdown from '../../../../../../Components/Form/Inputs/Dropdown';
import useLanguage from '../../../../../../Services/Language/useLanguageHook';
import styles from '../../MarketIndicatorFactorPage.module.scss';

interface IDataSelectorFrequencyDropdownProps {
    templateType: MarketIndicatorTemplateType;
    dataFrequency: DemeterDataFrequency;
    handleChange: (region?: DemeterDataFrequency) => void;
    excludedFrequencyOptions?: DemeterDataFrequency[];
    disabled?: boolean;
}

const DataSelectorFrequencyDropdown: React.FC<IDataSelectorFrequencyDropdownProps> = (props: IDataSelectorFrequencyDropdownProps) => {
    const [translations] = useLanguage();

    const periodocityOptions = useMemo(() => {
        const options: { label: string; value: DemeterDataFrequency }[] = [
            {
                label: translations.dataFrequency[DemeterDataFrequency.Daily],
                value: DemeterDataFrequency.Daily,
            },
            {
                label: translations.dataFrequency[DemeterDataFrequency.Weekly],
                value: DemeterDataFrequency.Weekly,
            },
            {
                label: translations.dataFrequency[DemeterDataFrequency.Monthly],
                value: DemeterDataFrequency.Monthly,
            },
        ];

        if (props.excludedFrequencyOptions) {
            options.filter((x) => !props.excludedFrequencyOptions?.includes(x.value));
        }

        return options;
    }, [translations, props.templateType]);

    return (
        <div className={styles.indicator_add_and_edit_dropdown}>
            <Dropdown
                value={props.dataFrequency}
                options={periodocityOptions}
                handleOptionChange={(value) => {
                    props.handleChange(value);
                }}
                label={translations.marketIndicatorsManagement.fields.periodicity}
                disabled={props.disabled}
            />
        </div>
    );
};

export default DataSelectorFrequencyDropdown;
