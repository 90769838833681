import { Currency, DemeterSymbolContractModel, UnitOfMeasure } from '../../../../Generated/Raven-Demeter/api';

export interface ICommodityPriceChartProps {
    symbolContracts: (DemeterSymbolContractModel & { displayName?: string })[];
    symbol: DemeterSymbolContractModel | any;
    setIsRollingData?: any;
    isRollingData?: boolean;
    disablePopoutButton?: boolean;
    selectedCurrency?: Currency;
    selectedUnitOfMeasure?: UnitOfMeasure;
    sourceData?: string;
    otcContracts?: any;
    numberOfTooltipSignificantDigits: number;
    hasActualRollingButons: boolean;
    hasOtcContracts?: boolean;
    ignoreAspectRatio?: boolean;
    displayName?: string;
    openIndicatorModal?: boolean;
}

export enum IndicatorStudy {
    annualPercentageChange = 'annualPercentageChange',
    absolutePrice = 'absolutePrice',
    accelerationBands = 'accelerationBands',
    averageTrueRange = 'averageTrueRange',
    aroon = 'aroon',
    bollingerBands = 'bollingerBands',
    commodityChannelIndex = 'commodityChannelIndex',
    doubleExponentialMovingAverage = 'doubleExponentialMovingAverage',
    exponentialMovingAverage = 'exponentialMovingAverage',
    detrendedPrice = 'detrendedPrice',
    keltnerChannels = 'keltnerChannels',
    linearRegression = 'linearRegression',
    linearRegressionAngle = 'linearRegressionAngle',
    linearRegressionIntercept = 'linearRegressionIntercept',
    linearRegressionSlope = 'linearRegressionSlope',
    momentum = 'momentum',
    movingAverage = 'movingAverage',
    movingAverageConvergenceDivergence = 'movingAverageConvergenceDivergence',
    normalizedAverageTrueRange = 'normalizedAverageTrueRange',
    pivotpoints = 'pivotpoints',
    percentagePrice = 'percentagePrice',
    priceChannel = 'priceChannel',
    priceEnvelopes = 'priceEnvelopes',
    rateOfChange = 'rateOfChange',
    relativeStrengthIndex = 'relativeStrengthIndex',
    slowStocastic = 'slowStocastic',
    stocastic = 'stocastic',
    superTrend = 'superTrend',
    tema = 'tema',
    trix = 'trix',
    weightedMovingAverage = 'weightedMovingAverage',
    zigzag = 'zigzag',

    // volume indicators
    volume = 'volume',
    onBalanceVolume = 'onBalanceVolume',
    moneyFlowIndex = 'moneyFlowIndex',
    klinger = 'klinger',
    chaikinMoneyFlow = 'chaikinMoneyFlow',
    chaikin = 'chaikin',
    accumulationDistribution = 'accumulationDistribution',
    volumeWeightedAveragePrice = 'volumeWeightedAveragePrice',
    volumeByPrice = 'volumeByPrice',
    ichimokuKinkoHyo = 'ichimokuKinkoHyo',

    aroonOscillator = 'aroonOscillator',
    awesomeOscillator = 'awesomeOscillator',
    chandeMomentumOscillator = 'chandeMomentumOscillator',
    directionalMovementIndex = 'directionalMovementIndex',
    disparityindex = 'disparityindex',
    williamsr = 'williamsr',
}
