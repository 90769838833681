import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import store from '../../Redux/ReduxStore';
import NotificationsWrapper from '../../Refactor/Components/Notifications/NotificationsWrapper';
import OktaSecureRouteContainer from '../../Refactor/Core/Okta/OktaSecureRouteContainer';
import OktaSecurity from '../../Refactor/Core/Okta/OktaSecurity';
import LanguageWrapper from '../../Refactor/Services/Language/LanguageWrapper';
import RouterRoutes from '../RouterRoutes';

const StandaloneEntrypoint = () => (
    <Provider store={store}>
        <LanguageWrapper>
            <NotificationsWrapper>
                <OktaSecurity>
                    <BrowserRouter basename="">
                        <RouterRoutes secureRouteContainer={<OktaSecureRouteContainer />} isMono={false} />
                    </BrowserRouter>
                </OktaSecurity>
            </NotificationsWrapper>
        </LanguageWrapper>
    </Provider>
);

export default StandaloneEntrypoint;
