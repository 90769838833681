import { useEffect, useMemo, useState } from 'react';
import { demeterApi } from '../../../../Apis/Apis';
import formattingService from '../../../../Core/Formatting/FormattingService';
import { DemeterCommodity, DemeterRegion, GlobalDairyTradeWeeklyPriceSummaryModel } from '../../../../Generated/Raven-Demeter/api';
import useApi from '../../../Apis/Hooks/useApiHook';
import ComponentHeader from '../../../Components/Headers/ComponentHeader';
import useLanguage from '../../../Services/Language/useLanguageHook';
import styleService from '../../../Services/Styles/StyleService';
import PricesSummaryTableWrapper from '../PricesSummaryTableWrapper';
import styles from '../PriceSummaries.module.scss';

export type GlobalDairyTradeSummaryRow = {
    commodity: DemeterCommodity | string;
    totalContract?: number | null;
    percentChange?: number | null;
};

const GlobalDairyTradeSummarySmall: React.FC = () => {
    const [, , globalDairyTradeData] = useApi(() => demeterApi.listGlobalDairyTradeWeeklyPricesSummary(), {});
    const [data, setData] = useState<GlobalDairyTradeSummaryRow[]>([]);
    const [translations] = useLanguage();

    const columnHeaders = useMemo(
        () => [translations.words.product, `${translations.text.averageShort} ${translations.words.price}`, translations.fields.percentChange],
        [translations],
    );
    const title = useMemo(
        () =>
            `${translations.dashboard.headings.globalDairyTradeResultTableHeading} ${globalDairyTradeData?.event} ${
                translations.words.summary
            } ${formattingService.toShortDayMonthYear(globalDairyTradeData?.eventDate)}` ?? '',
        [globalDairyTradeData, translations],
    );

    useEffect(() => {
        if (globalDairyTradeData && globalDairyTradeData.rows) {
            const transposedData = globalDairyTradeData.rows.map((row: GlobalDairyTradeWeeklyPriceSummaryModel) => {
                const globalDairyTradeWeeklyPrice = row.globalDairyTradeWeeklyPrices.find((x) => x.dataSource === DemeterRegion.GlobalDairyTrade);
                let result: GlobalDairyTradeSummaryRow = {
                    commodity: translations.commodity[row.commodity],
                    totalContract: globalDairyTradeWeeklyPrice?.value,
                    percentChange: globalDairyTradeWeeklyPrice?.percentChange,
                };
                if (row.commodity === DemeterCommodity.Index) {
                    result = {
                        commodity: translations.dashboard.fields.changeInOverallPriceIndex,
                        percentChange: globalDairyTradeWeeklyPrice?.percentChange,
                    };
                }
                return result;
            });
            setData(transposedData);
        }
    }, [translations, globalDairyTradeData]);

    return (
        <>
            <ComponentHeader title={title} subtitle={translations.dashboard.headings.globalDairyTradeResultTableSubHeading} />
            <PricesSummaryTableWrapper
                className={styles.price_summary_table_global_dairy_trade_small}
                loading={!globalDairyTradeData || globalDairyTradeData.rows?.length === 0}
                columnHeaders={columnHeaders}
            >
                <>
                    {data.map((row: GlobalDairyTradeSummaryRow) => {
                        const changeClassname = styleService.getPositiveOrNegativeClass(row.percentChange);

                        if (row.commodity === null) {
                            return null;
                        }
                        return (
                            <>
                                <p>{row.commodity}</p>
                                <p>{formattingService.toPriceString(row.totalContract)}</p>
                                <p className={changeClassname}>{formattingService.toPercent(row.percentChange)}</p>
                            </>
                        );
                    })}
                </>
            </PricesSummaryTableWrapper>
        </>
    );
};

export default GlobalDairyTradeSummarySmall;
