import { useEffect } from 'react';
import TextInput from '../../../../../../Components/Form/Inputs/TextInput';
import RegexValidators from '../../../../../../Core/Validation/RegexValidators';
import useLanguage from '../../../../../../Services/Language/useLanguageHook';
import { UpsertMarketIndicatorFactorRequest } from '../../../MarketIndicatorsManagementDefinitions';

interface IMarketFactorMultiplierTextInputProps {
    upsertMarketIndicatorFactorRequest: UpsertMarketIndicatorFactorRequest;
    setUpsertMarketIndicatorFactorRequest: (value: UpsertMarketIndicatorFactorRequest) => void;
}

const defautltMuliplier = 1;

const MarketFactorMultiplierTextInput: React.FC<IMarketFactorMultiplierTextInputProps> = (props: IMarketFactorMultiplierTextInputProps) => {
    const [translations] = useLanguage();

    // Whenver the dropdown appears set default.
    useEffect(() => {
        if (!props.upsertMarketIndicatorFactorRequest.multiplier) {
            props.setUpsertMarketIndicatorFactorRequest({
                ...props.upsertMarketIndicatorFactorRequest,
                multiplier: defautltMuliplier,
            });
        }
    }, []);

    return (
        <TextInput
            title={translations.words.multiplier}
            required
            value={`${props.upsertMarketIndicatorFactorRequest.multiplier ?? defautltMuliplier}`}
            handleTextChange={(value: string) =>
                props.setUpsertMarketIndicatorFactorRequest({
                    ...props.upsertMarketIndicatorFactorRequest,
                    multiplier: +value,
                })
            }
            validation={RegexValidators.Decimal}
            errorMessage={translations.marketIndicatorsManagement.messages.positiveNumber}
        />
    );
};

export default MarketFactorMultiplierTextInput;
