/* eslint-disable class-methods-use-this */
import { IChartData } from '../../ChartDefinitions';
import marketIndicatorChartDateService from '../MarketIndicatorChartDateService';

class MarketIndicatorAverageSeasonalChartService {
    calculateSeasonalAverageData = (data: IChartData[], numberOfYears: number): IChartData[] => {
        const weeklyData = marketIndicatorChartDateService.toWeeklyData(data);
        const weeklyDataWithAverages = weeklyData.map((weekData) => {
            const previousYearsWeeks = marketIndicatorChartDateService.getPreviousYearsWeeks(weekData.asOfDate, numberOfYears);
            const previousYearsData = previousYearsWeeks
                .map((weekDate) => weeklyData.find((d) => d.asOfDate.getTime() === weekDate.getTime()))
                .filter((d) => d !== undefined);
            const averageValue =
                previousYearsData.length > 0 ? previousYearsData.reduce((sum, d) => (d ? sum + d.value : sum), 0) / previousYearsData.length : 0;
            return {
                value: averageValue,
                asOfDate: weekData.asOfDate,
                isActualValue: true,
            } as IChartData;
        });
        return weeklyDataWithAverages;
    };
}

const marketIndicatorAverageSeasonalChartService = new MarketIndicatorAverageSeasonalChartService();
export default marketIndicatorAverageSeasonalChartService;
