/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
import { Children, useEffect, useState } from 'react';
import styles from './WindowWatcher.module.scss';

export interface IWindowWatcherProps {
    children: any | null;
    setWatcherClosed: Function;
    watchableClass?: string;
    testId?: string;
}

const WindowWatcher: React.FC<IWindowWatcherProps> = (props: IWindowWatcherProps) => {
    const [isOpen, setIsOpen] = useState(() => false);

    const changeStateOnClick = () => {
        if (props.watchableClass && props.watchableClass === 'multiselect_children' && isOpen) {
            props.setWatcherClosed(true);
            setIsOpen(true);
        } else if (isOpen) {
            props.setWatcherClosed(false);
            setIsOpen(false);
        } else {
            props.setWatcherClosed(true);
            setIsOpen(true);
        }
    };

    const close = (e: any) => {
        const { className } = e.target;
        if (
            !e.target ||
            !className ||
            (className && className.baseVal && className.baseVal.includes('Svg')) ||
            (className.includes && !className.includes(props.watchableClass)) ||
            (className.includes && className.includes('svg')) ||
            !className.includes
        ) {
            props.setWatcherClosed(false);
            setIsOpen(false);
        }
    };

    useEffect(() => {
        if (isOpen) {
            // setTimeout is needed to make give one tick on the first time Mono page load. Otherwise 'close' gets called
            setTimeout(() => {
                document.addEventListener('click', close);
            });
        }
        return () => document.removeEventListener('click', close);
    }, [isOpen]);

    return (
        <div className={styles.window_watcher} onClick={changeStateOnClick} data-testid={props.testId}>
            {Children.only(props.children)}
        </div>
    );
};

export default WindowWatcher;
