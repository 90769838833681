/* eslint-disable react/prop-types */
import { GridApi, GridReadyEvent, MenuItemDef } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { RefObject, useCallback, useEffect, useState } from 'react';
import { demeterUsersApi } from '../../Apis/Apis';
// @ts-ignore
import promiseHandler from '../../Core/Handlers/PromiseHandler';
import { DemeterUserType } from '../../Generated/Raven-Demeter';
import { useApplicationSelector } from '../../Redux/ReduxStore';
import { selectUserType } from '../../Redux/Slices/UserSlice';
import styles from './AgGridBuilder.module.scss';
import './AgGridThemeHelper.scss';
import { AgGridContextMenuItem, IAgGridProps } from './InterfaceAgGrid';
import useContainerWidth from './useContainerWidth';
import useWindowSize from './useWindowSize';

const AgGridBuilder: React.FC<IAgGridProps> = ({
    gridRef,
    rowData,
    cellClickedHandler,
    hasSaveColumnsState,
    columnDefinitions,
    setColumnDefinitions,
    defaultColumnDefinition,
    onSelectionChanged,
    onRowClicked,
    userStoreType,
    onGridReady,
    theme = 'ag-theme-demeter-alpine',
    debounce = 0,
    getRowStyle = (params: any) => (params.node.rowIndex % 2 === 0 ? { background: '#F3F4F4' } : { background: 'white' }),
    domLayout,
    disableExport = false,
    suppressMovableColumns = false,
    groupIncludeTotalFooter = undefined,
    pinnedBottomRowData = undefined,
    testId,
}) => {
    const [gridApi, setGridApi] = useState<GridApi | undefined>();
    const [windowWidth] = useWindowSize(debounce);
    const { width: containerWidth, ref } = useContainerWidth(debounce);
    const [isSavingState, setIsSavingState] = useState(false);
    const currentUserType = useApplicationSelector(selectUserType);

    useEffect(() => {
        if (gridApi) {
            gridApi.sizeColumnsToFit();
        }
    }, [windowWidth, containerWidth, gridApi]);

    const getRowId = useCallback((params: any) => params.data.id, []);

    // This useCallback fixes a memory leak
    useCallback(() => {
        let maxHeight = 55;
        const listOfTextNodes: any = document.getElementsByClassName('ag-header-cell-text');

        for (let i = 0; i < listOfTextNodes.length; i += 1) {
            if (listOfTextNodes[i].offsetHeight > 55 && listOfTextNodes[i].offsetHeight > maxHeight) {
                maxHeight = listOfTextNodes[i].offsetHeight + 10;
            }
        }
        if (gridRef?.current && listOfTextNodes.length > 0) {
            gridRef.current.api.setHeaderHeight(maxHeight);
        }
    }, [columnDefinitions]);

    const gridReady = async (event: GridReadyEvent) => {
        let myUserStore;
        if (userStoreType) {
            myUserStore = await promiseHandler(demeterUsersApi.getUserStore(userStoreType));
        }
        if (setColumnDefinitions) {
            if (myUserStore?.data.userStore?.value.commodityTablePreferences?.length > 0) {
                setColumnDefinitions(myUserStore?.data.userStore?.value.commodityTablePreferences);
            }
        }

        if (onGridReady) {
            onGridReady(event);
        }
        setGridApi(event.api);
    };

    const saveColumnsState = async (params: GridReadyEvent) => {
        if (hasSaveColumnsState && !isSavingState) {
            const savedState = params.columnApi.getColumnState();
            const newState = savedState.map((columnDefinition: any) => {
                let newField = {
                    ...columnDefinition,
                    field: columnDefinition.colId,
                    key: columnDefinition.colId,
                };

                if (columnDefinition.colId === 'Name') {
                    newField = {
                        ...newField,
                        resizable: true,
                        suppressSizeToFit: true,
                        width: 360,
                        maxWidth: 360,
                        minWidth: 320,
                    };
                }

                if (columnDefinition.colId === 'isSelected') {
                    newField = {
                        ...newField,
                        lockPinned: true,
                        pinned: 'right',
                        rowDrag: false,
                        rowDragManaged: false,
                        suppressMovable: true,
                    };
                }

                return newField;
            });
            if (userStoreType) {
                setIsSavingState(true);
                const response = await promiseHandler(
                    demeterUsersApi.updateUserStore(userStoreType, {
                        userStoreType,
                        value: { commodityTablePreferences: newState },
                    }),
                );

                if (response && response.status && response.status === 200) {
                    setIsSavingState(false);
                }
            }
        }
    };

    const getContextMenuItems = useCallback(() => {
        const result: Array<string | MenuItemDef> = [AgGridContextMenuItem.paste, AgGridContextMenuItem.separator];
        if (currentUserType === DemeterUserType.Regular || disableExport) {
            result.push({ name: AgGridContextMenuItem.customExport, disabled: true });
        } else {
            result.push(AgGridContextMenuItem.export);
        }
        return result;
    }, []);

    return (
        <div data-testid={testId} className={`${theme} ${styles.ag_grid_component}`} ref={ref}>
            <AgGridReact
                animateRows
                getRowId={getRowId}
                ref={gridRef as RefObject<AgGridReact>}
                rowSelection="multiple"
                groupSelectsChildren
                rowData={rowData}
                columnDefs={columnDefinitions}
                defaultColDef={defaultColumnDefinition}
                getRowStyle={getRowStyle}
                onGridReady={gridReady}
                onColumnMoved={saveColumnsState}
                onColumnResized={saveColumnsState}
                onDisplayedColumnsChanged={saveColumnsState}
                onColumnRowGroupChanged={saveColumnsState}
                onSelectionChanged={onSelectionChanged}
                onCellClicked={cellClickedHandler}
                suppressRowClickSelection
                onRowClicked={onRowClicked ?? (() => 0)}
                getContextMenuItems={getContextMenuItems}
                suppressContextMenu
                domLayout={domLayout || undefined}
                suppressMovableColumns={suppressMovableColumns}
                groupIncludeTotalFooter={groupIncludeTotalFooter}
                pinnedBottomRowData={pinnedBottomRowData}
            />
        </div>
    );
};

export default AgGridBuilder;
