import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import React, { HTMLInputTypeAttribute, useEffect, useState } from 'react';
import InputContainer from './InputContainer';
import styles from './Inputs.module.scss';

interface ITextInputProps {
    className?: string;
    type?: HTMLInputTypeAttribute | undefined;
    title?: string | JSX.Element;
    placeholder?: string;
    value?: string | null;
    required?: boolean;
    disabled?: boolean;
    handleTextChange?: (value: string) => void;
    handleBlur?: (value: string) => void;
    validation?: RegExp | ((value: string) => boolean);
    errorMessage?: string;
    handleKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
    columnNumber?: number;
    testId?: string;
}

const TextInput: React.FC<ITextInputProps> = (props: ITextInputProps) => {
    const [textValue, setTextValue] = useState<string>(props.value ?? '');
    const [isValid, setIsValid] = useState<boolean>(true);
    const [inputVisibility, setInputVisability] = useState<boolean>(false);

    const validateText = (value: string): boolean => {
        if (!props.validation || props.disabled) {
            return true;
        }

        if (!value) {
            return !props.required;
        }

        if (props.validation instanceof RegExp) {
            return props.validation.test(value.toLowerCase());
        }

        return props.validation(value);
    };

    const handleChange = (value: string): void => {
        setTextValue(value);
        if (props.handleTextChange) {
            props.handleTextChange(value ?? '');
        }

        if (validateText(value)) {
            setIsValid(true);
        }
    };

    const handleBlur = (): void => {
        setIsValid(validateText(textValue));

        if (props.handleBlur) {
            props.handleBlur(textValue);
        }
    };

    // Calendar events and field resets do not trigger an onChange event on the input. We need a way to capture and validate
    // when these events occur.
    useEffect(() => {
        if (!props.value) {
            return;
        }

        handleChange(props.value);
    }, [props.value]);

    // When a field becomes disabled, revalidate.
    useEffect(() => {
        if (!props.disabled) {
            return;
        }

        setIsValid(validateText(textValue));
    }, [props.disabled]);

    // Need to have a negative tab index to ignore these on the DOM when hitting tab.
    const displayInputVisabilityIcon = inputVisibility ? (
        <button type="button" tabIndex={-1} className={styles.input_password_visibility_icon} onClick={() => setInputVisability(false)}>
            <VisibilityOffIcon />
        </button>
    ) : (
        <button type="button" tabIndex={-1} className={styles.input_password_visibility_icon} onClick={() => setInputVisability(true)}>
            <VisibilityIcon />
        </button>
    );

    return (
        <>
            <InputContainer title={props.title}>
                <input
                    data-testid={props.testId}
                    type={inputVisibility || !props.type ? 'text' : props.type}
                    disabled={props.disabled}
                    className={`${isValid ? styles.input_input_component : styles.input_input_component_error} ${props.className}`}
                    placeholder={props.placeholder}
                    value={props.value ?? ''}
                    onChange={(e) => {
                        handleChange(e.target.value);
                    }}
                    onBlur={() => {
                        handleBlur();
                    }}
                    onWheel={(event) => {
                        if (props.type !== 'number') {
                            return;
                        }
                        event.preventDefault();
                        (event.target as HTMLInputElement).blur();
                    }}
                    onKeyDown={(event) => {
                        // Stop ALL number text inputs from going up and down on key press.
                        if (['ArrowUp', 'ArrowDown'].includes(event.key) && props.type === 'number') {
                            event.preventDefault();
                        }

                        if (props.handleKeyDown) {
                            props.handleKeyDown(event as React.KeyboardEvent<HTMLInputElement>);
                        }
                    }}
                    // React warnings specify that this attribute should not be camel cased.
                    data-column-number={props.columnNumber}
                />
                {props.type === 'password' && displayInputVisabilityIcon}
            </InputContainer>
            {!isValid && props.errorMessage && <p className={styles.input_error_message}>{props.errorMessage}</p>}
        </>
    );
};

export default TextInput;
