import { useEffect, useState } from 'react';
import { demeterMarketIndicatorsApi } from '../../../../../Apis/Apis';
import { AddMarketIndicatorRequest, DemeterCommodity, DemeterRegion, DemeterSymbolModel } from '../../../../../Generated/Raven-Demeter';
import useApiWithoutAutoExecute from '../../../../Apis/Hooks/useApiWithoutAutoExecute';
import LinkButton, { LinkButtonType } from '../../../../Components/Form/Buttons/LinkButton';
import CheckboxInput from '../../../../Components/Form/Inputs/CheckboxInput';
import SelectInput from '../../../../Components/Form/Inputs/SelectInput';
import TextInput from '../../../../Components/Form/Inputs/TextInput';
import ActionModal from '../../../../Components/Modals/ActionModal/ActionModal';
import RegexValidators from '../../../../Core/Validation/RegexValidators';
import useLanguage from '../../../../Services/Language/useLanguageHook';
import { marketIndicatorsManagementContractOptions } from '../MarketIndicatorsManagementDefinitions';
import styles from './MarketIndicatorActions.module.scss';
import MarketIndicatorBasisLocation from './MarketIndicatorBasisLocation';

// Defaults.
const defaultRegion = DemeterRegion.UnitedStates;
const defaultCommodity = DemeterCommodity.NaturalGas;
const defaultNumberOfYears = 4;
const defaultContractNumber = 1;

interface IMarketIndicatorActionsProps {
    symbols: DemeterSymbolModel[];
    symbolDropdownOptions: {
        label: string;
        value: DemeterSymbolModel;
    }[];
    handleAddMarketIndicator: () => void;
}

const MarketIndicatorAddButton: React.FC<IMarketIndicatorActionsProps> = (props: IMarketIndicatorActionsProps) => {
    // Application hooks
    const [translations] = useLanguage();

    // Component states.
    const [showModal, setShowModal] = useState(false);
    const [symbol, setSymbol] = useState<DemeterSymbolModel>();

    // Form request.
    const [addMarketIndicatorRequest, setAddMarketIndicatorRequest] = useState<AddMarketIndicatorRequest & { marketIndicatorGuid: string }>({
        marketIndicatorGuid: '',
        region: defaultRegion,
        commodity: defaultCommodity,
        contractNumber: defaultContractNumber,
        displayName: '',
        numberOfYears: defaultNumberOfYears,
    });

    // Api hooks.
    const [, handleAddMarketIndicator, handleAddMarketIndicatorResponse] = useApiWithoutAutoExecute(
        () => demeterMarketIndicatorsApi.addMarketIndicator(addMarketIndicatorRequest as AddMarketIndicatorRequest),
        {
            successMessage: translations.marketIndicatorsManagement.messages.indicatorAddSuccessful,
            errorMessage: translations.marketIndicatorsManagement.messages.indicatorAddFailed,
            conflictErrorMessage: translations.marketIndicatorsManagement.messages.indicatorAddConflict,
        },
    );

    useEffect(() => {
        props.handleAddMarketIndicator();
    }, [handleAddMarketIndicatorResponse]);

    useEffect(() => {
        if (symbol || !props.symbols) {
            return;
        }

        setSymbol(props.symbols[0]);
    }, [props.symbols]);

    const handleSymbolChange = (newSymbolModel?: DemeterSymbolModel) => {
        if (!newSymbolModel) {
            return;
        }

        setSymbol(newSymbolModel);
        setAddMarketIndicatorRequest({
            ...addMarketIndicatorRequest,
            region: newSymbolModel.region,
            commodity: newSymbolModel.commodity,
        });
    };

    return (
        <>
            <LinkButton
                title={translations.marketIndicatorsManagement.actions.addIndicators}
                type={LinkButtonType.Blue}
                onClick={() => {
                    setSymbol(props.symbolDropdownOptions[0].value);
                    setAddMarketIndicatorRequest({ ...addMarketIndicatorRequest, displayName: '' });
                    setShowModal(true);
                }}
            />
            <ActionModal
                header={translations.marketIndicatorsManagement.headers.addIndicators}
                showModal={showModal}
                actionButtonName={translations.actions.save}
                handleAction={() => {
                    handleAddMarketIndicator();
                    setShowModal(false);
                }}
                handleCancel={() => setShowModal(false)}
                actionButtonDisabled={
                    !RegexValidators.AlphaNumericMinimumLength2.test(addMarketIndicatorRequest.displayName) ||
                    !RegexValidators.PositiveNumberZeroThroughTen.test(`${addMarketIndicatorRequest.numberOfYears}`)
                }
            >
                <>
                    <div className={styles.market_indicator_actions_modal_input_wrapper}>
                        <TextInput
                            title={translations.marketIndicatorsManagement.headers.indicatorName}
                            placeholder={translations.marketIndicatorsManagement.headers.indicatorName}
                            required
                            value={addMarketIndicatorRequest.displayName}
                            handleTextChange={(value) => setAddMarketIndicatorRequest({ ...addMarketIndicatorRequest, displayName: value })}
                            validation={RegexValidators.AlphaNumericMinimumLength2}
                            errorMessage={translations.marketIndicatorsManagement.messages.displayNameInvalid}
                        />
                    </div>
                    <div className={styles.market_indicator_actions_modal_input_wrapper}>
                        <CheckboxInput
                            title={translations.marketIndicatorsManagement.fields.basisLocation}
                            isChecked={!!addMarketIndicatorRequest.usePrices}
                            handleIsChecked={(isChecked: boolean) =>
                                setAddMarketIndicatorRequest({
                                    ...addMarketIndicatorRequest,
                                    region: defaultRegion,
                                    commodity: defaultCommodity,
                                    usePrices: isChecked,
                                })
                            }
                        />
                    </div>
                    {!addMarketIndicatorRequest.usePrices && (
                        <>
                            <div className={styles.market_indicator_actions_modal_input_wrapper}>
                                <SelectInput
                                    title={translations.marketIndicatorsManagement.headers.exchangeAndCommodity}
                                    options={props.symbolDropdownOptions}
                                    value={symbol}
                                    handleOptionChange={handleSymbolChange}
                                />
                            </div>
                            <div className={styles.market_indicator_actions_modal_input_wrapper}>
                                <SelectInput
                                    title={translations.marketIndicatorsManagement.fields.contractNumber}
                                    value={addMarketIndicatorRequest.contractNumber}
                                    options={marketIndicatorsManagementContractOptions}
                                    handleOptionChange={(value) => setAddMarketIndicatorRequest({ ...addMarketIndicatorRequest, contractNumber: +value! })}
                                />
                            </div>
                        </>
                    )}
                    {addMarketIndicatorRequest.usePrices && (
                        <MarketIndicatorBasisLocation
                            region={addMarketIndicatorRequest.region}
                            subRegion={addMarketIndicatorRequest.subRegion}
                            commodity={addMarketIndicatorRequest.commodity}
                            dataSource={addMarketIndicatorRequest.dataSource}
                            handleChange={(region, subRegion, commodity, dataSource) =>
                                setAddMarketIndicatorRequest({ ...addMarketIndicatorRequest, region, subRegion, commodity, dataSource })
                            }
                        />
                    )}
                    <div className={styles.market_indicator_actions_modal_input_wrapper}>
                        <TextInput
                            title={translations.marketIndicatorsManagement.headers.numberOfYears}
                            placeholder={translations.marketIndicatorsManagement.headers.numberOfYears}
                            required
                            value={`${addMarketIndicatorRequest.numberOfYears}`}
                            handleTextChange={(value) => setAddMarketIndicatorRequest({ ...addMarketIndicatorRequest, numberOfYears: +value })}
                            validation={RegexValidators.PositiveNumberZeroThroughTen}
                            type="number"
                            errorMessage={translations.marketIndicatorsManagement.messages.numberOfYearsInvalid}
                        />
                    </div>
                </>
            </ActionModal>
        </>
    );
};

export default MarketIndicatorAddButton;
