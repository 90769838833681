import Close from '@mui/icons-material/Close';
import { SnackbarKey, useSnackbar } from 'notistack';
import * as React from 'react';
import IconButton from '../Form/Buttons/IconButton';
import styles from './NotificationsWrapper.module.scss';

interface ISnackbarCloseButtonProps {
    snackbarKey: SnackbarKey;
}

const SnackbarCloseButton: React.FC<ISnackbarCloseButtonProps> = (props) => {
    const { closeSnackbar } = useSnackbar();

    return (
        <IconButton handleClick={() => closeSnackbar(props.snackbarKey)} size="small">
            <Close className={styles.notifications_icon_close} />
        </IconButton>
    );
};
export default SnackbarCloseButton;
