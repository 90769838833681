import scssVariables from '../../../../Config.module.scss';
import { DemeterSymbolModel } from '../../../../Generated/Raven-Demeter';
import { IChartAreaRangeDataSeries, IChartDataSeries } from '../../../Components/Charts/ChartDefinitions';

export type ContractGroupName = 'annual' | 'summerMonths' | 'winterMonths' | 'quarter1' | 'quarter2' | 'quarter3' | 'quarter4';

export type ValueMatrixStripType = 'forwardContract' | 'twelveMonthStrip' | 'firstContractMonth' | 'secondContractMonth' | 'spot';

export type FieldName =
    | 'ninetyToOneHundredDecile'
    | 'eightyToNinetyDecile'
    | 'seventyToEightyDecile'
    | 'sixtyToSeventyDecile'
    | 'fiftyToSixtyDecile'
    | 'fourtyToFiftyDecile'
    | 'thirtyToFourtyDecile'
    | 'twentyToThirtyDecile'
    | 'tenToTwentyDecile'
    | 'zeroToTenDecile';

type DecileTextColors = typeof scssVariables.plainWhite;

type DecileTableBackgroundColors =
    | typeof scssVariables.deactivatedBlockColor
    | typeof scssVariables.ninetyToOneHundredDecile
    | typeof scssVariables.eightyToNinetyDecile
    | typeof scssVariables.seventytoEightyDecile
    | typeof scssVariables.sixtyToSeventyDecile
    | typeof scssVariables.fiftyToSixtyDecile
    | typeof scssVariables.fourtyToFiftyDecile
    | typeof scssVariables.thirtyToFourtyDecile
    | typeof scssVariables.twentyToThirtyDecile
    | typeof scssVariables.tenToTwentyDecile
    | typeof scssVariables.zeroToTenDecile;

export type TermClassification = {
    contractMonths: number[];
    contractGroupName: ContractGroupName;
};

export type TermClassificationOptions = {
    label: string;
    value: TermClassification;
};

export type DecileRowDefinitions = {
    text: DecileTextColors;
    backgroundColor: DecileTableBackgroundColors;
    fieldName?: FieldName;
    textColor?: string;
};

export type ValueMatrixUpdateTrigger = 'apiReset' | 'productChange' | 'stripTypeSelection' | 'generalFieldSelection' | 'textInputDefault' | 'textInputUpdated';

export interface ValueMatrixTableRequest {
    asOfDate: Date | undefined;
    product: DemeterSymbolModel;
    market: string;
    stripType: ValueMatrixStripType;
    termClassification: TermClassification[];
    timeSpan: number | null;
    applyProducerPriceIndex: boolean;
    weighted?: {
        innerRange?: number;
        innerWeight?: number;
        outerRange?: number;
        outerWeight?: number;
    };
    updateTriggeredBy: ValueMatrixUpdateTrigger;
}

type DecileRange = {
    minimumValue: number;
    maximumValue: number;
};

export interface ValueMatrixTableResults {
    title: string;
    meanPrice: string;
    medianPrice: string;
    zeroToTenDecile: DecileRange;
    tenToTwentyDecile: DecileRange;
    twentyToThirtyDecile: DecileRange;
    thirtyToFourtyDecile: DecileRange;
    fourtyToFiftyDecile: DecileRange;
    fiftyToSixtyDecile: DecileRange;
    sixtyToSeventyDecile: DecileRange;
    seventyToEightyDecile: DecileRange;
    eightyToNinetyDecile: DecileRange;
    ninetyToOneHundredDecile: DecileRange;
}

export type ValueMatrixChartRequest = {
    chartStartDate: Date | undefined;
    chartEndDate: Date | undefined;
    chartDeciles: number[];
    chartTerms: number[];
};

export type ValueMatrixChartResults = {
    product: DemeterSymbolModel;
    areasSeries: IChartAreaRangeDataSeries[];
    linesSeries: IChartDataSeries[];
    isVisible: boolean;
};

export type ProductOption = {
    label: string;
    value: DemeterSymbolModel;
};

export type ChartDisplayVisability = {
    chartControlsActive: boolean;
    chartIsVisible: boolean;
};

export const defaultNumberOfYearsToLookBackGeneral = 4;

export const timeSpanOptions = [
    {
        label: '1',
        value: 1,
    },
    {
        label: '2',
        value: 2,
    },
    {
        label: '3',
        value: 3,
    },
    {
        label: '4',
        value: 4,
    },
    {
        label: '5',
        value: 5,
    },
];

export type DecileChartOption = {
    label: string;
    value: number;
};
