import React from 'react';
import useSearchParameters from '../Hooks/useSearchParametersHook';
import styles from './Tabs.module.scss';

interface ITabOptions {
    title: string;
    key: string;
    component?: JSX.Element;
    showTab?: boolean;
}

interface ITabsProps {
    tabOptions: ITabOptions[];
}

const Tabs: React.FC<ITabsProps> = (props) => {
    const [searchParamters, setSearchParameters] = useSearchParameters();

    if (searchParamters.tab == null) {
        // Set default tab when no tab selected in searchParamenters.
        setSearchParameters({ tab: props.tabOptions.find((tab) => tab.showTab !== false)?.key ?? '' });
    }

    return (
        <>
            <div className={styles.tab_main_container}>
                {props.tabOptions.length > 0 &&
                    props.tabOptions.map(
                        (tab: ITabOptions) =>
                            tab.showTab !== false && (
                                <button
                                    type="button"
                                    className={tab.key === searchParamters.tab ? styles.tab_button_selected : styles.tab_button}
                                    key={`tab_${tab.key}`}
                                    onClick={() => {
                                        setSearchParameters({ tab: tab.key });
                                    }}
                                >
                                    {tab.title}
                                </button>
                            ),
                    )}
                <div className={styles.tab_empty_underline_space} />
            </div>
            <div>{props.tabOptions.find((tab) => tab.showTab !== false && tab.key === searchParamters.tab)?.component}</div>
        </>
    );
};

export default Tabs;
