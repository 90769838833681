import React, { useEffect, useState } from 'react';
import { demeterApi } from '../../Apis/Apis';
import CacheKeys from '../../Core/Cache/CacheKeys';
import {
    DemeterChartType,
    DemeterMarketNewsType,
    DemeterSymbolContractModel,
    DemeterSymbolModel,
    DemeterTableDefinitionType,
} from '../../Generated/Raven-Demeter';
import { useApplicationSelector } from '../../Redux/ReduxStore';
import { selectLanguage } from '../../Redux/Slices/SystemSlice';
import useCacheOrApi from '../../Refactor/Apis/Hooks/useCacheOrApiHook';
import ProjectionChart from '../../Refactor/Components/Charts/Projection/ProjectionChart';
import { IRegionCommoditySelection } from '../../Refactor/Components/Navigation/Hooks/useRegionCommodityNavigationHook';
import LatestNews from '../../Refactor/Pages/Dashboard/LatestNews/LatestNews';
import PriceSummaries from '../../Refactor/Pages/Dashboard/PriceSummaries';
import PriceSummarySmall from '../../Refactor/Pages/Dashboard/PriceSummarySmall';
import useLanguage from '../../Refactor/Services/Language/useLanguageHook';
import styles from './DashboardPage.module.scss';

interface DashboardChartInterface {
    dashboardPresetChart: Array<any>;
}

const DashboardCharts: React.FC<DashboardChartInterface> = (props) => {
    const [, , symbolsData] = useCacheOrApi(CacheKeys.ListDemeterSymbols, () => demeterApi.listDemeterSymbols());
    const [symbolsContracts, setSymbolsContracts] = useState<any>([]);
    const [translations, translate] = useLanguage();
    const language = useApplicationSelector(selectLanguage);

    // Load all of the symbols from the backend.
    useEffect(() => {
        setSymbolsContracts(symbolsData?.rows ?? []);
    }, [symbolsData, language, translations]);

    return (
        <div className={styles.dynamic_charts_style}>
            {props.dashboardPresetChart &&
                props.dashboardPresetChart.map((presetChart) => {
                    const { region, commodity, subRegion, extraParameters, dataFrequency }: IRegionCommoditySelection = presetChart;
                    if (presetChart.chartType === DemeterChartType.Futures) {
                        let symbol;
                        const filteredRows = symbolsContracts.filter((row: DemeterSymbolModel) =>
                            row.symbolContracts.some((element: DemeterSymbolContractModel) => {
                                if (element.reutersInstrumentCode === presetChart.reutersInstrumentCode) {
                                    symbol = element;
                                    return true;
                                }

                                return false;
                            }),
                        );
                        const selectedSymbolContract = filteredRows[0]?.symbolContracts.filter(
                            (element: DemeterSymbolContractModel) => element.reutersInstrumentCode === presetChart.reutersInstrumentCode,
                        );
                        return (
                            <div className={styles.dynamic_presetchart_news_container} key={presetChart.order}>
                                {presetChart.order === 1 && (
                                    <div className={styles.dynamic_news_previews_container}>
                                        <LatestNews marketNewsType={DemeterMarketNewsType.Headline} testId="LatestNewsHeadline" />
                                        <LatestNews marketNewsType={DemeterMarketNewsType.Reports} testId="LatestNewsReports" />
                                        <PriceSummarySmall />
                                    </div>
                                )}
                            </div>
                        );
                    }
                    // is there a preset type where the other charts would have the news UI instead^^^
                    if (presetChart.chartType === DemeterChartType.Value) {
                        let chartName = presetChart.tableDefinitionType.replace('CommodityMonthly', '').replace('Table', '');
                        // TODO: Fix this with translations.
                        if (chartName.charAt(chartName.length - 1) !== 's' && chartName.charAt(chartName.length - 1) !== 'n') {
                            chartName = `${chartName}s`;
                        }
                        let translatedChartName = '';
                        if (presetChart.tableDefinitionType === DemeterTableDefinitionType.CommodityMonthlyProductionTable) {
                            translatedChartName = translations.dashboard.chartName.production;
                        } else if (presetChart.tableDefinitionType === DemeterTableDefinitionType.CommodityMonthlyExportsTable) {
                            translatedChartName = translations.dashboard.chartName.exports;
                        } else if (presetChart.tableDefinitionType === DemeterTableDefinitionType.CommodityMonthlyMarginTable) {
                            translatedChartName = translations.dashboard.chartName.margin;
                        } else {
                            translatedChartName = translate(`dashboard.chartName.${chartName}`);
                        }
                        return (
                            <div className={styles.preset_chart_style} key={presetChart.order}>
                                <ProjectionChart
                                    title={translatedChartName}
                                    tableDefinitionType={presetChart?.tableDefinitionType}
                                    regionCommoditySelection={{ region, commodity, subRegion, extraParameters, dataFrequency }}
                                    solidLineFields={
                                        presetChart.tableDefinitionType === DemeterTableDefinitionType.CommodityMonthlyMarginTable
                                            ? [
                                                  { fieldName: 'expansion', title: translations.charts.solidLine.expansion },
                                                  { fieldName: 'contraction', title: translations.charts.solidLine.contraction },
                                              ]
                                            : []
                                    }
                                    hideCommodityDisplayName={presetChart.tableDefinitionType === DemeterTableDefinitionType.CommodityMonthlyMarginTable}
                                    testId="DashboardProjectionChart"
                                />
                            </div>
                        );
                    }
                    return <div key={presetChart.order} />;
                })}
            <PriceSummaries />
        </div>
    );
};

export default DashboardCharts;
