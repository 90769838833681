/* eslint-disable class-methods-use-this */
import { MarketIndicatorFactorDataModel } from '../../../../Generated/Raven-Demeter';
import { IChartAreaRangeData, IChartData } from '../ChartDefinitions';

class MarketIndicatorChartService {
    convertToChartData(data: MarketIndicatorFactorDataModel[]): IChartData[] {
        return data.map(
            (row) =>
                ({
                    value: row.value ?? 0,
                    asOfDate: new Date(row.asOfDate),
                    isActualValue: row.isActualValue,
                } as IChartData),
        );
    }

    filterByYears(data: (IChartData | IChartAreaRangeData)[], yearsBack: number): any[] {
        if (!data || data.length === 0) {
            return [];
        }
        const currentDate = data[data.length - 1].asOfDate;
        const cutoffDate = new Date();
        cutoffDate.setFullYear(currentDate.getFullYear() - yearsBack);
        return data.filter((item) => item.asOfDate >= cutoffDate);
    }
}

const marketIndicatorChartService = new MarketIndicatorChartService();
export default marketIndicatorChartService;
