import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { demeterRegistrationApi } from '../../../Apis/Apis';
import AlertModal from '../../../Components/AlertModal/AlertModal';
import applicationSettings from '../../../Core/Settings/ApplicationSettings';
import { RegisterDemeterUserForZohoRequest } from '../../../Generated/Raven-Demeter';
import NavigationRoutes from '../../../Layouts/NavigationRoutes';
import RegistrationButton, { RegistrationButtonType } from '../../Components/Form/Buttons/RegistrationButton/RegistrationButton';
import SelectInput from '../../Components/Form/Inputs/SelectInput';
import TextInput from '../../Components/Form/Inputs/TextInput';
import EmailLink from '../../Components/Navigation/EmailLink/EmailLink';
import useNotificationHook from '../../Components/Notifications/useNotificationHook';
import RegexValidators from '../../Core/Validation/RegexValidators';
import useLanguage from '../../Services/Language/useLanguageHook';
import styles from './RegistrationPage.module.scss';

type RegistrationSelfFormType = {
    firstName?: string | null;
    lastName?: string | null;
    email?: string | null;
    phone?: string | null;
    companyName?: string | null;
    country?: string | null;
};

const countries = [
    { value: 'US', label: 'United States' },
    { value: 'AX', label: 'Aland Islands' },
    { value: 'AL', label: 'Albania' },
    { value: 'DZ', label: 'Algeria' },
    { value: 'AS', label: 'American Samoa' },
    { value: 'AD', label: 'Andorra' },
    { value: 'AO', label: 'Angola' },
    { value: 'AI', label: 'Anguilla' },
    { value: 'AQ', label: 'Antarctica' },
    { value: 'AG', label: 'Antigua and Barbuda' },
    { value: 'AR', label: 'Argentina' },
    { value: 'AM', label: 'Armenia' },
    { value: 'AW', label: 'Aruba' },
    { value: 'AC', label: 'Ashmore and Cartier Islands' },
    { value: 'AU', label: 'Australia' },
    { value: 'AT', label: 'Austria' },
    { value: 'BS', label: 'Bahamas' },
    { value: 'BH', label: 'Bahrain' },
    { value: 'BB', label: 'Barbados' },
    { value: 'BY', label: 'Belarus' },
    { value: 'BE', label: 'Belgium' },
    { value: 'BZ', label: 'Belize' },
    { value: 'BJ', label: 'Benin' },
    { value: 'BM', label: 'Bermuda' },
    { value: 'BO', label: 'Bolivia' },
    { value: 'BQ', label: 'Bonaire Sint Eustatius and Saba' },
    { value: 'BA', label: 'Bosnia' },
    { value: 'BIH', label: 'Bosnia and Herzegovina' },
    { value: 'BW', label: 'Botswana' },
    { value: 'BV', label: 'Bouvet Island' },
    { value: 'BR', label: 'Brazil' },
    { value: 'IO', label: 'British Indian Ocean Territory' },
    { value: 'VG', label: 'British Virgin Islands' },
    { value: 'BG', label: 'Bulgaria' },
    { value: 'BF', label: 'Burkina Faso' },
    { value: 'BI', label: 'Burundi' },
    { value: 'CM', label: 'Cameroon' },
    { value: 'CA', label: 'Canada' },
    { value: 'CV', label: 'Cape Verde' },
    { value: 'KY', label: 'Cayman Islands' },
    { value: 'CF', label: 'Central African Republic' },
    { value: 'TD', label: 'Chad' },
    { value: 'CL', label: 'Chile' },
    { value: 'CX', label: 'Christmas Island' },
    { value: 'CP', label: 'Clipperton Island' },
    { value: 'CC', label: 'Cocos Islands' },
    { value: 'CO', label: 'Colombia' },
    { value: 'KM', label: 'Comoros' },
    { value: 'CG', label: 'Congo' },
    { value: 'CK', label: 'Cook Islands' },
    { value: 'IC', label: 'Coral Sea Islands' },
    { value: 'CR', label: 'Costa Rica' },
    { value: 'HR', label: 'Croatia' },
    { value: 'CU', label: 'Cuba' },
    { value: 'CW', label: 'Curacao' },
    { value: 'CY', label: 'Cyprus' },
    { value: 'CZ', label: 'Czech Republic' },
    { value: 'CD', label: 'DR Congo' },
    { value: 'DK', label: 'Denmark' },
    { value: 'DJ', label: 'Djibouti' },
    { value: 'DM', label: 'Dominica' },
    { value: 'DO', label: 'Dominican Republic' },
    { value: 'EC', label: 'Ecuador' },
    { value: 'EG', label: 'Egypt' },
    { value: 'SV', label: 'El Salvador' },
    { value: 'GQ', label: 'Equatorial Guinea' },
    { value: 'ER', label: 'Eritrea' },
    { value: 'EE', label: 'Estonia' },
    { value: 'ET', label: 'Ethiopia' },
    { value: 'FK', label: 'Falkland Islands' },
    { value: 'FO', label: 'Faroe Islands' },
    { value: 'FJ', label: 'Fiji' },
    { value: 'FI', label: 'Finland' },
    { value: 'FR', label: 'France' },
    { value: 'GF', label: 'French Guiana' },
    { value: 'PF', label: 'French Polynesia' },
    { value: 'TF', label: 'French Southern Territories' },
    { value: 'GA', label: 'Gabon' },
    { value: 'GM', label: 'Gambia' },
    { value: 'GE', label: 'Georgia' },
    { value: 'DE', label: 'Germany' },
    { value: 'GH', label: 'Ghana' },
    { value: 'GI', label: 'Gibraltar' },
    { value: 'GR', label: 'Greece' },
    { value: 'GL', label: 'Greenland' },
    { value: 'GD', label: 'Grenada' },
    { value: 'GP', label: 'Guadeloupe' },
    { value: 'GU', label: 'Guam' },
    { value: 'GT', label: 'Guatemala' },
    { value: 'GG', label: 'Guernsey' },
    { value: 'GN', label: 'Guinea' },
    { value: 'GW', label: 'Guinea-Bissau' },
    { value: 'GY', label: 'Guyana' },
    { value: 'HT', label: 'Haiti' },
    { value: 'HM', label: 'Heard Island and McDonald Islands' },
    { value: 'XX', label: 'High Seas' },
    { value: 'HN', label: 'Honduras' },
    { value: 'HU', label: 'Hungary' },
    { value: 'IS', label: 'Iceland' },
    { value: 'IR', label: 'Iran' },
    { value: 'IQ', label: 'Iraq' },
    { value: 'IE', label: 'Ireland' },
    { value: 'IM', label: 'Isle of Man' },
    { value: 'IL', label: 'Israel' },
    { value: 'IT', label: 'Italy' },
    { value: 'CI', label: "Côte d'Ivoire" },
    { value: 'JM', label: 'Jamaica' },
    { value: 'JE', label: 'Jersey' },
    { value: 'JO', label: 'Jordan' },
    { value: 'KE', label: 'Kenya' },
    { value: 'KI', label: 'Kiribati' },
    { value: 'XK', label: 'Kosovo' },
    { value: 'KW', label: 'Kuwait' },
    { value: 'LV', label: 'Latvia' },
    { value: 'LB', label: 'Lebanon' },
    { value: 'LS', label: 'Lesotho' },
    { value: 'LR', label: 'Liberia' },
    { value: 'LY', label: 'Libya' },
    { value: 'LI', label: 'Liechtenstein' },
    { value: 'LT', label: 'Lithuania' },
    { value: 'LU', label: 'Luxembourg' },
    { value: 'MG', label: 'Madagascar' },
    { value: 'MW', label: 'Malawi' },
    { value: 'ML', label: 'Mali' },
    { value: 'MT', label: 'Malta' },
    { value: 'MH', label: 'Marshall Islands' },
    { value: 'MQ', label: 'Martinique' },
    { value: 'MR', label: 'Mauritania' },
    { value: 'MU', label: 'Mauritius' },
    { value: 'YT', label: 'Mayotte' },
    { value: 'MX', label: 'Mexico' },
    { value: 'FM', label: 'Micronesia' },
    { value: 'MD', label: 'Moldova' },
    { value: 'MC', label: 'Monaco' },
    { value: 'ME', label: 'Montenegro' },
    { value: 'MS', label: 'Montserrat' },
    { value: 'MA', label: 'Morocco' },
    { value: 'MZ', label: 'Mozambique' },
    { value: 'NA', label: 'Namibia' },
    { value: 'NR', label: 'Nauru' },
    { value: 'NL', label: 'Netherlands' },
    { value: 'AN', label: 'Netherlands Antilles' },
    { value: 'NC', label: 'New Caledonia' },
    { value: 'NZ', label: 'New Zealand' },
    { value: 'NI', label: 'Nicaragua' },
    { value: 'NE', label: 'Niger' },
    { value: 'NG', label: 'Nigeria' },
    { value: 'NU', label: 'Niue' },
    { value: 'NF', label: 'Norfolk Island' },
    { value: 'MK', label: 'North Macedonia' },
    { value: 'MP', label: 'Northern Mariana Islands' },
    { value: 'NO', label: 'Norway' },
    { value: 'OM', label: 'Oman' },
    { value: 'PW', label: 'Palau' },
    { value: 'PS', label: 'Palestine' },
    { value: 'PA', label: 'Panama' },
    { value: 'PG', label: 'Papua New Guinea' },
    { value: 'PY', label: 'Paraguay' },
    { value: 'PE', label: 'Peru' },
    { value: 'PN', label: 'Pitcairn' },
    { value: 'PL', label: 'Poland' },
    { value: 'PT', label: 'Portugal' },
    { value: 'PR', label: 'Puerto Rico' },
    { value: 'QA', label: 'Qatar' },
    { value: 'RE', label: 'Reunion' },
    { value: 'RO', label: 'Romania' },
    { value: 'RU', label: 'Russia' },
    { value: 'RW', label: 'Rwanda' },
    { value: 'BL', label: 'Saint Barthelemy' },
    { value: 'SH', label: 'Saint Helena' },
    { value: 'KN', label: 'Saint Kitts and Nevis' },
    { value: 'LC', label: 'Saint Lucia' },
    { value: 'MF', label: 'Saint Martin' },
    { value: 'PM', label: 'Saint Pierre and Miquelon' },
    { value: 'VC', label: 'Saint Vincent and the Grenadines' },
    { value: 'WS', label: 'Samoa' },
    { value: 'SM', label: 'San Marino' },
    { value: 'ST', label: 'Sao Tome and Principe' },
    { value: 'SA', label: 'Saudi Arabia' },
    { value: 'SN', label: 'Senegal' },
    { value: 'RS', label: 'Serbia' },
    { value: 'CS', label: 'Serbia and Montenegro' },
    { value: 'SC', label: 'Seychelles' },
    { value: 'SL', label: 'Sierra Leone' },
    { value: 'SX', label: 'Sint Maarten' },
    { value: 'SK', label: 'Slovakia' },
    { value: 'SI', label: 'Slovenia' },
    { value: 'SB', label: 'Solomon Islands' },
    { value: 'SO', label: 'Somalia' },
    { value: 'SOM', label: 'Somaliland' },
    { value: 'ZA', label: 'South Africa' },
    { value: 'GS', label: 'South Georgia and the South Sandwich Islands' },
    { value: 'SS', label: 'South Sudan' },
    { value: 'ES', label: 'Spain' },
    { value: 'SD', label: 'Sudan' },
    { value: 'SR', label: 'Suriname' },
    { value: 'SJ', label: 'Svalbard and Jan Mayen' },
    { value: 'SZ', label: 'Swaziland' },
    { value: 'SE', label: 'Sweden' },
    { value: 'CH', label: 'Switzerland' },
    { value: 'SY', label: 'Syria' },
    { value: 'TZ', label: 'Tanzania' },
    { value: 'TG', label: 'Togo' },
    { value: 'TK', label: 'Tokelau' },
    { value: 'TO', label: 'Tonga' },
    { value: 'TT', label: 'Trinidad and Tobago' },
    { value: 'TN', label: 'Tunisia' },
    { value: 'TR', label: 'Turkey' },
    { value: 'TC', label: 'Turks and Caicos Islands' },
    { value: 'TV', label: 'Tuvalu' },
    { value: 'UG', label: 'Uganda' },
    { value: 'UA', label: 'Ukraine' },
    { value: 'AE', label: 'United Arab Emirates' },
    { value: 'GB', label: 'United Kingdom' },
    { value: 'UM', label: 'United States Minor Outlying Islands' },
    { value: 'UY', label: 'Uruguay' },
    { value: 'VU', label: 'Vanuatu' },
    { value: 'VA', label: 'Vatican City' },
    { value: 'VE', label: 'Venezuela' },
    { value: 'VG', label: 'Virgin Islands British' },
    { value: 'VI', label: 'Virgin Islands U.S.' },
    { value: 'WF', label: 'Wallis and Futuna' },
    { value: 'EH', label: 'Western Sahara' },
    { value: 'YE', label: 'Yemen' },
    { value: 'ZM', label: 'Zambia' },
    { value: 'ZW', label: 'Zimbabwe' },
];

const asianCountries = [
    { value: 'AF', label: 'Afghanistan' },
    { value: 'AZ', label: 'Azerbaijan' },
    { value: 'BD', label: 'Bangladesh' },
    { value: 'BT', label: 'Bhutan' },
    { value: 'BN', label: 'Brunei' },
    { value: 'KH', label: 'Cambodia' },
    { value: 'CN', label: 'China' },
    { value: 'HK', label: 'Hong Kong' },
    { value: 'IN', label: 'India' },
    { value: 'ID', label: 'Indonesia' },
    { value: 'JP', label: 'Japan' },
    { value: 'KZ', label: 'Kazakhstan' },
    { value: 'KG', label: 'Kyrgyzstan' },
    { value: 'LA', label: 'Laos' },
    { value: 'MO', label: 'Macau' },
    { value: 'MY', label: 'Malaysia' },
    { value: 'MV', label: 'Maldives' },
    { value: 'MN', label: 'Mongolia' },
    { value: 'MM', label: 'Myanmar' },
    { value: 'NP', label: 'Nepal' },
    { value: 'KP', label: 'North Korea' },
    { value: 'PK', label: 'Pakistan' },
    { value: 'PH', label: 'Philippines' },
    { value: 'SG', label: 'Singapore' },
    { value: 'KR', label: 'South Korea' },
    { value: 'LK', label: 'Sri Lanka' },
    { value: 'TW', label: 'Taiwan' },
    { value: 'TJ', label: 'Tajikistan' },
    { value: 'TH', label: 'Thailand' },
    { value: 'TL', label: 'Timor Leste' },
    { value: 'TM', label: 'Turkmenistan' },
    { value: 'UZ', label: 'Uzbekistan' },
    { value: 'VN', label: 'Vietnam' },
];

const RegistrationSelfFormComponent: React.FC = () => {
    // Application hooks.
    const navigate = useNavigate();
    const [translations] = useLanguage();
    const [displayError] = useNotificationHook();
    const [showModal, setShowModal] = useState(false);

    // User hooks.
    const [user, setUser] = useState<RegistrationSelfFormType>();

    // Actions hooks.
    const [isSaving, setIsSaving] = useState(false);

    // TODO: Perform stronger validation or get the validation from each input.
    const isValid = !!user?.email && !!user.country && !!user.firstName && !!user.lastName;

    const allCountries = [
        ...[countries[0]], // This is to keep United States on top.
        ...countries
            .slice(1)
            .concat(asianCountries)
            .sort((a, b) => a.label.localeCompare(b.label)),
    ];

    const handleRegistrationSelf = async (): Promise<void> => {
        if (isValid) {
            try {
                const response = await demeterRegistrationApi.registerDemeterUserForZoho({
                    ...user,
                    // Strip all non-digits from the phone number before sending request.
                    phone: user?.phone?.replace(/\D/g, ''),
                } as RegisterDemeterUserForZohoRequest);

                if (asianCountries.some((country) => country.value === user.country)) {
                    setShowModal(true);
                } else {
                    navigate(`${NavigationRoutes.RegistrationSelfSuccess}?redirectUrl=${response?.data.redirectUrl}`);
                }
            } catch (error) {
                displayError(translations.errors.pleaseContactYourBroker);
                setIsSaving(false);
            }
        } else {
            setIsSaving(false);
        }
    };

    return (
        <div className={styles.registration_self_form_page_container}>
            <div className={styles.registration_self_form_header_container}>
                <h2 className={styles.registration_self_form_header}>{translations.registration.text.startYourFreeTrial}</h2>
            </div>
            <div className={styles.registration_self_form_input_flex}>
                <div className={styles.registration_self_form_input}>
                    <TextInput
                        placeholder={`${translations.users.fields.firstName} *`}
                        required
                        value={user?.firstName}
                        handleTextChange={(value) => setUser({ ...user, firstName: value })}
                        validation={RegexValidators.AlphaNumericMinimumLength2}
                        errorMessage={translations.users.errors.firstNameRequired}
                    />
                </div>
                <div className={styles.registration_self_form_input}>
                    <TextInput
                        placeholder={`${translations.users.fields.lastName} *`}
                        required
                        value={user?.lastName}
                        handleTextChange={(value) => setUser({ ...user, lastName: value })}
                        validation={RegexValidators.AlphaNumericMinimumLength2}
                        errorMessage={translations.users.errors.lastNameRequired}
                    />
                </div>
            </div>
            <div className={styles.registration_self_form_input}>
                <TextInput
                    placeholder={`${translations.users.fields.email} *`}
                    type="email"
                    required
                    value={user?.email}
                    handleTextChange={(value) => setUser({ ...user, email: value })}
                    validation={RegexValidators.Email}
                    errorMessage={translations.users.errors.emailInvalid}
                />
            </div>
            <div className={styles.registration_self_form_input}>
                <TextInput
                    placeholder={`${translations.users.fields.phone} *`}
                    value={user?.phone}
                    handleTextChange={(value) => setUser({ ...user, phone: value })}
                    validation={RegexValidators.Phone}
                    errorMessage={translations.users.errors.phoneInvalid}
                />
            </div>
            <div className={styles.registration_self_form_input}>
                <TextInput
                    placeholder={`${translations.users.fields.company} *`}
                    value={user?.companyName}
                    handleTextChange={(value) => setUser({ ...user, companyName: value })}
                    validation={RegexValidators.AlphaNumericMinimumLength2}
                />
            </div>
            <div className={styles.registration_self_form_input}>
                <SelectInput
                    value={user?.country ?? ''}
                    options={[{ value: '', label: translations.registration.text.selectCountry }, ...allCountries]}
                    handleOptionChange={(value) => setUser({ ...user, country: value })}
                />
            </div>
            <div className={styles.registration_self_form_input}>
                <p className={styles.registration_self_form_subscription_note}>
                    <span>{translations.registration.text.accessToTheTrial} </span>
                    <EmailLink linkText={applicationSettings.emails.contactEmail} email={applicationSettings.emails.contactEmail} />
                </p>
                <p className={styles.registration_self_form_subscription_note}>
                    <span>{translations.registration.text.bySubmittingThisForm}</span>
                    <a className={styles.registration_self_form_privacy_policy} href={applicationSettings.links.privacyPolicyUri}>
                        {translations.registration.text.privacyPolicy}
                    </a>
                    <span>{translations.registration.text.toLearnMore}</span>
                </p>
            </div>

            <div className={styles.registration_self_submit_action_row}>
                <RegistrationButton
                    title={translations.actions.submit}
                    type={RegistrationButtonType.BluePrimary}
                    onClick={() => {
                        setIsSaving(true);
                        handleRegistrationSelf();
                    }}
                    disabled={!isValid || isSaving}
                />
            </div>
            {showModal ? <AlertModal setShowModal={setShowModal} message={<h3>{translations.registration.text.asianUserSuccessfullyRegistered} </h3>} /> : null}
        </div>
    );
};

export default RegistrationSelfFormComponent;
