const ContentType = {
    Json: 'application/json',
    Html: 'text/html',
    Text: 'text/plain',
    Xml: 'application/xml',
    FormUrlencoded: 'application/x-www-form-urlencoded',
    Csv: 'text/csv',
    MultipartFormData: 'multipart/form-data',
};

export default ContentType;
