import languageService from '../../../Services/Language/LanguageService';
import Dropdown from './Dropdown';
import InputContainer from './InputContainer';
import styles from './Inputs.module.scss';

export type SelectInputOption<T> = { value: T; label: string };

interface ISelectInputInputProps<T> {
    title?: string;
    value?: T;
    options: SelectInputOption<T>[];
    handleOptionChange: (value: T) => void;
    disabled?: boolean;
}

// eslint-disable-next-line @typescript-eslint/comma-dangle
const SelectInput = <T,>(props: ISelectInputInputProps<T>): JSX.Element => {
    const selectedValue = props.value;

    return (
        <InputContainer title={languageService.translate(props.title ?? '')}>
            <Dropdown
                className={styles.input_auto_complete}
                value={selectedValue}
                options={props.options}
                handleOptionChange={props.handleOptionChange}
                disabled={props.disabled}
            />
        </InputContainer>
    );
};

export default SelectInput;
