import { useMemo } from 'react';
import { Correlation } from '../../../../../../../Generated/Raven-Demeter/api';
import Dropdown from '../../../../../../Components/Form/Inputs/Dropdown';
import useLanguage from '../../../../../../Services/Language/useLanguageHook';
import { UpsertMarketIndicatorFactorRequest } from '../../../MarketIndicatorsManagementDefinitions';
import styles from '../../MarketIndicatorFactorPage.module.scss';

interface IMarketFactorCorrelationDropdownProps {
    upsertMarketIndicatorFactorRequest: UpsertMarketIndicatorFactorRequest;
    setUpsertMarketIndicatorFactorRequest: (value: UpsertMarketIndicatorFactorRequest) => void;
}

const MarketFactorCorrelationDropdown: React.FC<IMarketFactorCorrelationDropdownProps> = (props: IMarketFactorCorrelationDropdownProps) => {
    const [translations] = useLanguage();

    const correlationOptions = useMemo(
        () => [
            {
                label: translations.correlation[Correlation.Negative],
                value: Correlation.Negative,
            },
            {
                label: translations.correlation[Correlation.Positive],
                value: Correlation.Positive,
            },
        ],
        [translations],
    );

    return (
        <div className={styles.indicator_add_and_edit_dropdown}>
            <Dropdown
                value={props.upsertMarketIndicatorFactorRequest.correlation}
                options={correlationOptions}
                handleOptionChange={(value) => {
                    props.setUpsertMarketIndicatorFactorRequest({
                        ...props.upsertMarketIndicatorFactorRequest,
                        correlation: value,
                    });
                }}
                label={translations.marketIndicatorsManagement.fields.correlation}
            />
        </div>
    );
};

export default MarketFactorCorrelationDropdown;
