import { useEffect } from 'react';
import formattingService from '../../../../Core/Formatting/FormattingService';
import { DemeterRegion } from '../../../../Generated/Raven-Demeter';
import useSpotPricesSummaryApi from '../../../Apis/Hooks/useSpotPricesSummaryApiHook';
import useLanguage from '../../../Services/Language/useLanguageHook';
import styles from '../PriceSummaries.module.scss';
import CmeSpotSummaryDaily from './CmeSpotSummaryDaily';
import CmeSpotSummaryHistorical from './CmeSpotSummaryHistorical';
import CmeSpotSummaryWeekly from './CmeSpotSummaryWeekly';

interface ICmeSpotSummaryProps {
    setTitle: (title: string) => void;
}

const CmeSpotSummary: React.FC<ICmeSpotSummaryProps> = ({ setTitle }) => {
    const summaryData = useSpotPricesSummaryApi(DemeterRegion.UnitedStates);
    const [translations] = useLanguage();

    useEffect(
        () =>
            setTitle(
                ` ${translations.dashboard.priceSummary.cme.summaryHeader} ${translations.words.overview} ${
                    translations.words.results
                } ${formattingService.toShortDayMonthYear(summaryData?.asOfDate)}` ?? '',
            ),
        [summaryData, translations],
    );

    return (
        <div data-testid="CmeSpotSummary" className={styles.price_summary_dashboard_container}>
            <CmeSpotSummaryDaily summaryData={summaryData?.rows ?? undefined} />
            <CmeSpotSummaryWeekly summaryData={summaryData?.rows ?? undefined} />
            <CmeSpotSummaryHistorical summaryData={summaryData?.rows ?? undefined} />
        </div>
    );
};

export default CmeSpotSummary;
