import Switch from '../Form/Buttons/Switch';
import type { RegionOptionsType } from './AggregateRegionSelector';
import styles from './AggregateRegionSelector.module.scss';

export interface IAggregateRegionsProps {
    regionOptions: RegionOptionsType[];
    handleRegionsSelection: (regionOption: RegionOptionsType) => void;
}

const AggregateRegions: React.FC<IAggregateRegionsProps> = (props: IAggregateRegionsProps) => (
    <div className={styles.aggregate_region_selector_parent_switch_label}>
        {props.regionOptions?.map((regionOption) => (
            <p className={styles.aggregate_region_selector_switch_label} key={regionOption?.region}>
                <Switch checked={regionOption?.isSelected} handleChange={() => props.handleRegionsSelection(regionOption)} />
                {regionOption?.displayName}
            </p>
        ))}
    </div>
);

export default AggregateRegions;
