import { useEffect, useState } from 'react';
import { DemeterRegion, DemeterTableDefinitionType } from '../../../Generated/Raven-Demeter/api';
import useCommentaryApi from '../../Apis/Hooks/useCommentaryApiHook';
import AggregateRegionSelector from '../../Components/AggregateRegionSelector/AggregateRegionSelector';
import ProjectionChart from '../../Components/Charts/Projection/ProjectionChart';
import SeasonalChart from '../../Components/Charts/Seasonal/SeasonalChart';
import UnitOfMeasureMetricImperialDropdown from '../../Components/Form/Inputs/UnitOfMeasureMetricImperialDropdown';
import PageHeaderWithSelections from '../../Components/Headers/PageHeaderWithSelections';
import PageLoadingSpinner from '../../Components/LoadingSpinner/PageLoadingSpinner';
import useTableDefinitionNavigation from '../../Components/Navigation/Hooks/useTableDefinitionNavigationHook';
import SeasonalValueAndPercentTables from '../../Components/Tables/Seasonal/SeasonalValueAndPercentTables';
import useUnitOfMeasure from '../../Core/Hooks/useUnitOfMeasureHook';
import useLanguage from '../../Services/Language/useLanguageHook';
import styles from './StocksPage.module.scss';

const StocksPage: React.FC = () => {
    // Constants.
    const [translations] = useLanguage();
    const pageTitle = translations.stocks.title;
    const tableDefinitionType = DemeterTableDefinitionType.CommodityMonthlyStockTable;

    // Hooks.
    const [, tableDefinitionCommodity, regionCommoditySelection] = useTableDefinitionNavigation(tableDefinitionType);
    const commentary = useCommentaryApi(tableDefinitionCommodity?.demeterCommentaryGuid);
    const [unitOfMeasure, setUnitOfMeasure] = useUnitOfMeasure('MetricImperial');
    const [aggregateRegions, setAggregateRegions] = useState<DemeterRegion[]>([]);

    useEffect(() => {
        setAggregateRegions([]);
    }, [regionCommoditySelection?.region]);

    return (
        <div className={styles.master_page_container}>
            <PageHeaderWithSelections
                title={pageTitle}
                tableDefinitionType={tableDefinitionType}
                rightSideOfHeaderComponent={<UnitOfMeasureMetricImperialDropdown unitOfMeasure={unitOfMeasure} handleChange={setUnitOfMeasure} />}
                testId="StocksPageHeader"
            />
            {!tableDefinitionCommodity || !regionCommoditySelection ? (
                <PageLoadingSpinner />
            ) : (
                <>
                    <div className={styles.stocks_main_container}>
                        <div className={styles.stocks_projection_chart}>
                            <ProjectionChart
                                title={pageTitle}
                                tableDefinitionType={tableDefinitionType}
                                regionCommoditySelection={regionCommoditySelection}
                                aggregateRegions={aggregateRegions}
                                unitOfMeasure={unitOfMeasure}
                                testId="StocksProjectionChart"
                            />
                        </div>
                        {regionCommoditySelection.region === DemeterRegion.Aggregate ? (
                            <div className={styles.stocks_commentary_container}>
                                <AggregateRegionSelector
                                    title={translations.region.Aggregated}
                                    tableDefinitionType={tableDefinitionType}
                                    regionCommoditySelection={regionCommoditySelection}
                                    handleRegionSelections={setAggregateRegions}
                                />
                            </div>
                        ) : (
                            tableDefinitionCommodity?.demeterCommentaryGuid && (
                                <div className={styles.stocks_commentary_container}>
                                    <p>{commentary}</p>
                                </div>
                            )
                        )}
                    </div>
                    <SeasonalChart
                        title={pageTitle}
                        tableDefinitionType={tableDefinitionType}
                        regionCommoditySelection={regionCommoditySelection}
                        aggregateRegions={aggregateRegions}
                        unitOfMeasure={unitOfMeasure}
                        testId="StocksSeasonalChart"
                    />
                    <SeasonalValueAndPercentTables
                        title={pageTitle}
                        tableDefinitionType={tableDefinitionType}
                        regionCommoditySelection={regionCommoditySelection}
                        aggregateRegions={aggregateRegions}
                        unitOfMeasure={unitOfMeasure}
                        testId="StocksSeasonalValueAndPercentTables"
                    />
                </>
            )}
        </div>
    );
};
export default StocksPage;
