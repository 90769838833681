import { useMemo, useState } from 'react';
import Dropdown from '../../Components/Form/Inputs/Dropdown';
import { SelectInputOption } from '../../Components/Form/Inputs/SelectInput';
import ComponentHeader from '../../Components/Headers/ComponentHeader';
import useLanguage from '../../Services/Language/useLanguageHook';
import CmeSpotSummary from './CmeSpotSummary/CmeSpotSummary';
import EuropeanUnionWeeklySummary from './EuropeanUnionWeeklySummaries/EuropeanUnionWeeklySummary';
import GlobalDairyTradeSummary from './GlobalDairyTradeSummary/GlobalDairyTradeSummary';
import styles from './PriceSummaries.module.scss';

export type SummaryTableTypes = 'GlobalDairyTradeSummary' | 'CmeSpotSummary' | 'EuropeanUnionWeeklySummary';

const PriceSummaries: React.FC = () => {
    const [translations] = useLanguage();
    const [title, setTitle] = useState<string>('');

    const dropdownOptions: SelectInputOption<SummaryTableTypes>[] = useMemo(
        () => [
            {
                label: translations.dashboard.priceSummary.europeanUnion.summaryHeader,
                value: 'EuropeanUnionWeeklySummary',
            },
            {
                label: translations.dashboard.priceSummary.cme.summaryHeader,
                value: 'CmeSpotSummary',
            },
            {
                label: translations.dashboard.headings.globalDairyTradeResultTableHeading,
                value: 'GlobalDairyTradeSummary',
            },
        ],
        [translations],
    );
    const [selectedSummary, setSelectedSummary] = useState<SummaryTableTypes>('GlobalDairyTradeSummary');

    return (
        <div className={styles.price_summary_container}>
            <div className={styles.price_summary_header}>
                <ComponentHeader title={title} />
                <div className={styles.price_summary_header_dropdown}>
                    <p>{translations.dashboard.priceSummary.dropdownPrefix} </p>
                    <Dropdown
                        value={selectedSummary}
                        options={dropdownOptions}
                        handleOptionChange={(value) => {
                            setSelectedSummary(value);
                        }}
                        testId="PriceSummariesDropdown"
                    />
                </div>
            </div>
            <div className={styles.gdt_table_inner_style}>
                {selectedSummary === 'GlobalDairyTradeSummary' && <GlobalDairyTradeSummary setTitle={setTitle} />}
                {selectedSummary === 'CmeSpotSummary' && <CmeSpotSummary setTitle={setTitle} />}
                {selectedSummary === 'EuropeanUnionWeeklySummary' && <EuropeanUnionWeeklySummary setTitle={setTitle} />}
            </div>
        </div>
    );
};

export default PriceSummaries;
