import { useSnackbar } from 'notistack';
import styles from './NotificationsWrapper.module.scss';

const useNotificationHook = (): [displayErrorSnackbar: (message: string) => void, displaySuccessSnackbar: (message: string) => void] => {
    const { enqueueSnackbar } = useSnackbar();

    const displayError = (message: string) => {
        enqueueSnackbar(message, { variant: 'error' });
    };

    const displaySuccess = (message: string) => {
        enqueueSnackbar(message, {
            variant: 'success',
            className: styles.notifications_green_success,
        });
    };

    return [displayError, displaySuccess];
};

export default useNotificationHook;
