/* eslint-disable class-methods-use-this */
import { IChartAreaRangeData, IChartData } from '../../ChartDefinitions';
import marketIndicatorChartDateService from '../MarketIndicatorChartDateService';

class MarketIndicatorSeasonalChartService {
    calculateThisYearData(data: IChartData[]): IChartData[] {
        return marketIndicatorChartDateService.toWeeklyData(data);
    }

    calculateSeasonalAverageChange(data: IChartData[], numberOfYears: number): number {
        const weeklyData = marketIndicatorChartDateService.toWeeklyData(data);
        const weeklyDataWithAverageChanges = weeklyData.map((weekData) => {
            const previousYearsWeeks = marketIndicatorChartDateService.getPreviousYearsWeeks(weekData.asOfDate, numberOfYears);
            const previousYearsData = previousYearsWeeks
                .map((weekDate) => weeklyData.find((d) => d.asOfDate.getTime() === weekDate.getTime()))
                .filter((d) => d !== undefined);
            const previousWeekDate = marketIndicatorChartDateService.getPreviousWeek(weekData.asOfDate, 1);
            const previousYearsPreviousWeeks = marketIndicatorChartDateService.getPreviousYearsWeeks(previousWeekDate, numberOfYears);
            const previousYearsPreviousWeeksData = previousYearsPreviousWeeks
                .map((weekDate) => weeklyData.find((d) => d.asOfDate.getTime() === weekDate.getTime()))
                .filter((d) => d !== undefined);

            if (previousYearsData.length === 0 || previousYearsData.length !== previousYearsPreviousWeeksData.length) {
                return 0;
            }
            const totalChange = previousYearsData.reduce((acc, d, idx) => {
                if (previousYearsData[idx] !== undefined && previousYearsPreviousWeeksData[idx] !== undefined) {
                    const a = previousYearsData[idx];
                    const b = previousYearsPreviousWeeksData[idx];
                    return acc + (a && b ? a.value - b.value : 0);
                }
                return acc;
            }, 0);
            const averageChange = totalChange / previousYearsData.length;
            return averageChange;
        });
        return weeklyDataWithAverageChanges[weeklyDataWithAverageChanges.length - 1];
    }

    calculateLastChange(data: IChartData[]): number {
        const weeklyData = marketIndicatorChartDateService.toWeeklyData(data);
        const lastChange = weeklyData[weeklyData.length - 1].value - weeklyData[weeklyData.length - 2].value;
        return lastChange;
    }

    calculateLastYearData(data: IChartData[]): IChartData[] {
        const weeklyData = marketIndicatorChartDateService.toWeeklyData(data);
        if (weeklyData.length === 0) {
            return [];
        }
        const lastYearWeeksData = weeklyData.map((weekData) => {
            const previousYearWeekDate = marketIndicatorChartDateService.getPreviousYearWeek(weekData.asOfDate);
            const previousYearWeek = weeklyData.find((x) => x.asOfDate.getTime() >= previousYearWeekDate.getTime());
            return {
                value: previousYearWeek?.value,
                asOfDate: weekData.asOfDate,
                isActualValue: true,
            } as IChartData;
        });
        return lastYearWeeksData;
    }

    calculateSeasonalAverageData = (data: IChartData[], numberOfYears: number): IChartData[] => {
        const weeklyData = marketIndicatorChartDateService.toWeeklyData(data);
        const weeklyDataWithAverages = weeklyData.map((weekData) => {
            const previousYearsWeeks = marketIndicatorChartDateService.getPreviousYearsWeeks(weekData.asOfDate, numberOfYears);
            const previousYearsData = previousYearsWeeks
                .map((weekDate) => weeklyData.find((d) => d.asOfDate.getTime() === weekDate.getTime()))
                .filter((d) => d !== undefined);
            const averageValue =
                previousYearsData.length > 0 ? previousYearsData.reduce((sum, d) => (d ? sum + d.value : sum), 0) / previousYearsData.length : 0;
            return {
                value: averageValue,
                asOfDate: weekData.asOfDate,
                isActualValue: true,
            } as IChartData;
        });
        return weeklyDataWithAverages;
    };

    calculateRangeData = (data: IChartData[], numberOfYears: number): IChartAreaRangeData[] => {
        const weeklyData = marketIndicatorChartDateService.toWeeklyData(data);
        const weeklyDataWithMinMax = weeklyData
            .map((weekData) => {
                const previousYearsWeeks = marketIndicatorChartDateService.getPreviousYearsWeeks(weekData.asOfDate, numberOfYears);
                const previousYearsData = previousYearsWeeks
                    .map((weekDate) => weeklyData.find((d) => d.asOfDate.getTime() === weekDate.getTime()))
                    .filter((d) => d !== undefined);
                const minData =
                    previousYearsData.length > 0
                        ? previousYearsData.reduce((min, d) => (d && min && d.value < min.value ? d : min), previousYearsData[0])
                        : undefined;
                const maxData =
                    previousYearsData.length > 0
                        ? previousYearsData.reduce((max, d) => (d && max && d.value > max.value ? d : max), previousYearsData[0])
                        : undefined;

                if (!minData || !maxData) {
                    return undefined;
                }
                return {
                    minimumValue: minData ? minData.value : 0,
                    maximumValue: maxData ? maxData.value : 0,
                    asOfDate: weekData.asOfDate,
                    isActualValue: true,
                } as IChartAreaRangeData;
            })
            .filter((d) => d !== undefined) as IChartAreaRangeData[];
        return weeklyDataWithMinMax;
    };
}

const marketIndicatorSeasonalChartService = new MarketIndicatorSeasonalChartService();
export default marketIndicatorSeasonalChartService;
