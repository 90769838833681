/* eslint-disable no-nested-ternary */
import { Children } from 'react';
import LoadingSpinner from '../../Components/LoadingSpinner/LoadingSpinner';
import styles from './PriceSummaries.module.scss';

interface IPricesSummaryTableWrapperProps {
    className: string;
    loading: boolean;
    columnHeaders: string[];
    children: JSX.Element;
    largeFirstHeader?: boolean;
}

const PricesSummaryTableWrapper: React.FC<IPricesSummaryTableWrapperProps> = (props: IPricesSummaryTableWrapperProps) => (
    <div>
        {props.loading ? (
            <LoadingSpinner />
        ) : (
            <div className={props.className}>
                {props.columnHeaders.map((header: string, index) => (
                    <h3
                        key={header}
                        className={
                            index === 0
                                ? props.largeFirstHeader
                                    ? `${styles.price_summary_table_header_large} ${styles.price_summary_table_header_title}`
                                    : styles.price_summary_table_header_title
                                : styles.price_summary_table_header
                        }
                    >
                        {header}
                    </h3>
                ))}
                {Children.only(props.children)}
            </div>
        )}
    </div>
);

export default PricesSummaryTableWrapper;
