import { useMemo } from 'react';
import { demeterApi } from '../../../Apis/Apis';
import CacheKeys from '../../../Core/Cache/CacheKeys';
import { DemeterRegion, ListCommoditySpotPricesSummaryResponse } from '../../../Generated/Raven-Demeter/api';
import useCacheThenApi from './useCacheThenApiHook';

const useSpotPricesSummaryApi = (region: DemeterRegion): ListCommoditySpotPricesSummaryResponse | undefined => {
    const cacheKey = useMemo(() => `${CacheKeys.ListCommoditySpotPricesSummary}_${region}`, [region]);

    const [, , summaryResponse] = useCacheThenApi(cacheKey, () => demeterApi.listCommoditySpotPricesSummary(region));

    return summaryResponse;
};

export default useSpotPricesSummaryApi;
