import { AxiosResponse } from 'axios';
import { useEffect, useRef, useState } from 'react';
import { ApiError } from '../../../Apis/Apis';
import cacheService from '../../../Core/Cache/CacheService';
import useNotificationHook from '../../Components/Notifications/useNotificationHook';
import useLanguage from '../../Services/Language/useLanguageHook';
import { UseApiOptions } from './useApiHook';

// This states that we are going to use cache if the items exists and call the api afterwards and re-hyrate the cache with it.
const useCacheThenApi = <T>(
    key: string,
    apiCallback: () => Promise<AxiosResponse<T, any>> | null | undefined,
    options?: UseApiOptions,
): [loading: boolean, refreshApi: () => void, data?: T, error?: string] => {
    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<T | undefined>();
    const [error, setError] = useState<string>();
    const [refreshCounter, setRefreshCounter] = useState(0);
    const counterReference = useRef(0);
    const keyReference = useRef<string>('');
    const [displayError] = useNotificationHook();
    const [translations] = useLanguage();

    const refreshApi = () => {
        counterReference.current += 1;
        setRefreshCounter(counterReference.current);
    };

    useEffect(() => {
        let cancelRequest = false;
        const keyUsedForApi = key;
        keyReference.current = keyUsedForApi;

        const fetchDataFromApi = async () => {
            try {
                const apiToCall = apiCallback();
                if (!apiToCall) {
                    return;
                }

                const response = await apiToCall;
                const responseData = response.data;
                cacheService.set(keyUsedForApi, responseData);

                // This is where the response of this api call has been superceeded by another api call in this hook.
                if (keyReference.current !== keyUsedForApi || cancelRequest) {
                    return;
                }

                setData(responseData);
                setLoading(false);
            } catch (exception) {
                if (cancelRequest) {
                    return;
                }

                const problemMessage = (exception as ApiError).response.data?.detail ?? '';
                const errorMessage = (exception as ApiError).response.data?.errors ?? [];

                setError(problemMessage || errorMessage.join(', '));
                setLoading(false);

                if (!options?.stopGlobalErrorHandling) {
                    displayError(translations.errors.apiError);
                }
            }
        };

        const fetchDataFromCache = async () => {
            const cachedValue = await cacheService.get<T>(keyUsedForApi);
            if (keyReference.current !== keyUsedForApi) {
                return;
            }

            if (cachedValue) {
                setLoading(false);
                setData(cachedValue);
            } else {
                // Only set loading = true when there's no cached value.
                setLoading(true);
            }

            await fetchDataFromApi();
        };

        fetchDataFromCache();

        return (): void => {
            cancelRequest = true;
        };
    }, [key, refreshCounter]);

    return [loading, refreshApi, data, error];
};
export default useCacheThenApi;
