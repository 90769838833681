import { useEffect, useState } from 'react';

export type Dimensions = { width: number; height: number };

const useContainerDimensions = (containerReference: any): Dimensions => {
    const [dimensions, setDimensions] = useState<Dimensions>({ width: 0, height: 0 });

    const getDimensions = () => {
        const newDimensions = {
            width: containerReference?.current?.offsetWidth ?? dimensions.width,
            height: containerReference?.current?.offsetHeight ?? dimensions.height,
        };

        return newDimensions;
    };

    useEffect(() => {
        const handleResize = () => {
            setDimensions(getDimensions());
        };

        const dimensionsTimeout = setTimeout(() => {
            if (containerReference) {
                setDimensions(getDimensions());
            }
        }, 500);

        window.addEventListener('resize', handleResize);

        handleResize();

        return () => {
            clearTimeout(dimensionsTimeout);
            window.removeEventListener('resize', handleResize);
        };
    }, [containerReference]);

    return dimensions;
};

export default useContainerDimensions;
