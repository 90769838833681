/* eslint-disable no-nested-ternary */
import { FC, useEffect, useState } from 'react';
import Carousel from '../../../Components/Carousel/Carousel';
import useLanguage from '../../../Services/Language/useLanguageHook';
import styles from './BasisCalculator.module.scss';
import BasisCalculatorChart from './BasisCalculatorChart';
import { BasisCalculatorProductPair, BasisPeriod } from './BasisCalculatorDefinitions';
import { IBasisCalculatorCalculationResult } from './BasisCalculatorService';

interface IBasisCalculatorChartCarouselProps {
    productPair: BasisCalculatorProductPair;
    calculationResult: IBasisCalculatorCalculationResult | undefined;
}

const BasisCalculatorChartCarousel: FC<IBasisCalculatorChartCarouselProps> = (props: IBasisCalculatorChartCarouselProps) => {
    const [translations] = useLanguage();
    const [dataSourceTag, setDataSourceTag] = useState<string[]>([]);

    useEffect(() => {
        if (!props.productPair) {
            return;
        }
        const pricesDataSource = [];
        if (props.productPair.product1?.pricesDataSource) {
            pricesDataSource.push(props.productPair.product1?.pricesDataSource);
        }
        if (props.productPair.product2?.pricesDataSource) {
            pricesDataSource.push(props.productPair.product2?.pricesDataSource);
        }
        setDataSourceTag(pricesDataSource);
    }, [props.productPair]);

    return (
        <div className={styles.basis_calculator_chart_carousel}>
            <div className={styles.basis_calculator_container}>
                <div className={styles.basis_calculator_row}>
                    <div className={styles.basis_calculator_cell_full_width}>
                        <Carousel>
                            <BasisCalculatorChart
                                title={translations.calculators.basis.charts.basisAnalysis}
                                dataSourceTag={dataSourceTag}
                                currency={props.productPair.currency}
                                unitOfMeasure={props.productPair.unitOfMeasure}
                                linesSeries={[
                                    {
                                        label: props.productPair.product1?.name ?? '',
                                        data: props.calculationResult?.prices1 ?? [],
                                    },
                                    {
                                        label: `${props.productPair.product2?.name} ${
                                            props.calculationResult?.basisLagPeriod &&
                                            translations.calculators.basis.basisLagPeriod[props.calculationResult?.basisLagPeriod]
                                        }`,
                                        data: props.calculationResult?.lags ?? [],
                                    },
                                ]}
                                barSeries={{
                                    label: translations.calculators.basis.basisPeriod[BasisPeriod.Basis] ?? '',
                                    data: props.calculationResult?.basis ?? [],
                                }}
                            />
                            <BasisCalculatorChart
                                title={translations.calculators.basis.charts.rollingBasisHistory}
                                dataSourceTag={dataSourceTag}
                                currency={props.productPair.currency}
                                unitOfMeasure={props.productPair.unitOfMeasure}
                                linesSeries={[
                                    {
                                        label: translations.calculators.basis.basisPeriod[BasisPeriod.ThreeMonthBasis],
                                        data: props.calculationResult?.threeMonthsBasis ?? [],
                                    },
                                    {
                                        label: translations.calculators.basis.basisPeriod[BasisPeriod.SixMonthBasis],
                                        data: props.calculationResult?.sixMonthsBasis ?? [],
                                    },
                                    {
                                        label: translations.calculators.basis.basisPeriod[BasisPeriod.NineMonthBasis],
                                        data: props.calculationResult?.nineMonthsBasis ?? [],
                                    },
                                    {
                                        label: translations.calculators.basis.basisPeriod[BasisPeriod.TwelveMonthBasis],
                                        data: props.calculationResult?.twelveMonthsBasis ?? [],
                                    },
                                ]}
                                barSeries={{
                                    label: translations.calculators.basis.basisPeriod[BasisPeriod.Basis] ?? '',
                                    data: props.calculationResult?.basis ?? [],
                                }}
                            />
                            <BasisCalculatorChart
                                title={translations.calculators.basis.charts.rollingBasisHistory}
                                dataSourceTag={dataSourceTag}
                                currency={props.productPair.currency}
                                unitOfMeasure={props.productPair.unitOfMeasure}
                                linesSeries={[
                                    {
                                        label:
                                            (props.calculationResult?.basisPeriod &&
                                                translations.calculators.basis.basisPeriod[props.calculationResult?.basisPeriod]) ??
                                            '',
                                        data:
                                            props.calculationResult?.basisPeriod === BasisPeriod.ThreeMonthBasis
                                                ? props.calculationResult?.threeMonthsBasis
                                                : props.calculationResult?.basisPeriod === BasisPeriod.SixMonthBasis
                                                ? props.calculationResult?.sixMonthsBasis
                                                : props.calculationResult?.basisPeriod === BasisPeriod.NineMonthBasis
                                                ? props.calculationResult?.nineMonthsBasis
                                                : props.calculationResult?.basisPeriod === BasisPeriod.TwelveMonthBasis
                                                ? props.calculationResult?.twelveMonthsBasis
                                                : props.calculationResult?.basis ?? [],
                                    },
                                    {
                                        label:
                                            (props.calculationResult?.basisAdjustment &&
                                                translations.calculators.basis.basisAdjustment[props.calculationResult?.basisAdjustment]) ??
                                            '',
                                        data: props.calculationResult?.metricSerie ?? [],
                                    },
                                ]}
                                barSeries={{
                                    label: translations.calculators.basis.basisPeriod[BasisPeriod.Basis] ?? '',
                                    data: props.calculationResult?.basis ?? [],
                                }}
                            />
                            <BasisCalculatorChart
                                title={translations.calculators.basis.charts.twelveMonthRollingCorrelation}
                                dataSourceTag={dataSourceTag}
                                currency={props.productPair.currency}
                                unitOfMeasure={props.productPair.unitOfMeasure}
                                linesSeries={[
                                    {
                                        label: translations.calculators.basis.charts.correlation,
                                        data: props.calculationResult?.twelveMonthRollingCorrelation ?? [],
                                    },
                                ]}
                                barSeries={{
                                    label: translations.calculators.basis.basisPeriod[BasisPeriod.Basis] ?? '',
                                    data: props.calculationResult?.basis ?? [],
                                }}
                            />
                            <BasisCalculatorChart
                                title={translations.calculators.basis.charts.basisHistory}
                                dataSourceTag={dataSourceTag}
                                currency={props.productPair.currency}
                                unitOfMeasure={props.productPair.unitOfMeasure}
                                linesSeries={[
                                    {
                                        label: props.productPair.product1?.name ?? '',
                                        data: props.calculationResult?.prices1 ?? [],
                                    },
                                    {
                                        label: `${props.productPair.product2?.name} Lag`,
                                        data: props.calculationResult?.lags ?? [],
                                    },
                                ]}
                                barSeries={{
                                    label: translations.calculators.basis.basisPeriod[BasisPeriod.Basis] ?? '',
                                    data: props.calculationResult?.basis ?? [],
                                }}
                            />
                            <BasisCalculatorChart
                                title={translations.calculators.basis.charts.basisAnalysis}
                                dataSourceTag={dataSourceTag}
                                currency={props.productPair.currency}
                                unitOfMeasure={props.productPair.unitOfMeasure}
                                linesSeries={[
                                    {
                                        label: props.productPair.product1?.name ?? '',
                                        data: props.calculationResult?.prices1 ?? [],
                                    },
                                    {
                                        label: translations.calculators.basis.charts.regression,
                                        data: props.calculationResult?.regression ?? [],
                                    },
                                ]}
                                barSeries={{
                                    label: translations.calculators.basis.basisPeriod[BasisPeriod.Basis] ?? '',
                                    data: props.calculationResult?.basis ?? [],
                                }}
                            />
                        </Carousel>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BasisCalculatorChartCarousel;
