enum MonthNamesEnum {
    January,
    February,
    March,
    April,
    May,
    June,
    July,
    August,
    September,
    October,
    November,
    December,
}

const monthNamesArray = [
    MonthNamesEnum.January,
    MonthNamesEnum.February,
    MonthNamesEnum.March,
    MonthNamesEnum.April,
    MonthNamesEnum.May,
    MonthNamesEnum.June,
    MonthNamesEnum.July,
    MonthNamesEnum.August,
    MonthNamesEnum.September,
    MonthNamesEnum.October,
    MonthNamesEnum.November,
    MonthNamesEnum.December,
];

export { MonthNamesEnum, monthNamesArray };
