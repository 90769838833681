import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import applicationSettings from '../../../Core/Settings/ApplicationSettings';
import applicationConstants from '../../../Core/Utility/ApplicationConstants';
import NavigationRoutes from '../../../Layouts/NavigationRoutes';
import { useApplicationSelector } from '../../../Redux/ReduxStore';
import { selectUserTokens } from '../../../Redux/Slices/UserSlice';
import useUserSessionsApi from '../../Apis/Hooks/useUserSessionApiHook';
import LinkButton, { LinkButtonType } from '../../Components/Form/Buttons/LinkButton';
import EmailLink from '../../Components/Navigation/EmailLink/EmailLink';
import browserService from '../../Services/BrowserService';
import { translationKeys } from '../../Services/Language/TranslationDefinitions';
import useLanguage from '../../Services/Language/useLanguageHook';
import styles from './SessionPage.module.scss';

const SessionExpirationPage: React.FC = () => {
    const [, userSession, , refreshSession] = useUserSessionsApi(true);
    const userTokens = useApplicationSelector(selectUserTokens);
    const navigate = useNavigate();
    const [, translate, language] = useLanguage();
    const [loading, setLoading] = useState(true);

    const contactList = useMemo(
        () => [
            { label: translate(translationKeys.registration.text.chicagoTelephone), number: applicationSettings.telephoneNumbers.chicago },
            { label: translate(translationKeys.registration.text.singaporeTelephone), number: applicationSettings.telephoneNumbers.singapore },
            { label: translate(translationKeys.registration.text.dublinTelephone), number: applicationSettings.telephoneNumbers.dublin },
        ],
        [language],
    );

    useEffect(() => {
        if (!userTokens?.accessToken) {
            if (!applicationSettings.isMono) {
                // When you are logged out in standalone mode, just route the user to the home page.
                navigate(NavigationRoutes.Home);
            } else {
                // When you are logged in Mono, this mean you somehow refreshed the page with this url,
                // we need to send you back to the dashboard to start over and re-directed to this page
                // properly.
                navigate(NavigationRoutes.Dashboard, { replace: true });
            }
        }

        browserService.deleteLocalStorageItem(applicationConstants.SessionTokenHeader);
        setLoading(false);
    }, []);

    useEffect(() => {
        if (!userSession) {
            return;
        }

        navigate(NavigationRoutes.Dashboard);
    }, [userSession]);

    if (loading) {
        return null;
    }

    return (
        <div className={styles.session_in_use_container}>
            <div className={styles.session_in_use_block}>
                <LinkButton
                    onClick={() => refreshSession(true)}
                    title={translate(translationKeys.sessionExpiration.reloadSession)}
                    type={LinkButtonType.Blue}
                    testId="ClosePreviousSessionButton"
                />
            </div>
            <p className={styles.session_in_use_p}>
                <p className={styles.session_in_use_contact_header}>{translate(translationKeys.sessionExpiration.loggedInOnAnotherDevice)} </p>
                <p className={styles.session_in_use_contact_header}>{translate(translationKeys.sessionExpiration.clickClosePreviousSession)} </p>
                <div className={styles.session_in_use_contact_header_sentence}>
                    <p className={styles.session_in_use_contact_header}>{translate(translationKeys.sessionExpiration.forQuestionsContactUs)} </p>
                    <EmailLink linkText={applicationSettings.emails.contactEmail} email={applicationSettings.emails.contactEmail} />
                    <p className={styles.session_in_use_contact_header}>{translate(translationKeys.registration.text.callTheNumber)} </p>
                </div>
            </p>

            <div className={styles.session_in_use_contact_container}>
                {contactList.map((x) => (
                    <div key={x.number} className={styles.session_in_use_contact}>
                        <p className={styles.session_in_use_contact_label}>{x.label}</p>
                        <p>{x.number}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default SessionExpirationPage;
