import { Autocomplete, TextField } from '@mui/material';
import React, { useState } from 'react';
import InputContainer from './InputContainer';
import styles from './Inputs.module.scss';

export type AutoCompleteOption = { label: string };

interface IAutoCompleteInputProps {
    title: string;
    labelValue: string | undefined | null;
    options: AutoCompleteOption[];
    handleTextChange: (labelValue: string) => void;
    required?: boolean;
    errorMessage?: string;
}

const AutoCompleteInput: React.FC<IAutoCompleteInputProps> = (props: IAutoCompleteInputProps) => {
    const [isValid, setIsValid] = useState<boolean>(true);
    const selectedValue = props.options.find((option) => option.label === props.labelValue) ?? null;

    const handleChange = (value: string): void => {
        props.handleTextChange(value ?? '');

        if (props.labelValue) {
            setIsValid(true);
        }
    };

    const handleBlur = (): void => {
        if (props.required) {
            setIsValid(!!props.labelValue);
        }
    };

    return (
        <>
            <InputContainer title={props.title}>
                <Autocomplete
                    className={isValid ? styles.input_auto_complete : styles.input_auto_complete_error}
                    onInputChange={(_event, value) => handleChange(value)}
                    value={selectedValue}
                    disablePortal
                    clearOnBlur={false}
                    options={props.options}
                    renderInput={(params) => <TextField {...params} />}
                    onBlur={handleBlur}
                />
            </InputContainer>
            {!isValid && props.errorMessage && <p className={styles.input_error_message}>{props.errorMessage}</p>}
        </>
    );
};

export default AutoCompleteInput;
