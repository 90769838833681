import React from 'react';
import useLanguage from '../../../Services/Language/useLanguageHook';
import ActionModal from '../ActionModal/ActionModal';
import styles from './ConfirmModal.module.scss';

interface IConfirmModalProps {
    header: string;
    showModal: boolean;
    message: string;
    handleConfirm: () => void;
    handleCancel: () => void;
}
const ConfirmModal: React.FC<IConfirmModalProps> = (props: IConfirmModalProps) => {
    const [translations] = useLanguage();

    return (
        <ActionModal
            header={props.header}
            showModal={props.showModal}
            actionButtonName={translations.actions.confirm}
            handleAction={props.handleConfirm}
            handleCancel={props.handleCancel}
        >
            <div className={styles.alert_modal_message_container}>
                <h2>{props.message}</h2>
            </div>
        </ActionModal>
    );
};

export default ConfirmModal;
