import { useOktaAuth } from '@okta/okta-react';
import { Link } from 'react-router-dom';
import applicationSettings from '../../Core/Settings/ApplicationSettings';
import { useApplicationDispatch } from '../../Redux/ReduxStore';
import { resetUser } from '../../Redux/Slices/UserSlice';
import PageLoadingSpinner from '../../Refactor/Components/LoadingSpinner/PageLoadingSpinner';
import NavigationRoutes from '../NavigationRoutes';

const StandaloneHomePage = () => {
    const dispatch = useApplicationDispatch();
    const { oktaAuth, authState } = useOktaAuth();

    const handleLogin = async () => oktaAuth.signInWithRedirect();
    const handleLogout = async () => {
        oktaAuth.signOut({ postLogoutRedirectUri: applicationSettings.okta.postLogoutRedirectUri });

        dispatch(resetUser());
    };

    if (!authState) {
        return <PageLoadingSpinner />;
    }

    if (!authState.isAuthenticated) {
        return (
            <div>
                <p>StoneX Plus</p>
                <p>Please log in to continue.</p>
                <button type="button" onClick={handleLogin}>
                    Login
                </button>
            </div>
        );
    }

    return (
        <div>
            <p>StoneX Plus</p>
            <p>
                <Link to={NavigationRoutes.Dashboard}>Start Application</Link>
            </p>
            <button type="button" onClick={handleLogout}>
                Logout
            </button>
        </div>
    );
};

export default StandaloneHomePage;
