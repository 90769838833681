import { useEffect, useMemo, useState } from 'react';
import {
    DemeterCommodity,
    DemeterDataSource,
    DemeterMarket,
    DemeterRegion,
    DemeterSubRegion,
    DemeterTableDefinitionType,
} from '../../../../../Generated/Raven-Demeter';
import useTableDefinitionsApi from '../../../../Apis/Hooks/useTableDefinitionsApiHook';
import SelectInput from '../../../../Components/Form/Inputs/SelectInput';
import useLanguage from '../../../../Services/Language/useLanguageHook';
import styles from './MarketIndicatorActions.module.scss';

// Defaults.
const defaultMarket = DemeterMarket.Energy;

interface IMarketIndicatorActionsProps {
    region: DemeterRegion;
    subRegion?: DemeterSubRegion;
    commodity: DemeterCommodity;
    dataSource?: DemeterDataSource;
    handleChange: (
        region: DemeterRegion,
        subRegion: DemeterSubRegion | undefined,
        commodity: DemeterCommodity,
        dataSource: DemeterDataSource | undefined,
    ) => void;
}

const MarketIndicatorBasisLocation: React.FC<IMarketIndicatorActionsProps> = (props: IMarketIndicatorActionsProps) => {
    // Application hooks
    const [translations, translate] = useLanguage();

    // Form request.
    const [selection, setSelection] = useState<{
        region: DemeterRegion;
        subRegion?: DemeterSubRegion;
        commodity: DemeterCommodity;
        dataSource?: DemeterDataSource;
    }>({
        region: props.region,
        subRegion: props.subRegion,
        commodity: props.commodity,
        dataSource: props.dataSource,
    });

    // Api hooks.
    const tableDefinitions = useTableDefinitionsApi(DemeterTableDefinitionType.CommodityPricesTable, true, defaultMarket);
    const regionOptions = useMemo(() => {
        if (!tableDefinitions) {
            return [];
        }

        const regionDefinitions = tableDefinitions.map((definition) => ({
            label: translate(definition.displayName),
            value: definition.region,
        }));

        return regionDefinitions;
    }, [tableDefinitions]);

    const commodityOptions = useMemo(() => {
        if (!tableDefinitions) {
            return [];
        }

        return (
            tableDefinitions!
                .find((x) => x.region === selection.region)
                ?.demeterTableDefinitionGroups.map((tableDefinitionGroup) =>
                    tableDefinitionGroup.demeterTableDefinitions
                        .filter((x) => x.commodity)
                        .map((x) => ({
                            label: `${translate(tableDefinitionGroup.displayName)} - ${translate(x.displayName)}`,
                            value: {
                                subRegion: x.subRegions?.length > 0 ? x.subRegions[0] : undefined,
                                commodity: x.commodity!,
                                dataSource: x.extraParameters as DemeterDataSource | undefined,
                            },
                        })),
                )
                .flat() ?? []
        );
    }, [tableDefinitions, selection.region]);

    useEffect(() => {
        props.handleChange(selection.region, selection.subRegion, selection.commodity, selection.dataSource);
    }, [selection]);

    return (
        <>
            <div className={styles.market_indicator_actions_modal_input_wrapper}>
                <SelectInput
                    title={translations.marketIndicatorsManagement.fields.region}
                    options={regionOptions}
                    value={selection.region}
                    handleOptionChange={(value) => setSelection({ ...selection, region: value ?? props.region })}
                />
            </div>
            <div className={styles.market_indicator_actions_modal_input_wrapper}>
                <SelectInput
                    title={translations.marketIndicatorsManagement.fields.commodity}
                    value={commodityOptions
                        ?.map((x) => x.value)
                        ?.find((x) => x.subRegion === selection.subRegion && x.commodity === selection.commodity && x.dataSource === selection.dataSource)}
                    options={commodityOptions}
                    handleOptionChange={(value) =>
                        setSelection({
                            ...selection,
                            subRegion: value.subRegion,
                            commodity: value.commodity,
                            dataSource: value.dataSource,
                        })
                    }
                />
            </div>
        </>
    );
};

export default MarketIndicatorBasisLocation;
