import { LightstreamerClient, Subscription } from 'lightstreamer-client-web/lightstreamer-core.esm';
import loggingService from '../../Core/Logging/LoggingService';
import applicationSettings from '../../Core/Settings/ApplicationSettings';
import { MarketPriceModel, updateMarketPrices, updateMarketPricesDebounced } from '../../Redux/Slices/MarketPricesSlice';
import applicationInitializationService from '../ApplicationInitializationService';

class LightstreamerMarketPricesService {
    lightstreamerClient: any;

    lightstreamerSubscription: any;

    storeIdToCodesMap: { [key: string]: string[] } = {};

    accessToken: string = '';

    symbolToMarketPriceMap: { [key: string]: MarketPriceModel } = {};

    cachedMarketPriceUpdates: MarketPriceModel[] = [];

    updateInterval?: NodeJS.Timer;

    statusCheckInterval?: NodeJS.Timer;

    today = new Date();

    private adapterSet = 'MARKET_PRICES';

    private dataAdapter = 'MARKET_PRICES_DATA_PROVIDER';

    private lighstreamerStatusCodes = {
        connecting: ['CONNECTING', 'CONNECTED:STREAM-SENSING'],
        connected: ['CONNECTED:STREAM-SENSING', 'CONNECTED:WS-STREAMING', 'CONNECTED:HTTP-STREAMING', 'CONNECTED:WS-POLLING', 'CONNECTED:HTTP-POLLING'],
        disconnected: ['STALLED', 'DISCONNECTED:WILL-RETRY', 'DISCONNECTED:TRYING-RECOVERY', 'DISCONNECTED'],
    };

    private dataFields = [
        'ReutersInstrumentCode',
        'Currency',
        'ExpirationDate',
        'ExchangeSymbol',
        'SettlementPrice',
        'OpenPrice',
        'LatestPrice',
        'HighPrice',
        'LowPrice',
        'BidPrice',
        'BidSize',
        'AskPrice',
        'AskSize',
        'PercentChange',
        'NetChange',
        'Volume',
        'OpenInterest',
        'OpenInterestNetChange',
        'LastSessionVolume',
        'LastSessionHighPrice',
        'LastSessionLowPrice',
        'LatestPriceAsOfDate',
        'SettlementPriceAsOfDate',
        'SettlementNetChange',
        'TradeRegistrationPrice',
        'TradeRegistrationVolume',
    ];

    start = (storeId: string, reutersInstrumentCodes: string[], doNotRemoveExistingCodes = false): void => {
        try {
            const codes = reutersInstrumentCodes.filter((x) => !!x);
            this.storeIdToCodesMap[storeId] = doNotRemoveExistingCodes ? [...(this.storeIdToCodesMap[storeId] ?? []), ...codes] : codes;

            // Stop any existing subscriptions.
            if (this.lightstreamerSubscription) {
                try {
                    this.lightstreamerClient?.unsubscribe(this.lightstreamerSubscription);
                } catch (error) {
                    loggingService.trackException(error as Error, 'Error unsubscribing from Lightstreamer.');
                }
            }

            if (!this.lightstreamerClient) {
                this.accessToken = applicationInitializationService.getStore().getState()?.user.accessToken ?? '';

                // Start a new connection with all of the codes.
                this.lightstreamerClient = new LightstreamerClient(applicationSettings.api?.ravenLightstreamerUri, this.adapterSet);
                this.lightstreamerClient.connectionDetails.setUser(applicationInitializationService.getStore().getState()?.user.userGuid ?? '');
                this.lightstreamerClient.connectionDetails.setPassword(this.accessToken);

                this.lightstreamerClient.connect();

                loggingService.trackTrace('Starting Lightstreamer connection.');
            }

            this.lightstreamerSubscription = new Subscription('MERGE', this.getAllCodes(), this.dataFields);
            this.lightstreamerSubscription.setDataAdapter(this.dataAdapter);
            this.lightstreamerSubscription.setRequestedSnapshot('yes');

            this.lightstreamerSubscription.addListener({
                onItemUpdate: (item: any) => {
                    const marketPrice: MarketPriceModel = {
                        reutersInstrumentCode: item.getValue('ReutersInstrumentCode'),
                        expirationDate: item.getValue('ExpirationDate'),
                        exchangeSymbol: item.getValue('ExchangeSymbol'),
                        currency: item.getValue('Currency'),
                        latestPriceAsOfDate: item.getValue('LatestPriceAsOfDate'),
                        settlementPriceAsOfDate: item.getValue('SettlementPriceAsOfDate'),
                        settlementPrice: LightstreamerMarketPricesService.toNumber(item.getValue('SettlementPrice')),
                        openPrice: LightstreamerMarketPricesService.toNumber(item.getValue('OpenPrice')),
                        latestPrice: LightstreamerMarketPricesService.toNumber(item.getValue('LatestPrice')),
                        highPrice: LightstreamerMarketPricesService.toNumber(item.getValue('HighPrice')),
                        lowPrice: LightstreamerMarketPricesService.toNumber(item.getValue('LowPrice')),
                        bidPrice: LightstreamerMarketPricesService.toNumber(item.getValue('BidPrice')),
                        bidSize: LightstreamerMarketPricesService.toNumber(item.getValue('BidSize')),
                        askPrice: LightstreamerMarketPricesService.toNumber(item.getValue('AskPrice')),
                        askSize: LightstreamerMarketPricesService.toNumber(item.getValue('AskSize')),
                        percentChange: LightstreamerMarketPricesService.toNumber(item.getValue('PercentChange')),
                        netChange: LightstreamerMarketPricesService.toNumber(item.getValue('NetChange')),
                        volume: LightstreamerMarketPricesService.toNumber(item.getValue('Volume')),
                        openInterest: LightstreamerMarketPricesService.toNumber(item.getValue('OpenInterest')),
                        openInterestNetChange: LightstreamerMarketPricesService.toNumber(item.getValue('OpenInterestNetChange')),
                        lastSessionVolume: LightstreamerMarketPricesService.toNumber(item.getValue('LastSessionVolume')),
                        lastSessionHighPrice: LightstreamerMarketPricesService.toNumber(item.getValue('LastSessionHighPrice')),
                        lastSessionLowPrice: LightstreamerMarketPricesService.toNumber(item.getValue('LastSessionLowPrice')),
                        settlementNetChange: LightstreamerMarketPricesService.toNumber(item.getValue('SettlementNetChange')),
                        tradeRegistrationPrice: LightstreamerMarketPricesService.toNumber(item.getValue('TradeRegistrationPrice')),
                        tradeRegistrationVolume: LightstreamerMarketPricesService.toNumber(item.getValue('TradeRegistrationVolume')),
                    };

                    // Debugging EEX data, going to remove once we figure out the issue.
                    if (['EUR='].some((x) => marketPrice.reutersInstrumentCode.startsWith(x))) {
                        loggingService.trackTrace(`Lightsteamer data: ${marketPrice.reutersInstrumentCode}`, { ...marketPrice });
                    }

                    this.cachedMarketPriceUpdates.push(marketPrice);
                    this.symbolToMarketPriceMap[marketPrice.reutersInstrumentCode] = marketPrice;
                },
            });

            this.lightstreamerClient.subscribe(this.lightstreamerSubscription);

            loggingService.trackTrace(`Starting Lightstreamer subscription: ${this.getAllCodes()}`);

            if (this.updateInterval) {
                clearInterval(this.updateInterval);
            }

            if (this.statusCheckInterval) {
                clearInterval(this.statusCheckInterval);
            }

            // We only updates the stores on an interval as to not cause too many re-renders.
            this.updateInterval = setInterval(() => {
                if (this.cachedMarketPriceUpdates.length > 0) {
                    const updates = [...this.cachedMarketPriceUpdates];

                    this.cachedMarketPriceUpdates = [];
                    this.getAllStoreIds().forEach((updatedStoreId) => {
                        applicationInitializationService.getStore(updatedStoreId).dispatch(updateMarketPrices(updates));
                        applicationInitializationService.getStore(updatedStoreId).dispatch(updateMarketPricesDebounced());
                    });
                }
            }, 250);

            // Check the status on an interval.
            let counter = 0;
            this.statusCheckInterval = setInterval(() => {
                const status = this.lightstreamerClient?.getStatus();

                if (!this.lighstreamerStatusCodes.connected.some((x) => x === status)) {
                    // Only log non-connected statuses on interval.
                    loggingService.trackTrace(`Lightstreamer status: ${status}`);

                    // TODO: If status is disconnected, then reconnect.
                    // So far lightstreamer has been able to reconnect itself, we have to test further to see if we catch other issues.
                } else if (counter % 10 === 0) {
                    // Every 10th interval, check the access token.
                    counter = 0; // Prevent number overflow.

                    // Check if the access token changed and update it. Reconnections usually use a session id internal
                    // to lightstreamer, however if the server is restarted, the session id is lost and the access token
                    // is used instead to re-establish the connection.
                    const newAccessToken = applicationInitializationService.getStore().getState()?.user.accessToken ?? '';
                    if (newAccessToken !== this.accessToken) {
                        this.accessToken = newAccessToken;
                        this.lightstreamerClient.connectionDetails.setPassword(this.accessToken);
                    }
                }

                counter += 1;
            }, 1000);
        } catch (error) {
            loggingService.trackException(error as Error, `Error starting Lighstreamer connection to ${this.adapterSet}/${this.dataAdapter}.`);
        }
    };

    stop = (storeId: string): void => {
        try {
            delete this.storeIdToCodesMap[storeId];

            // If there are no more codes to retrieve, stop the master connection, otherwise, keep using it.
            if (this.getAllCodes().length === 0) {
                if (this.lightstreamerSubscription) {
                    this.lightstreamerClient?.unsubscribe(this.lightstreamerSubscription);
                    this.lightstreamerSubscription = undefined;
                }

                this.lightstreamerClient?.disconnect();
                this.lightstreamerClient = undefined;

                if (this.updateInterval) {
                    clearInterval(this.updateInterval);
                    this.updateInterval = undefined;
                }

                if (this.statusCheckInterval) {
                    clearInterval(this.statusCheckInterval);
                    this.statusCheckInterval = undefined;
                }
            }
        } catch (error) {
            loggingService.trackException(error as Error, `Error stopping Lighstreamer connection to ${this.adapterSet}/${this.dataAdapter}.`);
        }
    };

    private getAllStoreIds = (): string[] => Object.entries(this.storeIdToCodesMap).flatMap(([key]) => key);

    private getAllCodes = (): string[] => Object.entries(this.storeIdToCodesMap).flatMap(([, value]) => value);

    private static toNumber = (value: any): number | undefined => (value === null || value === undefined ? undefined : +value);
}

const lightstreamerMarketPricesService = new LightstreamerMarketPricesService();

export default lightstreamerMarketPricesService;
