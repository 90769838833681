import Tooltip from '@mui/material/Tooltip';
import additionSymbol from '../../../../Assets/Icons/additionSymbol.svg';
import styles from './IndicatorsManagementButton.module.scss';

interface IIndicatorsManagementButtonProps {
    tooltipText?: string;
    handleClick: () => void;
}

const IndicatorsManagementButton: React.FC<IIndicatorsManagementButtonProps> = (props: IIndicatorsManagementButtonProps) => (
    <Tooltip title={props.tooltipText} placement="right-start">
        <button type="button" onClick={props.handleClick} className={styles.indicators_management_button}>
            <img src={additionSymbol} alt="#" />
        </button>
    </Tooltip>
);

export default IndicatorsManagementButton;
