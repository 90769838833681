import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useMemo } from 'react';
import applicationConstants from '../../../../Core/Utility/ApplicationConstants';
import useLanguage from '../../../Services/Language/useLanguageHook';
import { ApiListResponse } from './ApiListResponse';
import styles from './TableWrapper.module.scss';

interface IPaginationProps {
    apiListResponse: ApiListResponse;
    handlePagination: (updatedPagination: ApiListResponse) => void;
}

type PaginationDirection = 'previous' | 'next';

const Pagination = (props: IPaginationProps) => {
    const [translations] = useLanguage();
    const { totalCount } = props.apiListResponse;
    const start = useMemo(() => (props.apiListResponse.skip ?? 0) + 1, [props.apiListResponse]);
    const take = useMemo(() => props.apiListResponse.take ?? applicationConstants.ItemsPerPage, [props.apiListResponse]);

    const getPageRange = (): string => {
        let end = start + (take ?? applicationConstants.ItemsPerPage) - 1;
        if (totalCount && end > totalCount) {
            end = totalCount;
        }

        return `${start} - ${end}`;
    };

    const handlePaginationClick = (direction: PaginationDirection): void => {
        const newSkip = direction === 'next' ? (props.apiListResponse.skip ?? 0) + take : Math.max(0, (props.apiListResponse.skip ?? 0) - take);

        if (newSkip > (totalCount ?? 0)) {
            return;
        }

        const newApiListResponse: ApiListResponse = {
            ...props.apiListResponse,
            skip: newSkip,
        };

        props.handlePagination(newApiListResponse);
    };

    return (
        <div className={styles.table_wrapper}>
            <div className={styles.table_wrapper_table_container}>
                <div className={styles.table_wrapper_pagination_control_box}>
                    <div className={styles.table_wrapper_pagination_control_box_text}>
                        <div className={styles.table_wrapper_pages_count}>
                            {getPageRange()} {translations.words.of} {totalCount}
                        </div>
                        <button className={styles.table_wrapper_transparent_button} type="button" onClick={() => handlePaginationClick('previous')}>
                            <KeyboardArrowLeftIcon className={styles.table_wrapper_pagination_control} />
                        </button>
                        <button className={styles.table_wrapper_transparent_button} type="button" onClick={() => handlePaginationClick('next')}>
                            <KeyboardArrowRightIcon className={styles.table_wrapper_pagination_control} />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Pagination;
