export enum EventActionsEnum {
    ChartInteraction = 'Chart Interaction',
    ModalInteraction = 'Modal Interaction',
    Navigation = 'Navigation',
    MenuSelection = 'Menu Selection',
    ButtonClick = 'Button Click',
    DropdownSelection = 'Dropdown Selection',
    TableInteraction = 'Table Interaction',
    UserDenied = 'User Denied',
    GeneralInfo = 'General Info',
    AppIssue = 'App Issue',
    SeasonalChartMultiselect = 'Seasonal Chart Multiselect',
    ChartMultiselect = 'Chart Multiselect',
    DatePickerInteraction = 'Date Picker Interaction',
    UserAuthenticated = 'User Passed Authentication',
    OutlookSelection = 'Outlook and Reports Link Clicked',
    ToggleClicked = 'Toggle Clicked',
}

export enum EventCategoriesEnum {
    AddChartRowFromTable = 'Chart Item Added from Table',
    AddedYears = 'Added Years',
    AddedYearsToSeasonalDataTables = 'Added additional years to seasonal tables',
    CommodityChange = 'Commodity Change',
    CommodityGroupChange = 'Commodity Group Change',
    DataSourceChange = 'Data Source Change',
    DateTimeChanged = 'Date Picker Date Chose',
    ExchangeChange = 'Exchange Change',
    FilterTimeSpanSelected = 'Length of time selected',
    ForcastDataToggled = 'Forecast Data Toggled',
    HideOrDisplayData = 'Hide Or Display Data',
    JoinedButtonsClicked = 'Joined Button Elements Clicked',
    LegendItemClicked = 'Legend Item Clicked',
    LocationNotAvailable = 'Location not available!',
    ManageModalClose = 'Manage Modal Closed',
    ManageModalOpen = 'Manage Modal Opened',
    PopoutChartModalClose = 'Chart Popout Modal Closed',
    PopoutChartModalOpen = 'Chart Popout Modal Opened',
    RegionChange = 'Region Change',
    RemoveChartRowFromTable = 'Chart Item Removed from Table',
    RemovedYearsFromSeasonalDataTables = 'Removed Years From Seasonal Data Tables',
    SubMenuSelection = 'Sub-menu Selection',
    SubRegionChange = 'Sub-region Change',
    Success = 'Success',
    UnauthenticatedUserPageVisit = 'User visited secured page without credentials.',
    UserDeniedLocationPermission = 'User has denied location permission.',
    UserMarketUpdated = 'User Market Updated.',
}

export enum EventDataTargetsEnum {
    BarChart = 'Bar Chart',
    CommodityPriceTable = 'Commodity Price Table',
    ExchangesMenu = 'Exchanges Menu',
    ForwardCurveGraph = 'Forward Curve Graph',
    LightStreamerChart = 'Light Streamer Chart',
    LightStreamerGraph = 'Light Streamer Graph',
    MangeModal = 'Manage Modal',
    OutlookLink = 'Outlook Link',
    PhysicalPricesChart = 'Physical Prices Chart',
    PhysicalPricesGraph = 'Physical Prices Graph',
    PriceGraph = 'Price Graph',
    PriceCurveGraph = 'Price Curve Graph',
    ProjectionGraph = 'Projection Graph',
    RegionsAndCommoditiesMenu = 'Regions and Commodities Menu',
    SeasonalGraph = 'Seasonal Graph',
    SeasonalDataTables = 'Seasonal Data Tables',
    SelectionTable = 'Selection Table',
    Sidebar = 'Sidebar',
    SeasonalTable = 'Seasonal Table',
}
