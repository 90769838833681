import { useMemo } from 'react';
import { SearchParameters } from '../../../../Redux/Slices/SystemSlice';
import useSearchParameters from './useSearchParametersHook';

export interface IRegionCommoditySelection extends SearchParameters {
    region: string;
    subRegion: string;
    commodity: string;
    extraParameters: string;
    dataFrequency: string;
}

const useRegionCommodityNavigation = (): IRegionCommoditySelection => {
    const [searchParameters] = useSearchParameters();

    const regionCommoditySelection = useMemo<IRegionCommoditySelection>(() => {
        const value: IRegionCommoditySelection = {
            region: searchParameters.region ?? '',
            subRegion: searchParameters.subRegion ?? '',
            commodity: searchParameters.commodity ?? '',
            extraParameters: searchParameters.extraParameters ?? '',
            dataFrequency: searchParameters.dataFrequency ?? '',
        };

        value.searchString = Object.keys(value).reduce(
            (searchString, key) => (searchString === '' ? `${key}=${value[key]}` : `${searchString}&${key}=${value[key]}`),
            '',
        );

        return value;
    }, [searchParameters]);

    return regionCommoditySelection;
};

export default useRegionCommodityNavigation;
