import applicationSettings from '../../../Core/Settings/ApplicationSettings';
import { DemeterRegion } from '../../../Generated/Raven-Demeter';

class LocationService {
    defaultLocation = DemeterRegion.EuropeanUnion;

    getUserLocationFromBrowser = async (): Promise<{ location: DemeterRegion; rawLocation: string } | undefined> => {
        try {
            const geoLocationDataPromise = await fetch(`https://geolocation-db.com/json/${applicationSettings.geolocationApiKey}}`);
            if (!geoLocationDataPromise.ok) {
                return undefined;
            }

            const newLocationData = await geoLocationDataPromise.json();
            return { location: this.demeterContinentMap[newLocationData.country_code], rawLocation: newLocationData };
        } catch (exception) {
            return undefined;
        }
    };

    private demeterContinentMap: { [key: string]: DemeterRegion } = {
        // seed mappings: https://gist.github.com/nobuti/3816985
        AF: DemeterRegion.Oceania, // "Islamic Republic of Afghanistan")
        AX: DemeterRegion.EuropeanUnion, // "Åland Islands")
        AL: DemeterRegion.EuropeanUnion, // "Republic of Albania")
        AS: DemeterRegion.Oceania, // "American Samoa")
        AD: DemeterRegion.EuropeanUnion, // "Principality of Andorra")
        AI: DemeterRegion.UnitedStates, // "Anguilla")
        AQ: DemeterRegion.Antarctica, // "Antarctica (the territory South of 60 deg S)")
        AG: DemeterRegion.UnitedStates, // "Antigua and Barbuda")
        AR: DemeterRegion.UnitedStates, // "Argentine Republic")
        AM: DemeterRegion.Oceania, // "Republic of Armenia")
        AW: DemeterRegion.UnitedStates, // "Aruba")
        AU: DemeterRegion.Oceania, // "Commonwealth of Australia")
        AT: DemeterRegion.EuropeanUnion, // "Republic of Austria")
        AZ: DemeterRegion.Oceania, // "Republic of Azerbaijan")
        BS: DemeterRegion.UnitedStates, // "Commonwealth of the Bahamas")
        BH: DemeterRegion.Oceania, // "Kingdom of Bahrain")
        BD: DemeterRegion.Oceania, // "People's Republic of Bangladesh")
        BB: DemeterRegion.UnitedStates, // "Barbados")
        BY: DemeterRegion.EuropeanUnion, // "Republic of Belarus")
        BE: DemeterRegion.EuropeanUnion, // "Kingdom of Belgium")
        BZ: DemeterRegion.UnitedStates, // "Belize")
        BM: DemeterRegion.UnitedStates, // "Bermuda")
        BT: DemeterRegion.Oceania, // "Kingdom of Bhutan")
        BO: DemeterRegion.UnitedStates, // "Plurinational State of Bolivia")
        BQ: DemeterRegion.UnitedStates, // '535'
        BA: DemeterRegion.EuropeanUnion, // "Bosnia and Herzegovina")
        BV: DemeterRegion.Antarctica, // "Bouvet Island (Bouvetoya)")
        BR: DemeterRegion.UnitedStates, // "Federative Republic of Brazil")
        IO: DemeterRegion.Oceania, // "British Indian Ocean Territory (Chagos Archipelago)")
        VG: DemeterRegion.UnitedStates, // "British Virgin Islands")
        BN: DemeterRegion.Oceania, // "Brunei Darussalam")
        BG: DemeterRegion.EuropeanUnion, // "Republic of Bulgaria")
        KH: DemeterRegion.Oceania, // "Kingdom of Cambodia")
        CA: DemeterRegion.UnitedStates, // "Canada")
        KY: DemeterRegion.UnitedStates, // "Cayman Islands")
        CL: DemeterRegion.UnitedStates, // "Republic of Chile")
        CN: DemeterRegion.Oceania, // "People's Republic of China")
        CX: DemeterRegion.Oceania, // "Christmas Island")
        CC: DemeterRegion.Oceania, // "Cocos (Keeling) Islands")
        CO: DemeterRegion.UnitedStates, // "Republic of Colombia")
        CK: DemeterRegion.Oceania, // "Cook Islands")
        CR: DemeterRegion.UnitedStates, // "Republic of Costa Rica")
        HR: DemeterRegion.EuropeanUnion, // "Republic of Croatia")
        CU: DemeterRegion.UnitedStates, // "Republic of Cuba")
        CW: DemeterRegion.UnitedStates, // "Curaçao")
        CY: DemeterRegion.Oceania, // "Republic of Cyprus")
        CZ: DemeterRegion.EuropeanUnion, // "Czech Republic")
        DK: DemeterRegion.EuropeanUnion, // "Kingdom of Denmark")
        DM: DemeterRegion.UnitedStates, // "Commonwealth of Dominica")
        DO: DemeterRegion.UnitedStates, // "Dominican Republic")
        EC: DemeterRegion.UnitedStates, // "Republic of Ecuador")
        SV: DemeterRegion.UnitedStates, // "Republic of El Salvador")
        EE: DemeterRegion.EuropeanUnion, // "Republic of Estonia")
        FO: DemeterRegion.EuropeanUnion, // "Faroe Islands")
        FK: DemeterRegion.UnitedStates, // "Falkland Islands (Malvinas)")
        FJ: DemeterRegion.Oceania, // "Republic of Fiji")
        FI: DemeterRegion.EuropeanUnion, // "Republic of Finland")
        FR: DemeterRegion.EuropeanUnion, // "French Republic")
        GF: DemeterRegion.UnitedStates, // "French Guiana")
        PF: DemeterRegion.Oceania, // "French Polynesia")
        TF: DemeterRegion.Antarctica, // "French Southern Territories")
        GE: DemeterRegion.Oceania, // "Georgia")
        DE: DemeterRegion.EuropeanUnion, // "Federal Republic of Germany")
        GI: DemeterRegion.EuropeanUnion, // "Gibraltar")
        GR: DemeterRegion.EuropeanUnion, // "Hellenic Republic Greece")
        GL: DemeterRegion.UnitedStates, // "Greenland")
        GD: DemeterRegion.UnitedStates, // "Grenada")
        GP: DemeterRegion.UnitedStates, // "Guadeloupe")
        GU: DemeterRegion.Oceania, // "Guam")
        GT: DemeterRegion.UnitedStates, // "Republic of Guatemala")
        GG: DemeterRegion.EuropeanUnion, // "Bailiwick of Guernsey")
        GY: DemeterRegion.UnitedStates, // "Co-operative Republic of Guyana")
        HT: DemeterRegion.UnitedStates, // "Republic of Haiti")
        HM: DemeterRegion.Antarctica, // "Heard Island and McDonald Islands")
        VA: DemeterRegion.EuropeanUnion, // "Holy See (Vatican City State)")
        HN: DemeterRegion.UnitedStates, // "Republic of Honduras")
        HK: DemeterRegion.Oceania, // "Hong Kong Special Administrative Region of China")
        HU: DemeterRegion.EuropeanUnion, // "Hungary")
        IS: DemeterRegion.EuropeanUnion, // "Republic of Iceland")
        IN: DemeterRegion.Oceania, // "Republic of India")
        ID: DemeterRegion.Oceania, // "Republic of Indonesia")
        IR: DemeterRegion.Oceania, // "Islamic Republic of Iran")
        IQ: DemeterRegion.Oceania, // "Republic of Iraq")
        IE: DemeterRegion.EuropeanUnion, // "Ireland")
        IM: DemeterRegion.EuropeanUnion, // "Isle of Man")
        IL: DemeterRegion.Oceania, // "State of Israel")
        IT: DemeterRegion.EuropeanUnion, // "Italian Republic")
        JM: DemeterRegion.UnitedStates, // "Jamaica")
        JP: DemeterRegion.Oceania, // "Japan")
        JE: DemeterRegion.EuropeanUnion, // "Bailiwick of Jersey")
        JO: DemeterRegion.Oceania, // "Hashemite Kingdom of Jordan")
        KZ: DemeterRegion.Oceania, // "Republic of Kazakhstan")
        KI: DemeterRegion.Oceania, // "Republic of Kiribati")
        KP: DemeterRegion.Oceania, // "Democratic People's Republic of Korea")
        KR: DemeterRegion.Oceania, // "Republic of Korea")
        KW: DemeterRegion.Oceania, // "State of Kuwait")
        KG: DemeterRegion.Oceania, // "Kyrgyz Republic")
        LA: DemeterRegion.Oceania, // "Lao People's Democratic Republic")
        LV: DemeterRegion.EuropeanUnion, // "Republic of Latvia")
        LB: DemeterRegion.Oceania, // "Lebanese Republic")
        LI: DemeterRegion.EuropeanUnion, // "Principality of Liechtenstein")
        LT: DemeterRegion.EuropeanUnion, // "Republic of Lithuania")
        LU: DemeterRegion.EuropeanUnion, // "Grand Duchy of Luxembourg")
        MO: DemeterRegion.Oceania, // "Macao Special Administrative Region of China")
        MK: DemeterRegion.EuropeanUnion, // "Republic of Macedonia")
        MY: DemeterRegion.Oceania, // "Malaysia")
        MV: DemeterRegion.Oceania, // "Republic of Maldives")
        MT: DemeterRegion.EuropeanUnion, // "Republic of Malta")
        MH: DemeterRegion.Oceania, // "Republic of the Marshall Islands")
        MQ: DemeterRegion.UnitedStates, // "Martinique")
        MX: DemeterRegion.UnitedStates, // "United Mexican States")
        FM: DemeterRegion.Oceania, // "Federated States of Micronesia")
        MD: DemeterRegion.EuropeanUnion, // "Republic of Moldova")
        MC: DemeterRegion.EuropeanUnion, // "Principality of Monaco")
        MN: DemeterRegion.Oceania, // "Mongolia")
        ME: DemeterRegion.EuropeanUnion, // "Montenegro")
        MS: DemeterRegion.UnitedStates, // "Montserrat")
        MM: DemeterRegion.Oceania, // "Republic of the Union of Myanmar")
        NR: DemeterRegion.Oceania, // "Republic of Nauru")
        NP: DemeterRegion.Oceania, // "Federal Democratic Republic of Nepal")
        NL: DemeterRegion.EuropeanUnion, // "Kingdom of the Netherlands")
        NC: DemeterRegion.Oceania, // "New Caledonia")
        NZ: DemeterRegion.NewZealand, // "New Zealand")
        NI: DemeterRegion.UnitedStates, // "Republic of Nicaragua")
        NU: DemeterRegion.Oceania, // "Niue")
        NF: DemeterRegion.Oceania, // "Norfolk Island")
        MP: DemeterRegion.Oceania, // "Commonwealth of the Northern Mariana Islands")
        NO: DemeterRegion.EuropeanUnion, // "Kingdom of Norway")
        OM: DemeterRegion.Oceania, // "Sultanate of Oman")
        PK: DemeterRegion.Oceania, // "Islamic Republic of Pakistan")
        PW: DemeterRegion.Oceania, // "Republic of Palau")
        PS: DemeterRegion.Oceania, // "Occupied Palestinian Territory")
        PA: DemeterRegion.UnitedStates, // "Republic of Panama")
        PG: DemeterRegion.Oceania, // "Independent State of Papua New Guinea")
        PY: DemeterRegion.UnitedStates, // "Republic of Paraguay")
        PE: DemeterRegion.UnitedStates, // "Republic of Peru")
        PH: DemeterRegion.Oceania, // "Republic of the Philippines")
        PN: DemeterRegion.Oceania, // "Pitcairn Islands")
        PL: DemeterRegion.EuropeanUnion, // "Republic of Poland")
        PT: DemeterRegion.EuropeanUnion, // "Portuguese Republic")
        PR: DemeterRegion.UnitedStates, // "Commonwealth of Puerto Rico")
        QA: DemeterRegion.Oceania, // "State of Qatar")
        RO: DemeterRegion.EuropeanUnion, // "Romania")
        RU: DemeterRegion.EuropeanUnion, // "Russian Federation")
        BL: DemeterRegion.UnitedStates, // "Saint Barthélemy")
        KN: DemeterRegion.UnitedStates, // "Federation of Saint Kitts and Nevis")
        LC: DemeterRegion.UnitedStates, // "Saint Lucia")
        MF: DemeterRegion.UnitedStates, // "Saint Martin (French part)")
        PM: DemeterRegion.UnitedStates, // "Saint Pierre and Miquelon")
        VC: DemeterRegion.UnitedStates, // "Saint Vincent and the Grenadines")
        WS: DemeterRegion.Oceania, // "Independent State of Samoa")
        SM: DemeterRegion.EuropeanUnion, // "Republic of San Marino")
        SA: DemeterRegion.Oceania, // "Kingdom of Saudi Arabia")
        RS: DemeterRegion.EuropeanUnion, // "Republic of Serbia")
        SG: DemeterRegion.Oceania, // "Republic of Singapore")
        SX: DemeterRegion.UnitedStates, // "Sint Maarten (Dutch part)")
        SK: DemeterRegion.EuropeanUnion, // "Slovakia (Slovak Republic)")
        SI: DemeterRegion.EuropeanUnion, // "Republic of Slovenia")
        SB: DemeterRegion.Oceania, // "Solomon Islands")
        GS: DemeterRegion.Antarctica, // "South Georgia and the South Sandwich Islands")
        ES: DemeterRegion.EuropeanUnion, // "Kingdom of Spain")
        LK: DemeterRegion.Oceania, // "Democratic Socialist Republic of Sri Lanka")
        SR: DemeterRegion.UnitedStates, // "Republic of Suriname")
        SJ: DemeterRegion.EuropeanUnion, // "Svalbard & Jan Mayen Islands")
        SE: DemeterRegion.EuropeanUnion, // "Kingdom of Sweden")
        CH: DemeterRegion.EuropeanUnion, // "Swiss Confederation")
        SY: DemeterRegion.Oceania, // "Syrian Arab Republic")
        TW: DemeterRegion.Oceania, // "Taiwan
        TJ: DemeterRegion.Oceania, // "Republic of Tajikistan")
        TH: DemeterRegion.Oceania, // "Kingdom of Thailand")
        TL: DemeterRegion.Oceania, // "Democratic Republic of Timor-Leste")
        TK: DemeterRegion.Oceania, // "Tokelau")
        TO: DemeterRegion.Oceania, // "Kingdom of Tonga")
        TT: DemeterRegion.UnitedStates, // "Republic of Trinidad and Tobago")
        TR: DemeterRegion.Oceania, // "Republic of Turkey")
        TM: DemeterRegion.Oceania, // "Turkmenistan")
        TC: DemeterRegion.UnitedStates, // "Turks and Caicos Islands")
        TV: DemeterRegion.Oceania, // "Tuvalu")
        UA: DemeterRegion.EuropeanUnion, // "Ukraine")
        AE: DemeterRegion.Oceania, // "United Arab Emirates")
        GB: DemeterRegion.EuropeanUnion, // "United Kingdom of Great Britain & Northern Ireland")
        US: DemeterRegion.UnitedStates, // "United States of America")
        UM: DemeterRegion.Oceania, // "United States Minor Outlying Islands")
        VI: DemeterRegion.UnitedStates, // "United States Virgin Islands")
        UY: DemeterRegion.UnitedStates, // "Eastern Republic of Uruguay")
        UZ: DemeterRegion.Oceania, // "Republic of Uzbekistan")
        VU: DemeterRegion.Oceania, // "Republic of Vanuatu")
        VE: DemeterRegion.UnitedStates, // "Bolivarian Republic of Venezuela")
        VN: DemeterRegion.Oceania, // "Socialist Republic of Vietnam")
        WF: DemeterRegion.Oceania, // "Wallis and Futuna")
        YE: DemeterRegion.Oceania, // "Yemen");
    };
}

const locationService = new LocationService();

export default locationService;
