import PageHeader from '../../Components/Headers/PageHeader';
import useLanguage from '../../Services/Language/useLanguageHook';
import styles from './DisclaimerPage.module.scss';

const year = new Date().getFullYear();

const DisclaimerPage: React.FC = () => {
    const [translations] = useLanguage();

    return (
        <div className={styles.master_page_container}>
            <div className={styles.disclaimer}>
                <PageHeader title={translations.disclaimer.title} />
                <div>
                    {translations.disclaimer.paragraph1}
                    <br />
                    &nbsp;
                    <br />
                    {translations.disclaimer.paragraph2}
                    <br />
                    &nbsp;
                    <br />
                    {translations.disclaimer.paragraph3}
                    <br />
                    &nbsp;
                    <br />
                    {translations.disclaimer.paragraph4}
                    <br />
                    &nbsp;
                    <br />
                    {translations.disclaimer.paragraph5}
                    <br />
                    &nbsp;
                    <br />
                    {translations.disclaimer.paragraph6}
                    <br />
                    &nbsp;
                    <br />
                    &copy;&nbsp;{year}&nbsp;StoneX Group Inc. All Rights Reserved
                </div>
            </div>
        </div>
    );
};

export default DisclaimerPage;
