import { useEffect, useState } from 'react';
import TextInput from '../../Components/Form/Inputs/TextInput';
import documentObjectModelService from '../../Services/DocumentObjectModelService';
import { MonthlyRiskCompositeModel, RiskRequest, TabEventKeyType } from './RiskDefinitions';
import styles from './RiskPage.module.scss';

interface IRiskCalculatorInputProps {
    riskRequest: RiskRequest;
    setRiskRequest: (riskRequest: RiskRequest) => void;
    index: number;
    fieldName: keyof MonthlyRiskCompositeModel;
    handleGridTabNavigation: (columnIndex: number, rowIndex: number, direction: TabEventKeyType) => void;
    disabled?: boolean;
    validation?: RegExp;
}

const RiskCalculatorInput: React.FC<IRiskCalculatorInputProps> = (props: IRiskCalculatorInputProps) => {
    const [textValue, setTextValue] = useState<string>('');
    const [riskVersion, setRiskVersion] = useState(1);
    const [inputUpdated, setInputUpdated] = useState(false);

    const validateText = (value: string): boolean => {
        if (!props.validation || value === '') {
            return true;
        }

        return props.validation.test(value.toLowerCase());
    };

    useEffect(() => {
        const risk: MonthlyRiskCompositeModel = props.riskRequest.risks![props.index];

        // When a new request version is made, reset the inputUpdated status.
        if (riskVersion < props.riskRequest.version) {
            setInputUpdated(false);
            setRiskVersion(props.riskRequest.version);
        }

        const value = risk ? risk[props.fieldName] : undefined;

        if (value === undefined || value === null) {
            setTextValue('');
            return;
        }

        setTextValue(value.toString());
    }, [props.riskRequest]);

    const assignValue = <T extends keyof MonthlyRiskCompositeModel>(fieldName: T, rawValue: string, value: MonthlyRiskCompositeModel[T]): void => {
        const risks = [...props.riskRequest.risks!];
        const risk: MonthlyRiskCompositeModel = risks[props.index];
        const previousValue = risk[fieldName];

        // If nothing changes then return.
        if ((previousValue === undefined && rawValue === '') || previousValue === value) {
            return;
        }

        risks[props.index] = {
            ...risk,
            ...{
                [fieldName]: value,
                isUpdatedValue: true,
            },
        };

        props.setRiskRequest({
            ...props.riskRequest,
            risks,
        });
    };

    return (
        <div className={inputUpdated ? styles.calculator_table_cell_updated : styles.calculator_table_base_column}>
            <TextInput
                className={inputUpdated ? `${styles.risk_input_updated} ${styles.risk_input}` : styles.risk_input}
                type="number"
                value={textValue}
                disabled={props.disabled}
                handleTextChange={(value) => {
                    if (!validateText(value) || value === textValue) {
                        return;
                    }

                    setTextValue(value);
                    setInputUpdated(true);
                    assignValue(props.fieldName, value, value ? +value : undefined);
                }}
                columnNumber={props.index}
                handleKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
                    if (!['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', 'Enter'].includes(event.key)) {
                        return;
                    }

                    event.preventDefault();
                    const eventTarget = event.target as HTMLElement;
                    const columnIndex = eventTarget.getAttribute('data-column-number') ?? 0;
                    const validInputElementsOfColumn = documentObjectModelService.selectElementsWithAttributeStatus('data-column-number', columnIndex, true);
                    const rowIndex = Array.from(validInputElementsOfColumn).indexOf(eventTarget);

                    props.handleGridTabNavigation(+columnIndex, rowIndex, event.key as TabEventKeyType);
                }}
            />
        </div>
    );
};

export default RiskCalculatorInput;
