import { Checkbox, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import isequal from 'lodash.isequal';
import { useEffect, useState } from 'react';
import { EventActionsEnum } from '../../../../Core/Logging/DataLayerDefinitions';
import loggingService from '../../../../Core/Logging/LoggingService';
import styles from './Inputs.module.scss';
import type { SelectInputOption } from './SelectInput';

interface IMuiDropdownProps<T> {
    values: T[];
    options: SelectInputOption<T>[];
    handleOptionSelect: (values: T[]) => void;
    label?: string;
    placeholder?: string;
    isSelectionLimit?: boolean;
    limitMessage?: string;
    disabled?: boolean;
    displayValues?: boolean;
    testId?: string;
}

// eslint-disable-next-line @typescript-eslint/comma-dangle
const CheckboxDropdown = <T,>(props: IMuiDropdownProps<T>): JSX.Element => {
    const [formValues, setFormValues] = useState<string[]>([]);

    useEffect(() => {
        const newFormValue: string[] = [];
        props.values.forEach((value) => {
            newFormValue.push(props.options.find((x) => isequal(x.value, value))?.label ?? '');
        });
        setFormValues([...newFormValue]);
    }, [props.values]);

    // If the options change, remove the values that are no longer part of the options.
    useEffect(() => {
        const newFormValue = formValues.filter((x) => props.options.some((option) => option.label === x));

        if (!isequal(newFormValue, formValues)) {
            setFormValues(newFormValue);
        }
    }, [props.options]);

    const handleOptionChange = (event: SelectChangeEvent<string[]>) => {
        const newLabels = event.target.value as string[];
        const newValues: T[] = [];
        newLabels.forEach((element: string) => {
            const option = props.options.find((x) => x.label === element)?.value;
            if (option) {
                newValues.push(option);
            }
        });

        loggingService.trackEventWithAnalytics(
            EventActionsEnum.ChartMultiselect,
            (event.target as HTMLInputElement).value,
            newValues.length > formValues.length ? 'Add' : 'Remove',
            EventActionsEnum.ChartMultiselect,
        );

        props.handleOptionSelect(newValues);
    };

    return (
        <>
            {props.label && <p className={styles.input_dropdown_label}>{props.label}</p>}
            <FormControl fullWidth sx={{ m: 1, margin: 0 }} size="small">
                {props.placeholder && <InputLabel shrink={false}>{props.placeholder}</InputLabel>}
                <Select
                    data-testid={props.testId}
                    multiple
                    className={styles.input_auto_complete}
                    value={formValues}
                    onChange={handleOptionChange}
                    renderValue={(selected) => (props.displayValues ? selected.join(', ') : '')}
                    sx={{ backgroundColor: 'white' }}
                    MenuProps={{ PaperProps: { sx: { maxHeight: 500 } } }}
                    disabled={props.disabled}
                >
                    <MenuItem value="" disabled disableRipple disableTouchRipple sx={{ color: 'black' }}>
                        <em>{props.limitMessage}</em>
                    </MenuItem>
                    {props.options.map((option) => (
                        <MenuItem
                            key={`${option.label}`}
                            value={`${option.label}`}
                            disabled={props.isSelectionLimit && !(formValues.indexOf(option.label) > -1)}
                        >
                            <Checkbox
                                checked={formValues.indexOf(option.label) > -1}
                                disabled={props.isSelectionLimit && !(formValues.indexOf(option.label) > -1)}
                            />
                            {option.label}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </>
    );
};

export default CheckboxDropdown;
