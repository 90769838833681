import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { demeterUsersApi } from '../../../Apis/Apis';
import styles from '../../../Main.module.scss';
import useApiWithoutAutoExecute from '../../Apis/Hooks/useApiWithoutAutoExecute';
import ConfirmModal from '../../Components/Modals/ConfirmModal/ConfirmModal';
import browserService from '../../Services/BrowserService';
import useLanguage from '../../Services/Language/useLanguageHook';

const CancelTrialPage: React.FC = () => {
    const [translations] = useLanguage();
    const navigate = useNavigate();

    const [, callCancelApi, cancelResponse] = useApiWithoutAutoExecute(() => demeterUsersApi.cancelDemeterSubscription(), {
        successMessage: translations.cancelTrial.text.userSuccessfullyCancelledSubscription,
        errorMessage: translations.cancelTrial.errors.failureCancelSubscription,
    });

    useEffect(() => {
        if (!cancelResponse) {
            return;
        }

        browserService.navigateToMarketIntelligence();
    }, [cancelResponse]);

    return (
        <div className={styles.master_page_container}>
            <ConfirmModal
                header={translations.cancelTrial.text.cancelTrialHeader}
                message={translations.cancelTrial.text.cancelTrialMessage}
                showModal
                handleConfirm={() => {
                    callCancelApi();
                }}
                handleCancel={() => {
                    navigate(-1);
                }}
            />
        </div>
    );
};
export default CancelTrialPage;
