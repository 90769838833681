import CheckIcon from '@mui/icons-material/Check';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Tooltip from '@mui/material/Tooltip';
import { useState } from 'react';
import { translationKeys } from '../../../Services/Language/TranslationDefinitions';
import useLanguage from '../../../Services/Language/useLanguageHook';
import IconButton from '../../Form/Buttons/IconButton';
import styles from './EmailLink.module.scss';

interface IEmailLinkProps {
    linkText: string;
    email: string;
}

const EmailLink: React.FC<IEmailLinkProps> = (props: IEmailLinkProps) => {
    const [, translate] = useLanguage();
    const [displayCheck, setDisplayCheck] = useState(false);

    return (
        <>
            <a className={styles.email_link} href={`mailto:${props.email}`}>
                {props.linkText}
            </a>
            <Tooltip
                title={
                    <div className={styles.email_copy_container}>
                        {translate(translationKeys.text.copyEmail)}
                        {displayCheck && <CheckIcon fontSize="small" />}
                    </div>
                }
                onMouseEnter={() => setDisplayCheck(false)}
            >
                <IconButton
                    handleClick={() => {
                        navigator.clipboard.writeText(props.email);
                        setDisplayCheck(true);
                    }}
                >
                    <ContentCopyIcon fontSize="small" />
                </IconButton>
            </Tooltip>
        </>
    );
};

export default EmailLink;
