import { useEffect, useMemo, useState } from 'react';
import { demeterApi, demeterUserDataApi, demeterUsersApi } from '../../../../Apis/Apis';
import {
    CommodityPriceModel,
    Currency,
    DemeterDataSource,
    DemeterDataValue,
    DemeterFilterTimeSpan,
    DemeterRegion,
    DemeterSymbolModel,
    DemeterTableDefinitionType,
    DemeterUserStoreType,
    ExchangeType,
    MarketPriceModel,
    MarketPricesTimeSpan,
    SymbolCategory,
    UnitOfMeasure,
} from '../../../../Generated/Raven-Demeter';
import useApi from '../../../Apis/Hooks/useApiHook';
import useSymbolsApi from '../../../Apis/Hooks/useSymbolsApiHook';
import useTableDefinitionsApi from '../../../Apis/Hooks/useTableDefinitionsApiHook';
import useUserStoreApi from '../../../Apis/Hooks/useUserStoreApiHook';
import LinkButton, { LinkButtonType } from '../../../Components/Form/Buttons/LinkButton';
import ComponentHeader from '../../../Components/Headers/ComponentHeader';
import PageLoadingSpinner from '../../../Components/LoadingSpinner/PageLoadingSpinner';
import useLanguage from '../../../Services/Language/useLanguageHook';
import styles from './BasisCalculator.module.scss';
import BasisCalculatorChartCarousel from './BasisCalculatorChartCarousel';
import {
    BasisAdjustment,
    BasisCalculatorProduct,
    BasisCalculatorProductPair,
    BasisLagPeriod,
    BasisPeriod,
    defaultStartDate,
    generateUniqueId,
} from './BasisCalculatorDefinitions';
import BasisCalculatorForm from './BasisCalculatorForm';
import BasisCalculatorGrid from './BasisCalculatorGrid';
import BasisCalculatorProductSelector from './BasisCalculatorProductSelector';
import BasisCalculatorService, { IBasisCalculatorCalculationResult } from './BasisCalculatorService';
import BasisCalculatorStartup from './BasisCalculatorStartup';

const defaultBasisCurrency = Currency.Usd;
const defaultBasisUnitOfMeasure = UnitOfMeasure.Kilogram;

const BasisCalculator = () => {
    const [translations] = useLanguage();
    const [productSelectorOpen, setProductSelectorOpen] = useState(false);
    const [productSelectorActiveTab, setProductSelectorActiveTab] = useState(0);
    const [products, setProducts] = useState<BasisCalculatorProduct[] | undefined>();
    const [userProducts, setUserProducts] = useState<BasisCalculatorProduct[] | undefined>();
    const [productPrices1, setProductPrices1] = useState<MarketPriceModel[] | CommodityPriceModel[] | DemeterDataValue[] | undefined>();
    const [productPrices2, setProductPrices2] = useState<MarketPriceModel[] | CommodityPriceModel[] | DemeterDataValue[] | undefined>();
    const [calculationResult, setCalculationResult] = useState<IBasisCalculatorCalculationResult | undefined>();
    const [savedProductPairs, setSavedProductPairs] = useState<BasisCalculatorProductPair[]>();
    const [productPair, setProductPair] = useState<BasisCalculatorProductPair>({
        product1: undefined,
        product2: undefined,
    });

    const symbols = useSymbolsApi();
    const tableDefinitionRegionModel = useTableDefinitionsApi(DemeterTableDefinitionType.CommodityPricesTable);
    const [userStoreValueResponse, updateUserStoreValue] = useUserStoreApi(DemeterUserStoreType.BasisCalculator);
    const [, listDemeterUserData, listDemeterUserDataResponse] = useApi(() => demeterUserDataApi.listDemeterUserData());
    const [, getDemeterUserDataProduct1, getDemeterUserDataProduct1Response] = useApi(() => {
        if (!productPair || !productPair.product1) {
            return null;
        }
        return demeterUserDataApi.getDemeterUserData(productPair.product1.id, productPair.currency!, productPair.unitOfMeasure!);
    });
    const [, getDemeterUserDataProduct2, getDemeterUserDataProduct2Response] = useApi(() => {
        if (!productPair || !productPair.product2) {
            return null;
        }
        return demeterUserDataApi.getDemeterUserData(productPair.product2.id, productPair.currency!, productPair.unitOfMeasure!);
    });

    useEffect(() => {
        if (!getDemeterUserDataProduct1Response) {
            return;
        }
        setProductPrices1(getDemeterUserDataProduct1Response.demeterUserData?.rows as DemeterDataValue[]);
    }, [getDemeterUserDataProduct1Response]);

    useEffect(() => {
        if (!getDemeterUserDataProduct2Response) {
            return;
        }
        setProductPrices2(getDemeterUserDataProduct2Response.demeterUserData?.rows as DemeterDataValue[]);
    }, [getDemeterUserDataProduct2Response]);

    const sortSymbols = (symbolsArray: DemeterSymbolModel[]): DemeterSymbolModel[] => {
        const categoryOrder = [
            SymbolCategory.Dairy,
            SymbolCategory.Energy,
            SymbolCategory.Grains,
            SymbolCategory.Livestock,
            SymbolCategory.Softs,
            SymbolCategory.Currency,
            SymbolCategory.Indices,
            SymbolCategory.Fertilizer,
        ];
        const exchangeOrder = [
            ExchangeType.Cme,
            ExchangeType.Eex,
            ExchangeType.Euronext,
            ExchangeType.Ice,
            ExchangeType.Sgx,
            ExchangeType.Mdx,
            ExchangeType.B3,
            ExchangeType.Mwe,
            ExchangeType.Otc,
            ExchangeType.Dce,
            ExchangeType.Safex,
        ];

        return symbolsArray.sort((a, b) => {
            const categoryIndexA = categoryOrder.indexOf(a.symbolCategory as SymbolCategory);
            const categoryIndexB = categoryOrder.indexOf(b.symbolCategory as SymbolCategory);

            if (categoryIndexA !== categoryIndexB) {
                if (categoryIndexA === -1) {
                    return 1;
                }
                if (categoryIndexB === -1) {
                    return -1;
                }
                return categoryIndexA - categoryIndexB;
            }
            const exchangeIndexA = exchangeOrder.indexOf(a.exchange as ExchangeType);
            const exchangeIndexB = exchangeOrder.indexOf(b.exchange as ExchangeType);
            if (exchangeIndexA !== exchangeIndexB) {
                if (exchangeIndexA === -1) {
                    return 1;
                }
                if (exchangeIndexB === -1) {
                    return -1;
                }
                return exchangeIndexA - exchangeIndexB;
            }
            return 0;
        });
    };

    useEffect(() => {
        listDemeterUserData();
    }, []);

    useEffect(() => {
        if (!listDemeterUserDataResponse) {
            return;
        }
        const responseData = listDemeterUserDataResponse.rows?.map(
            (x) =>
                ({
                    id: x.demeterUserDataGuid,
                    name: x.name,
                    currency: x.currency,
                    unitOfMeasure: x.unitOfMeasure,
                    category: 'userData',
                } as BasisCalculatorProduct),
        );
        setUserProducts(responseData);
    }, [listDemeterUserDataResponse]);

    const futureProducts = useMemo(() => {
        if (!symbols) {
            return [];
        }
        return sortSymbols(symbols)
            .filter((symbol) => symbol.symbolCategory !== SymbolCategory.Currency)
            .map((symbol) => {
                const product: BasisCalculatorProduct = {
                    id: generateUniqueId(`'futures-'${symbol.symbolCategory}-${symbol.exchange}-${symbol.displayName}`),
                    name: symbol.displayName,
                    category: 'futures',
                    market: symbol.symbolCategory,
                    reutersInstrumentCodePrefix: symbol.reutersInstrumentCodePrefix,
                    commodity: symbol.commodity,
                    region: symbol.exchange,
                    contractNumber: symbol.rollingContractNumber,
                    pricesDataSource: symbol.dataSourceTag ?? symbol.pricesDataSource,
                    currency: symbol.currency,
                    unitOfMeasure: symbol.unitOfMeasure,
                };
                return product;
            });
    }, [symbols]);

    const physicalProducts = useMemo(() => {
        const productList: BasisCalculatorProduct[] = [];
        tableDefinitionRegionModel
            ?.filter((group) => group.region !== 'All')
            .forEach((group) => {
                const market = group.market!;
                const region = group.region!;
                group.demeterTableDefinitionGroups.forEach((definitionGroup) => {
                    definitionGroup.demeterTableDefinitions.forEach((definition) => {
                        if (!productList.some((x) => x.region === region && x.commodity === definition.commodity)) {
                            productList.push({
                                id: generateUniqueId(`'physicalPrices-'${market}-${region}-${definition.displayName}`),
                                name: definition.displayName,
                                category: 'physicalPrices',
                                market,
                                commodity: definition.commodity,
                                region: DemeterRegion[region],
                                pricesDataSource: definition.extraParameters,
                            });
                        }
                    });
                });
            });
        return productList;
    }, [tableDefinitionRegionModel]);

    useEffect(() => {
        if (isProductPairDraftStored()) {
            setProductPairDraft();
        } else {
            setLastSavedPair();
        }
    }, [savedProductPairs]);

    useEffect(() => {
        setProducts([...futureProducts, ...physicalProducts]);
    }, [futureProducts, physicalProducts]);

    useEffect(() => {
        updateProductPrices1();
    }, [productPair.product1, productPair.currency, productPair.unitOfMeasure]);

    useEffect(() => {
        updateProductPrices2();
    }, [productPair.product2, productPair.currency, productPair.unitOfMeasure]);

    useEffect(() => {
        if (productPrices1 && productPrices2 && productPair.startDate && productPair.basisLagPeriod) {
            calculate();
        }
    }, [
        productPrices1,
        productPrices2,
        productPair.startDate,
        productPair.basisLagPeriod,
        productPair.basisPeriod,
        productPair.basisAdjustment,
        productPair.useRegression,
    ]);

    const isProductPairDraftStored = () => {
        const storedProductPairDraft = sessionStorage.getItem('productPairDraft');
        return storedProductPairDraft !== null;
    };

    const saveProductPairDraft = (productPairDraft: BasisCalculatorProductPair) => {
        sessionStorage.setItem('productPairDraft', JSON.stringify(productPairDraft));
    };

    const setProductPairDraft = () => {
        const storedProductPairDraft = sessionStorage.getItem('productPairDraft');
        if (storedProductPairDraft) {
            const productPairDraft = JSON.parse(storedProductPairDraft) as BasisCalculatorProductPair;
            if (productPairDraft) {
                setProductPair(productPairDraft);
            }
        }
    };

    useEffect(() => {
        if (userStoreValueResponse) {
            try {
                setSavedProductPairs(userStoreValueResponse.userStore?.value?.savedProductPairs ?? []);
            } catch {
                setSavedProductPairs([]);
            }
        }
    }, [userStoreValueResponse]);

    const deleteSavedProductPair = (deleteProductPair: BasisCalculatorProductPair) => {
        try {
            const remainingSavedProductPairs = savedProductPairs?.filter((p) => p !== deleteProductPair);
            const request = {
                userStoreType: DemeterUserStoreType.BasisCalculator,
                value: { savedProductPairs: remainingSavedProductPairs },
            };
            demeterUsersApi.updateUserStore(DemeterUserStoreType.BasisCalculator, request).then(() => {
                setSavedProductPairs(remainingSavedProductPairs);
            });
        } catch {
            /* empty */
        }
    };

    const setLastSavedPair = () => {
        if (savedProductPairs && savedProductPairs.length > 0) {
            const pair = savedProductPairs[savedProductPairs.length - 1];
            setProductPair(pair);
        }
    };

    const selectProductPair = () => {
        setProductSelectorActiveTab(0);
        setProductSelectorOpen(true);
    };

    const selectSavedProductPair = () => {
        setProductSelectorActiveTab(1);
        setProductSelectorOpen(true);
    };

    const saveProductPair = () => {
        if (!productPair) {
            return;
        }
        try {
            if (productPair) {
                productPair.saved = new Date();
            }
            const savedPairs = savedProductPairs ?? [];
            const remainingSavedProductPairs = savedPairs?.filter(
                (p) => !(p.product1?.id === productPair.product1?.id && p.product2?.id === productPair.product2?.id),
            );
            remainingSavedProductPairs.push(productPair);
            updateUserStoreValue({ savedProductPairs: remainingSavedProductPairs });
            setSavedProductPairs(remainingSavedProductPairs);
        } catch {
            /* empty */
        }
    };

    const updateProductPrices1 = () => {
        setProductPrices1(undefined);
        if (!productPair || !productPair.product1) {
            return;
        }
        if (productPair.product1.category === 'futures') {
            demeterApi
                .listMarketPricesRollingWithConversions(
                    productPair.product1.reutersInstrumentCodePrefix!,
                    productPair.product1.contractNumber!,
                    MarketPricesTimeSpan.TenYears,
                    productPair.currency,
                    productPair.unitOfMeasure,
                )
                .then((response) => {
                    setProductPrices1(response.data.rows as MarketPriceModel[]);
                });
        } else if (productPair.product1.category === 'userData') {
            getDemeterUserDataProduct1();
        } else {
            demeterApi
                .listCommodityMonthlyPrices(
                    productPair.product1.region!,
                    productPair.product1.commodity!,
                    productPair.product1.pricesDataSource as DemeterDataSource,
                    productPair.currency,
                    undefined,
                    productPair.unitOfMeasure,
                    DemeterFilterTimeSpan.TenYears,
                )
                .then((response) => {
                    setProductPrices1(response.data.rows as CommodityPriceModel[]);
                });
        }
    };

    const updateProductPrices2 = () => {
        setProductPrices2(undefined);
        if (!productPair || !productPair.product2) {
            return;
        }
        if (productPair.product2.category === 'futures') {
            demeterApi
                .listMarketPricesRollingWithConversions(
                    productPair.product2?.reutersInstrumentCodePrefix!,
                    productPair.product2?.contractNumber!,
                    MarketPricesTimeSpan.TenYears,
                    productPair?.currency,
                    productPair?.unitOfMeasure,
                )
                .then((response) => {
                    setProductPrices2(response.data.rows as MarketPriceModel[]);
                });
        } else if (productPair.product2.category === 'userData') {
            getDemeterUserDataProduct2();
        } else {
            demeterApi
                .listCommodityMonthlyPrices(
                    productPair.product2.region!,
                    productPair.product2.commodity!,
                    productPair.product2.pricesDataSource as DemeterDataSource,
                    productPair.currency,
                    undefined,
                    productPair.unitOfMeasure,
                    DemeterFilterTimeSpan.TenYears,
                )
                .then((response) => {
                    setProductPrices2(response.data.rows as CommodityPriceModel[]);
                });
        }
    };

    const calculate = () => {
        if (!productPrices1 || !productPrices2) {
            setCalculationResult(undefined);
            return;
        }
        const result = BasisCalculatorService.calculate({
            basisLagPeriod: productPair.basisLagPeriod,
            basisPeriod: productPair.basisPeriod,
            basisAdjustment: productPair.basisAdjustment,
            useOptimalLag: productPair.useOptimalLag,
            useRegression: productPair.useRegression,
            startDate: productPair.startDate,
            productPrices1,
            productPrices2,
        });
        setCalculationResult(result);
    };

    const changeProductPair = (value: BasisCalculatorProductPair) => {
        if (productPair && productPair.product1 && productPair.product1.id !== value.product1?.id) {
            value.currency = value.product1?.currency ?? defaultBasisCurrency;
            value.unitOfMeasure = value.product1?.unitOfMeasure ?? defaultBasisUnitOfMeasure;
        } else {
            value.currency = value.currency ?? value.product1?.currency ?? defaultBasisCurrency;
            value.unitOfMeasure = value.unitOfMeasure ?? value.product1?.unitOfMeasure ?? defaultBasisUnitOfMeasure;
        }

        value.basisPeriod = value.basisPeriod ?? BasisPeriod.Basis;
        value.basisAdjustment = value.basisAdjustment ?? BasisAdjustment.Average;
        value.basisLagPeriod = value.basisLagPeriod ?? BasisLagPeriod.NoLag;
        value.startDate = value.startDate ?? defaultStartDate;
        value.useOptimalLag = value.useOptimalLag ?? true;
        value.showAdvancedSettings = value.showAdvancedSettings ?? false;
        setProductPair(value);
        saveProductPairDraft(value);
    };

    const showLoading = !products || !userProducts || !savedProductPairs;
    const showStartup = !showLoading && (!productPair.product1 || !productPair.product2);
    const showCalculator = !showLoading && !showStartup;

    return (
        <>
            {showLoading && <PageLoadingSpinner />}
            {showStartup && (
                <div className={styles.basis_calculator}>
                    <div className={styles.basis_calculator_header_row}>
                        <ComponentHeader title={translations.calculators.text.basis} />
                    </div>
                    <BasisCalculatorStartup productOptions={products} userProductOptions={userProducts} onChangeProductPair={changeProductPair} />
                </div>
            )}
            {showCalculator && (
                <div className={styles.basis_calculator}>
                    <div className={styles.basis_calculator_header_row}>
                        <ComponentHeader title={translations.calculators.text.basis} />
                        <div className={styles.basis_calculator_controls}>
                            <LinkButton
                                title={translations.calculators.basis.actions.selectSavedPair}
                                type={LinkButtonType.White}
                                onClick={selectSavedProductPair}
                            />
                            <LinkButton title={translations.calculators.basis.actions.saveProductPair} type={LinkButtonType.White} onClick={saveProductPair} />
                        </div>
                    </div>
                    <div className={styles.basis_calculator_content_row}>
                        <BasisCalculatorForm
                            productPair={productPair}
                            calculationResult={calculationResult}
                            onChange={changeProductPair}
                            openProductSelector={selectProductPair}
                        />
                        <BasisCalculatorGrid calculationResult={calculationResult} />
                    </div>
                    <div className={styles.basis_calculator_chart_row}>
                        <BasisCalculatorChartCarousel productPair={productPair} calculationResult={calculationResult} />
                    </div>
                    <BasisCalculatorProductSelector
                        open={productSelectorOpen}
                        activeTab={productSelectorActiveTab}
                        productPair={productPair}
                        savedProductPairs={savedProductPairs}
                        onChange={changeProductPair}
                        onDelete={deleteSavedProductPair}
                        onClose={() => setProductSelectorOpen(false)}
                        productOptions={products}
                        userProductOptions={userProducts}
                    />
                </div>
            )}
        </>
    );
};

export default BasisCalculator;
