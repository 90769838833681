import loggingService from '../Logging/LoggingService';

// dont use, will be removed soon.
const promiseHandler: any = (promise: Promise<any>) =>
    promise
        .then((data: any) => data)
        .catch((error: Error & { response: {} }) => {
            loggingService.trackException(error, 'Api call failed.');
            return error.response;
        });

export default promiseHandler;
