/* eslint-disable no-confusing-arrow */
import React, { useEffect, useMemo, useState } from 'react';
import { demeterApi } from '../../../Apis/Apis';
import formattingService from '../../../Core/Formatting/FormattingService';
import {
    Currency,
    DemeterDataFrequency,
    DemeterDataSource,
    DemeterFilterTimeSpan,
    DemeterRegion,
    DemeterSubRegion,
    DemeterTableDefinitionType,
    DemeterUserType,
    ListCommodityMonthlyPricesResponse,
    UnitOfMeasure,
} from '../../../Generated/Raven-Demeter';
import { useApplicationSelector } from '../../../Redux/ReduxStore';
import { selectUserType } from '../../../Redux/Slices/UserSlice';
import useMultipleApis from '../../Apis/Hooks/useMultipleApisHook';
import { ListCommodityPricesResponse } from '../../Apis/Hooks/usePricesApiHook';
import useSymbolsApi from '../../Apis/Hooks/useSymbolsApiHook';
import { ChartDisplayType, IChartDataSeries } from '../../Components/Charts/ChartDefinitions';
import ChartWrapper from '../../Components/Charts/ChartWrapper/ChartWrapper';
import FilterTimeSpans from '../../Components/Charts/FilterTimeSpans/FilterTimeSpans';
import PriceChartRaw from '../../Components/Charts/Price/PriceChartRaw';
import { ChartStudyType } from '../../Components/Charts/Studies/StudiesDefinitions';
import StudiesDropdown from '../../Components/Charts/Studies/StudiesDropdown';
import { IRegionCommoditySelection } from '../../Components/Navigation/Hooks/useRegionCommodityNavigationHook';
import useLanguage from '../../Services/Language/useLanguageHook';

export interface IPhysicalPricesChartProps {
    title: string;
    regionCommoditySelections: IRegionCommoditySelection[];
    tableDefinitionType: DemeterTableDefinitionType;
    dataFrequency: DemeterDataFrequency;
    displayDecimalPlacesMinimum?: number;
    displayDecimalPlacesMaximum?: number;
    currency?: Currency;
    unitOfMeasure?: UnitOfMeasure;
    testId?: string;
}

const availableFilterTimeSpans = [
    DemeterFilterTimeSpan.OneYear,
    DemeterFilterTimeSpan.FiveYears,
    DemeterFilterTimeSpan.TenYears,
    DemeterFilterTimeSpan.TwentyYears,
];

const defaultFilterTimeSpan = DemeterFilterTimeSpan.FiveYears;

const defaultDisplayTypes = [ChartDisplayType.Line];
const singleLineDisplayTypes = [...defaultDisplayTypes, ChartDisplayType.Area, ChartDisplayType.Column, ChartDisplayType.Spline];

const PhysicalPricesChart: React.FC<IPhysicalPricesChartProps> = (props: IPhysicalPricesChartProps) => {
    // Text hooks.
    const [translations, translate] = useLanguage();
    const currentUserType = useApplicationSelector(selectUserType);
    const allowedDownloadUsers: DemeterUserType[] = [DemeterUserType.Administrator, DemeterUserType.Premium, DemeterUserType.BusinessOwner];

    // Api hooks.
    const symbols = useSymbolsApi();
    const [filterTimeSpan, setFilterTimeSpan] = useState<DemeterFilterTimeSpan>(defaultFilterTimeSpan);
    const [physicalPricesLoading, refreshApi, physicalPricesDatas] = useMultipleApis<undefined, ListCommodityPricesResponse>(
        () => {
            if (props.regionCommoditySelections.length === 0) {
                return null;
            }

            if (props.tableDefinitionType === DemeterTableDefinitionType.CommodityGlobalDairyTradeWeeklyPricesTable) {
                return props.regionCommoditySelections.map((x) =>
                    demeterApi.listGlobalDairyTradeWeeklyPrices(x.commodity, x.region as DemeterRegion, props.unitOfMeasure, filterTimeSpan),
                );
            }

            if (props.dataFrequency === DemeterDataFrequency.Monthly) {
                return props.regionCommoditySelections.map((x) =>
                    demeterApi.listCommodityMonthlyPrices(
                        x.region,
                        x.commodity,
                        x.extraParameters as DemeterDataSource,
                        props.currency,
                        x.subRegion as DemeterSubRegion,
                        props.unitOfMeasure,
                        filterTimeSpan,
                    ),
                );
            }

            if (props.dataFrequency === DemeterDataFrequency.Weekly) {
                return props.regionCommoditySelections.map((x) =>
                    demeterApi.listCommodityWeeklyPrices(
                        x.region,
                        x.commodity,
                        x.extraParameters as DemeterDataSource,
                        props.currency,
                        x.subRegion as DemeterSubRegion,
                        props.unitOfMeasure,
                        filterTimeSpan,
                    ),
                );
            }

            return props.regionCommoditySelections.map((x) =>
                demeterApi.listCommoditySpotPrices(
                    x.region,
                    x.commodity,
                    x.extraParameters as DemeterDataSource,
                    props.currency,
                    x.subRegion as DemeterSubRegion,
                    props.unitOfMeasure,
                    filterTimeSpan,
                ),
            );
        },
        { stopAutoExecute: true },
    );

    // Data hooks.
    const [linesSeries, setLinesSeries] = useState<IChartDataSeries[]>([]);
    const [displayType, setDisplayType] = useState<ChartDisplayType>(ChartDisplayType.Line);
    const [studies, setStudies] = useState<ChartStudyType[]>([]);

    useEffect(() => {
        if (props.regionCommoditySelections.length > 0) {
            refreshApi();
        }
        if (props.regionCommoditySelections.length > 1) {
            setStudies([]);
        }
    }, [props.regionCommoditySelections, props.currency, props.unitOfMeasure, filterTimeSpan]);

    useEffect(() => {
        if (!physicalPricesDatas || physicalPricesDatas.length === 0) {
            setLinesSeries([]);
            return;
        }

        const newLinesSeries = physicalPricesDatas.map((data, index) => {
            const selection = props.regionCommoditySelections[index];
            const label = translate(selection?.displayName!);
            const symbol = symbols?.find((x) => x.reutersInstrumentCodePrefix === (data as ListCommodityMonthlyPricesResponse).reutersInstrumentCodePrefix);

            return {
                label,
                forecastLabel: `${label} ${translations.words.forecast}`,
                data: data!
                    // Remove null value from first index if it exist to stop error from relative strength index study.
                    .rows!.filter((x, rowIndex) => x.value !== null || rowIndex !== 0)
                    .map((row) => ({
                        value: row.value!,
                        asOfDate: new Date(row.asOfDate),
                        isActualValue: row.isActualValue,
                    })),
                forwardCurveLabel: `${formattingService.toDisplayName(symbol)} ${translations.physicalPrices.text.futuresForwardCurve}`,
                forwardCurveData: (data as ListCommodityMonthlyPricesResponse).futuresMarketPricesForwardCurve?.map((curve) => ({
                    value: curve.settlementPrice,
                    asOfDate: new Date(curve.contractYear, curve.contractMonth - 1, 1),
                    isActualValue: false,
                })),
                currency: data.currency,
                unitOfMeasure: data.unitOfMeasure,
            };
        });

        setLinesSeries(newLinesSeries);
    }, [physicalPricesDatas, symbols]);

    const dataSourceTag = useMemo(() => physicalPricesDatas?.flatMap((x) => (x.dataSourceTag ?? '').split(', ')) ?? [], [physicalPricesDatas]);
    const oldestAsOfDate = useMemo((): Date | undefined => {
        if (!physicalPricesDatas || !physicalPricesDatas[0] || !physicalPricesDatas[0].oldestAsOfDate) {
            return undefined;
        }

        const date = Math.min(...physicalPricesDatas.map((x) => Date.parse(x.oldestAsOfDate ?? '')));

        return new Date(date);
    }, [physicalPricesDatas]);

    return (
        <ChartWrapper
            name="PhysicalPricesChart"
            title={linesSeries.length === 1 && linesSeries[0].label ? linesSeries[0].label : props.title}
            dataSourceTag={dataSourceTag}
            isLoading={physicalPricesLoading && linesSeries.length === 0}
            header={
                <StudiesDropdown disabled={linesSeries.length > 1} studies={studies} handleStudiesSelected={(selectedStudies) => setStudies(selectedStudies)} />
            }
            headerOptions={{
                // TODO: We might want this as a permission type added in the backend.
                isCsvDownloadAvailable: props.dataFrequency === DemeterDataFrequency.Weekly && allowedDownloadUsers.includes(currentUserType!),
                chartDisplayTypes: linesSeries.length <= 1 ? singleLineDisplayTypes : defaultDisplayTypes,
                handleChartDisplayTypeChange: (newDisplayType: ChartDisplayType) => {
                    setDisplayType(newDisplayType);
                },
            }}
            footer={
                <FilterTimeSpans
                    name="PhysicalPricesChart"
                    filterTimeSpanOptions={availableFilterTimeSpans}
                    filterTimeSpan={filterTimeSpan}
                    oldestAsOfDate={oldestAsOfDate}
                    handleTimeSpanSelected={(timeSpan) => setFilterTimeSpan(timeSpan)}
                />
            }
            testId={props.testId}
        >
            <PriceChartRaw
                displayType={displayType}
                linesSeries={linesSeries}
                dataFrequency={props.dataFrequency}
                currency={props.currency}
                unitOfMeasure={props.unitOfMeasure}
                filterTimeSpan={filterTimeSpan}
                displayDecimalPlacesMinimum={props.displayDecimalPlacesMinimum}
                displayDecimalPlacesMaximum={props.displayDecimalPlacesMaximum}
                studies={studies}
            />
        </ChartWrapper>
    );
};

export default PhysicalPricesChart;
