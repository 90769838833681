import { Tooltip } from '@mui/material';
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import useWindowDimensions from '../../Responsive/Hooks/useWindowDimensionsHook';
import styles from './OverflowCellWithTooltip.module.scss';

interface IOverflowCellWithTooltipProps {
    value: string;
    nonTooltipOverrideValue?: ReactElement;
}

const OverflowCellWithTooltip: React.FC<IOverflowCellWithTooltipProps> = (props: IOverflowCellWithTooltipProps) => {
    // Application hooks.
    const windowDimensions = useWindowDimensions();

    // Component states.
    const textElementReference = useRef<HTMLDivElement>(null);
    const [hasHoverState, setHasHoverState] = useState(false);

    useEffect(() => {
        const textLengthHasOverflow = textElementReference.current?.scrollWidth! > textElementReference.current?.clientWidth!;
        setHasHoverState(textLengthHasOverflow);
    }, [windowDimensions]);

    return (
        <Tooltip title={props.value} placement="top-start" disableHoverListener={!hasHoverState}>
            <span ref={textElementReference!} className={styles.overflow_tooltip}>
                {props.nonTooltipOverrideValue ?? props.value.trim()}
            </span>
        </Tooltip>
    );
};

export default OverflowCellWithTooltip;
