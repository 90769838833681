import { useMemo } from 'react';
import { DemeterDataFrequency, DemeterTableDefinitionType } from '../../../Generated/Raven-Demeter/api';
import JoinedSelectionButtons from '../../Components/Form/Buttons/JoinedSelectionButtons/JoinedSelectionButtons';
import CurrencyDropdown from '../../Components/Form/Inputs/CurrencyDropdown';
import UnitOfMeasureGeneralDropdown from '../../Components/Form/Inputs/UnitOfMeasureGeneralDropdown';
import PageHeaderWithSelections from '../../Components/Headers/PageHeaderWithSelections';
import PageLoadingSpinner from '../../Components/LoadingSpinner/PageLoadingSpinner';
import useSearchParameters from '../../Components/Navigation/Hooks/useSearchParametersHook';
import useTableDefinitionNavigation from '../../Components/Navigation/Hooks/useTableDefinitionNavigationHook';
import useCurrency from '../../Core/Hooks/useCurrencyHook';
import useUnitOfMeasure from '../../Core/Hooks/useUnitOfMeasureHook';
import useLanguage from '../../Services/Language/useLanguageHook';
import styles from './PhysicalPricesPage.module.scss';
import PhysicalPricesTable from './PhysicalPricesTable';

const PhysicalPricesRefactorPage: React.FC = () => {
    const [translations] = useLanguage();

    // Constants.
    const pageTitle = translations.physicalPrices.title;
    const tableDefinitionType = DemeterTableDefinitionType.CommodityPricesTable;

    // Custom hooks and state
    const [currency, setCurrency] = useCurrency();
    const [unitOfMeasure, setUnitOfMeasure] = useUnitOfMeasure('General');
    const [, , regionCommoditySelection] = useTableDefinitionNavigation(tableDefinitionType);
    const [searchParamters, setSearchParameters] = useSearchParameters();

    const updateDataFrequency = (dataFrequencyParameter: DemeterDataFrequency) => {
        setSearchParameters({ ...searchParamters, ...{ dataFrequency: dataFrequencyParameter } });
    };

    const dataFrequencyOptions = useMemo(
        () => [
            {
                label: translations.dataFrequency[DemeterDataFrequency.Monthly],
                value: DemeterDataFrequency.Monthly,
            },
            {
                label: translations.dataFrequency[DemeterDataFrequency.Weekly],
                value: DemeterDataFrequency.Weekly,
            },
            {
                label: translations.dataFrequency[DemeterDataFrequency.Daily],
                value: DemeterDataFrequency.Daily,
            },
        ],
        [],
    );

    const dataFrequency = useMemo(
        () => dataFrequencyOptions.find((x) => x.value === regionCommoditySelection?.dataFrequency)?.value || DemeterDataFrequency.Monthly,
        [regionCommoditySelection],
    );

    return (
        <div className={styles.master_page_container}>
            {!regionCommoditySelection ? (
                <PageLoadingSpinner />
            ) : (
                <>
                    <div className={styles.physical_prices_header_wrapper}>
                        <PageHeaderWithSelections
                            title={pageTitle}
                            tableDefinitionType={tableDefinitionType}
                            rightSideOfHeaderComponent={
                                <div className={styles.physical_prices_header_dropdown_wrapper}>
                                    <UnitOfMeasureGeneralDropdown unitOfMeasure={unitOfMeasure} handleChange={setUnitOfMeasure} />
                                    <CurrencyDropdown currency={currency} handleChange={setCurrency} />
                                </div>
                            }
                            showMainRegionsAndGroupsOnly
                            rightSideOfBreadcrumbsComponent={
                                <JoinedSelectionButtons options={dataFrequencyOptions} handleSelectionChange={updateDataFrequency} selection={dataFrequency} />
                            }
                            testId="PhysicalPricesPageHeader"
                        />
                    </div>
                    <div className={styles.physical_prices_main_container}>
                        <PhysicalPricesTable
                            regionCommoditySelection={regionCommoditySelection}
                            tableDefinitionType={tableDefinitionType}
                            unitOfMeasure={unitOfMeasure}
                            currency={currency}
                        />
                    </div>
                </>
            )}
        </div>
    );
};

export default PhysicalPricesRefactorPage;
