import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { demeterMarketIndicatorsApi } from '../../../../Apis/Apis';
import formattingService from '../../../../Core/Formatting/FormattingService';
import { DemeterRegion, ExchangeType } from '../../../../Generated/Raven-Demeter';
import NavigationRoutes from '../../../../Layouts/NavigationRoutes';
import useApi from '../../../Apis/Hooks/useApiHook';
import useSymbolsApi from '../../../Apis/Hooks/useSymbolsApiHook';
import ComponentHeader from '../../../Components/Headers/ComponentHeader';
import PageHeader from '../../../Components/Headers/PageHeader';
import PageLoadingSpinner from '../../../Components/LoadingSpinner/PageLoadingSpinner';
import OverflowCellWithTooltip from '../../../Components/Tables/OverflowCellWithTooltip/OverflowCellWithTooltip';
import useLanguage from '../../../Services/Language/useLanguageHook';
import MarketIndicatorActions from './MarketIndicatorActions/MarketIndicatorActions';
import MarketIndicatorAddButton from './MarketIndicatorActions/MarketIndicatorAddButton';
import styles from './MarketIndicatorsManagement.module.scss';

// Defaults.
const defaultRegion = DemeterRegion.UnitedStates;
const defaultAllowedExchanges: ExchangeType[] = [ExchangeType.Cme, ExchangeType.Eex, ExchangeType.Ice, ExchangeType.Dce, ExchangeType.Euronext];

const MarketIndicatorsMangementPage: React.FC = () => {
    // Application hooks.
    const [translations, translate] = useLanguage();

    // Api hooks.
    const symbols = useSymbolsApi();
    const [, refreshMarketIndicators, listMarketIndicatorsResponse] = useApi(() => demeterMarketIndicatorsApi.listMarketIndicators(false, true));

    // Dropdown options.
    const symbolDropdownOptions = useMemo(() => {
        if (!symbols || !listMarketIndicatorsResponse?.rows) {
            return [];
        }

        return symbols
            .filter((x) => x.region === defaultRegion || defaultAllowedExchanges.includes(x.exchange))
            .sort((a, b) => `${a.exchange}-${a.displayName}`.localeCompare(`${b.exchange}-${b.displayName}`))
            .map((x) => ({
                label: formattingService.toDisplayName(x),
                value: x,
            }));
    }, [symbols, listMarketIndicatorsResponse]);

    return !symbols || !listMarketIndicatorsResponse || !symbolDropdownOptions ? (
        <PageLoadingSpinner />
    ) : (
        <div className={styles.markets_management_page_container}>
            <PageHeader title={translations.marketIndicatorsManagement.title} />
            <div className={styles.markets_management_page_header_section}>
                <ComponentHeader title={translations.words.indicators} />
                <MarketIndicatorAddButton symbols={symbols} symbolDropdownOptions={symbolDropdownOptions} handleAddMarketIndicator={refreshMarketIndicators} />
            </div>
            <div data-testid="MarketIndicatorsManagementTable" className={styles.markets_management_common_table}>
                <div className={styles.markets_management_common_table_header_row}>
                    <div className={styles.markets_management_common_table_header_large}>{translations.marketIndicatorsManagement.headers.commodityName}</div>
                    <div>{translations.marketIndicatorsManagement.headers.exchangeAndCommodity}</div>
                    <div>{translations.marketIndicatorsManagement.headers.dateCreated}</div>
                    <div>{translations.marketIndicatorsManagement.headers.lastUpdated}</div>
                    <div>{translations.marketIndicatorsManagement.headers.status}</div>
                    <div className={styles.markets_management_common_table_header_large}>{translations.marketIndicatorsManagement.headers.actions}</div>
                </div>
                {listMarketIndicatorsResponse.rows?.map((marketIndicator) => (
                    <div key={`row_${marketIndicator.marketIndicatorGuid}`} className={styles.markets_management_common_table_row_group}>
                        <div className={styles.markets_management_common_table_row}>
                            <p className={styles.markets_management_common_table_cell_large}>
                                <Link
                                    className={styles.markets_management_common_table_link}
                                    to={`${NavigationRoutes.AdministrationMarketIndicators}/${marketIndicator.marketIndicatorGuid}/edit`}
                                >
                                    {translate(marketIndicator.displayName!)}
                                </Link>
                            </p>
                            <p className={styles.markets_management_common_table_cell}>
                                <OverflowCellWithTooltip
                                    value={`${marketIndicator.exchange ? translations.exchange[marketIndicator.exchange] : ''} ${translate(
                                        marketIndicator.commodity,
                                    )} ${marketIndicator.dataSource ? `- ${translate(marketIndicator.dataSource)}` : ''}`}
                                />
                            </p>
                            <p className={styles.markets_management_common_table_cell}>
                                {formattingService.toShortDayMonthYear(marketIndicator.createdAt ?? '')}
                            </p>
                            <p className={styles.markets_management_common_table_cell}>
                                {formattingService.toShortDayMonthYear(marketIndicator.modifiedAt ?? '')}
                            </p>
                            <p className={styles.markets_management_common_table_cell}>
                                <span className={marketIndicator.isPublished ? styles.markets_management_active : styles.markets_management_inactive}>
                                    {marketIndicator.isPublished ? translations.words.active : translations.words.inactive}
                                </span>
                            </p>
                            <p className={styles.markets_management_common_table_actions_container}>
                                <MarketIndicatorActions
                                    marketIndicator={marketIndicator}
                                    symbols={symbols}
                                    symbolDropdownOptions={symbolDropdownOptions}
                                    handleUpdateMarketIndicator={refreshMarketIndicators}
                                />
                            </p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default MarketIndicatorsMangementPage;
