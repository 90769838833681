/* eslint-disable @typescript-eslint/indent */
import { AxiosResponse } from 'axios';
import { useMemo } from 'react';
import { demeterApi, demeterDomesticsApi, demeterExportsApi, demeterImportsApi, demeterProductionApi, demeterStocksApi } from '../../../Apis/Apis';
import CacheKeys from '../../../Core/Cache/CacheKeys';
import {
    CommodityMonthlyDomesticModelSeasonalYearSummaryModel,
    CommodityMonthlyExportModelSeasonalYearSummaryModel,
    CommodityMonthlyImportModelSeasonalYearSummaryModel,
    CommodityMonthlyProductionModelSeasonalYearSummaryModel,
    CommodityMonthlyStockModelSeasonalYearSummaryModel,
    Currency,
    DemeterCommodity,
    DemeterDataSource,
    DemeterRegion,
    DemeterSubRegion,
    DemeterTableDefinitionType,
    UnitOfMeasure,
} from '../../../Generated/Raven-Demeter/api';
import { IRegionCommoditySelection } from '../../Components/Navigation/Hooks/useRegionCommodityNavigationHook';
import useTableDefinition from '../../Components/Navigation/Hooks/useTableDefinitionHook';
import useCacheThenApi from './useCacheThenApiHook';

export interface CommodityMonthlySeasonalModel {
    region: DemeterRegion;
    subRegion?: DemeterSubRegion;
    commodity: DemeterCommodity;
    dataSource?: DemeterDataSource;
    year: number;
    month: number;
    value?: number | null;
    normalizedValue: number;
    monthOverMonthChangePercent?: number | null;
    rollingYearChangePercent?: number | null;
    rollingYearValue?: number | null;
    unitOfMeasure?: UnitOfMeasure;
    currency?: Currency;
    isActualValue: boolean;
    asOfDate: string;
}

type SeasonalRowModel =
    | CommodityMonthlyExportModelSeasonalYearSummaryModel
    | CommodityMonthlyImportModelSeasonalYearSummaryModel
    | CommodityMonthlyStockModelSeasonalYearSummaryModel
    | CommodityMonthlyDomesticModelSeasonalYearSummaryModel
    | CommodityMonthlyProductionModelSeasonalYearSummaryModel;

type CommodityMonthlyDataByYear = { [key: string]: Array<CommodityMonthlySeasonalModel> };
export interface GetCommodityMonthlySeasonalResponse {
    commodityMonthlyDataByYear?: CommodityMonthlyDataByYear | null;
    unitOfMeasure?: UnitOfMeasure;
    currency?: Currency;
    dataSourceTag?: string | null;
    rows?: SeasonalRowModel[] | null;
}

const useSeasonalApi = (
    tableDefinitionType: DemeterTableDefinitionType,
    regionCommoditySelection: IRegionCommoditySelection,
    aggregateRegions?: DemeterRegion[],
    unitOfMeasure?: UnitOfMeasure,
    currency?: Currency,
    years?: Array<number>,
    showForecast?: boolean,
): GetCommodityMonthlySeasonalResponse | undefined => {
    const [, tableDefinitionCommodity] = useTableDefinition(tableDefinitionType, regionCommoditySelection);

    const cacheKey = useMemo(() => {
        const cacheKeyPrefix = `${CacheKeys.GetDemeterSeasonal}_${tableDefinitionType}`;
        const cacheKeySuffix = JSON.stringify({
            ...regionCommoditySelection,
            years,
            showForecast: !!showForecast,
            unitOfMeasure,
            currency,
        });
        const aggregateRegionsKey = aggregateRegions?.sort().join();

        if (tableDefinitionType !== DemeterTableDefinitionType.CommodityPricesTable && (!tableDefinitionCommodity || !regionCommoditySelection)) {
            return cacheKeyPrefix;
        }

        return `${cacheKeyPrefix}_${cacheKeySuffix}_${aggregateRegionsKey}`;
    }, [tableDefinitionCommodity, regionCommoditySelection, aggregateRegions, showForecast, years, unitOfMeasure, currency]);

    const [, , listDemeterSeasonalResponse] = useCacheThenApi(cacheKey, (): Promise<AxiosResponse<GetCommodityMonthlySeasonalResponse, any>> | null => {
        if (tableDefinitionType !== DemeterTableDefinitionType.CommodityPricesTable && (!tableDefinitionCommodity || !regionCommoditySelection)) {
            return null;
        }

        const subRegion = regionCommoditySelection.subRegion ? (regionCommoditySelection.subRegion as DemeterSubRegion) : undefined;
        const aggregatedRequest =
            aggregateRegions && aggregateRegions.length > 0
                ? {
                      commoditySelections: aggregateRegions.map((region) => ({
                          region: region as DemeterRegion,
                          commodity: regionCommoditySelection.commodity as DemeterCommodity,
                      })),
                      years,
                      showForecast,
                      unitOfMeasure,
                  }
                : undefined;
        if (tableDefinitionType === DemeterTableDefinitionType.CommodityMonthlyExportsTable) {
            if (aggregatedRequest) {
                return demeterExportsApi.getCommodityMonthlyExportsSeasonalAggregated(aggregatedRequest);
            }
            return demeterExportsApi.getCommodityMonthlyExportsSeasonal(
                regionCommoditySelection.region,
                regionCommoditySelection.commodity,
                subRegion,
                years,
                showForecast,
                unitOfMeasure,
            );
        }

        if (tableDefinitionType === DemeterTableDefinitionType.CommodityMonthlyImportsTable) {
            if (aggregatedRequest) {
                return demeterImportsApi.getCommodityMonthlyImportsSeasonalAggregated(aggregatedRequest);
            }
            return demeterImportsApi.getCommodityMonthlyImportsSeasonal(
                regionCommoditySelection.region,
                regionCommoditySelection.commodity,
                subRegion,
                years,
                showForecast,
                unitOfMeasure,
            );
        }

        if (tableDefinitionType === DemeterTableDefinitionType.CommodityMonthlyDomesticTable) {
            if (aggregatedRequest) {
                return demeterDomesticsApi.getCommodityMonthlyDomesticsSeasonalAggregated(aggregatedRequest);
            }
            return demeterDomesticsApi.getCommodityMonthlyDomesticsSeasonal(
                regionCommoditySelection.region,
                regionCommoditySelection.commodity,
                subRegion,
                years,
                showForecast,
                unitOfMeasure,
            );
        }

        if (tableDefinitionType === DemeterTableDefinitionType.CommodityMonthlyProductionTable) {
            if (aggregatedRequest) {
                return demeterProductionApi.getCommodityMonthlyProductionSeasonalAggregated(aggregatedRequest);
            }
            return demeterProductionApi.getCommodityMonthlyProductionSeasonal(
                regionCommoditySelection.region,
                regionCommoditySelection.commodity,
                subRegion,
                years,
                showForecast,
                unitOfMeasure,
            );
        }

        if (tableDefinitionType === DemeterTableDefinitionType.CommodityMonthlyStockTable) {
            const dataSource = DemeterDataSource[regionCommoditySelection.extraParameters as keyof typeof DemeterDataSource];
            if (aggregatedRequest) {
                return demeterStocksApi.getCommodityMonthlyStocksSeasonalAggregated({
                    commoditySelections: aggregatedRequest.commoditySelections.map((x) => ({ ...x, dataSource })),
                    years,
                    showForecast,
                    unitOfMeasure,
                });
            }
            return demeterStocksApi.getCommodityMonthlyStocksSeasonal(
                regionCommoditySelection.region,
                regionCommoditySelection.commodity,
                dataSource,
                subRegion,
                years,
                showForecast,
                unitOfMeasure,
            );
        }

        if (tableDefinitionType === DemeterTableDefinitionType.CommodityPricesTable) {
            return demeterApi.getCommodityMonthlyPricesSeasonal(
                regionCommoditySelection.region,
                regionCommoditySelection.commodity,
                regionCommoditySelection.extraParameters as DemeterDataSource,
                currency,
                subRegion,
                years,
                showForecast,
                unitOfMeasure,
            );
        }

        return null;
    });

    return listDemeterSeasonalResponse;
};

export default useSeasonalApi;
