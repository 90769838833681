import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import NavigationRoutes from '../../../Layouts/NavigationRoutes';
import RegistrationActivateComponent from './RegistrationActivateComponent';

const RegistrationPage: React.FC = () => {
    const [searchParameters] = useSearchParams();
    const navigate = useNavigate();

    return (
        <RegistrationActivateComponent
            activationCode={searchParameters.get('activationCode')}
            email={searchParameters.get('email')}
            navigateToLogin={() => {
                navigate(NavigationRoutes.Home);
            }}
        />
    );
};

export default RegistrationPage;
