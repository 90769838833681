import React, { useRef, useState } from 'react';
import cloudUpdateSvg from '../../../../Components/Assets/cloudUpload.svg';
import RegexValidators from '../../../Core/Validation/RegexValidators';
import languageService from '../../../Services/Language/LanguageService';
import useLanguage from '../../../Services/Language/useLanguageHook';
import useNotificationHook from '../../Notifications/useNotificationHook';
import LinkButton, { LinkButtonType } from '../Buttons/LinkButton';
import styles from './Inputs.module.scss';

interface IFileUploaderInterface {
    title: string;
    handleSubmit: (file: any) => void;
}

const FileUploader: React.FC<IFileUploaderInterface> = (props: IFileUploaderInterface) => {
    const hiddenFileInputReference = useRef<any>();
    const [dragActive, setDragActive] = useState(false);
    const [importFile, setImportFile] = useState<any>();
    const [displayError] = useNotificationHook();
    const [translations] = useLanguage();

    const validateFile = (file: any) => {
        if (file.name.match(RegexValidators.FileExtension)[0] === '.csv') {
            setImportFile(file);
            return true;
        }

        return false;
    };

    const handleFileChange = (event: any) => {
        validateFile(event.target.files[0]);
    };

    const handleDrag = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === 'dragenter' || e.type === 'dragover') {
            setDragActive(true);
        } else if (e.type === 'dragleave') {
            setDragActive(false);
        }
    };

    const handleDrop = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        const { files } = e.dataTransfer;
        if (files && files[0] && files.length === 1 && files[0].name.match(RegexValidators.FileExtension)[0] === '.csv') {
            validateFile(e.dataTransfer.files[0]);
        } else {
            displayError(translations.users.errors.notCsv);
        }
    };

    return (
        <form onDragEnter={handleDrag}>
            {dragActive && (
                <div
                    className={styles.input_file_upload_drag_overlay}
                    onDragEnter={handleDrag}
                    onDragLeave={handleDrag}
                    onDragOver={handleDrag}
                    onDrop={handleDrop}
                />
            )}
            <div className={styles.input_file_upload_drag_drop}>
                <img src={cloudUpdateSvg} alt="#" />
                {importFile && (
                    <div className={styles.input_file_upload_drag_drop_content_holder}>
                        <h3>{`${languageService.translate('actions.import')} ${importFile.name}`}</h3>
                        <LinkButton
                            title={languageService.translate('actions.import')}
                            type={LinkButtonType.Blue}
                            onClick={() => {
                                props.handleSubmit(importFile);
                            }}
                        />
                    </div>
                )}
                <div className={styles.input_file_upload_drag_drop_content_holder} style={{ display: importFile ? 'none' : '' }}>
                    <h3>{languageService.translate('page.import.dragAndDrop')}</h3>
                    <h3>{languageService.translate('page.import.or')}</h3>
                    <label id="label-file-upload" htmlFor="input-file-upload">
                        <LinkButton
                            title={props.title}
                            type={LinkButtonType.Blue}
                            onClick={() => {
                                hiddenFileInputReference.current.click();
                            }}
                        />
                        <input type="file" ref={hiddenFileInputReference} onChange={handleFileChange} style={{ display: 'none' }} />
                    </label>
                </div>
            </div>
        </form>
    );
};

export default FileUploader;
