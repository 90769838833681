import { useEffect } from 'react';
import Dropdown from '../../../../../../Components/Form/Inputs/Dropdown';
import useLanguage from '../../../../../../Services/Language/useLanguageHook';
import { marketIndicatorsManagementContractOptions } from '../../../MarketIndicatorsManagementDefinitions';

interface IDataSelectorContractNumberDropdownProps {
    extraParameters: number;
    handleChange: (extraParameters?: number) => void;
}

const defaultContractNumber = 1;

const DataSelectorContractNumberDropdown: React.FC<IDataSelectorContractNumberDropdownProps> = (props: IDataSelectorContractNumberDropdownProps) => {
    const [translations] = useLanguage();

    useEffect(() => {
        if (props.extraParameters) {
            return;
        }

        props.handleChange(defaultContractNumber);
    }, [props.extraParameters]);

    return (
        <Dropdown
            value={+props.extraParameters}
            options={marketIndicatorsManagementContractOptions}
            handleOptionChange={props.handleChange}
            label={translations.marketIndicatorsManagement.fields.contractNumber}
        />
    );
};

export default DataSelectorContractNumberDropdown;
