import useSearchParameters from '../../Components/Navigation/Hooks/useSearchParametersHook';
import UserDataManagement from './UserDataManagement';
import UserDataUpload from './UserDataUpload';

const UserDataPage: React.FC = () => {
    const [searchParamters] = useSearchParameters();

    const showUpload = searchParamters.show === 'Upload';
    const showManagement = !showUpload || searchParamters.show === 'Management';

    return (
        <>
            {showUpload && <UserDataUpload />}
            {showManagement && <UserDataManagement />}
        </>
    );
};
export default UserDataPage;
