import { AxiosResponse } from 'axios';
import { useEffect, useRef, useState } from 'react';
import { ApiError } from '../../../Apis/Apis';
import applicationConstants from '../../../Core/Utility/ApplicationConstants';
import useNotificationHook from '../../Components/Notifications/useNotificationHook';
import useLanguage from '../../Services/Language/useLanguageHook';

export interface UseApiOptions {
    stopAutoExecute?: boolean;
    stopGlobalErrorHandling?: boolean;
    successMessage?: string;
    errorMessage?: string;
    conflictErrorMessage?: string;
}

const useApi = <TRequest, TResponse>(
    apiCallback: (request?: TRequest) => Promise<AxiosResponse<TResponse, any>> | null | undefined,
    options?: UseApiOptions,
): [loading: boolean, refreshApi: (request?: TRequest) => void, data?: TResponse, error?: string] => {
    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<TResponse | undefined>();
    const [error, setError] = useState<string>();
    const [refreshCounter, setRefreshCounter] = useState(0);
    const refreshReference = useRef<{ counter: number; request: TRequest | undefined }>({ counter: 0, request: undefined });
    const [displayError, displaySuccess] = useNotificationHook();

    const [translations] = useLanguage();

    const refreshApi = (request?: TRequest) => {
        refreshReference.current.counter += 1;
        refreshReference.current.request = request;
        setRefreshCounter(refreshReference.current.counter);
    };

    useEffect(() => {
        let cancelRequest = false;
        const requestKey = refreshReference.current.counter;

        const fetchDataFromApi = async () => {
            try {
                const apiToCall = apiCallback(refreshReference.current?.request);
                if (!apiToCall) {
                    return;
                }
                setLoading(true);
                const response = await apiToCall;
                const responseData = response.data;

                if (requestKey !== refreshReference.current.counter || cancelRequest) {
                    return;
                }

                setData(responseData);
                setError(undefined);
                setLoading(false);

                if (options?.successMessage) {
                    displaySuccess(options.successMessage);
                }
            } catch (exception) {
                if (cancelRequest) {
                    return;
                }

                const problemMessage = (exception as ApiError).response.data?.detail ?? '';
                const errorMessage = (exception as ApiError).response.data?.errors ?? [];

                setError(problemMessage || errorMessage.join(', '));
                setLoading(false);

                if (options?.conflictErrorMessage && (exception as ApiError).response.status === applicationConstants.HttpStatus.Conflict) {
                    displayError(options.conflictErrorMessage);
                } else if (options?.errorMessage) {
                    displayError(options.errorMessage);
                } else if (!options?.stopGlobalErrorHandling) {
                    displayError(translations.errors.apiError);
                }
            }
        };

        if (!options?.stopAutoExecute || refreshCounter > 0) {
            fetchDataFromApi();
        }
        return (): void => {
            cancelRequest = true;
        };
    }, [refreshCounter]);

    return [loading, refreshApi, data, error];
};

export default useApi;
