import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { demeterUsersApi } from '../../../../Apis/Apis';
import AgGridBuilder from '../../../../Components/AgGridBuilder/AgGridBuilder';
import ContentType from '../../../../Core/Common/ContentTypes';
import FileExtension from '../../../../Core/Common/FileExtensions';
import Files from '../../../../Core/Settings/Files';
import NavigationRoutes from '../../../../Layouts/NavigationRoutes';
import useApi from '../../../Apis/Hooks/useApiHook';
import LinkButton, { LinkButtonType } from '../../../Components/Form/Buttons/LinkButton';
import FileUploader from '../../../Components/Form/Inputs/FileUploader';
import PageHeaderWithBackNavigation from '../../../Components/Headers/PageHeaderWithBackNavigation';
import PageLoadingSpinner from '../../../Components/LoadingSpinner/PageLoadingSpinner';
import useLanguage from '../../../Services/Language/useLanguageHook';
import styles from './UsersPage.module.scss';
import { usersImportResultsColumnDefinitions } from './UsersPageDefinitions';

const UsersImportPage = () => {
    const navigate = useNavigate();
    const [translations] = useLanguage();

    const usersImportGridReference = useRef();
    const [loading, refreshApi, batchImportDemeterUsersResponse]: any = useApi(
        (file: any) => {
            if (!file) {
                return null;
            }

            const isValidFile = validateFile(file);

            if (isValidFile) {
                return null;
            }

            return demeterUsersApi.batchImportDemeterUsers(file, {
                method: 'POST',
                headers: {
                    'Content-Type': ContentType.Csv,
                },
            });
        },
        { stopAutoExecute: true, stopGlobalErrorHandling: false },
    );

    const validateFile = (file: File | undefined): boolean => {
        if (!file) {
            return false;
        }
        if (file.type !== ContentType.Csv) {
            return false;
        }
        if (!file.name.endsWith(FileExtension.Csv)) {
            return false;
        }

        return true;
    };

    return (
        <div className={styles.import_page}>
            <div className={styles.import_page_container}>
                <div className={styles.import_page_header_container}>
                    <PageHeaderWithBackNavigation handleBackNavigation={() => navigate(-1)} title={translations.actions.import} />
                    {batchImportDemeterUsersResponse && (
                        <LinkButton title={translations.actions.done} navigateTo={`${NavigationRoutes.Users}`} type={LinkButtonType.White} />
                    )}
                </div>
                <div className={styles.import_page_download_import_template_message}>
                    <a href={Files.batchImportTemplate}>
                        <p>{translations.users.text.downloadTemplateLink} </p>
                    </a>
                    <p>{translations.users.text.batchImportWarning}</p>
                </div>
                <div className={styles.import_page_importBodyContainer}>
                    {loading && <PageLoadingSpinner />}
                    {!loading &&
                        (batchImportDemeterUsersResponse ? (
                            <AgGridBuilder
                                gridRef={usersImportGridReference}
                                rowData={batchImportDemeterUsersResponse.results}
                                columnDefinitions={usersImportResultsColumnDefinitions}
                                defaultColumnDefinition={usersImportResultsColumnDefinitions}
                                gridHeightFull
                            />
                        ) : (
                            <FileUploader title={translations.actions.browseFiles} handleSubmit={(file) => refreshApi(file)} />
                        ))}
                </div>
            </div>
        </div>
    );
};

export default UsersImportPage;
