import { Currency, UnitOfMeasure } from '../../../Generated/Raven-Demeter';

export interface IUserData {
    id: string;
    name: string;
    currency: Currency;
    unitOfMeasure: UnitOfMeasure;
    fileName: string;
    data: IUserDataItem[];
}

export interface IUserDataItem {
    value: number;
    asOfDate: Date;
}

export const userDataCurrencies = [Currency.Usd, Currency.Eur, Currency.Gbp];

export const userDataUnitOfMeasures = [
    UnitOfMeasure.HundredWeight,
    UnitOfMeasure.Kilogram,
    UnitOfMeasure.MetricTon,
    UnitOfMeasure.Pound,
    UnitOfMeasure.ShortTon,
];
