import { NavigateFunction } from 'react-router-dom';
import applicationSettings from '../../Core/Settings/ApplicationSettings';
import NavigationRoutes from '../../Layouts/NavigationRoutes';

/* eslint-disable class-methods-use-this */
class BrowserService {
    private navigate?: NavigateFunction;

    setReactNavigate(navigate: NavigateFunction) {
        this.navigate = navigate;
    }

    downloadCsv = async (stringifiedData: string, fileName: string) => {
        const csvBlob = new Blob([stringifiedData], { type: 'text/csv' });
        const url = URL.createObjectURL(csvBlob);
        const link = document.createElement('a');
        link.download = `${fileName}.csv`;
        link.href = url;
        link.click();
        link.remove();
    };

    navigateToDashboard = (reloadPage: boolean = false) => {
        this.navigate!(NavigationRoutes.Dashboard, { replace: true });
        if (reloadPage) {
            window.location.reload();
        }
    };

    navigateToLoginPage = () => {
        if (applicationSettings.isMono) {
            this.setWindowLocation(applicationSettings.links.monoUri ?? '');
        } else {
            this.navigate!(NavigationRoutes.Home);
        }
    };

    navigateToSessionExpiration = () => {
        this.navigate!(NavigationRoutes.SessionExpiration);
    };

    navigateToMarketIntelligence = () => {
        this.setWindowLocation(applicationSettings.links.marketIntelligenceUri ?? '');
    };

    openMarketIntelligenceArticle = (searchParameters: { language: string | null | undefined; sidebarLabel?: string; articleId?: string }) => {
        window.open(
            `${applicationSettings.links.marketIntelligenceUri}/article-landing?${Object.entries(searchParameters)
                .map(([key, value]) => `${key}=${value}`)
                .join('&')}`,
            '_blank',
        );
    };

    setWindowLocation = (newLocation: string) => window.location.assign(newLocation);

    getLocalStorageItem = (key: string) => localStorage.getItem(`${applicationSettings.applicationName}-${key}`);

    setLocalStorageItem = (key: string, value: string) => localStorage.setItem(`${applicationSettings.applicationName}-${key}`, value);

    deleteLocalStorageItem = (key: string) => localStorage.removeItem(`${applicationSettings.applicationName}-${key}`);
}

const browserService = new BrowserService();

export default browserService;
