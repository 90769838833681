import CloseIcon from '@mui/icons-material/Close';
import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import styles from './AlertModal.module.scss';
import AlertModalInterface from './AlertModalInterface';

const AlertModal: React.FC<AlertModalInterface> = ({ setShowModal, message }) => {
    const [domReady, setDomReady] = useState(false);

    useEffect(() => {
        const AlertDiv = document.createElement('div');
        AlertDiv.id = 'portal';
        document.body.appendChild(AlertDiv);
        const portal = document.getElementById('portal');
        if (portal) {
            setDomReady(true);
        }

        return () => {
            AlertDiv.remove();
        };
    }, []);

    // close the modal when clicking outside the modal.
    const modalRef = useRef<HTMLDivElement>(null);
    const closeModal = (e: any) => {
        if (e.target === modalRef.current) {
            setShowModal(false);
        }
    };
    // render the modal JSX in the portal div.
    return (
        <div>
            {domReady &&
                ReactDOM.createPortal(
                    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
                    <div className={styles.alert_modal_container} ref={modalRef} onClick={closeModal}>
                        <div className={styles.alert_modal_box}>
                            {typeof message === 'string' ? <h3>{message}</h3> : message}
                            <CloseIcon className={styles.alert_modal_close} onClick={() => setShowModal(false)} />
                        </div>
                    </div>,
                    document.getElementById('portal') as HTMLElement,
                )}
        </div>
    );
};

export default AlertModal;
