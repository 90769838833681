import { useEffect, useState } from 'react';
import formattingService from '../../../../../../../Core/Formatting/FormattingService';
import { MarketIndicatorOutlook } from '../../../../../../../Generated/Raven-Demeter';
import LinkButton, { LinkButtonType } from '../../../../../../Components/Form/Buttons/LinkButton';
import DatePickerInput from '../../../../../../Components/Form/Inputs/DatePickerInput';
import TextArea from '../../../../../../Components/Form/Inputs/TextArea';
import useLanguage from '../../../../../../Services/Language/useLanguageHook';
import { ManualEntryParameters, MarketIndicatorManualEntryLine, UpsertMarketIndicatorFactorRequest } from '../../../MarketIndicatorsManagementDefinitions';
import styles from '../../MarketIndicatorFactorPage.module.scss';
import MarketFactorOutlookDropdown from './MarketFactorOutlookDropdown';

interface IMarketFactorManualEntryAreaProps {
    upsertMarketIndicatorFactorRequest: UpsertMarketIndicatorFactorRequest;
    setUpsertMarketIndicatorFactorRequest: (value: UpsertMarketIndicatorFactorRequest) => void;
}

const MarketFactorManualEntryArea: React.FC<IMarketFactorManualEntryAreaProps> = (props: IMarketFactorManualEntryAreaProps) => {
    const [translations] = useLanguage();
    const [outlookHistoryRaw, setOutlookHistoryRaw] = useState<string>();
    const [manualEntryDate, setManualEntryDate] = useState(new Date());

    useEffect(() => {
        if ((props.upsertMarketIndicatorFactorRequest.parameters as ManualEntryParameters).outlookHistory) {
            setOutlookHistoryRaw(
                marketFactorManualEntryFormatToCommaSeparatedValue(
                    (props.upsertMarketIndicatorFactorRequest.parameters as ManualEntryParameters).outlookHistory,
                ),
            );
        }
    }, [props.upsertMarketIndicatorFactorRequest?.templateType]);

    useEffect(() => {
        if (!outlookHistoryRaw) {
            return;
        }

        props.setUpsertMarketIndicatorFactorRequest({
            ...props.upsertMarketIndicatorFactorRequest,
            parameters: {
                ...props.upsertMarketIndicatorFactorRequest.parameters,
                outlookHistory: JSON.stringify(marketFactorManualEntryFormatToJson(outlookHistoryRaw).map((x) => ({ ...x, Value: '0' }))),
            },
        });
    }, [outlookHistoryRaw]);

    // Convert comma separated value to stringified json to store in backend.
    const marketFactorManualEntryFormatToJson = (commaSeparatedValue?: string) => {
        const lines = commaSeparatedValue?.split('\n') ?? [];
        const marketIndicatorManualEntryKeys: (keyof MarketIndicatorManualEntryLine)[] = ['AsOfDate', 'Outlook'];

        const finalJsonArray = lines.map((x) => {
            const outlookObject = {} as MarketIndicatorManualEntryLine;
            const currentline = x
                .replace(/\s/g, '')
                .split(',')
                .filter((y) => y !== '0')
                .map((y) => y.replace('Neutral', MarketIndicatorOutlook.Flat));

            marketIndicatorManualEntryKeys.forEach((header, index) => {
                outlookObject[header as keyof typeof outlookObject] = currentline[index];
            });

            return outlookObject;
        });

        return finalJsonArray.filter((x) => x.AsOfDate && x.Outlook);
    };

    // Convert response json from to comma separated value for display.
    const marketFactorManualEntryFormatToCommaSeparatedValue = (stringifiedJson: string) => {
        const parsedJson: MarketIndicatorManualEntryLine[] = JSON.parse(stringifiedJson);
        const manualEntryValues =
            parsedJson
                .map((x) => {
                    delete x.Value;
                    return x;
                })
                .map((x) => Object.values(x)) ?? [];

        const commaSeparatedValuesWithoutHeaders = manualEntryValues.map((x) => x.join(',')).join('\n');

        return `${commaSeparatedValuesWithoutHeaders}`.replaceAll(MarketIndicatorOutlook.Flat, 'Neutral');
    };

    const updateOutlookHistory = () => {
        const newOutlook: MarketIndicatorManualEntryLine = {
            AsOfDate: formattingService.toApiDate(manualEntryDate),
            Outlook: (props.upsertMarketIndicatorFactorRequest.parameters as ManualEntryParameters).outlook,
        };
        const jsonFormattedManualEntry = marketFactorManualEntryFormatToJson(outlookHistoryRaw).filter((x) => x.AsOfDate !== newOutlook.AsOfDate);

        const newManualEntry = [...jsonFormattedManualEntry, newOutlook].sort((a, b) => new Date(a.AsOfDate).getTime() - new Date(b.AsOfDate).getTime());
        setOutlookHistoryRaw(marketFactorManualEntryFormatToCommaSeparatedValue(JSON.stringify(newManualEntry)));
    };

    return (
        <>
            <MarketFactorOutlookDropdown
                upsertMarketIndicatorFactorRequest={props.upsertMarketIndicatorFactorRequest}
                setUpsertMarketIndicatorFactorRequest={props.setUpsertMarketIndicatorFactorRequest}
            />
            <div className={styles.indicator_add_and_edit_manual_adding_section}>
                <DatePickerInput value={manualEntryDate} title={translations.text.asOfDate} handleDateChange={(value) => setManualEntryDate(value as Date)} />
                <div className={styles.indicator_add_and_edit_manual_apply_button}>
                    <LinkButton title={translations.actions.apply} type={LinkButtonType.Blue} onClick={updateOutlookHistory} />
                </div>
            </div>
            <TextArea
                title={translations.marketIndicatorsManagement.fields.manualEntry}
                className={styles.indicator_add_and_edit_manual_entry}
                value={outlookHistoryRaw}
                handleTextChange={(value) => setOutlookHistoryRaw(value)}
            />
        </>
    );
};

export default MarketFactorManualEntryArea;
