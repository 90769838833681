/* eslint-disable @typescript-eslint/indent */
import { useMemo } from 'react';
import { demeterApi } from '../../../Apis/Apis';
import CacheKeys from '../../../Core/Cache/CacheKeys';
import { Currency, DemeterFilterTimeSpan, DemeterTableDefinitionType, UnitOfMeasure } from '../../../Generated/Raven-Demeter/api';
import { useApplicationSelector } from '../../../Redux/ReduxStore';
import { selectUserCurrentMarket } from '../../../Redux/Slices/UserSlice';
import { IRegionCommoditySelection } from '../../Components/Navigation/Hooks/useRegionCommodityNavigationHook';
import useTableDefinition from '../../Components/Navigation/Hooks/useTableDefinitionHook';
import useCacheThenApi from './useCacheThenApiHook';
import { ListCommodityPricesResponse } from './usePricesApiHook';

const usePricesByFrequencyApi = (
    regionCommoditySelection: IRegionCommoditySelection,
    currency?: Currency,
    unitOfMeasure?: UnitOfMeasure,
    timeSpan?: DemeterFilterTimeSpan,
): ListCommodityPricesResponse | undefined => {
    const market = useApplicationSelector(selectUserCurrentMarket);
    const [, tableDefinitionCommodity] = useTableDefinition(DemeterTableDefinitionType.CommodityPricesTable, regionCommoditySelection);

    const cacheKey = useMemo(() => {
        const cacheKeyPrefix = `${CacheKeys.GetDemeterPrices}_${regionCommoditySelection.dataFrequency}`;
        if (!tableDefinitionCommodity || !regionCommoditySelection) {
            return cacheKeyPrefix;
        }

        const cacheKeySuffix = JSON.stringify({ ...regionCommoditySelection, currency, unitOfMeasure, timeSpan });
        return `${cacheKeyPrefix}_${cacheKeySuffix}`;
    }, [tableDefinitionCommodity, regionCommoditySelection, currency, unitOfMeasure, timeSpan]);

    const [, , listCommodityPricesByFrequency] = useCacheThenApi<ListCommodityPricesResponse>(cacheKey, () => {
        if (!tableDefinitionCommodity || !regionCommoditySelection || !regionCommoditySelection.region) {
            return null;
        }

        return demeterApi.listCommodityPricesByFrequency(
            regionCommoditySelection.region,
            regionCommoditySelection.commodity,
            regionCommoditySelection.dataFrequency,
            market,
            currency,
            unitOfMeasure,
        );
    });

    return listCommodityPricesByFrequency;
};

export default usePricesByFrequencyApi;
