import React from 'react';

export interface IPrivacyPolicyLinkProps {
    url?: string;
    text?: string;
}

const PrivacyPolicyLink: React.FC<IPrivacyPolicyLinkProps> = (props: IPrivacyPolicyLinkProps) => (
    <a href={props.url} target="_blank" rel="noopener noreferrer" className="privacy_policy_link">
        {props.text}
    </a>
);

export default PrivacyPolicyLink;
