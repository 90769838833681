export default {
    GetDemeterCommentary: 'getDemeterCommentary',
    GetCurrencyConversionChart: 'getCurrencyConversionChart',
    GetDemeterPrices: 'getDemeterPrices',
    GetDemeterProjection: 'getDemeterProjection',
    GetDemeterSeasonal: 'getDemeterSeasonal',
    GetUnitOfMeasureConversionChart: 'getUnitOfMeasureConversionChart',
    GetUserStore: 'getUserStore',
    GetDemeterOriginsOrDestinations: 'getDemeterOriginsDestinations',
    GetMarketIndicator: 'getMarketIndicator',
    GetMarketIndicatorFactor: 'getMarketIndicatorFactor',
    ListCommodityOtcPrices: 'listCommodityOtcPrices',
    ListCommodityOtcPricesCurrent: 'listCommodityOtcPricesCurrent',
    ListCommodityBasisPrices: 'listCommodityBasisPrices',
    ListCommoditySpotPricesSummary: 'listCommoditySpotPricesSummary',
    ListCommodityWeeklyPricesSummary: 'listCommodityWeeklyPricesSummary',
    ListCommodityYearlyBalances: 'listCommodityYearlyBalances',
    ListDemeterSymbols: 'listDemeterSymbols',
    ListDemeterTableDefinitions: 'listDemeterTableDefinitions',
    ListDemeterUsers: 'listDemeterUsers',
    ListDemeterUserPreferences: 'listDemeterUserPreferences',
    ListMarketIndicatorsCurrent: 'listMarketIndicatorsCurrent',
    ListMarketPriceVolatilities: 'listMarketPriceVolatilities',
    ListMarketPrices: 'listMarketPrices',
    ListMarketPricesComparisonChart: 'listMarketPricesComparisonChart',
    ListMarketPricesComparisons: 'listMarketPricesComparisons',
    ListMarketPricesForwardCurves: 'listMarketPricesForwardCurves',
    ListMarketPricesRolling: 'ListMarketPricesRolling',
    ListOtcPricesForwardCurves: 'listOtcPricesForwardCurves',
    ListProducerPriceIndex: 'ListProducerPriceIndex',
};
