import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { demeterMarketIndicatorsApi } from '../../../Apis/Apis';
import { DeleteMarketIndicatorFactorRequest, DemeterMarket, UpdateMarketIndicatorFactorGroupRequest } from '../../../Generated/Raven-Demeter';
import NavigationRoutes from '../../../Layouts/NavigationRoutes';
import useApi from '../../Apis/Hooks/useApiHook';
import useApiWithoutAutoExecute from '../../Apis/Hooks/useApiWithoutAutoExecute';
import useMultipleApis from '../../Apis/Hooks/useMultipleApisHook';
import addNewFactorGroup from '../../Assets/Icons/expand.svg';
import MarketIndicatorChart from '../../Components/Charts/MarketIndicator/MarketIndicatorChart';
import PageHeader from '../../Components/Headers/PageHeader';
import PageHeaderWithBackNavigation from '../../Components/Headers/PageHeaderWithBackNavigation';
import PageLoadingSpinner from '../../Components/LoadingSpinner/PageLoadingSpinner';
import useSearchParameters from '../../Components/Navigation/Hooks/useSearchParametersHook';
import useLanguage from '../../Services/Language/useLanguageHook';
import MarketIndicatorFactorGroup from './MarketIndicatorFactorGroup';
import styles from './MarketIndicatorFactorsPage.module.scss';
import MarketIndicatorGuageChart from './MarketIndicatorGuageChart';

interface IMarketIndicatorsProps {
    isInAdministrationMode?: boolean;
}

const defaultNumberOfFactorsInRow = 4;
const maximumNumberOfFactorGroups = 4;
const defaultMarket = DemeterMarket.All;

const MarketIndicatorsPage: React.FC<IMarketIndicatorsProps> = (props: IMarketIndicatorsProps) => {
    // Application hooks.
    const [translations, translate] = useLanguage();
    const navigate = useNavigate();
    const { marketIndicatorGuid } = useParams();
    const [searchParameters, setSearchParameters] = useSearchParameters();

    // Api hooks.
    const [, , listMarketIndicatorsResponse] = useApi(() => demeterMarketIndicatorsApi.listMarketIndicatorsCurrent());

    const [, refreshMarketIndicator, getMarketIndicatorResponse] = useApiWithoutAutoExecute(() =>
        demeterMarketIndicatorsApi.getMarketIndicator(marketIndicatorGuid!, true, props.isInAdministrationMode),
    );

    const [, refreshRunMarketIndicator, runMarketIndicatorResponse] = useApiWithoutAutoExecute(() =>
        demeterMarketIndicatorsApi.runMarketIndicator(marketIndicatorGuid!),
    );

    const [, refreshMarketIndicatorByRegionCommodity, getMarketIndicatorByRegionCommodityResponse] = useApiWithoutAutoExecute(() => {
        const { region, subRegion, commodity, dataSource } = listMarketIndicatorsResponse!.rows!.find(
            (x) => x.marketIndicatorGuid === searchParameters.marketIndicatorGuid,
        )!;
        return demeterMarketIndicatorsApi.getMarketIndicatorByRegionAndCommodity(region, commodity, subRegion ?? undefined, dataSource ?? undefined);
    });

    const [, handleUpdateMarketIndicatorFactorsGroupName] = useApiWithoutAutoExecute(
        (marketIndicatorFactorRequest?: UpdateMarketIndicatorFactorGroupRequest) =>
            demeterMarketIndicatorsApi.updateMarketIndicatorFactorGroup(
                marketIndicatorFactorRequest!.marketIndicatorGuid,
                marketIndicatorFactorRequest!.displayGroupName,
                {
                    marketIndicatorGuid: marketIndicatorFactorRequest!.marketIndicatorGuid,
                    displayGroupName: marketIndicatorFactorRequest!.displayGroupName,
                    newDisplayGroupName: marketIndicatorFactorRequest!.newDisplayGroupName,
                },
            ),
        {
            successMessage: translations.marketIndicatorsManagement.messages.factorGroupNameUpdateSuccessful,
            errorMessage: translations.marketIndicatorsManagement.messages.factorGroupNameUpdateFailed,
        },
    );

    const [, handleDeleteMarketIndicatorFactors, deleteMarketIndicatorsFactorsResponse] = useMultipleApis(
        (marketIndicatorFactorRequest?: DeleteMarketIndicatorFactorRequest | DeleteMarketIndicatorFactorRequest[]) => {
            const marketIndicatorFactorRequests = Array.isArray(marketIndicatorFactorRequest) ? marketIndicatorFactorRequest : [marketIndicatorFactorRequest];

            return marketIndicatorFactorRequests?.map((x) =>
                demeterMarketIndicatorsApi.deleteMarketIndicatorFactor(x!.marketIndicatorGuid, x!.marketIndicatorFactorGuid),
            );
        },
        {
            stopAutoExecute: true,
            successMessage: translations.marketIndicatorsManagement.messages.marketIndicatorFactorDeleteSuccessful,
            errorMessage: translations.marketIndicatorsManagement.messages.marketIndicatorFactorDeleteFailed,
        },
    );

    const marketFactorsResponse = useMemo(
        () => getMarketIndicatorResponse?.marketIndicator ?? getMarketIndicatorByRegionCommodityResponse?.marketIndicator,
        [getMarketIndicatorResponse, getMarketIndicatorByRegionCommodityResponse],
    );

    // Component states.
    const [addingFactorGroupOn, setAddingFactorGroupOn] = useState<boolean>();
    const guageChartTitleString = useMemo(() => translate(marketFactorsResponse?.displayName ?? ''), [translations, marketFactorsResponse]);
    const [selectedMarket, setSelectedMarket] = useState<DemeterMarket>(defaultMarket);

    // Wait until we have factor groups to avoid getting oops message.
    useEffect(() => {
        if (!props.isInAdministrationMode || !getMarketIndicatorResponse || getMarketIndicatorResponse?.marketIndicator?.factorGroups.length === 0) {
            return;
        }

        refreshRunMarketIndicator();
    }, [marketFactorsResponse]);

    useEffect(() => {
        if (!listMarketIndicatorsResponse || listMarketIndicatorsResponse.rows?.length === 0) {
            return;
        }

        if (props.isInAdministrationMode) {
            refreshMarketIndicator();
        } else {
            refreshMarketIndicatorByRegionCommodity();
        }
    }, [searchParameters.marketIndicatorGuid, listMarketIndicatorsResponse, deleteMarketIndicatorsFactorsResponse]);

    useEffect(() => {
        if (
            !listMarketIndicatorsResponse ||
            listMarketIndicatorsResponse?.rows?.length === 0 ||
            searchParameters.marketIndicatorGuid ||
            props.isInAdministrationMode
        ) {
            return;
        }

        setSearchParameters({ marketIndicatorGuid: listMarketIndicatorsResponse?.rows![0].marketIndicatorGuid! });
    }, [props.isInAdministrationMode, listMarketIndicatorsResponse]);

    const navigateBackToMarketIndicators = () => {
        setSearchParameters({ tab: 'MarketIndicators' });
        // Change this back to navigate(-1) after getting out of mono.
        navigate(NavigationRoutes.Administration);
    };

    const marketIndicatorMarketOptions = useMemo(() => {
        if (!listMarketIndicatorsResponse) {
            return [];
        }
        const options: { label: string; value: DemeterMarket }[] = [];

        listMarketIndicatorsResponse.rows?.forEach((x) => {
            if (options.find((y) => y.value === x.market)) {
                return;
            }

            options.push({
                label: translations.market[x.market!],
                value: x.market!,
            });
        });

        options.push({
            label: translations.market[DemeterMarket.All],
            value: DemeterMarket.All,
        });

        return options;
    }, [listMarketIndicatorsResponse, translations]);

    return !marketFactorsResponse ? (
        <PageLoadingSpinner />
    ) : (
        <div className={styles.master_page_container}>
            {props.isInAdministrationMode ? (
                <PageHeaderWithBackNavigation
                    handleBackNavigation={navigateBackToMarketIndicators}
                    title={translations.marketIndicatorsManagement.title}
                    testId="MarketIndicatorsPageHeader"
                />
            ) : (
                <>
                    <PageHeader title={translations.marketIndicators.title} testId="MarketIndicatorsPageHeader" />
                    <div className={styles.markets_management_indicator_menu_tabs}>
                        {marketIndicatorMarketOptions.map((x) => (
                            <button
                                className={
                                    selectedMarket === x.value
                                        ? styles.markets_management_indicator_menu_tab_selected
                                        : styles.markets_management_indicator_menu_tab
                                }
                                type="button"
                                onClick={() => {
                                    setSelectedMarket(x.value);
                                    setSearchParameters({
                                        marketIndicatorGuid: listMarketIndicatorsResponse?.rows!.filter((y) => x.value === y.market)[0].marketIndicatorGuid!,
                                    });
                                }}
                                data-testid={`MarketIndicatorMarket${x.value}`}
                            >
                                {x.label}
                            </button>
                        ))}
                    </div>
                    <div className={styles.markets_management_indicator_menu}>
                        {listMarketIndicatorsResponse?.rows
                            ?.filter((x) => selectedMarket === DemeterMarket.All || selectedMarket === x.market)
                            .map((marketIndicator) => (
                                <button
                                    type="button"
                                    onClick={() => setSearchParameters({ marketIndicatorGuid: marketIndicator.marketIndicatorGuid })}
                                    className={
                                        marketIndicator.marketIndicatorGuid === searchParameters.marketIndicatorGuid
                                            ? styles.markets_management_indicator_menu_button_selected
                                            : styles.markets_management_indicator_menu_button
                                    }
                                    data-testid={`MenuMarketIndicator${marketIndicator.displayName}`}
                                >
                                    {translate(marketIndicator.displayName ?? '')}
                                </button>
                            ))}
                    </div>
                </>
            )}
            <div className={styles.markets_management_indicator_row}>
                <div className={props.isInAdministrationMode ? styles.markets_management_indicators : styles.markets_management_indicators_reverse}>
                    {(runMarketIndicatorResponse?.marketIndicator?.factorGroups ?? marketFactorsResponse.factorGroups)
                        .filter((x) => x.factors.length > 0)
                        .map((x) => (
                            <MarketIndicatorFactorGroup
                                key={x.displayGroupName}
                                displayName={x.displayGroupName}
                                factors={x.factors.sort((a, b) => a.order - b.order)}
                                handleUpdateMarketIndicatorFactorsGroup={handleUpdateMarketIndicatorFactorsGroupName}
                                handleDeleteMarketIndicatorFactors={handleDeleteMarketIndicatorFactors}
                                isInAdministrationMode={props.isInAdministrationMode}
                                nextOrder={x.factors.sort((a, b) => a.order - b.order)[x.factors.length - 1].order + 1}
                            />
                        ))}
                    {addingFactorGroupOn && (
                        <MarketIndicatorFactorGroup
                            displayName={translations.marketIndicatorsManagement.text.newFactorGroup}
                            factors={[]}
                            handleUpdateMarketIndicatorFactorsGroup={handleUpdateMarketIndicatorFactorsGroupName}
                            handleDeleteMarketIndicatorFactors={() => setAddingFactorGroupOn(false)}
                            isInAdministrationMode={props.isInAdministrationMode}
                            nextOrder={
                                (marketFactorsResponse?.factorGroups[(marketFactorsResponse?.factorGroups.length ?? 0) - 1]?.order ?? 0) +
                                defaultNumberOfFactorsInRow
                            }
                        />
                    )}
                    {props.isInAdministrationMode &&
                        marketFactorsResponse?.factorGroups.filter((x) => x.factors.length > 0).length < maximumNumberOfFactorGroups && (
                            <button type="button" onClick={() => setAddingFactorGroupOn(true)} className={styles.markets_management_add_factor_group}>
                                <img src={addNewFactorGroup} alt="#" /> {translations.marketIndicatorsManagement.text.addFactorGroup}
                            </button>
                        )}
                </div>
                <div className={props.isInAdministrationMode ? styles.markets_management_guage : styles.markets_management_guage_reverse}>
                    <MarketIndicatorGuageChart
                        title={guageChartTitleString}
                        marketIndicator={runMarketIndicatorResponse?.marketIndicator ?? marketFactorsResponse!}
                    />
                </div>
            </div>
            <div className={styles.markets_management_timeline_chart}>
                <MarketIndicatorChart
                    runMarketIndicatorResponse={runMarketIndicatorResponse ?? getMarketIndicatorResponse ?? getMarketIndicatorByRegionCommodityResponse}
                />
            </div>
        </div>
    );
};

export default MarketIndicatorsPage;
