import { useLottie } from 'lottie-react';
import React from 'react';
import loaderGradient from '../../Assets/LoadingSpinner/loaderGradient.json';
import styles from './LoadingSpinner.module.scss';

const PageLoadingSpinner: React.FC = () => {
    const options = {
        animationData: loaderGradient,
        loop: true,
    };

    const { View } = useLottie(options);
    return <div className={styles.loading_spinner}>{View}</div>;
};

export default PageLoadingSpinner;
