import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-theme-dark.css';
import 'ag-grid-enterprise';
import React from 'react';
import useLanguage from '../../../Services/Language/useLanguageHook';
import styles from './ShowMoreAccordian.module.scss';

interface IShowMoreAccordianProps {
    showAccordian: boolean;
    showMore: boolean;
    handleShowMoreToggle: (showMore: boolean) => void;
    additionalText?: string;
}

const ShowMoreAccordian: React.FC<IShowMoreAccordianProps> = (props: IShowMoreAccordianProps) => {
    const [translations] = useLanguage();

    if (props.showAccordian === false && !props.additionalText) {
        return null;
    }

    return (
        <div className={styles.show_more_accordian_container}>
            <button
                type="button"
                className={styles.master_ag_grid_show_more_button}
                onClick={() => props.showAccordian && props.handleShowMoreToggle(!props.showMore)}
            >
                <div className={styles.show_more_accordian_pointer_on_hover}>
                    {props.showAccordian && (
                        <>
                            <span className={styles.show_more_accordian_more_or_less}>
                                {props.showMore ? translations.actions.less : translations.actions.more}
                            </span>
                            {props.showMore ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </>
                    )}
                    <div className={styles.show_more_accordian_additional_message}>{props.additionalText}</div>
                </div>
            </button>
        </div>
    );
};

export default ShowMoreAccordian;
