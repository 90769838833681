import { Checkbox, FormControlLabel } from '@mui/material';
import { useEffect, useState } from 'react';
import styles from './Inputs.module.scss';

interface ICheckboxInputProps {
    title: string;
    isChecked: boolean;
    handleIsChecked: (isChecked: boolean) => void;
}

const CheckboxInput = (props: ICheckboxInputProps): JSX.Element => {
    const [isChecked, setIsChecked] = useState<boolean>(props.isChecked);

    useEffect(() => {
        setIsChecked(props.isChecked);
    }, [props.isChecked]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { checked } = event.target;
        setIsChecked(checked);
        props.handleIsChecked(checked);
    };

    return (
        <div className={styles.input_input_container}>
            <FormControlLabel control={<Checkbox checked={isChecked} onChange={handleChange} color="primary" />} label={props.title} />
        </div>
    );
};

export default CheckboxInput;
