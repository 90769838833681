import { EventActionsEnum, EventDataTargetsEnum } from '../../../Core/Logging/DataLayerDefinitions';
import loggingService from '../../../Core/Logging/LoggingService';
import applicationSettings from '../../../Core/Settings/ApplicationSettings';
import applicationConstants from '../../../Core/Utility/ApplicationConstants';
import PageHeader from '../../Components/Headers/PageHeader';
import browserService from '../../Services/BrowserService';
import useLanguage from '../../Services/Language/useLanguageHook';
import styles from './OutlookAndReports.module.scss';
import outlooksAndReportsData from './OutlookAndReportsDefinitions';

const OutlookAndReportsPage: React.FC = () => {
    const logReportAnalytics = (linkTitle: string) => {
        loggingService.trackEventWithAnalytics(EventActionsEnum.OutlookSelection, linkTitle, '', EventDataTargetsEnum.OutlookLink);
    };
    const [translations, , language] = useLanguage();
    const isDevelopmentLikeEnvironment = applicationSettings.isDevelopmentLike;

    return (
        <div className={styles.master_page_container}>
            <PageHeader title={translations.outlookAndReports.title} />
            <div className={styles.outlook_flex_container}>
                {outlooksAndReportsData.map((element) => (
                    <div className={styles.outlook_column}>
                        <span className={styles.outlook_subheader}>{translations.outlookAndReports[element.sectionHeader]}</span>
                        {element.data.map((outlookLink) => (
                            <>
                                <button
                                    className={styles.outlook_internal_link}
                                    type="button"
                                    onClick={() => {
                                        const environmentSideBarLabel = isDevelopmentLikeEnvironment
                                            ? applicationConstants.MarketIntelligenceSidebarLabelDevelopment
                                            : outlookLink.sidebarLabel;
                                        logReportAnalytics(outlookLink.title);
                                        browserService.openMarketIntelligenceArticle({
                                            language,
                                            sidebarLabel: environmentSideBarLabel,
                                        });
                                    }}
                                >
                                    {translations.outlookAndReports[outlookLink.title]}
                                </button>
                                {outlookLink.hasLineBreakAfter && <br />}
                            </>
                        ))}
                        <br />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default OutlookAndReportsPage;
