import { useEffect, useMemo, useState } from 'react';
import { DemeterSymbolModel } from '../../../Generated/Raven-Demeter';
import chartArrowLeftSvg from '../../Assets/Icons/chartArrowLeft.svg';
import chartArrowRightSvg from '../../Assets/Icons/chartArrowRight.svg';
import useSearchParameters from '../../Components/Navigation/Hooks/useSearchParametersHook';
import documentObjectModelService from '../../Services/DocumentObjectModelService';
import CumulativeValueAtRiskChart from './Charts/CumulativeValueAtRiskChart';
import ForwardCurveAndBasisChart from './Charts/FowardCurveAndBasisChart';
import MarkToMarketChart from './Charts/MarkToMarketChart';
import MonthlyNetValueAtRiskChart from './Charts/MonthlyNetValueAtRiskChart';
import NetPositionChart from './Charts/NetPositionChart';
import NetPositionCumulativeBudgetAtRiskChart from './Charts/NetPositionCumulativeBudgetAtRiskChart';
import NetPositionMonthlyBudgetAtRiskChart from './Charts/NetPositionMonthlyBudgetAtRiskChart';
import ValueAtRiskAssumptionsChart from './Charts/ValueAtRiskAssumptionsChart';
import { MonthlyRiskCompositeModel, RiskRequest } from './RiskDefinitions';
import styles from './RiskPage.module.scss';

interface IRiskCarouselProps {
    symbol: DemeterSymbolModel;
    risksRequest: RiskRequest;
    calculatedRisks: MonthlyRiskCompositeModel[];
    displayDecimalPlaces: number;
}

const baseChartWidth = '900';

const RiskCarousel: React.FC<IRiskCarouselProps> = (props: IRiskCarouselProps) => {
    const [chartWidth, setChartWidth] = useState(baseChartWidth);
    const [searchParamters] = useSearchParameters();

    const chartDisplayOptions = useMemo(() => {
        const options = [
            {
                label: 'ValueAtRiskAssumptionsChart',
                value: (
                    <ValueAtRiskAssumptionsChart
                        symbol={props.symbol}
                        risksRequest={props.risksRequest}
                        calculatedRisks={props.calculatedRisks}
                        displayDecimalPlaces={props.displayDecimalPlaces}
                    />
                ),
            },
            {
                label: 'CumulativeValueAtRiskChart',
                value: <CumulativeValueAtRiskChart symbol={props.symbol} risksRequest={props.risksRequest} calculatedRisks={props.calculatedRisks} />,
            },
            {
                label: 'ForwardCurveAndBasisChart',
                value: (
                    <ForwardCurveAndBasisChart
                        symbol={props.symbol}
                        risksRequest={props.risksRequest}
                        calculatedRisks={props.calculatedRisks}
                        displayDecimalPlaces={props.displayDecimalPlaces}
                    />
                ),
            },
            {
                label: 'MarkToMarketChart',
                value: <MarkToMarketChart symbol={props.symbol} risksRequest={props.risksRequest} calculatedRisks={props.calculatedRisks} />,
            },
            {
                label: 'MonthlyNetValueAtRiskChart',
                value: <MonthlyNetValueAtRiskChart symbol={props.symbol} risksRequest={props.risksRequest} calculatedRisks={props.calculatedRisks} />,
            },
            {
                label: 'NetPositionChart',
                value: <NetPositionChart symbol={props.symbol} risksRequest={props.risksRequest} calculatedRisks={props.calculatedRisks} />,
            },
        ];

        if (searchParamters.tab === 'Budget') {
            options.push(
                {
                    label: 'NetPositionMonthlyBudgetAtRiskChart',
                    value: (
                        <NetPositionMonthlyBudgetAtRiskChart symbol={props.symbol} risksRequest={props.risksRequest} calculatedRisks={props.calculatedRisks} />
                    ),
                },
                {
                    label: 'NetPositionCumulativeBudgetAtRiskChart',
                    value: (
                        <NetPositionCumulativeBudgetAtRiskChart
                            symbol={props.symbol}
                            risksRequest={props.risksRequest}
                            calculatedRisks={props.calculatedRisks}
                        />
                    ),
                },
            );
        }

        return options;
    }, [props.symbol, props.risksRequest, props.calculatedRisks, props.displayDecimalPlaces]);

    const [currentChartToDisplay, setCurrentChartToDisplay] = useState(chartDisplayOptions[0]);

    useEffect(() => {
        const indexOfCurrentChartToDisplay = chartDisplayOptions.findIndex((x) => x.label === currentChartToDisplay.label);
        setCurrentChartToDisplay(chartDisplayOptions[indexOfCurrentChartToDisplay]);
    }, [chartDisplayOptions]);

    // TODO: IMPORTANT - Need to find what to listen for here. Maybe a ref?
    useEffect(() => {
        const chartDisplayAreaWidth = documentObjectModelService.getWidthOfAnElementByClassName('highcharts-background');

        if (chartDisplayAreaWidth && chartDisplayAreaWidth !== chartWidth) {
            setChartWidth(chartDisplayAreaWidth);
        }
    });

    const handleChartChange = (isNext: boolean) => {
        const indexOfCurrentChartToDisplay = chartDisplayOptions.findIndex((x) => x.label === currentChartToDisplay.label);

        if (isNext && indexOfCurrentChartToDisplay === chartDisplayOptions.length - 1) {
            setCurrentChartToDisplay(chartDisplayOptions[0]);
        } else if (isNext) {
            setCurrentChartToDisplay(chartDisplayOptions[indexOfCurrentChartToDisplay + 1]);
        } else if (!isNext && indexOfCurrentChartToDisplay === 0) {
            setCurrentChartToDisplay(chartDisplayOptions[chartDisplayOptions.length - 1]);
        } else {
            setCurrentChartToDisplay(chartDisplayOptions[indexOfCurrentChartToDisplay - 1]);
        }
    };

    return (
        <div className={styles.risk_carousel}>
            {currentChartToDisplay.value}
            <div style={{ width: `${chartWidth}px` }} className={styles.risk_carousel_buttons}>
                <button type="button" className={styles.risk_carousel_button} onClick={() => handleChartChange(false)}>
                    <img src={chartArrowLeftSvg} alt="#" />
                </button>
                <button type="button" className={styles.risk_carousel_button} onClick={() => handleChartChange(true)}>
                    <img src={chartArrowRightSvg} alt="#" />
                </button>
            </div>
        </div>
    );
};

export default RiskCarousel;
