import { Link } from 'react-router-dom';
import { DemeterCompanyModel } from '../../../../Generated/Raven-Demeter';
import NavigationRoutes from '../../../../Layouts/NavigationRoutes';
import useLanguage from '../../../Services/Language/useLanguageHook';
import styles from './CompaniesPage.module.scss';

interface ICompanyActionsProps {
    company: DemeterCompanyModel;
}

const CompanyActions: React.FC<ICompanyActionsProps> = (props: ICompanyActionsProps) => {
    const [translations] = useLanguage();

    return (
        <div className={styles.companies_actions_container}>
            <Link className={styles.companies_actions_link_button} to={`${NavigationRoutes.Companies}/${props.company.demeterCompanyGuid}/edit`}>
                {translations.actions.edit}
            </Link>
            {' | '}
            <Link className={styles.companies_actions_link_button} to={`${NavigationRoutes.Companies}/${props.company.demeterCompanyGuid}/users`}>
                {translations.actions.details}
            </Link>
        </div>
    );
};

export default CompanyActions;
