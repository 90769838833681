/* eslint-disable max-len */
import React, { useMemo } from 'react';
import { DemeterRegion, DemeterTableDefinitionType, UnitOfMeasure } from '../../../../Generated/Raven-Demeter';
import useLanguage from '../../../Services/Language/useLanguageHook';
import { IRegionCommoditySelection } from '../../Navigation/Hooks/useRegionCommodityNavigationHook';
import useTableDefinition from '../../Navigation/Hooks/useTableDefinitionHook';
import SeasonalTables from './SeasonalTables';
import styles from './SeasonalTables.module.scss';

export interface ISeasonalDataTableProps {
    title: string;
    tableDefinitionType: DemeterTableDefinitionType;
    regionCommoditySelection: IRegionCommoditySelection;
    aggregateRegions?: DemeterRegion[];
    unitOfMeasure?: UnitOfMeasure;
    testId?: string;
}

const SeasonalValueAndPercentTables: React.FC<ISeasonalDataTableProps> = (props: ISeasonalDataTableProps) => {
    const [tableDefinitionRegion, tableDefinitionCommodity] = useTableDefinition(props.tableDefinitionType, props.regionCommoditySelection);
    const [translations, translate] = useLanguage();

    const title = useMemo(
        () =>
            `${
                props.regionCommoditySelection?.subRegion
                    ? translate(props.regionCommoditySelection?.subRegion)
                    : translate(tableDefinitionRegion?.displayName!)
            } ${translate(tableDefinitionCommodity?.displayName!)} ${props.title} ${translations.words.historical}`,
        [props.title, props.regionCommoditySelection?.subRegion, tableDefinitionRegion, tableDefinitionCommodity],
    );

    return (
        <div className={styles.seasonal_table_wrapper_container}>
            <SeasonalTables
                title={title}
                tableDefinitionType={props.tableDefinitionType}
                regionCommoditySelection={props.regionCommoditySelection}
                aggregateRegions={props.aggregateRegions}
                unitOfMeasure={props.unitOfMeasure}
                showPercentTable
                testId={props.testId}
            />
        </div>
    );
};

export default SeasonalValueAndPercentTables;
