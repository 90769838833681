import Tooltip from '@mui/material/Tooltip';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { demeterCalculatorsApi, futuresApi } from '../../../../Apis/Apis';
import CacheKeys from '../../../../Core/Cache/CacheKeys';
import formattingService from '../../../../Core/Formatting/FormattingService';
import {
    Currency,
    DemeterCommodity,
    DemeterFilterTimeSpan,
    DemeterSymbolModel,
    FuturesDirection,
    SymbolCategory,
    UnitOfMeasure,
    UpdatePositionsCalculatorRequest,
} from '../../../../Generated/Raven-Demeter';
import useApi from '../../../Apis/Hooks/useApiHook';
import useCacheOrApi from '../../../Apis/Hooks/useCacheOrApiHook';
import useSymbolsApi from '../../../Apis/Hooks/useSymbolsApiHook';
import useUnitOfMeasureConversionApi from '../../../Apis/Hooks/useUnitOfMeasureConversionApiHook';
import minusSvg from '../../../Assets/Icons/collapse.svg';
import plusSvg from '../../../Assets/Icons/expand.svg';
import infoSvg from '../../../Assets/Icons/info.svg';
import LinkButton, { LinkButtonType } from '../../../Components/Form/Buttons/LinkButton';
import Dropdown from '../../../Components/Form/Inputs/Dropdown';
import TextInput from '../../../Components/Form/Inputs/TextInput';
import ComponentHeader from '../../../Components/Headers/ComponentHeader';
import PageLoadingSpinner from '../../../Components/LoadingSpinner/PageLoadingSpinner';
import useMarketPricesForwardCurve from '../../../Core/Hooks/useMarketPricesForwardCurveHook';
import RegexValidators from '../../../Core/Validation/RegexValidators';
import documentObjectModelService from '../../../Services/DocumentObjectModelService';
import useLanguage from '../../../Services/Language/useLanguageHook';
import RiskCalculatorInputRow from '../RiskCalculatorInputRow';
import RiskCarousel from '../RiskCarousel';
import RiskDataRow from '../RiskDataRow';
import { MonthlyRiskCompositeModel, RiskRequest, TabEventKeyType } from '../RiskDefinitions';
import styles from '../RiskPage.module.scss';
import StickyColumnGroup from '../StickyColumnGroup';

// Right now we only Dairy commodities with only USD and EUR currencies.
const allowedCategories: SymbolCategory[] = [SymbolCategory.Dairy];
const allowedCurrencies: Currency[] = [Currency.Usd, Currency.Eur];
const allowedUnitOfMeasures: UnitOfMeasure[] = [
    UnitOfMeasure.HundredWeight,
    UnitOfMeasure.Kilogram,
    UnitOfMeasure.MetricTon,
    UnitOfMeasure.Pound,
    UnitOfMeasure.ShortTon,
];
const notAllowedCommodities: DemeterCommodity[] = [DemeterCommodity.FluidMilk];
const initialCommodity = DemeterCommodity.Butter;
const defaultContractNumber = 2;
const defaultNumberOfContracts = 6;
const defaultNumberOfContractsToAdd = 3;
const defaultVolatility = 5;
const initialBasis = 0;
const initialProbability = 99;
const reutersInstrumentCodePrefixEurUsd = 'URO';
const initialVolatilityTimeSpan = DemeterFilterTimeSpan.OneYear;

const probabilityOptions = [
    { label: '99%', value: 99, multiplier: 2.576 },
    { label: '95%', value: 95, multiplier: 1.96 },
    { label: '90%', value: 90, multiplier: 1.645 },
    { label: '80%', value: 80, multiplier: 1.282 },
    { label: '50%', value: 50, multiplier: 0.674 },
];

const dataCellWidth = 160;
const stickyColumnWidth = 480;

const Positions: React.FC = () => {
    // Application hooks.
    const [translations, translate] = useLanguage();

    // Form/request object hooks.
    const [symbol, setSymbol] = useState<DemeterSymbolModel>();
    const [positionsRequest, setPositionsRequest] = useState<RiskRequest>({
        direction: FuturesDirection.Last,
        probability: initialProbability,
        volatilityTimeSpan: initialVolatilityTimeSpan,
        basisString: `${initialBasis}`,
        basis: initialBasis,
        risks: [],
        version: 1,
    });
    const [calculatedPositions, setCalculatedPositions] = useState<MonthlyRiskCompositeModel[]>([]);
    const [displayDecimalPlacesMinimum, setDisplayDecimalPlacesMinimum] = useState(0);

    // Data hooks.
    const symbols = useSymbolsApi();
    const [currentMarketPrices] = useMarketPricesForwardCurve(symbol);
    const currencySymbol = symbols?.find((x) => x.reutersInstrumentCodePrefix === reutersInstrumentCodePrefixEurUsd);
    const [currencyPrices, , currencySpotPrice] = useMarketPricesForwardCurve(currencySymbol);
    const [getUnitOfMeasureConversionRate, unitOfMeasureConversionMap] = useUnitOfMeasureConversionApi(symbol?.reutersInstrumentCodePrefix);
    const [, , listMarketPriceVolatilitiesResponse] = useCacheOrApi(
        `${CacheKeys.ListMarketPriceVolatilities}_${symbol?.reutersInstrumentCodePrefix}`,
        () => {
            if (!symbol?.reutersInstrumentCodePrefix) {
                return null;
            }

            return futuresApi.listMarketPriceVolatilities(symbol?.reutersInstrumentCodePrefix, defaultContractNumber);
        },
        { stopAutoExecute: true },
    );

    // Position hooks.
    const [, , listPositionsCalculatorResponse] = useApi(() => demeterCalculatorsApi.listPositionsCalculators());
    const [, refreshGetApi, getPositionsCalculatorResponse] = useApi(
        (symbolParameter?: DemeterSymbolModel) => {
            if (!symbolParameter) {
                return null;
            }

            return demeterCalculatorsApi.getPositionsCalculator(symbolParameter.region, symbolParameter.commodity);
        },
        { stopAutoExecute: true },
    );

    const [updating, refreshUpdateApi, updatePositionsCalculatorResponse] = useApi(
        () => {
            if (!symbol) {
                return null;
            }

            return demeterCalculatorsApi.updatePositionsCalculator(symbol.region, symbol.commodity, {
                region: symbol.region,
                commodity: symbol.commodity,
                ...positionsRequest,
                positions: positionsRequest.risks,
                currency: positionsRequest.currency!, // TODO: Remove these 2 lines when the backend is working.
                unitOfMeasure: positionsRequest.unitOfMeasure!,
            } as UpdatePositionsCalculatorRequest);
        },
        { stopAutoExecute: true },
    );

    // State hooks.
    const [volatility, setVolatility] = useState<number>(defaultVolatility);
    const [modified, setModified] = useState<{ modifiedBy?: string; modifiedAt?: string }>({});
    const [hidePhysicalPositionsGroup, setHidePhysicalPositionsColumnGroup] = useState(true);

    // Dropdown options.
    const symbolDropdownOptions = useMemo(() => {
        if (!symbols) {
            return [];
        }

        return symbols
            .filter((x) => allowedCategories.includes(x.symbolCategory as SymbolCategory))
            .filter((x) => !notAllowedCommodities.includes(x.commodity as DemeterCommodity))
            .filter((x) => x.symbolContracts.length >= defaultNumberOfContracts)
            .sort((a, b) => `${a.exchange}-${a.displayName}`.localeCompare(`${b.exchange}-${b.displayName}`))
            .map((x) => ({
                label: formattingService.toDisplayName(x),
                value: x,
            }));
    }, [symbols]);

    const futuresDirectionOptions = useMemo(
        () => [
            {
                label: translations.futures.fields.last,
                value: FuturesDirection.Last,
            },
            {
                label: translations.futures.fields.settle,
                value: FuturesDirection.Settle,
            },
        ],
        [translations],
    );

    const currencyDropdownOptions = useMemo(
        () =>
            allowedCurrencies.map((x) => ({
                label: x,
                value: x,
            })),
        [translations],
    );

    const unitOfMeasureDropdownOptions = useMemo(
        () =>
            allowedUnitOfMeasures.map((x) => ({
                label: translations.unitOfMeasure[x],
                value: x,
            })),
        [translations],
    );

    const volatilityOptions = useMemo<{ label: string; value: DemeterFilterTimeSpan }[]>(() => {
        const options: { label: string; value: DemeterFilterTimeSpan }[] = [];

        if (!listMarketPriceVolatilitiesResponse?.rows || listMarketPriceVolatilitiesResponse.rows?.length === 0) {
            return options;
        }

        return listMarketPriceVolatilitiesResponse.rows.map((x) => {
            if (x.isImpliedVolatility) {
                return {
                    label: `${translations.words.implied} - ${formattingService.toPercent(x.volatilityPercent)}`,
                    value: DemeterFilterTimeSpan.All,
                };
            }

            return {
                label: `${translations.filterTimeSpan[x.timeSpan as DemeterFilterTimeSpan]} - ${formattingService.toPercent(x.volatilityPercent)}`,
                value: x.timeSpan as DemeterFilterTimeSpan,
            };
        });
    }, [translations, listMarketPriceVolatilitiesResponse]);

    const volumeTextWithUnitOfMeasure = useMemo(
        () => `${translations.words.volume} (${translations.unitOfMeasure[`Short${positionsRequest?.unitOfMeasure!}`]})`,
        [positionsRequest],
    );

    const perUnitTextWithUnitOfMeasure = useMemo(
        () => `${translations.risk.text.perUnit} (${translations.unitOfMeasure[`Short${positionsRequest?.unitOfMeasure!}`]})`,
        [positionsRequest],
    );

    const weightedAveragePriceTextWithCurrency = useMemo(
        () => `${translations.risk.text.weightedAveragePrice} (${translations.currency[positionsRequest?.currency!]})`,
        [positionsRequest, translations],
    );

    const totalTextWithCurrency = useMemo(
        () => <strong>{`${translations.words.total} (${translations.currency[positionsRequest?.currency!]})`}</strong>,
        [positionsRequest, translations],
    );

    const priceTextWithCurrency = useMemo(
        () => `${translations.words.price} (${translations.currency[positionsRequest?.currency!]})`,
        [positionsRequest, translations],
    );

    // Set default symbols when we first load.
    useEffect(() => {
        if (!symbols || symbols.length === 0 || !listPositionsCalculatorResponse?.rows) {
            return;
        }

        let initialOptions = symbolDropdownOptions.filter((x) => x.value.commodity === initialCommodity);
        if (listPositionsCalculatorResponse.rows.length > 0) {
            const firstCalculator = listPositionsCalculatorResponse.rows[0];
            initialOptions = symbolDropdownOptions.filter((x) => x.value.region === firstCalculator.region && x.value.commodity === firstCalculator.commodity);
        }

        const newSymbolModel = initialOptions.length > 0 ? initialOptions[0].value : symbolDropdownOptions[0].value;

        setSymbol(newSymbolModel);
        refreshGetApi(newSymbolModel);
    }, [symbols, listPositionsCalculatorResponse]);

    useEffect(() => {
        if (!symbol || !getPositionsCalculatorResponse) {
            return;
        }

        let positions: MonthlyRiskCompositeModel[] = [];
        const startMonth = new Date(moment().year(), moment().month(), 1);

        if (getPositionsCalculatorResponse.positionCalculator?.positions && getPositionsCalculatorResponse.positionCalculator.positions.length > 0) {
            positions = (getPositionsCalculatorResponse.positionCalculator!.positions ?? []).map((position) => ({
                ...position,
                basis: positionsRequest.basis,
            }));

            // Remove all of the months that have past.
            positions = positions
                .filter((x) => {
                    const currentMonth = new Date(x.dataYear, x.dataMonth - 1, 1);
                    return currentMonth >= startMonth;
                })
                .map((x) => ({
                    ...x,
                    dataMonth: x.contractMonth,
                    dataYear: x.contractYear,
                }));
        }

        // Add in any empty positions to make up the rest of the missing positions.
        if (positions.length < defaultNumberOfContracts) {
            positions = addAndGetPositions(positions, defaultNumberOfContracts - positions.length);
        }

        const newCurrency = getPositionsCalculatorResponse?.positionCalculator!.currency ?? symbol.currency ?? Currency.Usd;
        const newUnitOfMeasure = getPositionsCalculatorResponse?.positionCalculator!.unitOfMeasure ?? symbol.unitOfMeasure ?? UnitOfMeasure.Pound;

        setPositionsRequest({
            ...positionsRequest,
            ...getPositionsCalculatorResponse.positionCalculator,
            ...{
                basisString: `${getPositionsCalculatorResponse?.positionCalculator?.basis ?? positionsRequest.basis}`,
                currency: newCurrency,
                unitOfMeasure: newUnitOfMeasure,
                risks: positions,
                version: positionsRequest.version + 1,
            },
        } as RiskRequest);
    }, [symbol, getPositionsCalculatorResponse]);

    useEffect(() => {
        if (!symbol || !listMarketPriceVolatilitiesResponse?.rows) {
            return;
        }

        const volatilityObject = listMarketPriceVolatilitiesResponse.rows.find(
            (x) => (x.timeSpan as DemeterFilterTimeSpan) === positionsRequest.volatilityTimeSpan,
        );

        setVolatility(volatilityObject?.volatilityPercent ?? defaultVolatility);
    }, [symbol, positionsRequest.volatilityTimeSpan, listMarketPriceVolatilitiesResponse]);

    useEffect(() => {
        if (!symbol || !currencyPrices?.length || positionsRequest.risks?.length === 0) {
            return;
        }

        const defaultCurrencyMarketPrice = currencyPrices[0];
        let hasPositionChanges = false;

        positionsRequest.risks?.forEach((position) => {
            const toComparator = (x: { year: number; month: number }) => x.year * 100 + x.month;
            let reutersInstrumentCodeCurrency: string = currencySymbol!.reutersInstrumentCodeSpotPrice!;

            // Find the closest valid currency contract.
            if (toComparator({ year: position.contractYear, month: position.contractMonth }) >= toComparator(currencySymbol!.symbolContracts[0])) {
                const filteredCurrencyContracts =
                    currencySymbol?.symbolContracts.filter(
                        (x) => toComparator(x) >= toComparator({ year: position.contractYear, month: position.contractMonth }),
                    ) ?? [];

                const prices = filteredCurrencyContracts
                    .map((x) =>
                        currencyPrices.find((price) => {
                            if (price.reutersInstrumentCode !== x.reutersInstrumentCode) {
                                return false;
                            }

                            // Sometimes we get a contract but it doesn't have an actually value attached.
                            // This usually occurs during the first trade day of that contract.
                            const latestOrSettlementPrice = (price.latestPrice ?? 0) || (price.settlementPrice ?? 0);
                            return latestOrSettlementPrice > 0;
                        }),
                    )
                    .filter((x) => !!x);

                if (prices && prices.length > 0) {
                    reutersInstrumentCodeCurrency = prices[0]!.reutersInstrumentCode;

                    // TODO: If we don't have a matching currency contract, then interpolate.
                }
            }

            // If we don't have a market price for the matching currency contract, then use the default.
            const currencyMarketPrice = currencyPrices.find((x) => x.reutersInstrumentCode === reutersInstrumentCodeCurrency) ?? defaultCurrencyMarketPrice;
            let currencyLatestPrice = !currencyMarketPrice ? 1.0 : currencyMarketPrice.latestPrice ?? currencyMarketPrice.settlementPrice ?? 1;

            // Use the spot price if it is the spot code.
            if (reutersInstrumentCodeCurrency === currencySymbol!.reutersInstrumentCodeSpotPrice) {
                currencyLatestPrice = currencySpotPrice ?? 1.0;
            }

            // Get the currency conversion rates.
            let currencyConversionRate = 1.0;
            if (positionsRequest.currency !== symbol.currency) {
                currencyConversionRate = currencyLatestPrice;
            }

            if (position.eurToUsdCurrencyConversionRate !== currencyLatestPrice) {
                position.eurToUsdCurrencyConversionRate = currencyLatestPrice;
                hasPositionChanges = true;
            }

            if (position.currencyConversionRate !== currencyConversionRate) {
                position.currencyConversionRate = currencyConversionRate;
                hasPositionChanges = true;
            }
        });

        if (hasPositionChanges) {
            setPositionsRequest({ ...positionsRequest });
        }
    }, [symbol, currencyPrices, positionsRequest]);

    const getSum = (...values: (number | undefined | null)[]): number | undefined => {
        if (values.every((x) => x === undefined || x === null)) {
            return undefined;
        }

        return values.reduce((currentSum: number, currentValue) => (currentValue || 0) + (currentSum || 0), 0);
    };

    const getSubtractedSum = (value1: number | undefined | null, value2: number | undefined | null): number | undefined => {
        if ((value1 === undefined || value1 === null) && (value2 === undefined || value2 === null)) {
            return undefined;
        }

        return (value1 ?? 0) - (value2 ?? 0);
    };

    const getWeightedPrice = (
        position1: string | number | undefined | null,
        price1: string | number | undefined | null,
        position2: string | number | undefined | null = undefined,
        price2: string | number | undefined | null = undefined,
        position3: string | number | undefined | null = undefined,
        price3: string | number | undefined | null = undefined,
    ): number | undefined => {
        // Cannot divide by 0.
        if (!position1 && !position2 && !position3) {
            return undefined;
        }

        const total1 = +(price1 ?? 0) * +(position1 ?? 0);
        const total2 = +(price2 ?? 0) * +(position2 ?? 0);
        const total3 = +(price3 ?? 0) * +(position3 ?? 0);
        const totalPosition = +(position1 ?? 0) + +(position2 ?? 0) + +(position3 ?? 0);

        return (total1 + total2 + total3) / totalPosition;
    };

    useEffect(() => {
        if (!currentMarketPrices || currentMarketPrices.length === 0 || !symbol) {
            return;
        }

        const newCalculatedPositions: MonthlyRiskCompositeModel[] = [];
        const today = moment.now();
        let hasPhysicalPriceUpdates = false;

        for (let i = 0; i < positionsRequest.risks!.length; i += 1) {
            const newCalculatedPosition = calculatedPositions[i] ?? [];
            const inputtedPosition = positionsRequest.risks![i];
            let symbolContract = symbol.symbolContracts.find((x) => x.month === inputtedPosition.contractMonth && x.year === inputtedPosition.contractYear);

            let marketPrice = currentMarketPrices.find((x) => x.reutersInstrumentCode === symbolContract?.reutersInstrumentCode);
            let price =
                positionsRequest.direction === FuturesDirection.Last && marketPrice?.latestPrice ? marketPrice?.latestPrice : marketPrice?.settlementPrice;

            // Find the most recent contract if we are missing the beginning set of contracts.
            if (!price && i < defaultNumberOfContracts) {
                marketPrice = currentMarketPrices.find((x) => x.settlementPrice)!;
            } else if (!price) {
                // Get the last known price if we can't get a price.
                marketPrice = currentMarketPrices.findLast((x) => x.settlementPrice)!;
            }

            symbolContract = symbol.symbolContracts.find((x) => x.reutersInstrumentCode === marketPrice?.reutersInstrumentCode);
            price = positionsRequest.direction === FuturesDirection.Last && marketPrice?.latestPrice ? marketPrice?.latestPrice : marketPrice?.settlementPrice;

            newCalculatedPosition.dataMonth = inputtedPosition.dataMonth;
            newCalculatedPosition.dataYear = inputtedPosition.dataYear;
            newCalculatedPosition.contractMonth = symbolContract?.month ?? inputtedPosition.contractMonth;
            newCalculatedPosition.contractYear = symbolContract?.year ?? inputtedPosition.contractYear;
            newCalculatedPosition.price = (price ?? 0) * symbol.priceMultiplierFutures;

            let currencyConversionRate = inputtedPosition.currencyConversionRate ?? 1.0;

            if (positionsRequest.currency !== symbol.currency) {
                currencyConversionRate = positionsRequest.currency === Currency.Eur ? 1.0 / currencyConversionRate : currencyConversionRate;
            }

            newCalculatedPosition.priceConverted =
                newCalculatedPosition.price * currencyConversionRate * getUnitOfMeasureConversionRate(positionsRequest.unitOfMeasure);
            newCalculatedPosition.basis = positionsRequest.basis;
            newCalculatedPosition.priceConvertedWithBasis = newCalculatedPosition.priceConverted! + newCalculatedPosition.basis;
            newCalculatedPosition.physicalPositionNetLongPosition = getSum(
                inputtedPosition.stocksPosition,
                inputtedPosition.stocksUnpricedIncrease,
                inputtedPosition.purchasesPosition,
            );
            newCalculatedPosition.physicalPositionNetLongPrice = getWeightedPrice(
                inputtedPosition.stocksPosition,
                inputtedPosition.stocksPrice || newCalculatedPosition.priceConvertedWithBasis,
                inputtedPosition.stocksUnpricedIncrease,
                newCalculatedPosition.priceConvertedWithBasis,
                inputtedPosition.purchasesPosition,
                inputtedPosition.purchasesPrice || newCalculatedPosition.priceConvertedWithBasis,
            );
            newCalculatedPosition.physicalPositionNetSalesPosition = getSum(inputtedPosition.stocksUnpricedDrawdown, inputtedPosition.salesPosition);
            newCalculatedPosition.physicalPositionNetSalesPrice = getWeightedPrice(
                inputtedPosition.stocksUnpricedDrawdown,
                newCalculatedPosition.priceConvertedWithBasis,
                inputtedPosition.salesPosition,
                inputtedPosition.salesPrice || newCalculatedPosition.priceConvertedWithBasis,
            );

            newCalculatedPosition.physicalPositionSecuredPosition = undefined;
            newCalculatedPosition.physicalPositionSecuredPrice = undefined;
            newCalculatedPosition.physicalPositionSecuredTotal = undefined;
            if (newCalculatedPosition.physicalPositionNetLongPosition && newCalculatedPosition.physicalPositionNetSalesPosition) {
                newCalculatedPosition.physicalPositionSecuredPosition = Math.min(
                    Math.abs(newCalculatedPosition.physicalPositionNetLongPosition ?? 0),
                    Math.abs(newCalculatedPosition.physicalPositionNetSalesPosition ?? 0),
                );

                if (newCalculatedPosition.physicalPositionSecuredPosition > 0) {
                    newCalculatedPosition.physicalPositionSecuredPrice =
                        getSubtractedSum(newCalculatedPosition.physicalPositionNetSalesPrice, newCalculatedPosition.physicalPositionNetLongPrice) ?? 0;
                    newCalculatedPosition.physicalPositionSecuredTotal =
                        newCalculatedPosition.physicalPositionSecuredPosition * newCalculatedPosition.physicalPositionSecuredPrice;
                }
            }

            // If anything is inputted in the sub-physical positions, then we sum up those values.
            // Otherwise, we use what's in the physical positions field.
            if (!!newCalculatedPosition.physicalPositionNetLongPosition || !!newCalculatedPosition.physicalPositionNetSalesPosition) {
                const physicalPosition = getSubtractedSum(
                    newCalculatedPosition.physicalPositionNetLongPosition,
                    newCalculatedPosition.physicalPositionNetSalesPosition,
                );
                const physicalPrice =
                    Math.abs(newCalculatedPosition.physicalPositionNetLongPosition ?? 0) > Math.abs(newCalculatedPosition.physicalPositionNetSalesPosition ?? 0)
                        ? newCalculatedPosition.physicalPositionNetLongPrice ?? 0
                        : newCalculatedPosition.physicalPositionNetSalesPrice ?? 0;

                // Only update if the values have changed, this prevents an infinite loop.
                if (
                    inputtedPosition.physicalPosition !== physicalPosition ||
                    inputtedPosition.physicalPrice !== +physicalPrice.toFixed(displayDecimalPlacesMinimum)
                ) {
                    inputtedPosition.physicalPosition = physicalPosition;
                    inputtedPosition.physicalPrice = +physicalPrice.toFixed(displayDecimalPlacesMinimum);

                    // This will force the input box to be updated in this refresh cycle otherwise it will be off one cycle.
                    hasPhysicalPriceUpdates = true;
                }
            }

            newCalculatedPosition.physicalPosition = inputtedPosition.physicalPosition;
            newCalculatedPosition.physicalPrice = inputtedPosition.physicalPrice || newCalculatedPosition.priceConvertedWithBasis;
            newCalculatedPosition.futuresPosition = inputtedPosition.futuresPosition;
            newCalculatedPosition.futuresPrice = inputtedPosition.futuresPrice || newCalculatedPosition.priceConvertedWithBasis;
            newCalculatedPosition.netUnhedgedPosition = getSum(newCalculatedPosition.physicalPosition, inputtedPosition.futuresPosition) ?? 0;
            newCalculatedPosition.netUnhedgedPrice =
                Math.abs(newCalculatedPosition.physicalPosition ?? 0) > Math.abs(newCalculatedPosition.futuresPosition ?? 0)
                    ? newCalculatedPosition.physicalPrice ?? 0
                    : newCalculatedPosition.futuresPrice ?? 0;

            if (newCalculatedPosition.netUnhedgedPosition && newCalculatedPosition.netUnhedgedPosition > 0) {
                newCalculatedPosition.markToMarketPriceDifference = getSubtractedSum(
                    newCalculatedPosition.priceConvertedWithBasis,
                    +newCalculatedPosition.netUnhedgedPrice,
                );
            } else if (newCalculatedPosition.netUnhedgedPosition && newCalculatedPosition.netUnhedgedPosition < 0) {
                newCalculatedPosition.markToMarketPriceDifference = getSubtractedSum(
                    +newCalculatedPosition.netUnhedgedPrice,
                    newCalculatedPosition.priceConvertedWithBasis,
                );
            } else {
                newCalculatedPosition.markToMarketPriceDifference = undefined;
            }

            newCalculatedPosition.markToMarketTotal = newCalculatedPosition.markToMarketPriceDifference
                ? newCalculatedPosition.markToMarketPriceDifference * Math.abs(newCalculatedPosition.netUnhedgedPosition)
                : undefined;

            const probabilityMultiplier = probabilityOptions.find((x) => x.value === positionsRequest.probability)?.multiplier!;

            const lastDayOfMonth = moment(new Date(newCalculatedPosition.dataYear, newCalculatedPosition.dataMonth, 0));
            newCalculatedPosition.riskDays = lastDayOfMonth.diff(today, 'days') + 1;

            newCalculatedPosition.riskMonthly = Math.abs(
                (newCalculatedPosition.priceConvertedWithBasis *
                    +(newCalculatedPosition.netUnhedgedPosition ?? 0) *
                    (volatility / 100.0) *
                    probabilityMultiplier) /
                    Math.sqrt(365 / newCalculatedPosition.riskDays),
            );

            if (Number.isNaN(newCalculatedPosition.riskMonthly)) {
                newCalculatedPosition.riskMonthly = 0;
            }

            if (i === 0) {
                newCalculatedPosition.riskCumulative = newCalculatedPosition.riskMonthly;
            } else if (newCalculatedPosition.riskMonthly === undefined && newCalculatedPositions[i - 1].riskMonthly === undefined) {
                newCalculatedPosition.riskCumulative = undefined;
            } else {
                newCalculatedPosition.riskCumulative = getSum(newCalculatedPosition.riskMonthly, newCalculatedPositions[i - 1]!.riskCumulative);
            }

            newCalculatedPosition.riskMargin = getSubtractedSum(newCalculatedPosition.markToMarketTotal, newCalculatedPosition.riskMonthly);
            newCalculatedPosition.riskRange =
                (newCalculatedPosition.priceConvertedWithBasis * (volatility / 100.0) * probabilityMultiplier) /
                Math.sqrt(365 / newCalculatedPosition.riskDays);

            newCalculatedPosition.isUpdatedValue = inputtedPosition.isUpdatedValue;

            newCalculatedPositions.push(newCalculatedPosition);
        }

        setCalculatedPositions(newCalculatedPositions);

        if (symbol?.priceMultiplierFutures && symbol?.priceMultiplierFutures !== 1) {
            setDisplayDecimalPlacesMinimum(formattingService.getDisplayDecimalPlacesMinimum(symbol?.priceMultiplierFutures));
        } else if (newCalculatedPositions.length > 0) {
            setDisplayDecimalPlacesMinimum(formattingService.getDisplayDecimalPlacesMinimum(newCalculatedPositions[0].priceConverted));
        }

        if (hasPhysicalPriceUpdates) {
            setPositionsRequest({ ...positionsRequest });
        }
    }, [symbol, currentMarketPrices, positionsRequest, volatility, unitOfMeasureConversionMap]);

    useEffect(() => {
        setModified({
            modifiedAt: getPositionsCalculatorResponse?.positionCalculator?.modifiedAt ?? undefined,
            modifiedBy: getPositionsCalculatorResponse?.positionCalculator?.modifiedBy ?? undefined,
        });
    }, [getPositionsCalculatorResponse]);

    useEffect(() => {
        setModified({
            modifiedAt: updatePositionsCalculatorResponse?.positionCalculator?.modifiedAt ?? undefined,
            modifiedBy: updatePositionsCalculatorResponse?.positionCalculator?.modifiedBy ?? undefined,
        });
    }, [updatePositionsCalculatorResponse]);

    useEffect(() => {
        if (hidePhysicalPositionsGroup) {
            return;
        }

        // This will be the first element in the table (first row, first column) when we expand the rows.
        documentObjectModelService.getFirstElementWithAnAttribute('data-column-number');
    }, [hidePhysicalPositionsGroup]);

    const handleGridTabNavigation = (columnIndex: number, rowIndex: number, direction: TabEventKeyType) => {
        const currentParentDivElement = documentObjectModelService.getActiveElementParent();
        const allInputElementsOfColumn = documentObjectModelService.selectElementsWithAttributeStatus('data-column-number', columnIndex, true);

        if (!direction || direction === 'ArrowUp') {
            allInputElementsOfColumn[rowIndex - 1]?.focus();
        } else if (direction === 'ArrowDown' || direction === 'Enter') {
            allInputElementsOfColumn[rowIndex + 1]?.focus();
        } else if (direction === 'ArrowLeft') {
            (currentParentDivElement?.previousElementSibling?.firstChild?.firstChild as HTMLElement)?.focus();
        } else {
            (currentParentDivElement?.nextElementSibling?.firstChild?.firstChild as HTMLElement)?.focus();
        }
    };

    const handleSymbolChange = (newSymbolModel?: DemeterSymbolModel) => {
        if (!newSymbolModel) {
            return;
        }
        setSymbol(newSymbolModel);
        refreshGetApi(newSymbolModel);
    };

    const addAndGetPositions = (currentPositions: MonthlyRiskCompositeModel[], numberOfContracts?: number): MonthlyRiskCompositeModel[] => {
        const calculatedNumberOfContractsToAdd = numberOfContracts ?? defaultNumberOfContractsToAdd;
        const newPositions: MonthlyRiskCompositeModel[] = [...currentPositions];

        if (newPositions.length === 0) {
            newPositions.push({
                dataMonth: moment().month() + 1,
                dataYear: moment().year(),
                contractMonth: moment().month() + 1,
                contractYear: moment().year(),
                basis: positionsRequest.basis,
            });
        }

        for (let i = newPositions.length; i < currentPositions.length + calculatedNumberOfContractsToAdd; i += 1) {
            const previousPosition = newPositions[i - 1];
            const month = previousPosition.dataMonth === 12 ? 1 : previousPosition.dataMonth + 1;
            const year = previousPosition.dataMonth === 12 ? previousPosition.dataYear + 1 : previousPosition.dataYear;

            newPositions.push({
                dataMonth: month,
                dataYear: year,
                contractMonth: month,
                contractYear: year,
                basis: positionsRequest.basis,
            });
        }

        newPositions.forEach((x) => {
            x.isUpdatedValue = false;
        });

        return newPositions;
    };

    const handleAddContracts = (): void => {
        if (!symbol) {
            return;
        }

        const newPositions = addAndGetPositions(positionsRequest.risks!);
        setPositionsRequest({ ...positionsRequest, risks: newPositions });
    };

    const handleSave = (): void => {
        const newPositions = positionsRequest.risks!.map((x) => {
            x.isUpdatedValue = false;
            return x;
        });
        setPositionsRequest({ ...positionsRequest, risks: newPositions, version: positionsRequest.version + 1 });
        refreshUpdateApi();
    };

    const handleReset = (): void => {
        refreshGetApi(symbol);
    };

    const clearPhysicalPositionsGroup = (newPositionsRequest: RiskRequest, index: number): void => {
        const positions = newPositionsRequest.risks![index];

        positions.stocksPosition = undefined;
        positions.stocksPrice = undefined;
        positions.stocksUnpricedDrawdown = undefined;
        positions.stocksUnpricedIncrease = undefined;
        positions.purchasesPosition = undefined;
        positions.purchasesPrice = undefined;
        positions.salesPosition = undefined;
        positions.salesPrice = undefined;
        positions.physicalPositionNetLongPosition = undefined;
        positions.physicalPositionNetLongPrice = undefined;
        positions.physicalPositionNetSalesPosition = undefined;
        positions.physicalPositionNetSalesPrice = undefined;
        positions.physicalPositionSecuredPosition = undefined;
        positions.physicalPositionSecuredPrice = undefined;
        positions.physicalPositionSecuredTotal = undefined;
    };

    const stickyColumns = (
        <div className={styles.master_flex}>
            <StickyColumnGroup
                columnHeaders={[
                    {
                        sectionTitle: translations.risk.headers.marketPrices,
                        subRowTitles: [
                            translations.words.month,
                            translations.words.contract,
                            priceTextWithCurrency,
                            translations.words.basis,
                            translations.risk.text.markToMarketReference,
                        ],
                    },
                    {
                        sectionTitle: translations.risk.headers.stocks,
                        subRowTitles: [
                            translations.risk.text.openingVolume,
                            translations.risk.text.openingWeightedAveragePrice,
                            translations.risk.text.unpricedDrawdown,
                            translations.risk.text.unpricedIncrease,
                        ],
                        hideGroup: hidePhysicalPositionsGroup,
                        useAlternateBackgroundColor: true,
                    },
                    {
                        sectionTitle: translations.risk.headers.purchases,
                        subRowTitles: [volumeTextWithUnitOfMeasure, weightedAveragePriceTextWithCurrency],
                        hideGroup: hidePhysicalPositionsGroup,
                        useAlternateBackgroundColor: true,
                    },
                    {
                        sectionTitle: translations.risk.headers.sales,
                        subRowTitles: [volumeTextWithUnitOfMeasure, weightedAveragePriceTextWithCurrency],
                        hideGroup: hidePhysicalPositionsGroup,
                        useAlternateBackgroundColor: true,
                    },
                    {
                        sectionTitle: translations.risk.headers.physicalPositionNetLong,
                        subRowTitles: [volumeTextWithUnitOfMeasure, weightedAveragePriceTextWithCurrency],
                        hideGroup: hidePhysicalPositionsGroup,
                        useAlternateBackgroundColor: true,
                    },
                    {
                        sectionTitle: translations.risk.headers.physicalPositionNetSales,
                        subRowTitles: [volumeTextWithUnitOfMeasure, weightedAveragePriceTextWithCurrency],
                        hideGroup: hidePhysicalPositionsGroup,
                        useAlternateBackgroundColor: true,
                    },
                    {
                        sectionTitle: translations.risk.headers.physicalPositionSecured,
                        subRowTitles: [translations.risk.text.nettedVolume, perUnitTextWithUnitOfMeasure, totalTextWithCurrency],
                        hideGroup: hidePhysicalPositionsGroup,
                        useAlternateBackgroundColor: true,
                    },
                    {
                        sectionTitle: (
                            <div className={styles.risk_net_physical_positions}>
                                {translations.risk.headers.physical}
                                <img className={styles.calculator_table_image} alt="#" src={hidePhysicalPositionsGroup ? plusSvg : minusSvg} />
                            </div>
                        ),
                        subRowTitles: [
                            <>
                                {volumeTextWithUnitOfMeasure}
                                <Tooltip className={styles.risk_tooltip} title={translations.risk.text.physicalPositionMessage} placement="top-start">
                                    <img src={infoSvg} alt="#" />
                                </Tooltip>
                            </>,
                            weightedAveragePriceTextWithCurrency,
                        ],
                        handleGroupShowHide: () => setHidePhysicalPositionsColumnGroup(!hidePhysicalPositionsGroup),
                        useAlternateBackgroundColor: !hidePhysicalPositionsGroup,
                    },
                    {
                        sectionTitle: translations.risk.headers.futures,
                        subRowTitles: [
                            <>
                                {volumeTextWithUnitOfMeasure}
                                <Tooltip className={styles.risk_tooltip} title={translations.risk.text.futuresPositionMessage} placement="top-start">
                                    <img src={infoSvg} alt="#" />
                                </Tooltip>
                            </>,
                            weightedAveragePriceTextWithCurrency,
                        ],
                    },
                    {
                        sectionTitle: translations.risk.headers.netUnhedgedPosition,
                        subRowTitles: [translations.risk.text.netPosition, translations.risk.text.openingPrice],
                    },
                    {
                        sectionTitle: translations.risk.headers.markToMarket,
                        subRowTitles: [perUnitTextWithUnitOfMeasure, totalTextWithCurrency],
                    },
                    {
                        sectionTitle: translations.risk.headers.valueAtRisk,
                        subRowTitles: [
                            translations.risk.text.riskDays,
                            translations.risk.text.riskMonthly,
                            translations.risk.text.riskCumulative,
                            translations.risk.text.marginAtRisk,
                        ],
                    },
                ]}
            />
        </div>
    );

    return !calculatedPositions || calculatedPositions.length === 0 || !currentMarketPrices || currentMarketPrices.length === 0 ? (
        <PageLoadingSpinner />
    ) : (
        <>
            <div className={styles.risk_page_subheader}>
                <ComponentHeader title={translations.risk.headers.valueAtRisk} />
            </div>
            <div className={styles.risk_request_and_chart_container}>
                <div data-testid="PositionsCalculator" className={styles.risk_dropdown_inputs_container}>
                    <div className={styles.risk_dropdown_row}>
                        <div className={styles.risk_full_width}>
                            <p className={styles.risk_input_label}>{translations.words.product}</p>
                            <Dropdown value={symbol} options={symbolDropdownOptions} handleOptionChange={handleSymbolChange} />
                        </div>
                    </div>
                    <div className={styles.risk_dropdown_row}>
                        <div className={styles.risk_half_width_left}>
                            <p className={styles.risk_input_label}>{translations.words.basis}</p>
                            <TextInput
                                className={styles.risk_text_input}
                                type="number"
                                value={positionsRequest.basisString}
                                handleTextChange={(value) => {
                                    const newPositionsRequest = {
                                        ...positionsRequest,
                                        basisString: value,
                                    };

                                    if ((+value ?? 0) !== positionsRequest.basis) {
                                        newPositionsRequest.basis = +value ?? 0;
                                    }

                                    setPositionsRequest(newPositionsRequest);
                                }}
                            />
                        </div>
                        <div className={styles.risk_half_width_right}>
                            <p className={styles.risk_input_label}>{translations.words.direction}</p>
                            <Dropdown
                                value={positionsRequest?.direction}
                                options={futuresDirectionOptions}
                                handleOptionChange={(value) => {
                                    setPositionsRequest({
                                        ...positionsRequest,
                                        direction: value,
                                    });
                                }}
                            />
                        </div>
                    </div>
                    <div className={styles.risk_dropdown_conversions_row}>
                        <div className={styles.risk_half_width_left}>
                            <p className={styles.risk_input_label}>{translations.words.probabilty}</p>
                            <Dropdown
                                value={positionsRequest?.probability}
                                options={probabilityOptions}
                                handleOptionChange={(value) => {
                                    setPositionsRequest({
                                        ...positionsRequest,
                                        probability: value,
                                    });
                                }}
                            />
                        </div>
                        <div className={styles.risk_half_width_right}>
                            <p className={styles.risk_input_label}>{translations.words.volatility}</p>
                            <Dropdown
                                value={positionsRequest?.volatilityTimeSpan}
                                options={volatilityOptions}
                                handleOptionChange={(value) => {
                                    setPositionsRequest({
                                        ...positionsRequest,
                                        volatilityTimeSpan: value,
                                    });
                                }}
                            />
                        </div>
                    </div>
                    <div className={styles.risk_gray_divider} />
                    <div className={styles.risk_dropdown_conversions_row}>
                        <div className={styles.risk_half_width_left}>
                            <p className={styles.risk_input_label}>{translations.words.currency}</p>
                            <Dropdown
                                value={positionsRequest?.currency}
                                options={currencyDropdownOptions}
                                handleOptionChange={(value) => {
                                    setPositionsRequest({
                                        ...positionsRequest,
                                        currency: value,
                                    });
                                }}
                            />
                        </div>
                        <div className={styles.risk_half_width_right}>
                            <p className={styles.risk_input_label}>{translations.words.unit}</p>
                            <Dropdown
                                value={positionsRequest?.unitOfMeasure}
                                options={unitOfMeasureDropdownOptions}
                                handleOptionChange={(value) => {
                                    setPositionsRequest({
                                        ...positionsRequest,
                                        unitOfMeasure: value,
                                    });
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div data-testid="PositionsRiskCarousel" className={styles.risk_chart_container}>
                    {symbol && (
                        <RiskCarousel
                            symbol={symbol!}
                            risksRequest={positionsRequest}
                            calculatedRisks={calculatedPositions}
                            displayDecimalPlaces={displayDecimalPlacesMinimum}
                        />
                    )}
                </div>
            </div>
            <div
                style={{
                    maxWidth: `${calculatedPositions.length * dataCellWidth + stickyColumnWidth}px`,
                }}
                className={styles.risk_area_above_table}
            >
                <ComponentHeader title={translate(symbol?.displayName ?? '')} />
                <div className={styles.risk_add_and_remove_contracts}>
                    <p>
                        {modified?.modifiedAt && modified?.modifiedBy && (
                            <>
                                {translations.words.saved} {formattingService.toTimestampFromUtc(modified?.modifiedAt)} {translations.words.by}{' '}
                                {modified?.modifiedBy}
                            </>
                        )}
                    </p>
                    <LinkButton onClick={handleAddContracts} title={translations.actions.addContracts} type={LinkButtonType.White} />
                    <LinkButton onClick={handleReset} title={translations.actions.loadLastSave} type={LinkButtonType.White} disabled={updating} />
                    <LinkButton onClick={handleSave} title={translations.actions.save} type={LinkButtonType.Blue} disabled={updating} />
                </div>
            </div>
            <div data-testid="PositionsComparisonTable" className={styles.risk_grid_wrapper}>
                <div
                    style={{
                        maxWidth: `${calculatedPositions.length * dataCellWidth + stickyColumnWidth}px`,
                    }}
                    className={styles.risk_table_wrapper_flex}
                >
                    {stickyColumns}
                    <div className={styles.risk_table}>
                        <div className={styles.calculator_table_header_row}>
                            {calculatedPositions.map((x) => (
                                <div
                                    className={
                                        x.isUpdatedValue ? styles.calculator_table_updated_column_text_only : styles.calculator_table_base_column_text_only
                                    }
                                    key={`data_date_${x.dataYear}_${x.dataMonth}`}
                                >
                                    {formattingService.toMonthYear(new Date(x.dataYear, x.dataMonth - 1, 1))}
                                </div>
                            ))}
                        </div>
                        <div className={styles.calculator_table_header_row}>
                            {calculatedPositions.map((x) => (
                                <div
                                    className={
                                        x.isUpdatedValue ? styles.calculator_table_updated_column_text_only : styles.calculator_table_base_column_text_only
                                    }
                                    key={`contract_date_${x.contractYear}_${x.contractMonth}`}
                                >
                                    {formattingService.toMonthYear(new Date(x.contractYear, x.contractMonth - 1, 1))}
                                </div>
                            ))}
                        </div>
                        <div className={styles.calculator_table_alternating_background_group}>
                            <RiskDataRow
                                className={styles.calculator_table_alternating_background}
                                calculatedRisk={calculatedPositions}
                                fieldName="priceConverted"
                                displayDecimalPlacesMinimum={displayDecimalPlacesMinimum}
                            />
                            <RiskDataRow className={styles.calculator_table_alternating_background} calculatedRisk={calculatedPositions} fieldName="basis" />
                            <RiskDataRow
                                className={styles.calculator_table_alternating_background}
                                calculatedRisk={calculatedPositions}
                                fieldName="priceConvertedWithBasis"
                                displayDecimalPlacesMinimum={displayDecimalPlacesMinimum}
                            />
                        </div>
                        {!hidePhysicalPositionsGroup && (
                            <>
                                <RiskCalculatorInputRow
                                    className={styles.calculator_table_alternate_background}
                                    riskRequest={positionsRequest}
                                    setRiskRequest={setPositionsRequest}
                                    fieldName="stocksPosition"
                                    handleGridTabNavigation={handleGridTabNavigation}
                                    validation={RegexValidators.PositiveNumber}
                                />
                                <RiskCalculatorInputRow
                                    className={styles.calculator_table_alternate_background}
                                    riskRequest={positionsRequest}
                                    setRiskRequest={setPositionsRequest}
                                    fieldName="stocksPrice"
                                    handleGridTabNavigation={handleGridTabNavigation}
                                />
                                <RiskCalculatorInputRow
                                    className={styles.calculator_table_alternate_background}
                                    riskRequest={positionsRequest}
                                    setRiskRequest={setPositionsRequest}
                                    fieldName="stocksUnpricedDrawdown"
                                    handleGridTabNavigation={handleGridTabNavigation}
                                />
                                <RiskCalculatorInputRow
                                    className={styles.calculator_table_alternate_background}
                                    riskRequest={positionsRequest}
                                    setRiskRequest={setPositionsRequest}
                                    fieldName="stocksUnpricedIncrease"
                                    handleGridTabNavigation={handleGridTabNavigation}
                                />
                                <RiskCalculatorInputRow
                                    className={styles.calculator_table_alternate_background}
                                    riskRequest={positionsRequest}
                                    setRiskRequest={setPositionsRequest}
                                    fieldName="purchasesPosition"
                                    handleGridTabNavigation={handleGridTabNavigation}
                                    validation={RegexValidators.PositiveNumber}
                                />
                                <RiskCalculatorInputRow
                                    className={styles.calculator_table_alternate_background}
                                    riskRequest={positionsRequest}
                                    setRiskRequest={setPositionsRequest}
                                    fieldName="purchasesPrice"
                                    handleGridTabNavigation={handleGridTabNavigation}
                                />
                                <RiskCalculatorInputRow
                                    className={styles.calculator_table_alternate_background}
                                    riskRequest={positionsRequest}
                                    setRiskRequest={setPositionsRequest}
                                    fieldName="salesPosition"
                                    handleGridTabNavigation={handleGridTabNavigation}
                                    validation={RegexValidators.PositiveNumber}
                                />
                                <RiskCalculatorInputRow
                                    className={styles.calculator_table_alternate_background}
                                    riskRequest={positionsRequest}
                                    setRiskRequest={setPositionsRequest}
                                    fieldName="salesPrice"
                                    handleGridTabNavigation={handleGridTabNavigation}
                                />
                                <RiskDataRow
                                    className={styles.calculator_table_alternate_background}
                                    calculatedRisk={calculatedPositions}
                                    fieldName="physicalPositionNetLongPosition"
                                    displayDecimalPlacesMinimum={0}
                                />
                                <RiskDataRow
                                    className={styles.calculator_table_alternate_background}
                                    calculatedRisk={calculatedPositions}
                                    fieldName="physicalPositionNetLongPrice"
                                    displayDecimalPlacesMinimum={displayDecimalPlacesMinimum}
                                />
                                <RiskDataRow
                                    className={styles.calculator_table_alternate_background}
                                    calculatedRisk={calculatedPositions}
                                    fieldName="physicalPositionNetSalesPosition"
                                    displayDecimalPlacesMinimum={0}
                                />
                                <RiskDataRow
                                    className={styles.calculator_table_alternate_background}
                                    calculatedRisk={calculatedPositions}
                                    fieldName="physicalPositionNetSalesPrice"
                                    displayDecimalPlacesMinimum={displayDecimalPlacesMinimum}
                                />
                                <RiskDataRow
                                    className={styles.calculator_table_alternate_background}
                                    calculatedRisk={calculatedPositions}
                                    fieldName="physicalPositionSecuredPosition"
                                    displayDecimalPlacesMinimum={0}
                                />
                                <RiskDataRow
                                    className={styles.calculator_table_alternate_background}
                                    calculatedRisk={calculatedPositions}
                                    fieldName="physicalPositionSecuredPrice"
                                    displayDecimalPlacesMinimum={displayDecimalPlacesMinimum}
                                />
                                <RiskDataRow
                                    className={styles.calculator_table_alternate_background}
                                    calculatedRisk={calculatedPositions}
                                    fieldName="physicalPositionSecuredTotal"
                                    displayDecimalPlacesMinimum={0}
                                />
                                <div className={styles.calculator_table_alternating_background_group} />
                            </>
                        )}
                        <div className={styles.calculator_table_alternating_background_group}>
                            <RiskCalculatorInputRow
                                className={styles.calculator_table_alternating_background}
                                riskRequest={positionsRequest}
                                setRiskRequest={(newPositionsRequest: RiskRequest, index: number) => {
                                    clearPhysicalPositionsGroup(newPositionsRequest, index);
                                    setPositionsRequest(newPositionsRequest);
                                }}
                                disabled={!hidePhysicalPositionsGroup}
                                fieldName="physicalPosition"
                                handleGridTabNavigation={handleGridTabNavigation}
                            />
                            <RiskCalculatorInputRow
                                className={styles.calculator_table_alternating_background}
                                riskRequest={positionsRequest}
                                setRiskRequest={(newPositionsRequest: RiskRequest, index: number) => {
                                    clearPhysicalPositionsGroup(newPositionsRequest, index);
                                    setPositionsRequest(newPositionsRequest);
                                }}
                                disabled={!hidePhysicalPositionsGroup}
                                fieldName="physicalPrice"
                                handleGridTabNavigation={handleGridTabNavigation}
                            />
                        </div>
                        <div className={styles.calculator_table_alternating_background_group}>
                            <RiskCalculatorInputRow
                                className={styles.calculator_table_alternating_background}
                                riskRequest={positionsRequest}
                                setRiskRequest={setPositionsRequest}
                                fieldName="futuresPosition"
                                handleGridTabNavigation={handleGridTabNavigation}
                            />
                            <RiskCalculatorInputRow
                                className={styles.calculator_table_alternating_background}
                                riskRequest={positionsRequest}
                                setRiskRequest={setPositionsRequest}
                                fieldName="futuresPrice"
                                handleGridTabNavigation={handleGridTabNavigation}
                            />
                        </div>
                        <div className={styles.calculator_table_alternating_background_group}>
                            <RiskDataRow
                                className={styles.calculator_table_alternating_background}
                                calculatedRisk={calculatedPositions}
                                fieldName="netUnhedgedPosition"
                                displayDecimalPlacesMinimum={0}
                            />
                            <RiskDataRow
                                className={styles.calculator_table_alternating_background}
                                calculatedRisk={calculatedPositions}
                                fieldName="netUnhedgedPrice"
                                displayDecimalPlacesMinimum={displayDecimalPlacesMinimum}
                            />
                        </div>
                        <div className={styles.calculator_table_alternating_background_group}>
                            <RiskDataRow
                                className={styles.calculator_table_alternating_background}
                                calculatedRisk={calculatedPositions}
                                fieldName="markToMarketPriceDifference"
                                displayDecimalPlacesMinimum={displayDecimalPlacesMinimum}
                            />
                            <RiskDataRow
                                className={styles.calculator_table_alternating_background}
                                calculatedRisk={calculatedPositions}
                                fieldName="markToMarketTotal"
                                displayDecimalPlacesMinimum={0}
                            />
                        </div>
                        <div className={styles.calculator_table_alternating_background_group}>
                            <RiskDataRow
                                className={styles.calculator_table_alternating_background}
                                calculatedRisk={calculatedPositions}
                                fieldName="riskDays"
                                displayDecimalPlacesMinimum={0}
                            />
                            <RiskDataRow
                                className={styles.calculator_table_alternating_background}
                                calculatedRisk={calculatedPositions}
                                fieldName="riskMonthly"
                                displayDecimalPlacesMinimum={0}
                            />
                            <RiskDataRow
                                className={styles.calculator_table_alternating_background}
                                calculatedRisk={calculatedPositions}
                                fieldName="riskCumulative"
                                displayDecimalPlacesMinimum={0}
                            />
                            <RiskDataRow
                                className={styles.calculator_table_alternating_background}
                                calculatedRisk={calculatedPositions}
                                fieldName="riskMargin"
                                displayDecimalPlacesMinimum={0}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div
                style={{ maxWidth: `${calculatedPositions.length * dataCellWidth + stickyColumnWidth}px` }}
                className={styles.calculator_table_chart_bottom_bar}
            />
            <p className={styles.risk_disclaimer}>{translations.risk.text.disclaimer}</p>
        </>
    );
};

export default Positions;
