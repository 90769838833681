import HighchartsReact from 'highcharts-react-official';
import React, { useEffect } from 'react';

const useChartReflow = (chartReference: React.RefObject<HighchartsReact.RefObject>): void => {
    useEffect(() => {
        const reflowInterval = setInterval(() => {
            if (chartReference?.current?.chart) {
                chartReference.current.chart.reflow();
                clearInterval(reflowInterval);
            }
        }, 300);

        return () => {
            clearInterval(reflowInterval);
        };
    }, []);
};

export default useChartReflow;
