import { useEffect, useMemo } from 'react';
import useSymbolsApi from '../../../../../../Apis/Hooks/useSymbolsApiHook';
import Dropdown from '../../../../../../Components/Form/Inputs/Dropdown';
import { ForwardCurveParameters, UpsertMarketIndicatorFactorRequest } from '../../../MarketIndicatorsManagementDefinitions';
import styles from '../../MarketIndicatorFactorPage.module.scss';

interface IMarketFactorLongTermContractMonthDropdownProps {
    upsertMarketIndicatorFactorRequest: UpsertMarketIndicatorFactorRequest;
    setUpsertMarketIndicatorFactorRequest: (value: UpsertMarketIndicatorFactorRequest) => void;
}

const MarketFactorLongTermContractMonthDropdown: React.FC<IMarketFactorLongTermContractMonthDropdownProps> = (
    props: IMarketFactorLongTermContractMonthDropdownProps,
) => {
    const symbols = useSymbolsApi();

    const longTermContractMonthOptions = useMemo(() => {
        if (!symbols) {
            return [];
        }

        const currentContract = symbols.find(
            (x) => x.region === props.upsertMarketIndicatorFactorRequest.region && x.commodity === props.upsertMarketIndicatorFactorRequest.commodity,
        );

        const contractOptions = currentContract?.contractMonths?.map((contractMonth) => ({
            label: `${contractMonth}`,
            value: `${contractMonth}`,
        }));

        return contractOptions ?? [];
    }, [props.upsertMarketIndicatorFactorRequest, symbols]);

    useEffect(() => {
        if (
            longTermContractMonthOptions.length === 0 ||
            longTermContractMonthOptions.find(
                (x) => x.value === (props.upsertMarketIndicatorFactorRequest.parameters as ForwardCurveParameters).longTermContractMonth,
            )
        ) {
            return;
        }

        props.setUpsertMarketIndicatorFactorRequest({
            ...props.upsertMarketIndicatorFactorRequest,
            parameters: {
                ...props.upsertMarketIndicatorFactorRequest.parameters,
                longTermContractMonth: longTermContractMonthOptions[0].value,
            },
        });
    }, [longTermContractMonthOptions]);

    return (
        <div className={styles.indicator_add_and_edit_dropdown}>
            <Dropdown
                value={(props.upsertMarketIndicatorFactorRequest.parameters as ForwardCurveParameters).longTermContractMonth}
                options={longTermContractMonthOptions}
                handleOptionChange={(value) => {
                    props.setUpsertMarketIndicatorFactorRequest({
                        ...props.upsertMarketIndicatorFactorRequest,
                        parameters: {
                            ...props.upsertMarketIndicatorFactorRequest.parameters,
                            longTermContractMonth: value,
                        },
                    });
                }}
            />
        </div>
    );
};

export default MarketFactorLongTermContractMonthDropdown;
