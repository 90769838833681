export enum OutlookAndReportsTitleEnum {
    DailyReports = 'DailyReports',
    EuropeanUnionOceaniaDaily = 'EuropeanUnionOceaniaDaily',
    EarlyMorniningUpdateUnitedStates = 'EarlyMorniningUpdateUnitedStates',
    WeeklyReports = 'WeeklyReports',
    UdderIntelligence = 'UdderIntelligence',
    UdderIntelligenceChina = 'UdderIntelligenceChina',
    EuropeanUnionWeeklySummary = 'EuropeanUnionWeeklySummary',
    EuropeanUnionWeeklySummaryFr = 'EuropeanUnionWeeklySummaryFr',
    EuropeanUnionWeeklySummaryDe = 'EuropeanUnionWeeklySummaryDe',
    EuropeanUnionWeeklySummaryPl = 'EuropeanUnionWeeklySummaryPl',
    WeeklyMarketRecapChina = 'WeeklyMarketRecapChina',
    ProfitViewFarmerReport = 'ProfitViewFarmerReport',
    CommitmentOfTradersReport = 'CommitmentOfTradersReport',
    WeeklyPrices = 'WeeklyPrices',
    EuropeanUnionQuotations = 'EuropeanUnionQuotations',
    EuropeanUnionCheeseIndices = 'EuropeanUnionCheeseIndices',
    MilkPriceEquivalent = 'MilkPriceEquivalent',
    MilkPriceEquivalentFr = 'MilkPriceEquivalentFr',
    InternationalButterSpreads = 'InternationalButterSpread',
    InternationalWMPSpreads = 'InternationalWMPSpread',
    InternationalSMPSpreads = 'InternationalSMPSpread',
    InternationalWheySpreads = 'InternationalWheySpread',
    SeasonalPriceReport = 'SeasonalPriceReport',
    Video = 'Video',
    WeeklyVideo = 'WeeklyVideo',
    MonthlyWebinarReplay = 'MonthlyWebinarReplay',
    MonthlyOutlooks = 'MonthlyOutlooks',
    GlobalOutlook = 'GlobalOutlook',
    UnitedStatesOutlook = 'UnitedStatesOutlook',
    GlobalSlideDeck = 'GlobalSlideDeck',
    DairyProductsReport = 'DairyProductsReport',
    ColdStorageReport = 'ColdStorageReport',
    MilkProduction = 'MilkProduction',
    EuropeanUnionAndUnitedKingdomProduction = 'EuropeanUnionAndUnitedKingdomProduction',
    UnitedStatesProduction = 'UnitedStatesProduction',
    NewZealandProduction = 'NewZealandProduction',
    AustraliaProduction = 'AustraliaProduction',
    Exports = 'Exports',
    EuropeanUnionAndUnitedKingdomExports = 'EuropeanUnionAndUnitedKingdomExports',
    UnitedStatesExports = 'UnitedStatesExports',
    NewZealandExports = 'NewZealandExports',
    AustraliaExports = 'AustraliaExports',
    Imports = 'Imports',
    ChineseImports = 'ChineseImports',
    GlobalDairyTradeReport = 'GlobalDairyTradeReport',
    PreGlobalDairyTradeReport = 'PreGlobalDairyTradeReport',
    PostGlobalDairyTradeReport = 'PostGlobalDairyTradeReport',
}

const outlooksAndReportsData: {
    sectionHeader: OutlookAndReportsTitleEnum;
    data: { title: OutlookAndReportsTitleEnum; sidebarLabel: string; hasLineBreakAfter?: boolean }[];
}[] = [
    {
        sectionHeader: OutlookAndReportsTitleEnum.DailyReports,
        data: [
            {
                title: OutlookAndReportsTitleEnum.EuropeanUnionOceaniaDaily,
                sidebarLabel: '6141',
            },
            {
                title: OutlookAndReportsTitleEnum.EarlyMorniningUpdateUnitedStates,
                sidebarLabel: '5996',
            },
        ],
    },
    {
        sectionHeader: OutlookAndReportsTitleEnum.WeeklyReports,
        data: [
            {
                title: OutlookAndReportsTitleEnum.UdderIntelligence,
                sidebarLabel: '6086',
            },
            {
                title: OutlookAndReportsTitleEnum.UdderIntelligenceChina,
                sidebarLabel: '10742',
                hasLineBreakAfter: true,
            },
            {
                title: OutlookAndReportsTitleEnum.EuropeanUnionWeeklySummary,
                sidebarLabel: '6131',
            },
            {
                title: OutlookAndReportsTitleEnum.EuropeanUnionWeeklySummaryFr,
                sidebarLabel: '11536',
            },
            {
                title: OutlookAndReportsTitleEnum.EuropeanUnionWeeklySummaryDe,
                sidebarLabel: '11506',
            },
            {
                title: OutlookAndReportsTitleEnum.EuropeanUnionWeeklySummaryPl,
                sidebarLabel: '11652',
                hasLineBreakAfter: true,
            },
            {
                title: OutlookAndReportsTitleEnum.WeeklyMarketRecapChina,
                sidebarLabel: '17051',
            },

            {
                title: OutlookAndReportsTitleEnum.ProfitViewFarmerReport,
                sidebarLabel: '6001',
            },
            {
                title: OutlookAndReportsTitleEnum.CommitmentOfTradersReport,
                sidebarLabel: '5921',
            },
        ],
    },
    {
        sectionHeader: OutlookAndReportsTitleEnum.WeeklyPrices,
        data: [
            {
                title: OutlookAndReportsTitleEnum.EuropeanUnionQuotations,
                sidebarLabel: '6166',
            },
            {
                title: OutlookAndReportsTitleEnum.EuropeanUnionCheeseIndices,
                sidebarLabel: '11544',
                hasLineBreakAfter: true,
            },
            {
                title: OutlookAndReportsTitleEnum.MilkPriceEquivalent,
                sidebarLabel: '6156',
            },
            {
                title: OutlookAndReportsTitleEnum.MilkPriceEquivalentFr,
                sidebarLabel: '11651',
                hasLineBreakAfter: true,
            },
            {
                title: OutlookAndReportsTitleEnum.InternationalButterSpreads,
                sidebarLabel: '10476',
            },
            {
                title: OutlookAndReportsTitleEnum.InternationalWMPSpreads,
                sidebarLabel: '11617',
            },
            {
                title: OutlookAndReportsTitleEnum.InternationalSMPSpreads,
                sidebarLabel: '10521',
            },
            {
                title: OutlookAndReportsTitleEnum.InternationalWheySpreads,
                sidebarLabel: '11510',
            },
            {
                title: OutlookAndReportsTitleEnum.SeasonalPriceReport,
                sidebarLabel: '5931',
            },
        ],
    },
    {
        sectionHeader: OutlookAndReportsTitleEnum.Video,
        data: [
            {
                title: OutlookAndReportsTitleEnum.WeeklyVideo,
                sidebarLabel: '10759',
            },
            {
                title: OutlookAndReportsTitleEnum.MonthlyWebinarReplay,
                sidebarLabel: '10471',
            },
        ],
    },
    {
        sectionHeader: OutlookAndReportsTitleEnum.MonthlyOutlooks,
        data: [
            {
                title: OutlookAndReportsTitleEnum.GlobalOutlook,
                sidebarLabel: '6011',
            },
            {
                title: OutlookAndReportsTitleEnum.UnitedStatesOutlook,
                sidebarLabel: '6096',
            },
            {
                title: OutlookAndReportsTitleEnum.GlobalSlideDeck,
                sidebarLabel: '10491',
            },
            {
                title: OutlookAndReportsTitleEnum.DairyProductsReport,
                sidebarLabel: '6076',
            },
            {
                title: OutlookAndReportsTitleEnum.ColdStorageReport,
                sidebarLabel: '6091',
            },
        ],
    },
    {
        sectionHeader: OutlookAndReportsTitleEnum.MilkProduction,
        data: [
            {
                title: OutlookAndReportsTitleEnum.EuropeanUnionAndUnitedKingdomProduction,
                sidebarLabel: '6161',
            },
            {
                title: OutlookAndReportsTitleEnum.UnitedStatesProduction,
                sidebarLabel: '6021',
            },
            {
                title: OutlookAndReportsTitleEnum.NewZealandProduction,
                sidebarLabel: '10466',
            },
            {
                title: OutlookAndReportsTitleEnum.AustraliaProduction,
                sidebarLabel: '10581',
            },
        ],
    },
    {
        sectionHeader: OutlookAndReportsTitleEnum.Exports,
        data: [
            {
                title: OutlookAndReportsTitleEnum.EuropeanUnionAndUnitedKingdomExports,
                sidebarLabel: '6146',
            },
            {
                title: OutlookAndReportsTitleEnum.UnitedStatesExports,
                sidebarLabel: '10486',
            },
            {
                title: OutlookAndReportsTitleEnum.NewZealandExports,
                sidebarLabel: '6171',
            },
            {
                title: OutlookAndReportsTitleEnum.AustraliaExports,
                sidebarLabel: '10416',
            },
        ],
    },
    {
        sectionHeader: OutlookAndReportsTitleEnum.Imports,
        data: [
            {
                title: OutlookAndReportsTitleEnum.ChineseImports,
                sidebarLabel: '6136',
            },
        ],
    },
    {
        sectionHeader: OutlookAndReportsTitleEnum.GlobalDairyTradeReport,
        data: [
            {
                title: OutlookAndReportsTitleEnum.PreGlobalDairyTradeReport,
                sidebarLabel: '10481',
            },
            {
                title: OutlookAndReportsTitleEnum.PostGlobalDairyTradeReport,
                sidebarLabel: '10496',
            },
        ],
    },
];

export default outlooksAndReportsData;
