import React, { useEffect, useMemo, useState } from 'react';
import { demeterApi, marketIntelligenceApi } from '../../../../Apis/Apis';
import formattingService from '../../../../Core/Formatting/FormattingService';
import applicationSettings from '../../../../Core/Settings/ApplicationSettings';
import { DemeterMarketNewsType, DemeterNewsModel } from '../../../../Generated/Raven-Demeter';
import useApi from '../../../Apis/Hooks/useApiHook';
import browserService from '../../../Services/BrowserService';
import useLanguage from '../../../Services/Language/useLanguageHook';
import styles from './LatestNews.module.scss';
import LatestNewsModal from './LatestNewsModal';

interface ILatestNewsProps {
    marketNewsType: DemeterMarketNewsType;
    testId?: string;
}

const refreshTime = 300000; // Refreshes every 5 minutes.
const numberOfArticles = 10;

const LatestNews: React.FC<ILatestNewsProps> = (props) => {
    const [translations] = useLanguage();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [headlineNewsPreviewData, setHeadlineNewsPreviewData] = useState<{ data: DemeterNewsModel; index: number; finalArticleIndex: number }>();
    const [, refreshHeadlineNewsApi, demeterHeadlineNewsResponse] = useApi(() => {
        if (props.marketNewsType === 'Headline' || props.marketNewsType === 'Reports') {
            return demeterApi.listDemeterHeadlineNews(props.marketNewsType);
        }

        return null;
    });

    const [, refreshArticlesApi, listArticlesResponse] = useApi(() => {
        if (props.marketNewsType === 'All') {
            return marketIntelligenceApi.listArticles(
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                false,
                undefined,
                undefined,
                applicationSettings.applicationName,
                true,
                numberOfArticles,
                0,
            );
        }

        return null;
    });

    const articles = useMemo<DemeterNewsModel[]>(() => {
        if (demeterHeadlineNewsResponse) {
            return demeterHeadlineNewsResponse.rows ?? [];
        }

        if (listArticlesResponse) {
            return listArticlesResponse.rows?.map((x) => ({
                articleId: x.articleId,
                authorName: x.author.name,
                title: x.title,
                abstract: x.abstract,
                publishedDate: x.publishedDate,
                isShortForm: false,
                heroImageUrl: x.heroImageUrl,
                languageCode: x.languageCode,
            })) as DemeterNewsModel[];
        }

        return [];
    }, [demeterHeadlineNewsResponse, listArticlesResponse]);

    const handleHeadlinePreviewClick = async (headlineNewsPreviewObject: DemeterNewsModel, index: number) => {
        if (props.marketNewsType === DemeterMarketNewsType.Headline) {
            setHeadlineNewsPreviewData({ data: headlineNewsPreviewObject, index, finalArticleIndex: articles.length - 1 });
            setIsModalOpen(true);
        } else {
            browserService.openMarketIntelligenceArticle({
                language: headlineNewsPreviewObject.languageCode,
                articleId: headlineNewsPreviewObject.articleId,
            });
        }
    };

    const handleArticleChange = (isNext: boolean) => {
        const finalArticleIndex = articles.length - 1;
        if (headlineNewsPreviewData) {
            if (isNext) {
                setHeadlineNewsPreviewData((previous) => ({
                    data: articles[previous?.index ? previous.index + 1 : 0 + 1],
                    index: previous?.index ? previous.index + 1 : 0 + 1,
                    finalArticleIndex,
                }));
            } else {
                setHeadlineNewsPreviewData((previous) => ({
                    data: articles[previous?.index ? previous.index - 1 : 0],
                    index: previous?.index ? previous.index - 1 : 0,
                    finalArticleIndex,
                }));
            }
        }
    };

    useEffect(() => {
        const interval = setInterval(() => {
            refreshHeadlineNewsApi();
            refreshArticlesApi();
        }, refreshTime);

        return () => clearInterval(interval);
    }, []);

    return (
        <div data-testid={props.testId} className={styles.latest_news_parent_container}>
            <div className={styles.latest_news_title_cover}>
                <h2>
                    {props.marketNewsType === DemeterMarketNewsType.Headline
                        ? translations.dashboard.latestNews.latest
                        : translations.dashboard.latestNews.marketIntelligenceReports}
                </h2>
            </div>
            <div className={styles.latest_news_body}>
                <div className={styles.latest_news_body_top_spacer} />
                {articles.length &&
                    articles.map((demeterNews: DemeterNewsModel, index: number) => {
                        const formattedDate = formattingService.toDayOfWeekWithDayMonthYear(new Date(demeterNews.publishedDate));
                        return (
                            <button
                                className={styles.latest_news_body_row}
                                onClick={() => handleHeadlinePreviewClick(demeterNews, index)}
                                type="button"
                                key={demeterNews.articleId}
                            >
                                <h3>{demeterNews.title}</h3>
                                <h4>{demeterNews.authorName}</h4>
                                <h5>{formattedDate}</h5>
                                {index !== articles.length - 1 && <div className={styles.latest_news_body_line_break} />}
                            </button>
                        );
                    })}
                <div className={styles.latest_news_body_bottom_spacer} />
                {headlineNewsPreviewData?.data.isShortForm !== undefined && (
                    <LatestNewsModal
                        isModalOpen={isModalOpen}
                        setIsModalOpen={setIsModalOpen}
                        headlineNewsPreviewData={headlineNewsPreviewData}
                        handleArticleChange={handleArticleChange}
                    />
                )}
            </div>
            <div className={styles.latest_news_body_gradient} />
        </div>
    );
};

export default LatestNews;
