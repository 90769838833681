import React from 'react';
import stonexLogoSvg from '../../Assets/Icons/stonexPlusDark.svg';
import styles from './RegistrationPage.module.scss';

const RegistrationSelfLogoHeader: React.FC = () => (
    <div className={styles.registration_self_logo_header_container}>
        <img className={styles.registration_self_brand_logo} src={stonexLogoSvg} alt="#" />
    </div>
);

export default RegistrationSelfLogoHeader;
