import { useEffect, useState } from 'react';
import { demeterMarketIndicatorsApi } from '../../../../../Apis/Apis';
import {
    AddMarketIndicatorRequest,
    CloneMarketIndicatorRequest,
    DemeterCommodity,
    DemeterRegion,
    DemeterSymbolModel,
    MarketIndicatorModel,
    UpdateMarketIndicatorRequest,
} from '../../../../../Generated/Raven-Demeter';
import useApiWithoutAutoExecute from '../../../../Apis/Hooks/useApiWithoutAutoExecute';
import CheckboxInput from '../../../../Components/Form/Inputs/CheckboxInput';
import SelectInput from '../../../../Components/Form/Inputs/SelectInput';
import TextInput from '../../../../Components/Form/Inputs/TextInput';
import ActionModal from '../../../../Components/Modals/ActionModal/ActionModal';
import ActionsCellButton from '../../../../Components/Tables/ActionsCellButton/ActionsCellButton';
import RegexValidators from '../../../../Core/Validation/RegexValidators';
import useLanguage from '../../../../Services/Language/useLanguageHook';
import { marketIndicatorsManagementContractOptions } from '../MarketIndicatorsManagementDefinitions';
import styles from './MarketIndicatorActions.module.scss';
import MarketIndicatorBasisLocation from './MarketIndicatorBasisLocation';

// Defaults.
const defaultRegion = DemeterRegion.UnitedStates;
const defaultCommodity = DemeterCommodity.Butter;
const defaultNumberOfYears = 4;
const defaultContractNumber = 1;

interface IMarketIndicatorActionsProps {
    marketIndicator: MarketIndicatorModel;
    symbols: DemeterSymbolModel[];
    symbolDropdownOptions: {
        label: string;
        value: DemeterSymbolModel;
    }[];
    handleUpdateMarketIndicator: () => void;
}

const MarketIndicatorActions: React.FC<IMarketIndicatorActionsProps> = (props: IMarketIndicatorActionsProps) => {
    // Application hooks.
    const [translations] = useLanguage();

    // Component states.
    const [showCloneIndicatorModal, setShowCloneIndicatorModal] = useState(false);
    const [showEditIndicatorModal, setShowEditIndicatorModal] = useState(false);
    const [symbol, setSymbol] = useState<DemeterSymbolModel>();

    // Form request.
    const [marketIndicatorRequest, setMarketIndicatorRequest] = useState<AddMarketIndicatorRequest & { marketIndicatorGuid: string; isPublished?: boolean }>({
        marketIndicatorGuid: '',
        region: defaultRegion,
        commodity: defaultCommodity,
        contractNumber: defaultContractNumber,
        displayName: '',
        numberOfYears: defaultNumberOfYears,
        isPublished: true,
    });

    // Api hooks.
    const [, handleCloneMarketIndicator, cloneMarketIndicatorResponse] = useApiWithoutAutoExecute(
        () =>
            demeterMarketIndicatorsApi.cloneMarketIndicator(marketIndicatorRequest!.marketIndicatorGuid, marketIndicatorRequest as CloneMarketIndicatorRequest),
        {
            successMessage: translations.marketIndicatorsManagement.messages.indicatorCloneSuccessful,
            errorMessage: translations.marketIndicatorsManagement.messages.indicatorCloneFailed,
            conflictErrorMessage: translations.marketIndicatorsManagement.messages.indicatorCloneConflict,
        },
    );

    const [, handleUpdateMarketIndicator, updateMarketIndicatorResponse] = useApiWithoutAutoExecute(
        (marketIndicatorPublishUpdateRequest?: UpdateMarketIndicatorRequest) =>
            demeterMarketIndicatorsApi.updateMarketIndicator(
                marketIndicatorPublishUpdateRequest?.marketIndicatorGuid ?? marketIndicatorRequest!.marketIndicatorGuid,
                marketIndicatorPublishUpdateRequest ?? (marketIndicatorRequest as UpdateMarketIndicatorRequest),
            ),
        {
            successMessage: translations.marketIndicatorsManagement.messages.indicatorUpdateSuccessful,
            errorMessage: translations.marketIndicatorsManagement.messages.indicatorUpdateFailed,
        },
    );

    useEffect(() => {
        props.handleUpdateMarketIndicator();
    }, [cloneMarketIndicatorResponse, updateMarketIndicatorResponse]);

    const setMarketIndicatorReqestByRow = (row: MarketIndicatorModel, isEditing?: boolean) => {
        setMarketIndicatorRequest({
            ...marketIndicatorRequest,
            marketIndicatorGuid: row.marketIndicatorGuid,
            region: row.region,
            commodity: row.commodity,
            contractNumber: row.contractNumber,
            displayName: row.displayName ?? '',
            numberOfYears: row.numberOfYears,
            isPublished: row.isPublished,
        });

        if (isEditing) {
            setSymbol(props.symbols?.find((x) => x.commodity === row.commodity && x.region === row.region));
        } else {
            setSymbol(props.symbolDropdownOptions[0].value);
        }
    };

    const handleSymbolChange = (newSymbolModel?: DemeterSymbolModel) => {
        if (!newSymbolModel) {
            return;
        }

        setSymbol(newSymbolModel);
        setMarketIndicatorRequest({
            ...marketIndicatorRequest,
            region: newSymbolModel.region,
            commodity: newSymbolModel.commodity,
        });
    };

    return (
        <>
            <ActionsCellButton
                text={translations.marketIndicatorsManagement.actions.editDetails}
                handleClick={() => {
                    setMarketIndicatorReqestByRow(props.marketIndicator, true);
                    setShowEditIndicatorModal(true);
                }}
                ignoreSeparator
            />
            <ActionsCellButton
                text={translations.marketIndicatorsManagement.actions.clone}
                handleClick={() => {
                    setMarketIndicatorReqestByRow(props.marketIndicator);
                    setShowCloneIndicatorModal(true);
                }}
            />
            <ActionsCellButton
                text={
                    props.marketIndicator.isPublished
                        ? translations.marketIndicatorsManagement.actions.deactivate
                        : translations.marketIndicatorsManagement.actions.activate
                }
                handleClick={() =>
                    handleUpdateMarketIndicator({
                        marketIndicatorGuid: props.marketIndicator.marketIndicatorGuid,
                        displayName: props.marketIndicator.displayName ?? '',
                        numberOfYears: props.marketIndicator.numberOfYears,
                        isPublished: !props.marketIndicator.isPublished,
                        contractNumber: props.marketIndicator.contractNumber,
                    })
                }
            />
            <ActionModal
                header={translations.marketIndicatorsManagement.headers.cloneIndicators}
                showModal={showCloneIndicatorModal}
                actionButtonName={translations.actions.save}
                handleAction={() => {
                    handleCloneMarketIndicator();
                    setShowCloneIndicatorModal(false);
                }}
                handleCancel={() => setShowCloneIndicatorModal(false)}
                actionButtonDisabled={!RegexValidators.AlphaNumericMinimumLength2.test(marketIndicatorRequest.displayName)}
            >
                <>
                    <div className={styles.market_indicator_actions_modal_input_wrapper}>
                        <TextInput
                            title={translations.marketIndicatorsManagement.headers.indicatorName}
                            placeholder={translations.marketIndicatorsManagement.headers.indicatorName}
                            required
                            value={marketIndicatorRequest.displayName}
                            handleTextChange={(value) => setMarketIndicatorRequest({ ...marketIndicatorRequest, displayName: value })}
                            validation={RegexValidators.AlphaNumericMinimumLength2}
                            errorMessage={translations.marketIndicatorsManagement.messages.displayNameInvalid}
                        />
                    </div>
                    <div className={styles.market_indicator_actions_modal_input_wrapper}>
                        <CheckboxInput
                            title={translations.marketIndicatorsManagement.fields.basisLocation}
                            isChecked={!!marketIndicatorRequest.usePrices}
                            handleIsChecked={(isChecked: boolean) =>
                                setMarketIndicatorRequest({
                                    ...marketIndicatorRequest,
                                    region: defaultRegion,
                                    commodity: defaultCommodity,
                                    usePrices: isChecked,
                                })
                            }
                        />
                    </div>
                    {!marketIndicatorRequest.usePrices && (
                        <>
                            <div className={styles.market_indicator_actions_modal_input_wrapper}>
                                <SelectInput
                                    title={translations.marketIndicatorsManagement.headers.exchangeAndCommodity}
                                    options={props.symbolDropdownOptions}
                                    value={symbol}
                                    handleOptionChange={handleSymbolChange}
                                />
                            </div>
                            <div className={styles.market_indicator_actions_modal_input_wrapper}>
                                <SelectInput
                                    title={translations.marketIndicatorsManagement.fields.contractNumber}
                                    value={marketIndicatorRequest.contractNumber}
                                    options={marketIndicatorsManagementContractOptions}
                                    handleOptionChange={(value) => setMarketIndicatorRequest({ ...marketIndicatorRequest, contractNumber: +value! })}
                                />
                            </div>
                        </>
                    )}
                    {marketIndicatorRequest.usePrices && (
                        <MarketIndicatorBasisLocation
                            region={marketIndicatorRequest.region}
                            subRegion={marketIndicatorRequest.subRegion}
                            commodity={marketIndicatorRequest.commodity}
                            dataSource={marketIndicatorRequest.dataSource}
                            handleChange={(region, subRegion, commodity, dataSource) =>
                                setMarketIndicatorRequest({ ...marketIndicatorRequest, region, subRegion, commodity, dataSource })
                            }
                        />
                    )}
                </>
            </ActionModal>
            <ActionModal
                header={translations.marketIndicatorsManagement.actions.editDetails}
                showModal={showEditIndicatorModal}
                actionButtonName={translations.actions.save}
                handleAction={() => {
                    handleUpdateMarketIndicator();
                    setShowEditIndicatorModal(false);
                }}
                handleCancel={() => setShowEditIndicatorModal(false)}
                actionButtonDisabled={
                    !RegexValidators.AlphaNumericMinimumLength2.test(marketIndicatorRequest.displayName) ||
                    !RegexValidators.PositiveNumberZeroThroughTen.test(`${marketIndicatorRequest.numberOfYears}`)
                }
            >
                <>
                    <div className={styles.market_indicator_actions_modal_input_wrapper}>
                        <TextInput
                            title={translations.marketIndicatorsManagement.headers.indicatorName}
                            placeholder={translations.marketIndicatorsManagement.headers.indicatorName}
                            required
                            value={marketIndicatorRequest.displayName}
                            handleTextChange={(value) => setMarketIndicatorRequest({ ...marketIndicatorRequest, displayName: value })}
                            validation={RegexValidators.AlphaNumericMinimumLength2}
                            errorMessage={translations.marketIndicatorsManagement.messages.displayNameInvalid}
                        />
                    </div>
                    {!props.marketIndicator.usePrices && (
                        <>
                            <div className={styles.market_indicator_actions_modal_input_wrapper}>
                                <SelectInput
                                    disabled
                                    title={translations.marketIndicatorsManagement.headers.exchangeAndCommodity}
                                    options={props.symbolDropdownOptions}
                                    value={symbol}
                                    handleOptionChange={() => {}}
                                />
                            </div>
                            <div className={styles.market_indicator_actions_modal_input_wrapper}>
                                <SelectInput
                                    disabled
                                    title={translations.marketIndicatorsManagement.fields.contractNumber}
                                    value={marketIndicatorRequest.contractNumber}
                                    options={marketIndicatorsManagementContractOptions}
                                    handleOptionChange={(value) => setMarketIndicatorRequest({ ...marketIndicatorRequest, contractNumber: +value! })}
                                />
                            </div>
                        </>
                    )}
                    {props.marketIndicator.usePrices && (
                        <div className={styles.market_indicator_actions_modal_input_wrapper}>
                            <SelectInput
                                disabled
                                title={translations.marketIndicatorsManagement.fields.product}
                                options={props.symbolDropdownOptions}
                                value={symbol}
                                handleOptionChange={() => {}}
                            />
                        </div>
                    )}
                    <div className={styles.market_indicator_actions_modal_input_wrapper}>
                        <TextInput
                            title={translations.marketIndicatorsManagement.headers.numberOfYears}
                            placeholder={translations.marketIndicatorsManagement.headers.numberOfYears}
                            required
                            value={`${marketIndicatorRequest.numberOfYears}`}
                            handleTextChange={(value) => setMarketIndicatorRequest({ ...marketIndicatorRequest, numberOfYears: +value })}
                            validation={RegexValidators.PositiveNumberZeroThroughTen}
                            type="number"
                            errorMessage={translations.marketIndicatorsManagement.messages.numberOfYearsInvalid}
                        />
                    </div>
                </>
            </ActionModal>
        </>
    );
};

export default MarketIndicatorActions;
