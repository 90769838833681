import { Currency } from '../../../Generated/Raven-Demeter';
import { useApplicationDispatch, useApplicationSelector } from '../../../Redux/ReduxStore';
import { selectCurrency, setCurrency } from '../../../Redux/Slices/SystemSlice';

const useCurrency = (): [Currency | undefined, (currency: Currency | undefined) => void] => {
    const dispatch = useApplicationDispatch();
    const currency = useApplicationSelector(selectCurrency);

    return [
        currency,
        (newCurrency: Currency | undefined): void => {
            dispatch(setCurrency({ currency: newCurrency as Currency }));
        },
    ];
};

export default useCurrency;
