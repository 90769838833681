import { useEffect } from 'react';
import formattingService from '../../../../Core/Formatting/FormattingService';
import { DemeterRegion } from '../../../../Generated/Raven-Demeter';
import useWeeklyPricesSummaryApi from '../../../Apis/Hooks/useWeeklyPricesSummaryApiHook';
import useLanguage from '../../../Services/Language/useLanguageHook';
import styles from '../PriceSummaries.module.scss';
import EuropeanUnionWeeklySummaryBySubRegions from './EuropeanUnionWeeklySummaryBySubRegions';
import EuropeanUnionWeeklySummaryIndices from './EuropeanUnionWeeklySummaryIndices';

interface IEuropeanUnionWeeklySummaryProps {
    setTitle: (title: string) => void;
}

const EuropeanUnionWeeklySummary: React.FC<IEuropeanUnionWeeklySummaryProps> = ({ setTitle }) => {
    const summaryData = useWeeklyPricesSummaryApi(DemeterRegion.EuropeanUnion);
    const [translations] = useLanguage();

    useEffect(
        () =>
            setTitle(
                ` ${translations.dashboard.priceSummary.europeanUnion.summaryHeader} ${translations.words.overview} ${
                    translations.words.results
                } ${formattingService.toShortDayMonthYear(summaryData?.asOfDate)}` ?? '',
            ),
        [summaryData, translations],
    );

    return (
        <div data-testid="EuropeanUnionWeeklySummary" className={styles.price_summary_dashboard_container}>
            <EuropeanUnionWeeklySummaryIndices summaryData={summaryData?.rows ?? undefined} />
            <EuropeanUnionWeeklySummaryBySubRegions summaryData={summaryData?.rows ?? undefined} />
        </div>
    );
};

export default EuropeanUnionWeeklySummary;
