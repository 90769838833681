import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import useLanguage from '../../Services/Language/useLanguageHook';
import LinkButton, { LinkButtonType } from '../Form/Buttons/LinkButton';
import styles from './Headers.module.scss';

interface IHeaderWithBackNavigationProps {
    title: string;
    handleBackNavigation: () => void;
    testId?: string;
}

const PageHeaderWithBackNavigation = (props: IHeaderWithBackNavigationProps) => {
    const [translations] = useLanguage();

    return (
        <div className={styles.header_with_back_navigation}>
            <LinkButton title={translations.actions.back} onClick={props.handleBackNavigation} type={LinkButtonType.White} icon={<ArrowBackIcon />} />
            <h3 className={styles.page_header_page_title} data-testid={props.testId}>
                {props.title}
            </h3>
        </div>
    );
};

export default PageHeaderWithBackNavigation;
