import { DemeterRegion } from '../../../Generated/Raven-Demeter';
import { useApplicationSelector } from '../../../Redux/ReduxStore';
import { selectLocation } from '../../../Redux/Slices/SystemSlice';
import CmeSpotSummarySmall from './CmeSpotSummary/CmeSpotSummarySmall';
import EuropeanUnionWeeklySummarySmall from './EuropeanUnionWeeklySummaries/EuropeanUnionWeeklySummarySmall';
import GlobalDairyTradeSummarySmall from './GlobalDairyTradeSummary/GlobalDairyTradeSummarySmall';
import styles from './PriceSummaries.module.scss';

const PriceSummarySmall: React.FC = () => {
    const location = useApplicationSelector(selectLocation);

    return (
        <div data-testid="PriceSummarySmall" className={styles.price_summary_small_dashboard_container}>
            {location === DemeterRegion.UnitedStates && <CmeSpotSummarySmall />}
            {location === DemeterRegion.Oceania && <GlobalDairyTradeSummarySmall />}
            {location !== DemeterRegion.UnitedStates && location !== DemeterRegion.Oceania && <EuropeanUnionWeeklySummarySmall />}
        </div>
    );
};

export default PriceSummarySmall;
