import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Collapse } from '@mui/material';
import { FC, ReactNode, useEffect, useState } from 'react';
import styles from './ToggleableContainer.module.scss';

interface IToggleableContainer {
    expandedText: string;
    collapsedText: string;
    open?: boolean;
    onChange?: (open: boolean) => void;
    children: ReactNode | ReactNode[];
}

const ToggleableContainer: FC<IToggleableContainer> = (props: IToggleableContainer) => {
    const [open, setOpen] = useState(props.open ?? false);

    useEffect(() => {
        if (props.onChange) {
            props.onChange(open);
        }
    }, [open]);

    const handleToggle = () => {
        setOpen(!open);
    };

    return (
        <div className={styles.toggleable_container}>
            <Collapse in={open}>
                <div className={styles.toggleable_container_content}>{props.children}</div>
            </Collapse>
            <button onClick={handleToggle} type="button" className={styles.toggleable_container_text_button}>
                {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                {open ? props.expandedText : props.collapsedText}
            </button>
        </div>
    );
};

export default ToggleableContainer;
