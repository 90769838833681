import React from 'react';

export interface IUserDataExternalLinkProps {
    url?: string;
    text?: string;
}

const UserDataExternalLink: React.FC<IUserDataExternalLinkProps> = (props: IUserDataExternalLinkProps) => (
    <a href={props.url} target="_blank" rel="noopener noreferrer" className="privacy_policy_link">
        {props.text}
    </a>
);

export default UserDataExternalLink;
