import { useEffect, useMemo, useState } from 'react';
import formattingService from '../../../Core/Formatting/FormattingService';
import { Currency, DemeterMarket, DemeterRegion, DemeterSymbolModel, ExchangeType, SymbolCategory, UnitOfMeasure } from '../../../Generated/Raven-Demeter/api';
import { useApplicationSelector } from '../../../Redux/ReduxStore';
import { selectUserCurrentMarket } from '../../../Redux/Slices/UserSlice';
import useSymbolsApi from '../../Apis/Hooks/useSymbolsApiHook';
import useUnitOfMeasureConversionApi from '../../Apis/Hooks/useUnitOfMeasureConversionApiHook';
import CurrencyDropdown from '../../Components/Form/Inputs/CurrencyDropdown';
import Dropdown from '../../Components/Form/Inputs/Dropdown';
import UnitOfMeasureGeneralDropdown from '../../Components/Form/Inputs/UnitOfMeasureGeneralDropdown';
import PageHeader from '../../Components/Headers/PageHeader';
import PageLoadingSpinner from '../../Components/LoadingSpinner/PageLoadingSpinner';
import useExchangeCommodityNavigation from '../../Components/Navigation/Hooks/useExchangeCommodityNavigationHook';
import useCurrency from '../../Core/Hooks/useCurrencyHook';
import useUnitOfMeasure from '../../Core/Hooks/useUnitOfMeasureHook';
import useLanguage from '../../Services/Language/useLanguageHook';
import ForwardSpreadCalculator from '../Calculators/ForwardSpreadCalculator/ForwardSpreadCalculator';
import FuturesExchangeCommodityMenu from './FuturesExchangeCommodityMenu';
import styles from './FuturesPage.module.scss';
import OtcPricesTable from './Otc/OtcPricesTable';
import FuturesPricesTable from './Prices/FuturesPricesTable';

export type FuturesTabType = ExchangeType | 'forwardSpreadCalculatorExchange';

export interface IFuturesConversionsDropdownsProps {
    futuresTabType: FuturesTabType;
    symbolModel?: DemeterSymbolModel;
    handleCurrencyChange: (currency: Currency | undefined) => void;
    handleUnitOfMeasureChange: (unitOfMeasure: UnitOfMeasure | undefined) => void;
}

const FuturesConversionsDropdowns: React.FC<IFuturesConversionsDropdownsProps> = (props: IFuturesConversionsDropdownsProps) => {
    const [translations] = useLanguage();

    // Otc and others.
    const [currency, setCurrency] = useCurrency();
    const [unitOfMeasure, setUnitOfMeasure] = useUnitOfMeasure('MetricImperial');

    // Only futures.
    const [currencyFutures, setCurrencyFutures] = useState<Currency>();
    const [unitOfMeasureFutures, setUnitOfMeasureFutures] = useState<UnitOfMeasure>();
    const [, unitOfMeasureConversionMap] = useUnitOfMeasureConversionApi(props.symbolModel?.reutersInstrumentCodePrefix);

    const unitOfMeasureFuturesOptions = useMemo(() => {
        const initialOptions = [{ label: translations.dropdown.nativeUnits, value: undefined }];

        if (!unitOfMeasureConversionMap || props.futuresTabType === ExchangeType.Otc) {
            return initialOptions;
        }

        const options = Object.keys(unitOfMeasureConversionMap)
            .map((x) => formattingService.toPascalCase(x))
            .map((x) => ({ label: translations.unitOfMeasure[x], value: x as UnitOfMeasure | undefined }));

        return [...initialOptions, ...options];
    }, [props.futuresTabType, props.symbolModel, unitOfMeasureConversionMap]);

    useEffect(() => {
        if (props.futuresTabType === ExchangeType.Otc) {
            props.handleCurrencyChange(currency);
        } else {
            props.handleCurrencyChange(currencyFutures);
        }
    }, [props.futuresTabType, currency, currencyFutures]);

    useEffect(() => {
        if (props.futuresTabType === ExchangeType.Otc) {
            props.handleUnitOfMeasureChange(unitOfMeasure);
        } else {
            props.handleUnitOfMeasureChange(unitOfMeasureFutures);
        }
    }, [props.futuresTabType, unitOfMeasure, unitOfMeasureFutures]);

    if (props.futuresTabType === 'forwardSpreadCalculatorExchange') {
        return null;
    }

    if (props.futuresTabType === ExchangeType.Otc) {
        return (
            <>
                <div className={styles.futures_dropdown_unit_of_measure}>
                    <UnitOfMeasureGeneralDropdown unitOfMeasure={unitOfMeasure} handleChange={setUnitOfMeasure} />
                </div>
                <div className={styles.futures_dropdown_currency}>
                    <CurrencyDropdown currency={currency} handleChange={setCurrency} />
                </div>
            </>
        );
    }

    return (
        <>
            <div className={styles.futures_page_dropdown_container_unit_of_measure}>
                <Dropdown
                    value={unitOfMeasureFutures}
                    options={unitOfMeasureFuturesOptions}
                    handleOptionChange={(newUnitOfMeasure: UnitOfMeasure | undefined) => setUnitOfMeasureFutures(newUnitOfMeasure)}
                />
            </div>
            <div className={styles.futures_dropdown_currency}>
                <CurrencyDropdown
                    disabled={props.symbolModel?.symbolCategory === SymbolCategory.Currency}
                    currency={props.symbolModel?.symbolCategory !== SymbolCategory.Currency ? currencyFutures : undefined}
                    handleChange={(newCurrency: Currency | undefined) => setCurrencyFutures(newCurrency)}
                />
            </div>
        </>
    );
};

const FuturesRefactorPage: React.FC = () => {
    // Constants.
    const [translations] = useLanguage();
    const market = useApplicationSelector(selectUserCurrentMarket);
    const pageTitle = useMemo(
        () => (market === DemeterMarket.Energy ? translations.futures.titleWithoutOtc : translations.futures.title),
        [market, translations],
    );

    // Navigation hooks.
    const symbols = useSymbolsApi();
    const [exchangeCommoditySelection, symbolModel] = useExchangeCommodityNavigation();
    const isOtc = exchangeCommoditySelection.exchange === ExchangeType.Otc;
    const isForwardSpreadCalculator = exchangeCommoditySelection.exchange === 'forwardSpreadCalculatorExchange';
    const isFuture = !isOtc && !isForwardSpreadCalculator;
    const futuresTabType = exchangeCommoditySelection.exchange as FuturesTabType;

    // Data hooks.
    const [currency, setCurrency] = useState<Currency>();
    const [unitOfMeasure, setUnitOfMeasure] = useState<UnitOfMeasure>();

    return (
        <div className={styles.master_page_container}>
            <PageHeader
                title={pageTitle}
                rightSideComponent={
                    <FuturesConversionsDropdowns
                        futuresTabType={futuresTabType}
                        symbolModel={symbolModel}
                        handleCurrencyChange={setCurrency}
                        handleUnitOfMeasureChange={setUnitOfMeasure}
                    />
                }
                testId="FuturesPageHeader"
            >
                <FuturesExchangeCommodityMenu />
            </PageHeader>
            {!symbols || !exchangeCommoditySelection.exchange ? (
                <PageLoadingSpinner />
            ) : (
                <>
                    <div className={styles.futures_main_container}>
                        {isFuture && (
                            <FuturesPricesTable exchangeCommoditySelection={exchangeCommoditySelection} currency={currency} unitOfMeasure={unitOfMeasure} />
                        )}
                        {isOtc && (
                            <OtcPricesTable
                                regionCommoditySelection={{
                                    region: DemeterRegion.EuropeanUnion,
                                    commodity: exchangeCommoditySelection.commodity,
                                    subRegion: '',
                                    extraParameters: '',
                                    dataFrequency: '',
                                }}
                                currency={currency}
                                unitOfMeasure={unitOfMeasure}
                            />
                        )}
                    </div>
                    {isForwardSpreadCalculator && (
                        <div className={styles.futures_calculator_container}>
                            <ForwardSpreadCalculator />
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default FuturesRefactorPage;
