import { useEffect, useState } from 'react';
import { demeterCalculatorsApi } from '../../../../Apis/Apis';
import useApi from '../../../Apis/Hooks/useApiHook';
import TextInput from '../../../Components/Form/Inputs/TextInput';
import PageLoadingSpinner from '../../../Components/LoadingSpinner/PageLoadingSpinner';
import useLanguage from '../../../Services/Language/useLanguageHook';
import styles from './FederalMilkMarketingOrderCalculator.module.scss';

export interface CalculationInputs {
    cheeseBlockPrice: string;
    cheeseBarrelPrice: string;
    dryWheyPrice: string;
    butterPrice: string;
    nonFatDryMilkPrice: string;
    cheeseBlockPercent: string;
}

interface CalculationResults {
    averageCheesePrice: number;
    milkClass3Price: number;
    milkClass4Price: number;
    milkClass1Price: number;
    milkClass2Price: number;
    butterfatPrice: number;
    proteinPrice: number;
    skimMilkClass3Price: number;
    skimMilkClass4Price: number;
    nonfatSolidsPrice: number;
    otherSolidsPrice: number;
}

const FederalMilkMarketingOrderCalculator = () => {
    const [calculatorInputs, setCalculatorInputs] = useState<CalculationInputs>();
    const [calculationResults, setCalculationResults] = useState<CalculationResults>();

    const [translations] = useLanguage();
    const [loading, refreshApi, calculatorResponse] = useApi(() => demeterCalculatorsApi.getFederalMilkMarketingOrderCalculator());

    useEffect(() => {
        if (!calculatorResponse) {
            return;
        }
        setCalculatorInputs({
            cheeseBlockPrice: calculatorResponse.cheeseBlockPrice!.toString(),
            cheeseBarrelPrice: calculatorResponse.cheeseBarrelPrice!.toString(),
            dryWheyPrice: calculatorResponse.dryWheyPrice!.toString(),
            butterPrice: calculatorResponse.butterPrice!.toString(),
            nonFatDryMilkPrice: calculatorResponse.nonFatDryMilkPrice!.toString(),
            cheeseBlockPercent: calculatorResponse.cheeseBlockPercent!.toString(),
        });
    }, [calculatorResponse]);

    useEffect(() => {
        if (calculatorInputs?.cheeseBlockPercent) {
            const butterfatPrice = +((+calculatorInputs!.butterPrice - 0.1715) * 1.211).toFixed(4);
            const averageCheesePrice = +(
                +calculatorInputs!.cheeseBlockPrice! * (+calculatorInputs!.cheeseBlockPercent! / 100) +
                (+calculatorInputs!.cheeseBarrelPrice! + 0.03) * (getCheeseBarrelPercent() / 100)
            ).toFixed(4);
            const proteinPrice = +((averageCheesePrice - 0.2003) * 1.383 + ((averageCheesePrice - 0.2003) * 1.572 - butterfatPrice * 0.9) * 1.17).toFixed(4);
            const otherSolidsPrice = +((+calculatorInputs!.dryWheyPrice! - 0.1991) * 1.03).toFixed(4);
            const skimMilkClass3Price = +(proteinPrice * 3.1 + otherSolidsPrice * 5.9).toFixed(4);
            const milkClass3Price = +(skimMilkClass3Price * 0.965 + butterfatPrice * 3.5).toFixed(2);
            const nonfatSolidsPrice = +((+calculatorInputs!.nonFatDryMilkPrice! - 0.1678) * 0.99).toFixed(4);
            const skimMilkClass4Price = +(nonfatSolidsPrice * 9).toFixed(4);
            const milkClass4Price = +(skimMilkClass4Price * 0.965 + butterfatPrice * 3.5).toFixed(2);
            const skimMilkClass3AndClass4AveragePrice = +((skimMilkClass3Price + skimMilkClass4Price) / 2.0).toFixed(4);
            const milkClass1Price = +(0.965 * (skimMilkClass3AndClass4AveragePrice + 0.74) + 3.5 * butterfatPrice).toFixed(2);
            const milkClass2Price = +(0.965 * (nonfatSolidsPrice * 9 + 0.7) + (butterfatPrice + 0.007) * 3.5).toFixed(2);

            setCalculationResults({
                averageCheesePrice,
                butterfatPrice,
                proteinPrice,
                skimMilkClass3Price,
                skimMilkClass4Price,
                nonfatSolidsPrice,
                otherSolidsPrice,
                milkClass3Price,
                milkClass4Price,
                milkClass1Price,
                milkClass2Price,
            });
        }
    }, [calculatorInputs]);

    const getCheeseBarrelPercent = (): number => 100 - +(calculatorInputs?.cheeseBlockPercent ?? 50);

    return loading && !calculatorInputs ? (
        <PageLoadingSpinner />
    ) : (
        <div>
            <div className={styles.calculators_sub_heading_main}>
                <div className={styles.calculators_sub_heading_bold_item}>{translations.calculators.text.federalMilkMarketingOrder}</div>
                <div className={styles.calculators_sub_heading_item}>{translations.calculators.text.federalMilkMarketingOrderDescription}</div>
            </div>
            <div data-testid="FederalMilkMarketingOrderCalculator" className={styles.calculators_calculation_container}>
                <div className={styles.calculators_calculation_heading}>{translations.calculators.text.milkPriceCalculator}</div>
                <div className={styles.calculators_input_container}>
                    <div className={styles.calculators_input}>
                        <TextInput
                            title={`${translations.commodity.CheeseBlock} ${translations.words.price}`}
                            type="number"
                            required
                            value={calculatorInputs?.cheeseBlockPrice?.toString()}
                            handleTextChange={(value) => setCalculatorInputs({ ...calculatorInputs!, cheeseBlockPrice: value ?? '' })}
                        />
                    </div>
                    <div className={styles.calculators_input}>
                        <TextInput
                            title={`${translations.commodity.CheeseBarrel} ${translations.words.price}`}
                            type="number"
                            required
                            value={calculatorInputs?.cheeseBarrelPrice?.toString()}
                            handleTextChange={(value) => setCalculatorInputs({ ...calculatorInputs!, cheeseBarrelPrice: value ?? '' })}
                        />
                    </div>
                    <div className={styles.calculators_input}>
                        <TextInput
                            title={`${translations.commodity.DryWhey} ${translations.words.price}`}
                            type="number"
                            required
                            value={calculatorInputs?.dryWheyPrice?.toString()}
                            handleTextChange={(value) => setCalculatorInputs({ ...calculatorInputs!, dryWheyPrice: value ?? '' })}
                        />
                    </div>
                    <div className={styles.calculators_input}>
                        <TextInput
                            title={`${translations.commodity.Butter} ${translations.words.price}`}
                            type="number"
                            required
                            value={calculatorInputs?.butterPrice?.toString()}
                            handleTextChange={(value) => setCalculatorInputs({ ...calculatorInputs!, butterPrice: value ?? '' })}
                        />
                    </div>
                    <div className={styles.calculators_input}>
                        <TextInput
                            title={`${translations.commodity.NonFatDryMilk} ${translations.words.price}`}
                            type="number"
                            required
                            value={calculatorInputs?.nonFatDryMilkPrice?.toString()}
                            handleTextChange={(value) => setCalculatorInputs({ ...calculatorInputs!, nonFatDryMilkPrice: value ?? '' })}
                        />
                    </div>
                </div>
                <div className={styles.calculators_calculation_heading}>{translations.calculators.text.cheesePricing}</div>
                <div className={styles.calculators_input_container}>
                    <div className={styles.calculators_input}>
                        <TextInput
                            title={`${translations.commodity.CheeseBlock} %`}
                            type="number"
                            required
                            value={calculatorInputs?.cheeseBlockPercent?.toString()}
                            handleTextChange={(value) => setCalculatorInputs({ ...calculatorInputs!, cheeseBlockPercent: value ?? '' })}
                        />
                        <div className={styles.calculators_percent_label}> % </div>
                    </div>
                    <div className={styles.calculators_input}>
                        <TextInput
                            title={`${translations.commodity.CheeseBarrel} %`}
                            type="number"
                            required
                            value={getCheeseBarrelPercent().toString()}
                            handleTextChange={(value) => setCalculatorInputs({ ...calculatorInputs!, cheeseBlockPercent: `${100 - +value}` })}
                        />
                        <div className={styles.calculators_percent_label}> % </div>
                    </div>

                    <div className={styles.calculators_average_price}>
                        <div className={styles.calculators_result_price_heading}>{translations.calculators.text.averageCheesePrice}</div>
                        <div className={styles.calculators_average_price_unit}>
                            ${calculationResults?.averageCheesePrice} / {translations.unitOfMeasure.ShortPoundSingle}
                        </div>
                    </div>
                </div>
                <button className={styles.calculators_button_cancel} type="button" onClick={() => refreshApi()}>
                    {translations.actions.reset}
                </button>
            </div>
            <div data-testid="FederalMilkMarketingOrderCalculatorResult" className={styles.calculators_result_container}>
                <div className={styles.calculators_calculation_heading}>{translations.calculators.text.result}</div>
                <div className={styles.calculators_result_upper_container}>
                    <div className={styles.calculators_result_upper_item}>
                        <div className={styles.calculators_result_price}>
                            <div className={styles.calculators_result_price_heading}>{translations.commodity.MilkClass3}</div>
                            <div className={styles.calculators_result_price_unit}>
                                ${calculationResults?.milkClass3Price} / {translations.unitOfMeasure.ShortHundredWeight}
                            </div>
                        </div>
                        <div className={styles.calculators_result_price}>
                            <div className={styles.calculators_result_price_heading}>{translations.commodity.MilkClass1}</div>
                            <div className={styles.calculators_result_price_unit}>
                                ${calculationResults?.milkClass1Price} / {translations.unitOfMeasure.ShortHundredWeight}
                            </div>
                        </div>
                    </div>
                    <div className={styles.calculators_result_upper_item}>
                        <div className={styles.calculators_result_price}>
                            <div className={styles.calculators_result_price_heading}>{translations.commodity.MilkClass4}</div>

                            <div className={styles.calculators_result_price_unit}>
                                ${calculationResults?.milkClass4Price} / {translations.unitOfMeasure.ShortHundredWeight}
                            </div>
                        </div>
                        <div className={styles.calculators_result_price}>
                            <div className={styles.calculators_result_price_heading}>{translations.commodity.MilkClass2}</div>
                            <div className={styles.calculators_result_price_unit}>
                                ${calculationResults?.milkClass2Price} / {translations.unitOfMeasure.ShortHundredWeight}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.calculators_result_table}>
                    <div className={styles.calculators_result_table_row}>
                        <div>{translations.words.product}</div>
                        <div>{translations.words.price}</div>
                    </div>
                    <div className={styles.calculators_result_table_bar} />
                    <div className={styles.calculators_result_table_row}>
                        <div>
                            {translations.commodity.Butterfat} {translations.words.price}
                        </div>
                        <div>
                            {calculationResults?.butterfatPrice} / {translations.unitOfMeasure.ShortPoundSingle}
                        </div>
                    </div>
                    <div className={styles.calculators_result_table_bar} />
                    <div className={styles.calculators_result_table_row}>
                        <div>
                            {translations.commodity.Protein} {translations.words.price}
                        </div>
                        <div>
                            {calculationResults?.proteinPrice} / {translations.unitOfMeasure.ShortPoundSingle}
                        </div>
                    </div>
                    <div className={styles.calculators_result_table_bar} />
                    <div className={styles.calculators_result_table_row}>
                        <div>
                            {translations.commodity.SkimMilkClass3} {translations.words.price}
                        </div>
                        <div>
                            {calculationResults?.skimMilkClass3Price} / {translations.unitOfMeasure.ShortHundredWeight}
                        </div>
                    </div>
                    <div className={styles.calculators_result_table_bar} />
                    <div className={styles.calculators_result_table_row}>
                        <div>
                            {translations.commodity.SkimMilkClass4} {translations.words.price}
                        </div>
                        <div>
                            {calculationResults?.skimMilkClass4Price} / {translations.unitOfMeasure.ShortHundredWeight}
                        </div>
                    </div>
                    <div className={styles.calculators_result_table_bar} />
                    <div className={styles.calculators_result_table_row}>
                        <div>
                            {translations.commodity.NonFatSolids} {translations.words.price}
                        </div>
                        <div>
                            {calculationResults?.nonfatSolidsPrice} / {translations.unitOfMeasure.ShortPoundSingle}
                        </div>
                    </div>
                    <div className={styles.calculators_result_table_bar} />
                    <div className={styles.calculators_result_table_row}>
                        <div>
                            {translations.commodity.OtherSolids} {translations.words.price}
                        </div>
                        <div>
                            {calculationResults?.otherSolidsPrice} / {translations.unitOfMeasure.ShortPoundSingle}
                        </div>
                    </div>
                    <div className={styles.calculators_result_table_bar} />
                </div>
            </div>
        </div>
    );
};

export default FederalMilkMarketingOrderCalculator;
