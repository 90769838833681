import React from 'react';
import check from '../../Assets/Registration/check.png';
import RegistrationButton, { RegistrationButtonType } from '../../Components/Form/Buttons/RegistrationButton/RegistrationButton';
import useSearchParameters from '../../Components/Navigation/Hooks/useSearchParametersHook';
import useLanguage from '../../Services/Language/useLanguageHook';
import styles from './RegistrationPage.module.scss';
import RegistrationSelfLogoHeader from './RegistrationSelfLogoHeader';
import RegistrationSelfSummary from './RegistrationSelfSummary';

const RegistrationSelfSuccessPage: React.FC = () => {
    // Actions hooks.
    const [translations] = useLanguage();
    const [searchParameters] = useSearchParameters();

    return (
        <div className={styles.registration_self_success}>
            <RegistrationSelfLogoHeader />
            <div className={styles.registration_self_success_header}>
                <h3 className={styles.registration_self_success_main_header}>{translations.registration.success.header}</h3>
                <div className={styles.registration_self_success_subheader}>{translations.registration.success.subheader}</div>
                <div className={styles.registration_self_success_what_happens_next}>{translations.registration.success.whatWillHappenNext}</div>
                <ul>
                    <li>
                        <img className={styles.registration_self_list_checkmark} src={check} alt="#" />
                        {translations.registration.success.youWillRecieveEmail}
                    </li>
                    <li>
                        <img className={styles.registration_self_list_checkmark} src={check} alt="#" />
                        {translations.registration.success.watchTheTutorial}
                    </li>
                    <li>
                        <img className={styles.registration_self_list_checkmark} src={check} alt="#" />
                        {translations.registration.success.clickTheButton}
                    </li>
                </ul>
                <RegistrationButton
                    title={translations.actions.gainInstantAccess}
                    type={RegistrationButtonType.WhitePrimary}
                    navigateTo={searchParameters.redirectUrl}
                />
            </div>
            <RegistrationSelfSummary />
        </div>
    );
};

export default RegistrationSelfSuccessPage;
