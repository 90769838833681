import { useMediaQuery } from 'react-responsive';

export type ScreenType = 'xs' | 'sm' | 'md' | 'lg';

const screenTypeVals = {
    xs: 0,
    sm: 1,
    md: 2,
    lg: 3,
};
export class ScreenSize {
    public type: ScreenType = 'xs';

    constructor(public settings: IScreenSizeSettings) {
        if (this.settings.isSmall) {
            this.type = 'sm';
        } else if (this.settings.isMedium) {
            this.type = 'md';
        } else if (this.settings.isLarge) {
            this.type = 'lg';
        }
    }

    is(type: ScreenType): boolean {
        return type === this.type;
    }

    isAtLeast(type: ScreenType): boolean {
        return screenTypeVals[this.type] >= screenTypeVals[type];
    }

    isAtMost(type: ScreenType): boolean {
        return screenTypeVals[this.type] <= screenTypeVals[type];
    }
}

interface IScreenSizeSettings {
    isExtraSmall: boolean;
    isSmall: boolean;
    isMedium: boolean;
    isLarge: boolean;
}

export default function useScreenSize(): ScreenSize {
    return new ScreenSize({
        isExtraSmall: useMediaQuery({ maxWidth: 480 }),
        isSmall: useMediaQuery({ minWidth: 480, maxWidth: 768 }),
        isMedium: useMediaQuery({ minWidth: 768, maxWidth: 992 }),
        isLarge: useMediaQuery({ minWidth: 992 }),
    });
}
