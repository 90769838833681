import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SearchIcon from '@mui/icons-material/Search';
import { AgGridReact } from 'ag-grid-react';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { demeterApi, demeterUsersApi } from '../../../../Apis/Apis';
import AgGridBuilder from '../../../../Components/AgGridBuilder/AgGridBuilder';
import downloadSvg from '../../../../Components/Assets/download.svg';
import formattingService from '../../../../Core/Formatting/FormattingService';
import applicationConstants from '../../../../Core/Utility/ApplicationConstants';
import {
    DemeterPermissionType,
    DemeterUserModel,
    DemeterUserStatus,
    DemeterUserType,
    ListDemeterUsersResponse,
    SortDirection,
} from '../../../../Generated/Raven-Demeter';
import NavigationRoutes from '../../../../Layouts/NavigationRoutes';
import { useApplicationSelector } from '../../../../Redux/ReduxStore';
import { SearchParameters } from '../../../../Redux/Slices/SystemSlice';
import { selectCompanyGuid, selectUserType } from '../../../../Redux/Slices/UserSlice';
import useApi from '../../../Apis/Hooks/useApiHook';
import LinkButton, { LinkButtonType } from '../../../Components/Form/Buttons/LinkButton';
import Dropdown from '../../../Components/Form/Inputs/Dropdown';
import PageLoadingSpinner from '../../../Components/LoadingSpinner/PageLoadingSpinner';
import useSearchParameters from '../../../Components/Navigation/Hooks/useSearchParametersHook';
import useNotificationHook from '../../../Components/Notifications/useNotificationHook';
import { ApiListResponse } from '../../../Components/Tables/TableWrapper/ApiListResponse';
import TableWrapper from '../../../Components/Tables/TableWrapper/TableWrapper';
import browserService from '../../../Services/BrowserService';
import useLanguage from '../../../Services/Language/useLanguageHook';
import usePermission from '../../../Services/Permissions/usePermissionHook';
import UserActions from './UserActions';
import styles from './UsersPage.module.scss';
import { RendererParameters, usersColumnDefinitions, usersColumnOptions } from './UsersPageDefinitions';
import useUserTypeOptions from './useUserTypeOptionsHook';

const sortColumns = ['companyName', 'fullName', 'email'];

type SortColumn = (typeof sortColumns)[number];

interface ListDemeterUsersRequest {
    searchTerm?: string;
    userType?: DemeterUserType;
    userStatus?: DemeterUserStatus;
    demeterCompanyGuid?: string;
    sortColumn?: SortColumn;
    sortDirection?: SortDirection;
    take: number;
    skip: number;
}

const UsersPage: React.FC = () => {
    const [translations] = useLanguage();
    const routeParameters = useParams();
    const companyGuidFromRoute = routeParameters.demeterCompanyGuid;
    const companyGuidFromRedux = useApplicationSelector(selectCompanyGuid);
    const currentUserType = useApplicationSelector(selectUserType);
    const isPremium = currentUserType === DemeterUserType.Premium;
    const navigate = useNavigate();
    const [searchParameters, setSearchParameters] = useSearchParameters();
    const [displayError, displaySuccess] = useNotificationHook();
    const usersExportPermission = usePermission(DemeterPermissionType.UsersExport);

    const [listDemeterUsersRequest, setListDemeterUsersRequest] = useState<ListDemeterUsersRequest>(() => ({
        searchTerm: searchParameters.searchTerm ?? '',
        userType: searchParameters.userType !== 'All' ? (searchParameters.userType as DemeterUserType) : undefined,
        userStatus: searchParameters.userStatus !== 'All' ? (searchParameters.userStatus as DemeterUserStatus) : undefined,
        demeterCompanyGuid: companyGuidFromRoute ?? undefined,
        sortColumn: searchParameters.sortColumn ?? 'fullName',
        sortDirection: (searchParameters.sortDirection as SortDirection) ?? SortDirection.Asc,
        take: applicationConstants.ItemsPerPage,
        skip: (searchParameters.skip as unknown as number) ?? 0,
    }));
    const [searchTermInput, setSearchTermInput] = useState<string>('');
    const [listDemeterUsersResponse, setListDemeterUsersResponse] = useState<ListDemeterUsersResponse>();
    const gridReference = useRef<AgGridReact>();

    const selectedCompanyGuid = useMemo(() => {
        if (isPremium && companyGuidFromRedux) {
            return companyGuidFromRedux;
        }

        if (companyGuidFromRoute) {
            return companyGuidFromRoute;
        }

        return null;
    }, [companyGuidFromRedux, companyGuidFromRoute]);

    const [, , getCompanyResponse] = useApi(() => {
        if (selectedCompanyGuid) {
            return demeterApi.getDemeterCompany(selectedCompanyGuid);
        }

        return null;
    });

    const companyDetails = useMemo(() => {
        if (!getCompanyResponse) {
            return [];
        }

        return [
            {
                label: translations.companies.fields.numberOfLicenses,
                value: formattingService.toFormattedNumber(getCompanyResponse.demeterCompany?.numberOfLicenses),
            },
            {
                label: translations.companies.fields.invoiceDate,
                value: formattingService.toShortDayMonthYear(getCompanyResponse.demeterCompany?.invoiceDate),
            },
            {
                label: translations.companies.fields.subscriptionFrequency,
                value: getCompanyResponse.demeterCompany?.subscriptionFrequency
                    ? translations.subscriptionFrequency[getCompanyResponse.demeterCompany.subscriptionFrequency]
                    : null,
            },
            {
                label: translations.companies.fields.subscriptionPrice,
                value: formattingService.toPriceString(getCompanyResponse.demeterCompany?.subscriptionPrice),
            },
            {
                label: translations.companies.fields.billingCurrency,
                value: getCompanyResponse.demeterCompany?.billingCurrency,
            },
            {
                label: translations.companies.fields.billingRegion,
                value: getCompanyResponse.demeterCompany?.billingRegion ? translations.region[getCompanyResponse.demeterCompany.billingRegion] : null,
            },
        ];
    }, [translations, getCompanyResponse]);

    const handleUserDeactivated = () => {
        refreshApi();
    };

    useEffect(() => {
        setSearchTermInput(searchParameters?.searchTerm);
        setListDemeterUsersRequest({ ...listDemeterUsersRequest, searchTerm: searchParameters?.searchTerm, skip: 0 });
        refreshApi();
    }, [searchParameters]);

    const filteredUsersColumnDefinitions = useMemo(() => {
        const newUsersColumnDefinitions = usersColumnDefinitions;
        if (selectedCompanyGuid) {
            return newUsersColumnDefinitions.filter(
                (usersColumnDefinition) =>
                    usersColumnDefinition.field === 'fullName' ||
                    usersColumnDefinition.field === 'email' ||
                    usersColumnDefinition.field === 'userType' ||
                    usersColumnDefinition.field === 'userStatus' ||
                    usersColumnDefinition.field === 'actions',
            );
        }

        return newUsersColumnDefinitions;
    }, [selectedCompanyGuid]);

    useEffect(() => {
        const actionIndex = filteredUsersColumnDefinitions.findIndex((element) => element.field === 'actions');
        filteredUsersColumnDefinitions[actionIndex].cellRenderer = (parameters: RendererParameters) => (
            <UserActions user={parameters.data} handleUserDeactivated={handleUserDeactivated} />
        );
    }, []);

    const [, refreshApi, apiResponse] = useApi(() => {
        if (!listDemeterUsersRequest) {
            return null;
        }
        return demeterUsersApi.listDemeterUsers(
            listDemeterUsersRequest.searchTerm,
            listDemeterUsersRequest.userType,
            listDemeterUsersRequest.userStatus,
            listDemeterUsersRequest.demeterCompanyGuid,
            listDemeterUsersRequest.sortColumn,
            listDemeterUsersRequest.sortDirection,
            listDemeterUsersRequest.take,
            listDemeterUsersRequest.skip,
        );
    });

    useEffect(() => {
        if (apiResponse?.rows) {
            apiResponse.rows = apiResponse?.rows.map((row: DemeterUserModel) => ({ ...row, id: row.demeterUserTrialGuid }));
            setListDemeterUsersResponse(apiResponse);
        }
    }, [apiResponse]);

    // Have event listeners on the column headers for sorting.
    useEffect(() => {
        const headers: NodeListOf<Element> = document.querySelectorAll('.ag-header-cell');
        headers.forEach((header) => header.addEventListener('click', () => handleHeaderClick(header)));
        // Cloning and replacing allows me to remove the listener. Was having some issues
        // achieving this with a remove listener function call alone.
        return () =>
            headers.forEach((header) => {
                const headerClone = header.cloneNode(true);
                header?.parentNode?.replaceChild(headerClone, header);
            });
    }, [listDemeterUsersResponse, gridReference?.current]);

    const handleSearch = (event: KeyboardEvent) => {
        const { value } = event.target as HTMLInputElement;
        if (event.key === 'Enter') {
            const newSearchParameters: SearchParameters = {
                searchTerm: value as string,
                userStatus: listDemeterUsersRequest.userStatus ?? 'All',
                userType: listDemeterUsersRequest.userType ?? 'All',
                sortColumn: listDemeterUsersRequest.sortColumn as string,
                sortDirection: listDemeterUsersRequest.sortDirection as string,
                skip: listDemeterUsersRequest.skip as unknown as string,
                tab: 'Users',
            };

            setSearchParameters(newSearchParameters);
            setListDemeterUsersRequest({ ...listDemeterUsersRequest, searchTerm: value, skip: 0 });
        }
    };

    const handleHeaderClick: (header: Element | any) => Promise<void> | EventListenerOrEventListenerObject | null = async (header) => {
        const currentColId = header.getAttribute('col-id');
        if (sortColumns.includes(currentColId)) {
            const newRequest = { ...listDemeterUsersRequest };

            if (newRequest.sortColumn === currentColId) {
                newRequest.sortDirection = newRequest.sortDirection === SortDirection.Asc ? SortDirection.Desc : SortDirection.Asc;
            } else {
                newRequest.sortColumn = currentColId;
                newRequest.sortDirection = SortDirection.Asc;
            }

            setListDemeterUsersRequest(newRequest);
            refreshApi();
        }
    };

    const handlePagination = (apiListResponse: ApiListResponse) => {
        const newRequest = { ...listDemeterUsersRequest };
        newRequest.skip = apiListResponse.skip ?? 0;
        setListDemeterUsersRequest(newRequest);
        refreshApi();
    };

    const navigateBackToCompanies = () => {
        setSearchParameters({ tab: 'Companies' });
        navigate(NavigationRoutes.Administration);
    };

    const handleExportUsers = async () => {
        try {
            const listUsersDownload = await demeterUsersApi.listDemeterUsers(
                listDemeterUsersRequest.searchTerm,
                listDemeterUsersRequest.userType,
                listDemeterUsersRequest.userStatus,
                undefined,
                listDemeterUsersRequest.sortColumn,
                listDemeterUsersRequest.sortDirection,
                -1,
                0,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'text/csv',
                        accept: 'text/csv',
                    },
                },
            );

            browserService.downloadCsv(listUsersDownload.data as string, 'Users');
            displaySuccess(translations.users.text.successExport);
        } catch {
            displayError(translations.users.errors.failureExport);
        }
    };

    const filterRoleDropdownOptions = useUserTypeOptions(true);
    const userStatusOptions: { value: DemeterUserStatus | 'All'; label: string }[] = (Object.keys(DemeterUserStatus) as Array<keyof typeof DemeterUserStatus>)
        .filter((el) => el !== DemeterUserStatus.Synced && el !== DemeterUserStatus.Locked)
        .map((key) => ({
            value: key,
            label: translations.users.status[key],
        }));

    userStatusOptions.unshift({ value: 'All', label: translations.users.status.All });

    return !listDemeterUsersResponse ? (
        <PageLoadingSpinner />
    ) : (
        <div className={companyGuidFromRoute ? styles.master_page_container : styles.users_container}>
            {companyGuidFromRoute && (
                <div className={styles.add_edit_user_header_container}>
                    <LinkButton title={translations.actions.back} onClick={navigateBackToCompanies} type={LinkButtonType.White} icon={<ArrowBackIcon />} />
                    <h2>{getCompanyResponse?.demeterCompany?.name}</h2>
                </div>
            )}
            <div className={selectedCompanyGuid ? styles.users_buttons_row_details : styles.users_actions_row}>
                {!selectedCompanyGuid && (
                    <div className={styles.users_actions_search}>
                        <div className={styles.users_search_area}>
                            <SearchIcon className={styles.users_search_area_icon} />
                            <input
                                onKeyDown={handleSearch as any}
                                className={styles.users_actions_row_input}
                                placeholder={translations.actions.search}
                                value={searchTermInput}
                                onChange={(event) => setSearchTermInput((event.target as HTMLInputElement).value)}
                            />
                        </div>
                        <div className={styles.users_dropdown_container}>
                            <Dropdown
                                value={listDemeterUsersRequest.userType ?? 'All'}
                                options={filterRoleDropdownOptions}
                                handleOptionChange={(value: DemeterUserType | 'All') => {
                                    if (value === 'All') {
                                        setListDemeterUsersRequest({ ...listDemeterUsersRequest, userType: undefined, skip: 0 });
                                        refreshApi();
                                    } else {
                                        setListDemeterUsersRequest({ ...listDemeterUsersRequest, userType: value as DemeterUserType, skip: 0 });
                                        refreshApi();
                                    }
                                }}
                            />
                        </div>
                        <div className={styles.users_dropdown_container}>
                            <Dropdown
                                value={listDemeterUsersRequest.userStatus ?? 'All'}
                                options={userStatusOptions}
                                handleOptionChange={(value: DemeterUserStatus | 'All') => {
                                    if (value === 'All') {
                                        setListDemeterUsersRequest({ ...listDemeterUsersRequest, userStatus: undefined, skip: 0 });
                                        refreshApi();
                                    } else {
                                        setListDemeterUsersRequest({ ...listDemeterUsersRequest, userStatus: value as DemeterUserStatus, skip: 0 });
                                        refreshApi();
                                    }
                                }}
                            />
                        </div>
                    </div>
                )}
                <div className={styles.users_buttons_row}>
                    {!selectedCompanyGuid && (
                        <LinkButton title={translations.actions.import} navigateTo={`${NavigationRoutes.UsersImport}`} type={LinkButtonType.White} />
                    )}
                    {usersExportPermission && (
                        <LinkButton
                            title={translations.users.actions.export}
                            type={LinkButtonType.White}
                            icon={<img style={{ marginRight: '8px' }} src={downloadSvg} alt="download" />}
                            onClick={handleExportUsers}
                        />
                    )}
                    <LinkButton title={translations.actions.add} navigateTo={`${NavigationRoutes.UsersAdd}`} type={LinkButtonType.Blue} />
                </div>
            </div>
            {companyDetails.length > 0 && (
                <div className={styles.users_companies_details_container}>
                    {companyDetails.map((companyDetail) => (
                        <div className={styles.users_companies_detail}>
                            <p className={styles.users_companies_detail_label}>{companyDetail.label}</p>
                            <p className={styles.users_companies_detail_value}>{companyDetail.value}</p>
                        </div>
                    ))}
                </div>
            )}
            <div className={styles.users_table_container}>
                <TableWrapper apiListResponse={listDemeterUsersResponse} showPagination handlePagination={handlePagination}>
                    <AgGridBuilder
                        gridRef={gridReference}
                        rowData={listDemeterUsersResponse.rows!}
                        hasSaveColumnsState
                        columnDefinitions={filteredUsersColumnDefinitions}
                        defaultColumnDefinition={usersColumnOptions}
                        gridHeightFull
                        domLayout="autoHeight"
                        testId="UsersTable"
                    />
                </TableWrapper>
            </div>
        </div>
    );
};

export default UsersPage;
