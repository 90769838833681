import { CommodityPriceModel, DemeterDataValue, MarketPriceModel } from '../../../../Generated/Raven-Demeter';
import { BasisAdjustment, BasisLagPeriod, BasisMonthlyDate, BasisPeriod, BasisValueModel } from './BasisCalculatorDefinitions';

export interface IBasisCalculatorCalculationParameters {
    basisLagPeriod?: BasisLagPeriod;
    basisPeriod?: BasisPeriod;
    basisAdjustment?: BasisAdjustment;
    useOptimalLag?: boolean;
    useRegression?: boolean;
    startDate?: BasisMonthlyDate;
    productPrices1: MarketPriceModel[] | CommodityPriceModel[] | DemeterDataValue[] | undefined;
    productPrices2: MarketPriceModel[] | CommodityPriceModel[] | DemeterDataValue[] | undefined;
}

export interface IBasisCalculatorCalculationResult {
    correlation: number;
    rSquared: number;
    optimalLagCorrelation: number;
    optimalLagMonth?: number;
    intercept: number;
    slope: number;
    basisDeciles: number[];
    threeMonthsBasisDeciles: number[];
    sixMonthsBasisDeciles: number[];
    nineMonthsBasisDeciles: number[];
    twelveMonthsBasisDeciles: number[];
    basisAverage: number;
    threeMonthsBasisAverage: number;
    sixMonthsBasisAverage: number;
    nineMonthsBasisAverage: number;
    twelveMonthsBasisAverage: number;
    prices1: BasisValueModel[];
    basis: BasisValueModel[];
    threeMonthsBasis: BasisValueModel[];
    sixMonthsBasis: BasisValueModel[];
    nineMonthsBasis: BasisValueModel[];
    twelveMonthsBasis: BasisValueModel[];
    lags: BasisValueModel[];
    metricSerie: BasisValueModel[];
    twelveMonthRollingCorrelation: BasisValueModel[];
    regression: BasisValueModel[];
    metric: number | undefined;
    basisPeriod: BasisPeriod | undefined;
    basisAdjustment: BasisAdjustment | undefined;
    basisLagPeriod: BasisLagPeriod | undefined;
}

const BasisCalculatorService = {
    calculate: (params: IBasisCalculatorCalculationParameters): IBasisCalculatorCalculationResult => {
        const getBasisLagMonths = () => {
            switch (params.basisLagPeriod) {
                case BasisLagPeriod.OneMonthLag:
                    return 1;
                case BasisLagPeriod.TwoMonthLag:
                    return 2;
                case BasisLagPeriod.ThreeMonthLag:
                    return 3;
                case BasisLagPeriod.FourMonthLag:
                    return 4;
                case BasisLagPeriod.FiveMonthLag:
                    return 5;
                case BasisLagPeriod.SixMonthLag:
                    return 6;
                default:
                    return 0;
            }
        };

        const lagMonths = getBasisLagMonths();

        const assertEqualLength = (array1: BasisValueModel[], array2: BasisValueModel[]) => {
            if (array1.length !== array2.length) {
                throw Error(`Both arrays must be the same size. Array1 has ${array1.length} elements and Array2 has ${array2.length} elements`);
            }
        };

        const unifyPriceModels = (priceModels: MarketPriceModel[] | CommodityPriceModel[] | DemeterDataValue[] | undefined): BasisValueModel[] => {
            if (!priceModels) {
                return [];
            }
            const unifiedPrices = priceModels.map((priceModel) => {
                if ('settlementPrice' in priceModel) {
                    return { asOfDate: new Date(priceModel.asOfDate), value: priceModel.settlementPrice } as BasisValueModel;
                }
                return { asOfDate: new Date(priceModel.asOfDate), value: priceModel.value } as BasisValueModel;
            });
            return unifiedPrices as BasisValueModel[];
        };

        const convertToMonthlyData = (unifiedPrices: BasisValueModel[]): BasisValueModel[] => {
            const monthlyPrices: { [key: string]: number[] } = {};
            unifiedPrices.forEach((priceModel) => {
                const yearMonth = `${priceModel.asOfDate.getFullYear()}-${String(priceModel.asOfDate.getMonth() + 1).padStart(2, '0')}`;
                if (!monthlyPrices[yearMonth]) {
                    monthlyPrices[yearMonth] = [];
                }
                monthlyPrices[yearMonth].push(priceModel.value);
            });
            const monthlyAveragePrices: BasisValueModel[] = Object.keys(monthlyPrices).map((yearMonth) => {
                const prices = monthlyPrices[yearMonth];
                const averagePrice = prices.reduce((sum, price) => sum + price, 0) / prices.length;
                const [year, month] = yearMonth.split('-');
                return { asOfDate: new Date(parseInt(year, 10), parseInt(month, 10) - 1), value: averagePrice, isActualValue: true };
            });
            return monthlyAveragePrices;
        };

        const filterCommonDateItems = (prices1: BasisValueModel[], prices2: BasisValueModel[]): BasisValueModel[] => {
            const datesInArray2 = new Set(prices2.map((item) => item.asOfDate.getTime()));
            return prices1.filter((item) => datesInArray2.has(item.asOfDate.getTime()));
        };

        const filterFromStartDate = (values: BasisValueModel[]): BasisValueModel[] => {
            const startDate = new Date(Date.parse(params.startDate!));
            return values.filter((item) => item.asOfDate >= startDate);
        };

        const calculateRegression = (array1: BasisValueModel[], slope: number, intercept: number): BasisValueModel[] => {
            const regression = array1.map((item, index) => {
                const value = array1[index].value * slope + intercept;
                return {
                    asOfDate: item.asOfDate,
                    value,
                    isActualValue: true,
                };
            });
            return regression;
        };

        const calculateBasis = (array1: BasisValueModel[], array2: BasisValueModel[], slope: number, intercept: number): BasisValueModel[] => {
            assertEqualLength(array1, array2);
            if (params.useRegression) {
                const regression = calculateRegression(array2, slope, intercept);
                const basis = array1.map((item, index) => {
                    const difference = item.value - regression[index].value;
                    return {
                        asOfDate: item.asOfDate,
                        value: difference,
                        isActualValue: true,
                    };
                });
                return basis;
            }
            const basis = array1.map((item, index) => {
                const difference = item.value - array2[index].value;
                return {
                    asOfDate: item.asOfDate,
                    value: difference,
                    isActualValue: true,
                };
            });
            return basis;
        };

        const calculateBasisAverages = (array: BasisValueModel[], numMonths: number): BasisValueModel[] => {
            const result = array
                .map((item, i, arr) => {
                    if (i + 1 < numMonths) {
                        return null;
                    }
                    const lastNMonths = arr.slice(i + 1 - numMonths, i + 1);
                    const sum = lastNMonths.reduce((accumulator, price) => accumulator + price.value, 0);
                    const average = sum / numMonths;
                    return { value: average, asOfDate: item.asOfDate, isActualValue: true };
                })
                .filter((avg) => avg !== null) as BasisValueModel[];
            return result;
        };

        const calculateLags = (values: BasisValueModel[], months?: number | undefined): BasisValueModel[] => {
            const numMonths = months || lagMonths;
            const lags = values.map((item, index, array) => {
                if (index < numMonths) {
                    return {
                        asOfDate: item.asOfDate,
                        value: 0,
                        isActualValue: true,
                    };
                }
                return {
                    asOfDate: item.asOfDate,
                    value: array[index - numMonths].value,
                    isActualValue: true,
                };
            });
            return lags;
        };

        const calculateDeciles = (priceArray: BasisValueModel[]): number[] => calculateDecilesExc(priceArray).reverse();

        const calculateDecilesExc = (priceArray: BasisValueModel[]): number[] => {
            const sortedArray = priceArray.map((item) => item.value).sort((a, b) => a - b);
            const deciles = [];
            for (let i = 0; i <= 10; i += 1) {
                deciles.push(percentileExc(sortedArray, i * 10));
            }
            return deciles;
        };

        const percentileExc = (sortedArr: number[], percentile: number): number => {
            const rank = (percentile / 100) * (sortedArr.length + 1);
            if (rank < 1) {
                return sortedArr[0];
            }
            if (rank >= sortedArr.length) {
                return sortedArr[sortedArr.length - 1];
            }
            const lowerIndex = Math.floor(rank) - 1;
            const upperIndex = lowerIndex + 1;
            const weight = rank - Math.floor(rank);
            return sortedArr[lowerIndex] * (1 - weight) + sortedArr[upperIndex] * weight;
        };

        const calculateCorrelation = (array1: BasisValueModel[], array2: BasisValueModel[]): number => {
            assertEqualLength(array1, array2);
            const n = array1.length;
            const mean1 = array1.reduce((sum, item) => sum + item.value, 0) / n;
            const mean2 = array2.reduce((sum, item) => sum + item.value, 0) / n;
            let numerator = 0;
            let denominator1 = 0;
            let denominator2 = 0;
            for (let i = 0; i < n; i += 1) {
                const diff1 = array1[i].value - mean1;
                const diff2 = array2[i].value - mean2;
                numerator += diff1 * diff2;
                denominator1 += diff1 * diff1;
                denominator2 += diff2 * diff2;
            }
            const denominator = Math.sqrt(denominator1) * Math.sqrt(denominator2);
            const correlation = denominator === 0 ? 0 : numerator / denominator;
            return correlation * 100;
        };

        const calculateRollingCorrelation = (array1: BasisValueModel[], array2: BasisValueModel[], monthNum: number): BasisValueModel[] => {
            assertEqualLength(array1, array2);
            const result: BasisValueModel[] = [];
            const n = array1.length;
            for (let i = monthNum; i < n; i += 1) {
                const sliceArray1 = array1.slice(i - monthNum, i);
                const sliceArray2 = array2.slice(i - monthNum, i);
                const correlation = calculateCorrelation(sliceArray1, sliceArray2);
                result.push({ asOfDate: array1[i].asOfDate, value: correlation, isActualValue: true });
            }
            return result;
        };

        const calculateRSquared = (array1: BasisValueModel[], array2: BasisValueModel[]): number => {
            assertEqualLength(array1, array2);
            const n = array1.length;
            const sumX = array2.reduce((sum, item) => sum + item.value, 0);
            const sumY = array1.reduce((sum, item) => sum + item.value, 0);
            const sumXY = array2.reduce((sum, item, index) => sum + item.value * array1[index].value, 0);
            const sumXX = array2.reduce((sum, item) => sum + item.value * item.value, 0);
            const sumYY = array1.reduce((sum, item) => sum + item.value * item.value, 0);
            const r2 = ((n * sumXY - sumX * sumY) / Math.sqrt((n * sumXX - sumX * sumX) * (n * sumYY - sumY * sumY))) ** 2;
            return r2;
        };

        const calculateAverage = (values: BasisValueModel[]): number => {
            const sum = values.reduce((accumulator, item) => accumulator + item.value, 0);
            const average = sum / values.length;
            return average;
        };

        const calculateOptimalLagMonth = (array1: BasisValueModel[], array2: BasisValueModel[], maxMonths: number = 6): number => {
            assertEqualLength(array1, array2);
            let optimalLag = 0;
            let highestCorrelation = -Infinity;
            for (let lag = 1; lag <= maxMonths; lag += 1) {
                const laggedValues = calculateLags(array2, lag);
                const correlation = calculateCorrelation(array1, laggedValues);
                if (correlation > highestCorrelation) {
                    highestCorrelation = correlation;
                    optimalLag = lag;
                }
            }
            return optimalLag;
        };

        const calculateIntercept = (array1: BasisValueModel[], array2: BasisValueModel[]): number => {
            assertEqualLength(array1, array2);
            const n = array1.length;
            const sumX = array2.reduce((sum, item) => sum + item.value, 0);
            const sumY = array1.reduce((sum, item) => sum + item.value, 0);
            const sumXY = array2.reduce((sum, item, index) => sum + item.value * array1[index].value, 0);
            const sumXX = array2.reduce((sum, item) => sum + item.value * item.value, 0);
            const slope = (n * sumXY - sumX * sumY) / (n * sumXX - sumX * sumX);
            const intercept = (sumY - slope * sumX) / n;
            return intercept;
        };

        const calculateSlope = (array1: BasisValueModel[], array2: BasisValueModel[]): number => {
            assertEqualLength(array1, array2);
            const n = array1.length;
            const sumX = array2.reduce((sum, item) => sum + item.value, 0);
            const sumY = array1.reduce((sum, item) => sum + item.value, 0);
            const sumXY = array2.reduce((sum, item, index) => sum + item.value * array1[index].value, 0);
            const sumXX = array2.reduce((sum, item) => sum + item.value * item.value, 0);
            const slope = (n * sumXY - sumX * sumY) / (n * sumXX - sumX * sumX);
            return slope;
        };

        const unifiedPrices1 = unifyPriceModels(params.productPrices1);
        const unifiedPrices2 = unifyPriceModels(params.productPrices2);
        const monthlyPrices1 = convertToMonthlyData(unifiedPrices1);
        const monthlyPrices2 = convertToMonthlyData(unifiedPrices2);
        const prices1 = filterCommonDateItems(monthlyPrices1, monthlyPrices2);
        const prices2 = filterCommonDateItems(monthlyPrices2, monthlyPrices1);

        const lags = calculateLags(prices2);

        const lagsSinceStartDate = filterFromStartDate(lags);
        const prices1SinceStartDate = filterFromStartDate(prices1);
        const prices2SinceStartDate = filterFromStartDate(prices2);

        const correlation = calculateCorrelation(prices1SinceStartDate, prices2SinceStartDate);
        const rSquared = calculateRSquared(prices1SinceStartDate, prices2SinceStartDate);
        const intercept = calculateIntercept(prices1SinceStartDate, lagsSinceStartDate);
        const slope = calculateSlope(prices1SinceStartDate, lagsSinceStartDate);

        const regression = calculateRegression(lagsSinceStartDate, slope, intercept);
        const basis = calculateBasis(prices1SinceStartDate, lagsSinceStartDate, slope, intercept);
        const threeMonthsBasis = calculateBasisAverages(basis, 3);
        const sixMonthsBasis = calculateBasisAverages(basis, 6);
        const nineMonthsBasis = calculateBasisAverages(basis, 9);
        const twelveMonthsBasis = calculateBasisAverages(basis, 12);

        const regressionSinceStartDate = filterFromStartDate(regression);
        const basisSinceStartDate = filterFromStartDate(basis);
        const threeMonthsBasisSinceStartDate = filterFromStartDate(threeMonthsBasis);
        const sixMonthsBasisSinceStartDate = filterFromStartDate(sixMonthsBasis);
        const nineMonthsBasisSinceStartDate = filterFromStartDate(nineMonthsBasis);
        const twelveMonthsBasisSinceStartDate = filterFromStartDate(twelveMonthsBasis);

        const twelveMonthRollingCorrelation = calculateRollingCorrelation(prices1, lags, 12);
        const twelveMonthRollingCorrelationSinceStartDate = filterFromStartDate(twelveMonthRollingCorrelation);

        const basisAverage = calculateAverage(basisSinceStartDate);
        const threeMonthsBasisAverage = calculateAverage(threeMonthsBasisSinceStartDate);
        const sixMonthsBasisAverage = calculateAverage(sixMonthsBasisSinceStartDate);
        const nineMonthsBasisAverage = calculateAverage(nineMonthsBasisSinceStartDate);
        const twelveMonthsBasisAverage = calculateAverage(twelveMonthsBasisSinceStartDate);

        const basisDeciles = calculateDeciles(basisSinceStartDate);
        const threeMonthsBasisDeciles = calculateDeciles(threeMonthsBasisSinceStartDate);
        const sixMonthsBasisDeciles = calculateDeciles(sixMonthsBasisSinceStartDate);
        const nineMonthsBasisDeciles = calculateDeciles(nineMonthsBasisSinceStartDate);
        const twelveMonthsBasisDeciles = calculateDeciles(twelveMonthsBasisSinceStartDate);

        const optimalLagMonth = calculateOptimalLagMonth(prices1, prices2);
        const optimalLags = calculateLags(prices2, optimalLagMonth);
        const optimalLagsSinceStartDate = filterFromStartDate(optimalLags);
        const optimalLagCorrelation = calculateCorrelation(prices1SinceStartDate, optimalLagsSinceStartDate);

        const metrics = {
            Average: {
                Basis: basisAverage,
                ThreeMonthBasis: threeMonthsBasisAverage,
                SixMonthBasis: sixMonthsBasisAverage,
                NineMonthBasis: nineMonthsBasisAverage,
                TwelveMonthBasis: twelveMonthsBasisAverage,
            },
            Max: {
                Basis: basisDeciles[0],
                ThreeMonthBasis: threeMonthsBasisDeciles[0],
                SixMonthBasis: sixMonthsBasisDeciles[0],
                NineMonthBasis: nineMonthsBasisDeciles[0],
                TwelveMonthBasis: twelveMonthsBasisDeciles[0],
            },
            Top10Percent: {
                Basis: basisDeciles[1],
                ThreeMonthBasis: threeMonthsBasisDeciles[1],
                SixMonthBasis: sixMonthsBasisDeciles[1],
                NineMonthBasis: nineMonthsBasisDeciles[1],
                TwelveMonthBasis: twelveMonthsBasisDeciles[1],
            },
            Top20Percent: {
                Basis: basisDeciles[2],
                ThreeMonthBasis: threeMonthsBasisDeciles[2],
                SixMonthBasis: sixMonthsBasisDeciles[2],
                NineMonthBasis: nineMonthsBasisDeciles[2],
                TwelveMonthBasis: twelveMonthsBasisDeciles[2],
            },
            Top30Percent: {
                Basis: basisDeciles[3],
                ThreeMonthBasis: threeMonthsBasisDeciles[3],
                SixMonthBasis: sixMonthsBasisDeciles[3],
                NineMonthBasis: nineMonthsBasisDeciles[3],
                TwelveMonthBasis: twelveMonthsBasisDeciles[3],
            },
            Top40Percent: {
                Basis: basisDeciles[4],
                ThreeMonthBasis: threeMonthsBasisDeciles[4],
                SixMonthBasis: sixMonthsBasisDeciles[4],
                NineMonthBasis: nineMonthsBasisDeciles[4],
                TwelveMonthBasis: twelveMonthsBasisDeciles[4],
            },
            Top50Percent: {
                Basis: basisDeciles[5],
                ThreeMonthBasis: threeMonthsBasisDeciles[5],
                SixMonthBasis: sixMonthsBasisDeciles[5],
                NineMonthBasis: nineMonthsBasisDeciles[5],
                TwelveMonthBasis: twelveMonthsBasisDeciles[5],
            },
            Bottom40Percent: {
                Basis: basisDeciles[6],
                ThreeMonthBasis: threeMonthsBasisDeciles[6],
                SixMonthBasis: sixMonthsBasisDeciles[6],
                NineMonthBasis: nineMonthsBasisDeciles[6],
                TwelveMonthBasis: twelveMonthsBasisDeciles[6],
            },
            Bottom30Percent: {
                Basis: basisDeciles[7],
                ThreeMonthBasis: threeMonthsBasisDeciles[7],
                SixMonthBasis: sixMonthsBasisDeciles[7],
                NineMonthBasis: nineMonthsBasisDeciles[7],
                TwelveMonthBasis: twelveMonthsBasisDeciles[7],
            },
            Bottom20Percent: {
                Basis: basisDeciles[8],
                ThreeMonthBasis: threeMonthsBasisDeciles[8],
                SixMonthBasis: sixMonthsBasisDeciles[8],
                NineMonthBasis: nineMonthsBasisDeciles[8],
                TwelveMonthBasis: twelveMonthsBasisDeciles[8],
            },
            Bottom10Percent: {
                Basis: basisDeciles[9],
                ThreeMonthBasis: threeMonthsBasisDeciles[9],
                SixMonthBasis: sixMonthsBasisDeciles[9],
                NineMonthBasis: nineMonthsBasisDeciles[9],
                TwelveMonthBasis: twelveMonthsBasisDeciles[9],
            },
            Min: {
                Basis: basisDeciles[10],
                ThreeMonthBasis: threeMonthsBasisDeciles[10],
                SixMonthBasis: sixMonthsBasisDeciles[10],
                NineMonthBasis: nineMonthsBasisDeciles[10],
                TwelveMonthBasis: twelveMonthsBasisDeciles[10],
            },
        };

        const metric = params.basisAdjustment && params.basisPeriod && metrics[params.basisAdjustment][params.basisPeriod];

        const metricSerie = basisSinceStartDate.map((x) => ({ asOfDate: x.asOfDate!, value: metric, isActualValue: true } as BasisValueModel));

        return {
            correlation,
            rSquared,
            optimalLagCorrelation,
            optimalLagMonth,
            intercept,
            slope,
            basisDeciles,
            threeMonthsBasisDeciles,
            sixMonthsBasisDeciles,
            nineMonthsBasisDeciles,
            twelveMonthsBasisDeciles,
            basisAverage,
            threeMonthsBasisAverage,
            sixMonthsBasisAverage,
            nineMonthsBasisAverage,
            twelveMonthsBasisAverage,
            prices1: prices1SinceStartDate,
            basis: basisSinceStartDate,
            threeMonthsBasis: threeMonthsBasisSinceStartDate,
            sixMonthsBasis: sixMonthsBasisSinceStartDate,
            nineMonthsBasis: nineMonthsBasisSinceStartDate,
            twelveMonthsBasis: twelveMonthsBasisSinceStartDate,
            lags: lagsSinceStartDate,
            twelveMonthRollingCorrelation: twelveMonthRollingCorrelationSinceStartDate,
            regression: regressionSinceStartDate,
            metric,
            basisPeriod: params.basisPeriod,
            basisAdjustment: params.basisAdjustment,
            basisLagPeriod: params.basisLagPeriod,
            metricSerie,
        };
    },
};

export default BasisCalculatorService;
