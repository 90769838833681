import React, { useEffect, useRef } from 'react';

const useEffectAfterFirstRender = (effect: React.EffectCallback, dependencies: React.DependencyList): void => {
    const hasRendered = useRef(false);

    useEffect(() => {
        if (!hasRendered.current) {
            hasRendered.current = true;
            return undefined;
        }

        return effect();
    }, dependencies);

    // In StrictMode in development environment, components get mounted twice,
    // therefore we need to properly clean up after the first mount.
    useEffect(
        () => () => {
            hasRendered.current = false;
        },
        [],
    );
};

export default useEffectAfterFirstRender;
