/* eslint-disable no-constructor-return */

// https://stackoverflow.com/questions/61073939/how-to-import-method-into-worker-scope-in-javascript
export default class WebWorker {
    constructor(worker: () => void) {
        const code = worker.toString();
        const blob = new Blob([`(${code})()`]);
        return new Worker(URL.createObjectURL(blob));
    }
}
