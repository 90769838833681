import { LeadingIndicatorType } from '../../../../../../../Generated/Raven-Demeter';
import Dropdown from '../../../../../../Components/Form/Inputs/Dropdown';
import useLanguage from '../../../../../../Services/Language/useLanguageHook';
import { LeadingIndicatorTypeOptions } from '../../../MarketIndicatorsManagementDefinitions';
import styles from '../../MarketIndicatorFactorPage.module.scss';

interface IDataSelectorLeadingIndicatorTypeDropdownProps {
    leadingIndicatorType: LeadingIndicatorType | undefined;
    handleChange: (leadingIndicatorType?: LeadingIndicatorType) => void;
    leadingIndicatorTypeOptions: LeadingIndicatorTypeOptions[];
}

const DataSelectorLeadingIndicatorTypeDropdown: React.FC<IDataSelectorLeadingIndicatorTypeDropdownProps> = (
    props: IDataSelectorLeadingIndicatorTypeDropdownProps,
) => {
    const [translations] = useLanguage();

    return (
        <div className={styles.indicator_add_and_edit_dropdown}>
            <Dropdown
                value={props.leadingIndicatorType}
                options={props.leadingIndicatorTypeOptions}
                handleOptionChange={props.handleChange}
                label={translations.marketIndicatorsManagement.fields.fundamentalCategory}
            />
        </div>
    );
};

export default DataSelectorLeadingIndicatorTypeDropdown;
