import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { Currency, UnitOfMeasure } from '../../../Generated/Raven-Demeter';
import LinkButton, { LinkButtonType } from '../../Components/Form/Buttons/LinkButton';
import SelectInput, { SelectInputOption } from '../../Components/Form/Inputs/SelectInput';
import TextInput from '../../Components/Form/Inputs/TextInput';
import ComponentSubHeader from '../../Components/Headers/ComponentSubHeader';
import useLanguage from '../../Services/Language/useLanguageHook';
import { IUserData, userDataCurrencies, userDataUnitOfMeasures } from './UserDataDefinitions';
import styles from './UserDataPage.module.scss';

const defaultUserData: IUserData = {
    id: '',
    name: '',
    fileName: '',
    currency: Currency.Eur,
    unitOfMeasure: UnitOfMeasure.MetricTon,
    data: [],
};

interface IUserDataFormProps {
    open: boolean;
    value?: IUserData;
    onClose: () => void;
    onChange: (value: IUserData) => void;
}

const UserDataForm: FC<IUserDataFormProps> = (props: IUserDataFormProps) => {
    const [translations] = useLanguage();
    const [name, setName] = useState<string>(defaultUserData.name);
    const [fileName, setFileName] = useState<string>(defaultUserData.fileName);
    const [currency, setCurrency] = useState<Currency>(defaultUserData.currency);
    const [unitOfMeasure, setUnitOfMeasure] = useState<UnitOfMeasure>(defaultUserData.unitOfMeasure);
    const [isDisabled, setDisabled] = useState(true);

    const currencyOptions: SelectInputOption<Currency>[] = userDataCurrencies.map((currencyValue) => ({
        label: translations.currency[currencyValue],
        value: currencyValue,
    }));
    const unitOfMeasureOptions: SelectInputOption<UnitOfMeasure>[] = userDataUnitOfMeasures.map((unitOfMeasureValue) => ({
        label: translations.unitOfMeasure[unitOfMeasureValue],
        value: unitOfMeasureValue,
    }));

    useEffect(() => {
        setName(props.value?.name ?? defaultUserData.name);
        setFileName(props.value?.fileName ?? '');
        setCurrency(props.value?.currency ?? defaultUserData.currency);
        setUnitOfMeasure(props.value?.unitOfMeasure ?? defaultUserData.unitOfMeasure);
    }, [props.value]);

    useEffect(() => {
        if (name && currency && unitOfMeasure) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    }, [name, currency, unitOfMeasure]);

    const handleChange = () => {
        const value = { ...(props.value ?? defaultUserData), name, fileName, currency, unitOfMeasure };
        props.onChange(value);
    };

    const handleExited = () => {
        setName(defaultUserData.name);
        setFileName('');
        setCurrency(defaultUserData.currency);
        setUnitOfMeasure(defaultUserData.unitOfMeasure);
    };

    return (
        <Dialog open={props.open} onClose={props.onClose} TransitionProps={{ onExited: handleExited }} sx={{ '& .MuiDialog-paper': { maxWidth: '575px' } }}>
            <DialogTitle>
                <div className={styles.user_data_upload_form_header}>
                    <ComponentSubHeader title={translations.userData.form.header} />
                    <IconButton onClick={props.onClose} size="small">
                        <CloseIcon fontSize="small" />
                    </IconButton>
                </div>
            </DialogTitle>
            <DialogContent>
                <div className={styles.user_data_upload_form_content}>
                    <div className={styles.user_data_upload_form_content_container}>
                        <div className={styles.user_data_upload_form_content_row}>
                            <div className={styles.user_data_upload_form_content_cell_full_width}>
                                <TextInput
                                    title={translations.userData.form.name}
                                    value={name}
                                    handleTextChange={(nameValue) => setName(nameValue)}
                                    errorMessage={translations.userData.form.invalidName}
                                    required
                                />
                            </div>
                        </div>
                        <div className={styles.user_data_upload_form_content_row}>
                            <div className={styles.user_data_upload_form_content_cell_half_width}>
                                <SelectInput
                                    value={unitOfMeasure}
                                    options={unitOfMeasureOptions}
                                    handleOptionChange={(unitOfMeasureValue) => setUnitOfMeasure(unitOfMeasureValue)}
                                    title={translations.words.unit}
                                />
                            </div>
                            <div className={styles.user_data_upload_form_content_cell_half_width}>
                                <SelectInput
                                    value={currency}
                                    options={currencyOptions}
                                    handleOptionChange={(currencyValue) => setCurrency(currencyValue)}
                                    title={translations.words.currency}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <div className={styles.user_data_upload_form_actions}>
                    <LinkButton title={translations.actions.cancel} type={LinkButtonType.White} onClick={props.onClose} />
                    <LinkButton title={translations.actions.set} type={LinkButtonType.Blue} disabled={isDisabled} onClick={handleChange} />
                </div>
            </DialogActions>
        </Dialog>
    );
};
export default UserDataForm;
