import { useMemo } from 'react';
import languageService from '../../Refactor/Services/Language/LanguageService';
import styles from './DataSourceTag.module.scss';

interface IDataSourceTagProps {
    value?: string | string[] | null;
}

const DataSourceTag: React.FC<IDataSourceTagProps> = (props: IDataSourceTagProps) => {
    const dataSourceString = useMemo(() => {
        if (!props.value) {
            return ' ';
        }

        if (Array.isArray(props.value)) {
            return props.value.filter((value, index, array) => array.indexOf(value) === index).join(', ');
        }

        return props.value;
    }, [props.value]);

    return (
        <p data-testid="dataSourceTag" className={styles.data_source_tag}>
            {dataSourceString !== ' ' && `${languageService.translate('Source')}: ${languageService.multiWordTranslate(dataSourceString)}`}
            {/* We need to take up some space so we can auto-size any parent elements. */}
            {dataSourceString === ' ' && <>&nbsp;</>}
        </p>
    );
};

export default DataSourceTag;
