import { DemeterFilterTimeSpan } from '../../Generated/Raven-Demeter';

const timeSpreadButtonsOneYearThroughAll = [
    DemeterFilterTimeSpan.OneYear,
    DemeterFilterTimeSpan.FiveYears,
    DemeterFilterTimeSpan.TenYears,
    DemeterFilterTimeSpan.TwentyYears,
    DemeterFilterTimeSpan.All,
];

// get current browser location first time user accesses Demeter
const { search } = window.location;
const location = search.substring(search.indexOf('Demeter/app/') + 12);
const page = location.charAt(0).toUpperCase() + location.slice(1);

export const breadCrumbs = {
    page,
    regionOfFocus: '',
    subRegionOfFocus: '',
    commodityOfFocus: '',
    commodityGroupOfFocus: '',
    dataSourceOfFocus: '',
    dataTarget: '',
};

export default timeSpreadButtonsOneYearThroughAll;
