import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import ErrorIcon from '@mui/icons-material/Error';
import ReportProblemRoundedIcon from '@mui/icons-material/ReportProblemRounded';
import moment from 'moment';
import { Link } from 'react-router-dom';
import scssVariables from '../../../../Config.module.scss';
import formattingService from '../../../../Core/Formatting/FormattingService';
import applicationConstants from '../../../../Core/Utility/ApplicationConstants';
import { DemeterUserModel, DemeterUserStatus, DemeterUserType, ImportDemeterUserResult } from '../../../../Generated/Raven-Demeter';
import NavigationRoutes from '../../../../Layouts/NavigationRoutes';
import languageService from '../../../Services/Language/LanguageService';
import { translationKeys } from '../../../Services/Language/TranslationDefinitions';
import styles from './UsersPage.module.scss';

const expiringDays = 7;

// Renderer functions.
export type RendererParameters = {
    data: DemeterUserModel;
};

const fullNameCellRenderer = (parameters: RendererParameters) => {
    const { fullName, demeterUserTrialGuid } = parameters.data;

    return (
        <Link style={{ color: scssVariables.agGridLinkLolor }} to={`${NavigationRoutes.Users}/${demeterUserTrialGuid}/edit`}>
            {fullName}
        </Link>
    );
};

const trialExpirationDateCellRenderer = (parameters: RendererParameters) => {
    if (!parameters.data.trialExpirationDate) {
        return applicationConstants.TablePlaceholderZeroOrEmpty;
    }

    return formattingService.toLongFormatDate(new Date(parameters.data.trialExpirationDate));
};

const userStatusCellRenderer = (parameters: RendererParameters) => {
    const style = { backgroundColor: '', color: scssVariables.mainTextColor };
    const { userStatus, userType, trialExpirationDate } = parameters.data;

    let status = languageService.translate(translationKeys.users.status[userStatus]);
    switch (userStatus) {
        case DemeterUserStatus.Active:
            if (userType !== DemeterUserType.Regular) {
                style.backgroundColor = scssVariables.activeBlockColor;
                break;
            }

            if (moment(trialExpirationDate).diff(moment(), 'days') < expiringDays) {
                style.backgroundColor = scssVariables.expiringBlockColor;
                status = languageService.translate(translationKeys.users.status.Expiring);
                break;
            }

            style.backgroundColor = scssVariables.trialBlockColor;
            style.color = scssVariables.plainWhite;
            status = languageService.translate(translationKeys.users.roles.Regular);
            break;
        case DemeterUserStatus.Deactivated:
            style.backgroundColor = scssVariables.deactivatedBlockColor;
            break;
        case DemeterUserStatus.Expired:
            style.backgroundColor = scssVariables.expiredBlockColor;
            break;
        case DemeterUserStatus.Pending:
        default:
            style.backgroundColor = scssVariables.pendingBlockColor;
            break;
    }

    return (
        <div className={styles.users_status_field} style={style}>
            {status}
        </div>
    );
};

const lastUseDateCellRenderer = (parameters: RendererParameters) => {
    if (!parameters.data.lastUseDate) {
        return applicationConstants.TablePlaceholderZeroOrEmpty;
    }

    return formattingService.toTimeAgo(parameters.data.lastUseDate);
};

// Column definitions.
export const usersColumnDefinitions = [
    {
        field: 'fullName',
        headerValueGetter: () => languageService.translate(translationKeys.users.fields.name),
        minWidth: 100,
        cellRenderer: fullNameCellRenderer,
        pinned: 'left',
        lockPinned: true,
    },
    {
        field: 'email',
        headerValueGetter: () => languageService.translate(translationKeys.users.fields.email),
        minWidth: 100,
    },
    {
        field: 'phone',
        headerValueGetter: () => languageService.translate(translationKeys.users.fields.phone),
        minWidth: 50,
        hide: true,
    },
    {
        field: 'companyName',
        headerValueGetter: () => languageService.translate(translationKeys.users.fields.company),
        minWidth: 100,
        cellRenderer: (parameters: RendererParameters) => parameters.data.companyName ?? applicationConstants.TablePlaceholderZeroOrEmpty,
    },
    {
        field: 'userType',
        headerValueGetter: () => languageService.translate(translationKeys.users.fields.role),
        minWidth: 100,
        cellRenderer: (parameters: RendererParameters) =>
            languageService.translate(translationKeys.users.roles[parameters.data.userType]) || applicationConstants.TablePlaceholderZeroOrEmpty,
    },
    {
        field: 'trialExpirationDate',
        headerValueGetter: () => languageService.translate(translationKeys.users.fields.expiration),
        minWidth: 100,
        cellRenderer: trialExpirationDateCellRenderer,
    },
    {
        field: 'userStatus',
        headerValueGetter: () => languageService.translate(translationKeys.users.fields.status),
        minWidth: 160,
        maxWidth: 160,
        cellRenderer: userStatusCellRenderer,
    },
    {
        field: 'lastUseDate',
        headerValueGetter: () => languageService.translate(translationKeys.users.fields.lastUseDate),
        minWidth: 100,
        cellRenderer: lastUseDateCellRenderer,
    },
    {
        field: 'actions',
        headerValueGetter: () => languageService.translate(translationKeys.words.actions),
        minWidth: 100,
        pinned: 'right',
        lockPinned: true,
        cellRenderer: undefined,
    },
    {
        field: 'marketPrecision',
        headerValueGetter: () => languageService.translate(translationKeys.users.fields.marketPrecision),
        minWidth: 100,
        hide: true,
    },
    {
        field: 'subscribedDate',
        headerValueGetter: () => languageService.translate(translationKeys.users.fields.subscribedDate),
        minWidth: 100,
        hide: true,
    },
];

export const usersColumnOptions = {
    resizable: true,
    autoHeaderHeight: true,
    wrapHeaderText: true,
    flex: 1,
    rowDragManaged: true,
    animateRows: true,
    wrapText: true,
    enableCellChangeFlash: true,
    headerClass: 'ag-header-cell-flex-start',
    sortable: false,
};

export const usersImportResultsColumnDefinitions = [
    {
        field: 'name',
        headerValueGetter: () => languageService.translate(translationKeys.users.fields.name),
        cellRenderer: (params: { data: ImportDemeterUserResult }) => {
            const element = params.data;
            return `${element.firstName} ${element.lastName}`;
        },
    },
    {
        field: 'email',
        headerValueGetter: () => languageService.translate(translationKeys.users.fields.email),
    },
    {
        headerValueGetter: () => languageService.translate(translationKeys.users.fields.importStatus),
        cellRenderer: (params: { data: ImportDemeterUserResult }) => {
            if (params.data.isSuccess) {
                return (
                    <div className={styles.import_page_statusCellRenderStyle}>
                        <CheckCircleRoundedIcon color="success" />
                        <p>{languageService.translate(translationKeys.users.import.success)}</p>
                    </div>
                );
            }

            if (params.data.message === 'User already exists.') {
                return (
                    <div className={styles.import_page_statusCellRenderStyle}>
                        <ReportProblemRoundedIcon color="warning" />
                        <p>{languageService.translate(translationKeys.users.import.skipped)}</p>
                    </div>
                );
            }

            return (
                <div className={styles.import_page_statusCellRenderStyle}>
                    <ErrorIcon color="error" />
                    <p>
                        {languageService.translate(translationKeys.users.import.failed)} - {params.data.message}
                    </p>
                </div>
            );
        },
    },
];
