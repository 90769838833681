import Close from '@mui/icons-material/Close';
import { Modal } from '@mui/material';
import { Children } from 'react';
import styles from './ChartPopOutModal.module.scss';
import ChartsEnum from './ChartsEnum';
import { IChartPopOutModalProps } from './InterfaceChartPopOutModal';

// eslint-disable-next-line no-confusing-arrow
const ChartPopOutModal: React.FC<IChartPopOutModalProps> = ({ children, chartNameRef, openChart, closeChart }) =>
    openChart ? (
        <>
            <Modal open={openChart} onClose={closeChart} disableEnforceFocus>
                <div className={styles.chart_popout}>
                    <div className={`${styles.chart_popout_close} ${chartNameRef === ChartsEnum.CommodityChart && styles.chart_popout_alignCloseBtn}`}>
                        <Close className={styles.chart_popout_close_icon} onClick={closeChart} />
                    </div>
                    {Children.only(children)}
                </div>
            </Modal>
            {Children.only(children)}
        </>
    ) : (
        Children.only(children)
    );

export default ChartPopOutModal;
