import { DemeterTableDefinitionModel, DemeterTableDefinitionRegionModel, DemeterTableDefinitionType } from '../../../../Generated/Raven-Demeter/api';
import useRegionCommodityNavigation, { IRegionCommoditySelection } from './useRegionCommodityNavigationHook';
import useTableDefinition from './useTableDefinitionHook';

// Gets the table definition based on the navigation.
const useTableDefinitionNavigation = (
    tableDefinitionType: DemeterTableDefinitionType,
): [
    tableDefinitionRegion?: DemeterTableDefinitionRegionModel,
    tableDefinitionCommodity?: DemeterTableDefinitionModel,
    regionCommoditySelection?: IRegionCommoditySelection,
] => {
    const regionCommoditySelection = useRegionCommodityNavigation();
    const [tableDefinitionRegion, tableDefinitionCommodity] = useTableDefinition(tableDefinitionType, regionCommoditySelection);

    return [tableDefinitionRegion, tableDefinitionCommodity, regionCommoditySelection];
};

export default useTableDefinitionNavigation;
