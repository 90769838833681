import { useEffect, useMemo, useState } from 'react';
import formattingService from '../../../../Core/Formatting/FormattingService';
import { CommodityWeeklyPriceSummaryModel, DemeterCommodity } from '../../../../Generated/Raven-Demeter/api';
import useLanguage from '../../../Services/Language/useLanguageHook';
import styleService from '../../../Services/Styles/StyleService';
import PricesSummaryTableWrapper from '../PricesSummaryTableWrapper';
import styles from '../PriceSummaries.module.scss';
import Sparkline from '../Sparkline';

interface IEuropeanUnionWeeklySummaryBySubRegionsProps {
    summaryData?: CommodityWeeklyPriceSummaryModel[];
}

const EuropeanUnionWeeklySummaryBySubRegions: React.FC<IEuropeanUnionWeeklySummaryBySubRegionsProps> = (
    props: IEuropeanUnionWeeklySummaryBySubRegionsProps,
) => {
    const [translations, translate] = useLanguage();

    const columnHeaders = useMemo(
        () => [
            '',
            formattingService.toDayMonth(props.summaryData ? new Date(props.summaryData[0].previousWeekPrice.asOfDate) : '') ?? '',
            formattingService.toDayMonth(props.summaryData ? new Date(props.summaryData[0].asOfDate) : '') ?? '',
            translations.words.change,
            translations.fields.percentChange,
        ],
        [props.summaryData],
    );

    const [filteredCommodities, setFilteredCommodities] = useState<DemeterCommodity[]>([]);
    const summaryDataFiltered = useMemo(() => props.summaryData ?? [], [props.summaryData]);

    useEffect(() => {
        if (!props.summaryData) {
            return;
        }

        const commodities: DemeterCommodity[] = [];
        props.summaryData?.forEach((row: CommodityWeeklyPriceSummaryModel) => {
            if (row.subRegion && !commodities.some((addedCommodity) => addedCommodity === row.commodity)) {
                commodities.push(row.commodity);
            }
        });

        setFilteredCommodities(commodities);
    }, [props.summaryData]);

    return (
        <>
            {filteredCommodities.map((choosenCommodity: DemeterCommodity) => (
                <PricesSummaryTableWrapper
                    className={styles.price_summary_table_cheese_indices}
                    loading={!props.summaryData || props.summaryData.length === 0}
                    columnHeaders={[translations.commodity[choosenCommodity], ...columnHeaders]}
                    largeFirstHeader
                >
                    <>
                        {summaryDataFiltered
                            .filter((row) => row.commodity === choosenCommodity)
                            .map((row: CommodityWeeklyPriceSummaryModel) => {
                                const { historicalSummary, currentWeekPrice, subRegion } = row;
                                const firstAndLastStyleOverride = subRegion
                                    ? styles.master_bold
                                    : styles.price_summary_table_cheese_indices_regular_font_weight;
                                const changeClassname = styleService.getPositiveOrNegativeClass(currentWeekPrice.netChange);

                                return (
                                    <>
                                        <p className={firstAndLastStyleOverride}>
                                            {subRegion ? translate(subRegion) : translations.dashboard.priceSummary.europeanUnion.headers.averageIndex}
                                        </p>
                                        <p className={styles.price_summary_sparkline}>
                                            <Sparkline data={row.weeklyValues} />
                                        </p>
                                        <p className={firstAndLastStyleOverride}>{formattingService.toPriceString(historicalSummary.priceOneWeekAgo)}</p>
                                        <p className={firstAndLastStyleOverride}>{formattingService.toPriceString(historicalSummary.priceCurrentWeek)}</p>
                                        <p className={`${changeClassname} ${firstAndLastStyleOverride}`}>
                                            {formattingService.toPriceString(currentWeekPrice.netChange)}
                                        </p>
                                        <p className={`${changeClassname} ${firstAndLastStyleOverride}`}>
                                            {formattingService.toPercent(currentWeekPrice.percentChange)}
                                        </p>
                                    </>
                                );
                            })}
                    </>
                </PricesSummaryTableWrapper>
            ))}
        </>
    );
};

export default EuropeanUnionWeeklySummaryBySubRegions;
