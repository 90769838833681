import Dropdown from '../../../../../../Components/Form/Inputs/Dropdown';
import useLanguage from '../../../../../../Services/Language/useLanguageHook';
import { ForwardCurveParameters, shortTermContractNumberOptions, UpsertMarketIndicatorFactorRequest } from '../../../MarketIndicatorsManagementDefinitions';
import styles from '../../MarketIndicatorFactorPage.module.scss';

interface IMarketFactorShortTermContractNumberDropdownProps {
    upsertMarketIndicatorFactorRequest: UpsertMarketIndicatorFactorRequest;
    setUpsertMarketIndicatorFactorRequest: (value: UpsertMarketIndicatorFactorRequest) => void;
}

const MarketFactorShortTermContractNumberDropdown: React.FC<IMarketFactorShortTermContractNumberDropdownProps> = (
    props: IMarketFactorShortTermContractNumberDropdownProps,
) => {
    const [translations] = useLanguage();

    return (
        <div className={styles.indicator_add_and_edit_dropdown}>
            <Dropdown
                value={(props.upsertMarketIndicatorFactorRequest.parameters as ForwardCurveParameters).shortTermContractNumber}
                options={shortTermContractNumberOptions}
                handleOptionChange={(value) => {
                    props.setUpsertMarketIndicatorFactorRequest({
                        ...props.upsertMarketIndicatorFactorRequest,
                        parameters: {
                            ...props.upsertMarketIndicatorFactorRequest.parameters,
                            shortTermContractNumber: value,
                        },
                    });
                }}
                label={translations.marketIndicatorsManagement.fields.shortTermContractNumber}
            />
        </div>
    );
};

export default MarketFactorShortTermContractNumberDropdown;
