import React from 'react';
import checkPng from '../../Assets/Registration/check.png';
import styles from './RegistrationPage.module.scss';

interface IRegistrationCheckmarkListProps {
    list: string[];
}

const RegistrationCheckmarkList: React.FC<IRegistrationCheckmarkListProps> = (props: IRegistrationCheckmarkListProps) => (
    <>
        {props.list.map((item) => (
            <div className={styles.registration_self_form_benefit}>
                <img className={styles.registration_self_form_benefit_image} src={checkPng} alt="#" />
                <p className={styles.registration_self_form_benefit_text}>{item}</p>
            </div>
        ))}
    </>
);

export default RegistrationCheckmarkList;
