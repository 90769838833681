import { getConfiguredCache } from 'money-clip';
import applicationSettings from '../Settings/ApplicationSettings';

class CacheService {
    private internalCache: any;

    constructor() {
        this.internalCache = getConfiguredCache({
            version: applicationSettings.applicationVersion,
            maxAge: applicationSettings.isDevelopmentLike ? 30000 : 3600000, // 5 minutes/1 hour.
            name: applicationSettings.applicationName,
        });
    }

    // TODO: Allow setting an expiration window or expired time.
    set = <T>(key: string, value: T): Promise<void> => this.internalCache.set(key, value, { version: applicationSettings.applicationVersion });

    get = <T>(key: string): Promise<T> => this.internalCache.get(key, { version: applicationSettings.applicationVersion }) as Promise<T>;

    delete = (key: string): Promise<void> => this.internalCache.del(key, { version: applicationSettings.applicationVersion }) as Promise<void>;
}

const cacheService = new CacheService();

export default cacheService;
