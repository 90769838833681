/* eslint-disable react/jsx-no-useless-fragment */
import { useMemo } from 'react';
import formattingService from '../../../../Core/Formatting/FormattingService';
import { CommoditySpotPriceSummaryModel } from '../../../../Generated/Raven-Demeter';
import useLanguage from '../../../Services/Language/useLanguageHook';
import PricesSummaryTableWrapper from '../PricesSummaryTableWrapper';
import styles from '../PriceSummaries.module.scss';
import Sparkline from '../Sparkline';

interface ICmeWeeklySummaryProps {
    summaryData?: CommoditySpotPriceSummaryModel[];
}

const CmeSpotSummaryWeekly: React.FC<ICmeWeeklySummaryProps> = (props: ICmeWeeklySummaryProps) => {
    const [translations] = useLanguage();

    const columnHeaders = useMemo(
        () => [
            translations.dashboard.priceSummary.cme.headers.weekly,
            '',
            translations.words.monday,
            translations.words.tuesday,
            translations.words.wednesday,
            translations.words.thursday,
            translations.words.friday,
            translations.dashboard.priceSummary.cme.fields.currentWeekAverage,
            translations.dashboard.priceSummary.cme.fields.priorWeekAverage,
            translations.dashboard.priceSummary.cme.fields.weekVolume,
        ],
        [],
    );

    return (
        <PricesSummaryTableWrapper
            className={styles.price_summary_table_weekly}
            loading={!props.summaryData || props.summaryData.length === 0}
            columnHeaders={columnHeaders}
            largeFirstHeader
        >
            <>
                {props.summaryData?.map((row: CommoditySpotPriceSummaryModel) => {
                    const { weeklySummary, commodity } = row;

                    return (
                        <>
                            <p>{translations.commodity[commodity]}</p>
                            <p className={styles.price_summary_sparkline}>
                                <Sparkline data={row.weeklyValues} />
                            </p>
                            <p>{formattingService.toPriceString(weeklySummary.priceMonday)}</p>
                            <p>{formattingService.toPriceString(weeklySummary.priceTuesday)}</p>
                            <p>{formattingService.toPriceString(weeklySummary.priceWednesday)}</p>
                            <p>{formattingService.toPriceString(weeklySummary.priceThursday)}</p>
                            <p>{formattingService.toPriceString(weeklySummary.priceFriday)}</p>
                            <p>{formattingService.toPriceString(weeklySummary.weeklyAverage)}</p>
                            <p>{formattingService.toPriceString(weeklySummary.previousWeeklyAverage)}</p>
                            <p>{weeklySummary.sales}</p>
                        </>
                    );
                })}
            </>
        </PricesSummaryTableWrapper>
    );
};

export default CmeSpotSummaryWeekly;
