import React from 'react';
import { Link } from 'react-router-dom';
import { NavigationRouteType } from '../../../../Layouts/NavigationRoutes';
import styles from './LinkButton.module.scss';

export enum LinkButtonType {
    White = 'WHITE',
    Blue = 'BLUE',
}

interface ILinkButtonProps {
    title: string;
    type: LinkButtonType;
    icon?: any;
    navigateTo?: NavigationRouteType;
    onClick?: () => void;
    disabled?: boolean;
    testId?: string;
}

const LinkButton: React.FC<ILinkButtonProps> = (props: ILinkButtonProps) => {
    let buttonStyle = styles.button_blue;
    let linkStyle = styles.button_link_white;

    if (props.type === LinkButtonType.Blue) {
        buttonStyle = styles.button_blue;
        linkStyle = styles.button_link_white;
    } else if (props.type === LinkButtonType.White) {
        buttonStyle = styles.button_white;
        linkStyle = styles.button_link_blue;
    }

    return (
        <button type="button" data-testid={props.testId} disabled={props.disabled} className={buttonStyle} onClick={() => props.onClick && props.onClick()}>
            {props.icon}
            {props.navigateTo ? (
                <Link className={linkStyle} to={props.navigateTo}>
                    {props.title}
                </Link>
            ) : (
                <div className={linkStyle}>{props.title}</div>
            )}
        </button>
    );
};

export default LinkButton;
