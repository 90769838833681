import { useEffect, useState } from 'react';
import Dropdown from '../../../Refactor/Components/Form/Inputs/Dropdown';
import languageService from '../../../Refactor/Services/Language/LanguageService';
import useLanguage from '../../../Refactor/Services/Language/useLanguageHook';
import styles from './ForwardCurveSpread.module.scss';

interface IForwardCurveSpreadHeader {
    header?: string;
    leftSideOfSelectionGroup?: string;
    forwardCurveOptions?: any;
    forwardCurveSelection?: string;
    forwardCurveChoose?: Function;
    setForwardCurveSelection?: Function;
}

const forwardCurveSpreadHeaderDefalutProps = {
    header: '',
    leftSideOfSelectionGroup: '',
    forwardCurveOptions: '',
    setForwardCurveSelection: () => null,
    forwardCurveSelection: '',
    forwardCurveChoose: () => null,
};

const ForwardCurveSpreadHeader: React.FC<IForwardCurveSpreadHeader> = (props) => {
    const [oldForwardCurveOptions, setOldForwardCurveOptions] = useState<any>([{ label: 'nothing' }]);
    const [translations] = useLanguage();

    useEffect(() => {
        if (
            props.forwardCurveOptions &&
            props.forwardCurveOptions.length > 0 &&
            oldForwardCurveOptions.length > 0 &&
            oldForwardCurveOptions[0].label !== props.forwardCurveOptions[0].label
        ) {
            if (
                props.leftSideOfSelectionGroup &&
                props.leftSideOfSelectionGroup.length &&
                props.leftSideOfSelectionGroup.substring(4, props.leftSideOfSelectionGroup.length) === props.forwardCurveOptions[0].label &&
                props.forwardCurveOptions.length > 1
            ) {
                props.setForwardCurveSelection!(props.forwardCurveOptions[1].label);
                setOldForwardCurveOptions(props.forwardCurveOptions);
            } else {
                setOldForwardCurveOptions(props.forwardCurveOptions);
                props.setForwardCurveSelection!(props.forwardCurveOptions[0].label);
            }
        }
    }, [props.forwardCurveOptions]);

    return (
        <div className={styles.forward_curve}>
            {props.header && <div className={styles.forward_curve_subheader}>{props.header}</div>}
            <div className={styles.forward_curve_double_dropdown}>
                <div className={styles.forward_curve_primary_selection}>{props.leftSideOfSelectionGroup} </div>
                {translations.words.vs}
                <div className={styles.forward_curve_comparison_selection}>
                    <Dropdown
                        options={props.forwardCurveOptions}
                        handleOptionChange={props.forwardCurveChoose as (value: unknown) => void}
                        placeholder={props.forwardCurveSelection ? languageService.translate(props.forwardCurveSelection) : props.forwardCurveSelection}
                        customStyles={{
                            border: 'none !important',
                            boxShadow: 'none',
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': { border: 0 },
                            '.MuiOutlinedInput-notchedOutline': { border: 0 },
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

ForwardCurveSpreadHeader.defaultProps = forwardCurveSpreadHeaderDefalutProps;

export default ForwardCurveSpreadHeader;
