import { useEffect, useMemo } from 'react';
import { DemeterMarket, DemeterRegion, DemeterTableDefinitionType } from '../../../../../../../Generated/Raven-Demeter';
import useTableDefinitionsApi from '../../../../../../Apis/Hooks/useTableDefinitionsApiHook';
import Dropdown from '../../../../../../Components/Form/Inputs/Dropdown';
import useLanguage from '../../../../../../Services/Language/useLanguageHook';
import styles from '../../MarketIndicatorFactorPage.module.scss';

interface IDataSelectorRegionDropdownProps {
    market: DemeterMarket;
    fundamentalCategory: DemeterTableDefinitionType | null;
    region?: DemeterRegion;
    handleChange: (region?: DemeterRegion) => void;
}

const DataSelectorRegionDropdown: React.FC<IDataSelectorRegionDropdownProps> = (props: IDataSelectorRegionDropdownProps) => {
    const [translations, translate] = useLanguage();
    const tableDefinitions = useTableDefinitionsApi(props.fundamentalCategory!, true, props.market);

    // Dropdown Options.
    const regionOptions = useMemo(() => {
        if (!tableDefinitions) {
            return [];
        }

        const regionDefinitions = tableDefinitions.map((definition) => ({
            label: translate(definition.displayName),
            value: definition.region,
        }));

        return regionDefinitions;
    }, [tableDefinitions, props.fundamentalCategory]);

    // If we change the available regions options and the current selected region isn't and option any more, select
    // the first region.
    useEffect(() => {
        if (!regionOptions[0]?.value || regionOptions.map((x) => x.value).includes(props.region)) {
            return;
        }

        props.handleChange(regionOptions[0].value);
    }, [regionOptions]);

    return (
        <div className={styles.indicator_add_and_edit_dropdown}>
            <Dropdown
                value={props.region}
                options={regionOptions}
                handleOptionChange={(value) => {
                    props.handleChange(value);
                }}
                label={translations.marketIndicatorsManagement.fields.region}
            />
        </div>
    );
};

export default DataSelectorRegionDropdown;
