import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import MarketPricesSlice from './Slices/MarketPricesSlice';
import SystemSlice from './Slices/SystemSlice';
import UserSlice from './Slices/UserSlice';

export const createReduxStore = () => {
    const store = configureStore({
        reducer: {
            marketPrices: MarketPricesSlice,
            system: SystemSlice,
            user: UserSlice,
        },
    });

    return store;
};

const storeInternal = createReduxStore();
export type ReduxStore = typeof storeInternal;
export type RootState = ReturnType<typeof storeInternal.getState>;
export type ApplicationDispatch = typeof storeInternal.dispatch;
export const useApplicationDispatch = (): ApplicationDispatch => useDispatch<ApplicationDispatch>();
export const useApplicationSelector: TypedUseSelectorHook<RootState> = useSelector;

const store: ReduxStore = storeInternal;
export default store;
