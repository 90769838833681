import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import Tooltip from '@mui/material/Tooltip';
import { CellClassParams } from 'ag-grid-community';
import formattingService from '../../../Core/Formatting/FormattingService';
import applicationConstants from '../../../Core/Utility/ApplicationConstants';
import { CommodityPriceModel, DemeterDataFrequency } from '../../../Generated/Raven-Demeter';
import dailySvg from '../../Assets/PhysicalPricesTableIcons/daily.svg';
import dailySvgForecast from '../../Assets/PhysicalPricesTableIcons/dailyForecast.svg';
import monthlySvg from '../../Assets/PhysicalPricesTableIcons/monthly.svg';
import monthlySvgForecast from '../../Assets/PhysicalPricesTableIcons/monthlyForecast.svg';
import weeklySvg from '../../Assets/PhysicalPricesTableIcons/weekly.svg';
import weeklySvgForecast from '../../Assets/PhysicalPricesTableIcons/weeklyForecast.svg';
import languageService from '../../Services/Language/LanguageService';
import { translationKeys } from '../../Services/Language/TranslationDefinitions';
import useLanguage from '../../Services/Language/useLanguageHook';
import styleService from '../../Services/Styles/StyleService';
import styles from './PhysicalPricesPage.module.scss';

export type RendererParameters = {
    data: PhysicalPricesCompositeModel;
    node: { data: PhysicalPricesCompositeModel };
    value: number | string;
    column: { colId: string };
};

export type PhysicalPricesCompositeModel = CommodityPriceModel & {
    id: string;
    rowIndex: number;
    selected: boolean;
};

const iconDefintionObjects = {
    [DemeterDataFrequency.Daily]: {
        currentTitle: translationKeys.physicalPrices.tooltip.daily,
        forecastTitle: translationKeys.physicalPrices.tooltip.dailyForecast,
        currentSvg: dailySvg,
        forecastSvg: dailySvgForecast,
    },
    [DemeterDataFrequency.Weekly]: {
        currentTitle: translationKeys.physicalPrices.tooltip.weekly,
        forecastTitle: translationKeys.physicalPrices.tooltip.weeklyForecast,
        currentSvg: weeklySvg,
        forecastSvg: weeklySvgForecast,
    },
    [DemeterDataFrequency.Monthly]: {
        currentTitle: translationKeys.physicalPrices.tooltip.monthly,
        forecastTitle: translationKeys.physicalPrices.tooltip.monthlyForecast,
        currentSvg: monthlySvg,
        forecastSvg: monthlySvgForecast,
    },
};

const percentFormatter = (parameters: CellClassParams) => {
    if (!parameters.value) {
        return applicationConstants.TablePlaceholderZeroOrEmpty;
    }
    return formattingService.toPercent(+parameters.value);
};

const numberFormatter = (parameters: CellClassParams) => {
    if (!parameters.value) {
        return applicationConstants.TablePlaceholderZeroOrEmpty;
    }
    return formattingService.toNumberStringWithTrailingZeros(+parameters.value, 0, 4);
};

const frequencyCellRenderer = (parameters: RendererParameters) => {
    const [, translate] = useLanguage();

    const iconsToRender: ReactJSXElement[] = parameters.node.data.priceFrequencyDefinitions.map((element) => {
        const currentIconDefinitionObject = iconDefintionObjects[element.dataFrequency as keyof typeof iconDefintionObjects];

        return (
            <Tooltip
                key={`${parameters.node.data.id}_${element.dataFrequency}`}
                title={element?.hasForecastData ? translate(currentIconDefinitionObject?.forecastTitle) : translate(currentIconDefinitionObject?.currentTitle)}
                placement="top-start"
            >
                <img src={element?.hasForecastData ? currentIconDefinitionObject?.forecastSvg : currentIconDefinitionObject?.currentSvg} alt="#" />
            </Tooltip>
        );
    });

    return <div className={styles.physical_prices_frequency_icons_container}>{iconsToRender.reverse().map((icon) => icon)}</div>;
};

export const physicalPriceColumnOptions = {
    resizable: true,
    autoHeaderHeight: true,
    wrapHeaderText: true,
    flex: 1,
    rowDragManaged: true,
    animateRows: true,
    wrapText: true,
    enableCellChangeFlash: true,
    suppressSizeToFit: true,
    minWidth: 72,
    width: 100,
};

export const physicalPricesColumnDefinitions = [
    {
        field: 'displayName',
        headerValueGetter: () => languageService.translate(translationKeys.physicalPrices.fields.name),
        width: 200,
        maxWidth: 280,
        minWidth: 200,
        pinned: 'left',
        suppressMovable: true,
    },
    {
        field: 'asOfDate',
        headerValueGetter: () => languageService.translate(translationKeys.physicalPrices.fields.date),
    },
    {
        field: 'value',
        headerValueGetter: () => languageService.translate(translationKeys.physicalPrices.fields.price),
        type: 'rightAligned',
        valueFormatter: numberFormatter,
    },
    {
        field: 'percentChange',
        headerValueGetter: () => languageService.translate(translationKeys.physicalPrices.fields.percentChange),
        cellClass: styleService.positiveOrNegativeCellClassSetter,
        type: 'rightAligned',
        valueFormatter: percentFormatter,
    },
    {
        field: 'netChange',
        headerValueGetter: () => languageService.translate(translationKeys.physicalPrices.fields.netChange),
        cellClass: styleService.positiveOrNegativeCellClassSetter,
        type: 'rightAligned',
        valueFormatter: numberFormatter,
    },
    {
        field: 'previousValue',
        headerValueGetter: () => languageService.translate(translationKeys.physicalPrices.fields.previousPrice),
        type: 'rightAligned',
        valueFormatter: numberFormatter,
    },
    {
        field: 'currencyDisplayName',
        headerValueGetter: () => languageService.translate(translationKeys.physicalPrices.fields.currency),
    },
    {
        field: 'unitOfMeasureDisplayName',
        headerValueGetter: () => languageService.translate(translationKeys.physicalPrices.fields.unit),
    },
    {
        field: 'frequency',
        headerValueGetter: () => languageService.translate(translationKeys.physicalPrices.fields.frequencyOrForcast),
        width: 150,
        cellRenderer: frequencyCellRenderer,
    },
    {
        field: 'actions',
        headerValueGetter: () => languageService.translate(translationKeys.fields.chartControl),
        maxWidth: 125,
        width: 125,
        pinned: 'right',
        suppressMovable: true,
        type: 'rightAligned',
    },
];
