/* eslint-disable class-methods-use-this */
import { CellClassParams } from 'ag-grid-community';
import styles from '../../../Main.module.scss';

class StyleService {
    getPositiveOrNegativeClass = (value: number | string | undefined | null): string => {
        if (!value) {
            return '';
        }
        const convertedValue = +value;
        if (convertedValue > 0) {
            return styles.master_positive;
        }
        if (convertedValue < 0) {
            return styles.master_negative;
        }
        return styles.master_neutral;
    };

    positiveOrNegativeCellClassSetter = (parameters: CellClassParams) => [
        parameters.colDef.type === 'rightAligned' ? styles.master_right_align : styles.master_left_align,
        styleService.getPositiveOrNegativeClass(+parameters.value),
    ];
}

const styleService = new StyleService();

export default styleService;
