import scssVariables from '../../../../Config.module.scss';
import styles from './ValueMatrixCalculator.module.scss';
import { DecileRowDefinitions } from './ValueMatrixDefinitions';

interface ValueMatrixStickyColumnProps {
    decileRowDefinitions: DecileRowDefinitions[];
}

const ValueMatrixStickyColumn: React.FC<ValueMatrixStickyColumnProps> = (props: ValueMatrixStickyColumnProps) => (
    <div className={styles.value_matrix_sticky_column_group}>
        {props.decileRowDefinitions.map((x, index) => (
            <div
                key={x.text}
                className={styles.value_matrix_sticky_column_group_cell}
                style={{ backgroundColor: x.backgroundColor, color: x.textColor ?? scssVariables.mainTextColor }}
            >
                {index === 1 && <div className={styles.value_matrix_sticky_column_group_median} />}
                {x.text}
            </div>
        ))}
    </div>
);

export default ValueMatrixStickyColumn;
