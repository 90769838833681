import { useMemo } from 'react';
import { Currency } from '../../../../Generated/Raven-Demeter';
import useLanguage from '../../../Services/Language/useLanguageHook';
import Dropdown from './Dropdown';

type CurrencyDropdownOptionsType = { label: string; value: Currency };

interface ICurrencyDropdownProps {
    disabled?: boolean;
    currency?: Currency;
    handleChange: (value: Currency | undefined) => void;
}

const CurrencyDropdown: React.FC<ICurrencyDropdownProps> = (props): JSX.Element => {
    const [translations] = useLanguage();

    const currencyDropdownOptions = useMemo(
        () => [
            {
                label: translations.dropdown.nativeCurrency,
                value: undefined,
            },
            {
                label: translations.currency[Currency.Cad],
                value: Currency.Cad,
            },
            {
                label: translations.currency[Currency.Eur],
                value: Currency.Eur,
            },
            {
                label: translations.currency[Currency.Gbp],
                value: Currency.Gbp,
            },
            {
                label: translations.currency[Currency.Nzd],
                value: Currency.Nzd,
            },
            {
                label: translations.currency[Currency.Usd],
                value: Currency.Usd,
            },
        ],
        [translations],
    ) as CurrencyDropdownOptionsType[];

    return (
        <Dropdown
            disabled={props.disabled ?? false}
            value={props.currency}
            options={currencyDropdownOptions}
            handleOptionChange={(value: Currency | undefined) => props.handleChange(value)}
        />
    );
};

export default CurrencyDropdown;
